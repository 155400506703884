export type StandardPropertyCondition =
    (typeof standardPropertyConditions)[number]["value"];

const standardPropertyConditions = [
    {
        key: "propertyCondition.NEW",
        value: "NEW",
    },
    {
        key: "propertyCondition.RECENTLY_REFURBISHED",
        value: "RECENTLY_REFURBISHED",
    },
    {
        key: "propertyCondition.DATED",
        value: "DATED",
    },
    {
        key: "propertyCondition.UNSPECIFIED",
        value: "UNSPECIFIED",
    }
] as const;

export default standardPropertyConditions;
