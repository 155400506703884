export type CommercialPropertyType = (typeof commercialPropertyTypes)[number];

const commercialPropertyTypes = [
    "Office and Commercial Building",
    "Retail",
    "Industrial",
    "Car Dealer",
    "Shopping Centre",
    "Commercial Land",
    "Cold Storage",
    "Gastronomy",
    "Slaughterhouse",
    "Storage",
    "Other Commercial",
] as const;

export default commercialPropertyTypes;
