import {
    Button,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Heading,
    HStack,
    VStack,
    Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@chakra-ui/icons";
import { EntityType, UserType } from "../../../../../types";
import { FirebaseCertificateType } from "../../../../../utils/storage/storage";

type PropTypes = {
    progress: number;
};

const ProgressCircle = (props: PropTypes) => {
    const { progress } = props;
    let color = "";

    switch (true) {
        case progress < 20:
            color = "red.500";
            break;
        case progress < 40:
        case progress < 60:
            color = "orange.500";
            break;
        case progress < 80:
        case progress < 100:
            color = "green.500";
            break;
        default:
            color = "green.500";
            break;
    }
    return (
        <CircularProgress
            value={progress}
            color={color}
            size="5rem"
        >
            <CircularProgressLabel>{`${progress}%`}</CircularProgressLabel>
        </CircularProgress>
    );
};

type ProfileCompletionBannerProps = {
    entity: EntityType;
    user: UserType;
    userCertificates: FirebaseCertificateType[];
    sampleValuations: FirebaseCertificateType[];
    userRoles: {
        userIsCompanyAdmin: boolean;
        userIsClient: boolean;
        userIsValuer: boolean;
    };
};

const ProfileCompletionBanner = (props: ProfileCompletionBannerProps) => {
    const { t } = useTranslation(["dashboard"]);
    const navigate = useNavigate();

    const { entity, user, userRoles, userCertificates, sampleValuations } =
        props;

    const { userIsCompanyAdmin, userIsValuer } = userRoles;

    const userIsValuerAdmin = userIsValuer && userIsCompanyAdmin;

    const valuerDoesValuations = user.doesValuations;

    let hasUserProvidedContactDetails = false;

    let hasUserProvidedFirmDetails = false;

    let hasUserProvidedValuationSample = false;

    let hasValuerProvidedQualificationCertificate = false;

    const requiredValuerFirmDetails =
        entity.valuerNumberOfPartners &&
        entity.valuerYearsOfExperience &&
        (entity.piCoverPercentage > 0 ||
            entity.piCoverPercentageUnlimited ||
            entity.piCoverValue > 0 ||
            entity.piCoverValueUnlimited);

    const profileRequiredContactDetails =
        user.street &&
        user.postalCode &&
        user.country &&
        user.city &&
        user.mobileDialCode &&
        user.mobile;

    const companyRequiredContactDetails =
        entity.websiteAddress &&
        entity.street &&
        entity.postalCode &&
        entity.city &&
        entity.country &&
        entity.contactNumberDialCode &&
        entity.contactNumber;

    const percentageForFirmDetails = 30;

    const percentageForQualificationCertificate = 10;

    const percentageForValuationSample = 10;

    const percentageForContactDetails = 50;

    if (userIsValuerAdmin) {
        if (requiredValuerFirmDetails) {
            hasUserProvidedFirmDetails = true;
        } else {
            hasUserProvidedFirmDetails = false;
        }
    } else {
        hasUserProvidedFirmDetails = true;
    }

    if (userIsValuer && valuerDoesValuations) {
        if (
            user.systemStandardsOffered?.every((standard) => {
                return userCertificates.find((cert) => cert.type === standard);
            })
        ) {
            hasValuerProvidedQualificationCertificate = true;
        } else {
            hasValuerProvidedQualificationCertificate = false;
        }
    } else {
        hasValuerProvidedQualificationCertificate = true;
    }

    if (userIsValuerAdmin) {
        if (
            entity.valuationTypes?.every((propertyType) => {
                return sampleValuations.find(
                    (sample) => sample.type.toUpperCase() === propertyType
                );
            })
        ) {
            hasUserProvidedValuationSample = true;
        } else {
            hasUserProvidedValuationSample = false;
        }
    } else {
        hasUserProvidedValuationSample = true;
    }

    if (userIsCompanyAdmin) {
        if (companyRequiredContactDetails && profileRequiredContactDetails) {
            hasUserProvidedContactDetails = true;
        } else {
            hasUserProvidedContactDetails = false;
        }
    } else {
        if (profileRequiredContactDetails) {
            hasUserProvidedContactDetails = true;
        } else {
            hasUserProvidedContactDetails = false;
        }
    }

    const profileProgressCompletion = () => {
        let progress = 0;

        if (hasUserProvidedFirmDetails) {
            progress += percentageForFirmDetails;
        }
        if (hasValuerProvidedQualificationCertificate) {
            progress += percentageForQualificationCertificate;
        }
        if (hasUserProvidedValuationSample) {
            progress += percentageForValuationSample;
        }
        if (hasUserProvidedContactDetails) {
            progress += percentageForContactDetails;
        }

        return progress;
    };

    const progress = profileProgressCompletion();

    const benefits = [
        t("profileCompletion.benefitOne"),
        t("profileCompletion.benefitTwo"),
        t("profileCompletion.benefitThree"),
    ];

    if (progress !== 100) {
        return (
            <Flex
                w="100%"
                paddingInline="2.5rem"
                paddingBlock="1rem"
                backgroundColor="white"
                borderRadius="10"
                border="1px solid #e2e8f0"
                alignItems="center"
            >
                <HStack
                    spacing="6"
                    justifyItems="top"
                    w="100%"
                >
                    <ProgressCircle progress={progress} />

                    <HStack
                        w="100%"
                        flexWrap={{
                            base: "wrap",
                            lg: "nowrap",
                        }}
                        justifyContent="space-between"
                    >
                        <VStack
                            align="left"
                            spacing="1"
                        >
                            <Heading size="md">
                                {t("profileCompletion.title")}
                            </Heading>

                            <Text variant="secondary">
                                {t("profileCompletion.subtitle")}
                            </Text>

                            {userIsValuer && (
                                <VStack
                                    spacing="0"
                                    align="left"
                                >
                                    {benefits.map((benefit) => (
                                        <HStack key={benefit}>
                                            <CheckIcon color="green.500" />

                                            <Text>{benefit}</Text>
                                        </HStack>
                                    ))}
                                </VStack>
                            )}
                        </VStack>

                        <Button
                            variant="primary"
                            onClick={() => navigate("/complete-profile")}
                            minWidth="fit-content"
                        >
                            {t("profileCompletion.button")}
                        </Button>
                    </HStack>
                </HStack>
            </Flex>
        );
    }
    return null;
};

export default ProfileCompletionBanner;
