import { DeleteIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { EnquiryType, OfferType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useRejectOffer from "./useRejectOffer";

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
    offer: OfferType;
    enquiry: EnquiryType;
};

const RejectOffer = (params: ParamsType) => {
    const {
        offer: { status },
        offer,
        enquiry,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { rejectOffer, hasPermissions, confirmationModal } = useRejectOffer({
        onSuccess,
        onError,
        offer,
        enquiry,
    });

    const canReject = hasPermissions && status === "PENDING";

    return (
        <Button
            leftIcon={<DeleteIcon />}
            colorScheme={"red"}
            width={"100%"}
            isDisabled={!canReject}
            onClick={rejectOffer}
        >
            {t("domain.offer.ACTIVITIES.reject.NAME")}
            {confirmationModal}
        </Button>
    );
};

export default RejectOffer;
