import {
    Flex,
    GridItem,
    HStack,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { StandardPropertyType } from "../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { PropertyType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../common/MISSING_TRANSLATION";
import { NO_DATA } from "../../common/NO_DATA";
import Subsection from "../../layout/Subsection";
import PropertyDocumentList from "../../screens/platform/valuer/enquiry-details/PropertyDocumentList";

type Props = {
    property: PropertyType;
};

const Subtypes = ({
    type,
    property,
}: {
    type: StandardPropertyType;
    property: PropertyType;
}) => {
    const {
        agricultural,
        commercial,
        development,
        land,
        residential,
        specialRealEstate,
    } = property;

    const t = useSmartTranslation();

    let subtypes: string[] = [];
    let suffix;

    switch (type) {
        case "Agricultural":
            subtypes = agricultural;
            suffix = "residentialPropertyType";
            break;
        case "Commercial":
            subtypes = commercial;
            suffix = "commercialPropertyType";
            break;
        case "Development":
            subtypes = development;
            suffix = "developmentPropertyType";
            break;
        case "Land":
            subtypes = land;
            suffix = "landPropertyType";
            break;
        case "Residential":
            subtypes = residential;
            suffix = "residentialPropertyType";
            break;
        case "Special":
            subtypes = specialRealEstate;
            suffix = "specialPropertyType";
            break;
    }

    const dictionary = `domain.standards.${suffix}`;

    return (
        <>
            {subtypes.map((i, index) => (
                <Text
                    key={i}
                    fontSize={"sm"}
                >{`${index === 0 ? "/" : ", "} ${t(`${dictionary}.${i}`, {
                    defaultValue: MISSING_TRANSLATION,
                })}`}</Text>
            ))}
        </>
    );
};

export const PropertyDetails = (props: Props) => {
    const { property } = props;

    const t = useSmartTranslation();

    const estValue = (
        <Subsection title={t("domain.property.propertyDetails.estimateValue")}>
            <Text
                fontWeight="bold"
                color="cyan.500"
                fontSize={"sm"}
            >
                {`€${delimitNumber(property.estimateValue)}`}
            </Text>
        </Subsection>
    );

    const area = (
        <Subsection title={t("domain.property.propertyDetails.area")}>
            <HStack
                spacing={0}
                fontWeight="bold"
                color="gray.900"
                flexWrap={"wrap"}
            >
                <Text fontSize={"sm"}>
                    {`${delimitNumber(property.areaGrossInternal)}`}/
                </Text>
                <Text fontSize={"sm"}>{`${
                    property.areaOverall !== null
                        ? delimitNumber(property.areaOverall)
                        : NO_DATA
                } m²`}</Text>
            </HStack>
        </Subsection>
    );

    const conditionProperty = property.propertyCondition
        ? t(`domain.standards.propertyCondition.${property.propertyCondition}`)
        : NO_DATA;

    const condition = (
        <Subsection title={t("domain.property.propertyDetails.condition")}>
            <Text
                fontWeight="bold"
                color="gray.900"
                fontSize={"sm"}
            >
                {conditionProperty}
            </Text>
        </Subsection>
    );

    const buildYear = (
        <Subsection title={t("domain.property.propertyDetails.buildYear")}>
            <Text
                fontWeight="bold"
                color="gray.900"
                fontSize={"sm"}
            >
                {`${property.buildYear ? property.buildYear : NO_DATA}`}
            </Text>
        </Subsection>
    );

    const propertyType = (
        <Subsection>
            <HStack
                spacing={2}
                align="start"
            >
                <Text
                    variant="secondary"
                    fontSize={"sm"}
                    w={"100%"}
                    maxW={"7rem"}
                    lineHeight={"1.3rem"}
                >
                    {t("domain.property.propertyDetails.propertyType")}
                </Text>
                <VStack align="start">
                    {property.propertyTypes.map((type, i) => (
                        <VStack
                            key={i}
                            align={"start"}
                        >
                            <HStack
                                spacing={0}
                                flexWrap={"wrap"}
                            >
                                <Text
                                    fontWeight="bold"
                                    color="gray.900"
                                    fontSize={"sm"}
                                    mr={1}
                                >
                                    {t(`domain.standards.propertyType.${type}`)}
                                </Text>

                                <Subtypes
                                    type={type}
                                    property={property}
                                />
                            </HStack>
                        </VStack>
                    ))}
                </VStack>
            </HStack>
        </Subsection>
    );

    const propertyParking = property.propertyFeatures.length
        ? property.propertyFeatures
              .map((parking) =>
                  t(`domain.standards.propertyFeature.${parking}`)
              )
              .join(", ")
        : NO_DATA;

    const parking = (
        <Subsection>
            <HStack
                spacing={2}
                align="start"
            >
                <Text
                    variant="secondary"
                    fontSize={"sm"}
                    w={"100%"}
                    maxW={"7rem"}
                    lineHeight={"1.3rem"}
                >
                    {t("domain.property.propertyDetails.parking")}
                </Text>
                <Flex
                    align="start"
                    wrap="wrap"
                    gap={1}
                >
                    <Text
                        fontWeight="bold"
                        color="gray.900"
                        fontSize={"sm"}
                    >
                        {propertyParking}
                    </Text>
                </Flex>
            </HStack>
        </Subsection>
    );

    const propertyOtherFeatures = property.otherFeatures.length
        ? property.otherFeatures.join(", ")
        : NO_DATA;

    const specialFeatures = (
        <Subsection>
            <HStack
                spacing={2}
                align="start"
            >
                <Text
                    variant="secondary"
                    fontSize={"sm"}
                    w={"100%"}
                    maxW={"7rem"}
                    lineHeight={"1.3rem"}
                >
                    {t("domain.property.propertyDetails.specialFeatures")}
                </Text>
                <Flex
                    align="start"
                    wrap="wrap"
                    gap={1}
                >
                    <Text
                        fontWeight="bold"
                        color="gray.900"
                        fontSize={"sm"}
                    >
                        {propertyOtherFeatures}
                    </Text>
                </Flex>
            </HStack>
        </Subsection>
    );

    const documents = (
        <>
            <Text
                fontSize={"sm"}
                variant={"secondary"}
                mb={1}
            >
                {t("domain.property.propertyDetails.documents", {
                    count: property.documents?.length,
                })}
            </Text>
            <VStack
                align="stretch"
                spacing={"0.1rem"}
                borderRadius="8"
                overflow={"hidden"}
            >
                <PropertyDocumentList documents={property.documents} />
            </VStack>
        </>
    );

    return (
        <SimpleGrid
            columns={4}
            gap={2}
            gridTemplateColumns={"repeat(auto-fit, minmax(20%, 1fr))"}
        >
            {estValue}
            {area}
            {condition}
            {buildYear}
            <GridItem colSpan={4}>{propertyType}</GridItem>
            <GridItem colSpan={4}>{parking}</GridItem>
            <GridItem colSpan={4}>{specialFeatures}</GridItem>
            <GridItem colSpan={4}>{documents}</GridItem>
        </SimpleGrid>
    );
};
