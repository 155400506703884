export const translations = {
    de: {
        offerDetailsTitle: "Angebots-Details",
        offerAttachmentsTitle: "Angebots-Anhänge",
        valuerCompetences: {
            title: "Gutachter-Kompetenzen",
            valuationTypes: "Bewertungsumfang",
            specialValuationTypes: "Spezielle Bewertungsumfänge",
            valuationOccasions: "Bewertungsanlässe",
            propertyTypes: "Immobilienarten",
        },
        valuerCompanyDetails: {
            title: "Gutachter-Firmen-Details",
            experience: "Erfahrung",
            partners: "Gutachter",
            piCoverAbsolute: "Haftpflicht - max. Deckungssumme",
            piCoverPercentage: "Haftpflicht - max. Deckungsanteil (%)",
            piAgreement: "Sonderkonditionen HP",
        },
        valuerDetails: {
            title: "Gutachter-Details",
            propertyDistance: "Entfernung zur Immobilie {{distance}} km",
            propertyClosestDistance: "Entfernung zur nächstgelegenen Immobilie {{distance}} km",
        },
        valuerQualifications: {
            certificates: "Hochgeladene Zertifikate",
            previewCertificate: "Ansicht Nachweis",
            title: "Qualifikationen",
            updated: "Aktualisiert: {{date}}",
        },
    },
    en: {
        offerDetailsTitle: "Offer Details",
        offerAttachmentsTitle: "Offer attachments",
        valuerCompetences: {
            title: "Valuer Competences",
            valuationTypes: "Valuation Scope",
            specialValuationTypes: "Specific Valuation Scopes",
            valuationOccasions: "Valuation Occasions",
            propertyTypes: "Property types",
        },
        valuerCompanyDetails: {
            title: "Valuer Company Details",
            experience: "Experience",
            partners: "Valuers",
            piCoverAbsolute: "PI Cover - max. absolute",
            piCoverPercentage: "PI Cover - max %",
            piAgreement: "Special PI Agreements",
        },
        valuerDetails: {
            title: "Valuer Details",
            propertyDistance: "Distance to property {{distance}} km",
            propertyClosestDistance: "Distance to closest property {{distance}} km",
        },
        valuerQualifications: {
            certificates: "Uploaded certificates",
            previewCertificate: "View certificate",
            title: "Qualifications",
            updated: "Updated: {{date}}",
        },
    },
};
