import useMutationHook from "./useMutationHook";

type Params = {
    onSuccess: (data: google.maps.StreetViewResponse) => void;
    onError?: (error: Error) => void;
};

type LatLng = {
    lat: number;
    lng: number;
};

export const useGoogleStreetViewGetPano = (params: Params) => {
    const { onError, onSuccess } = params;

    const sv = new google.maps.StreetViewService();

    const mutation = useMutationHook({
        mutationFunction: (location: LatLng) =>
            sv.getPanorama({
                location,
                radius: 50,
            }),
        refreshes: [],
        onSuccess,
        onError,
        suppressErrorToast: true,
    });

    return mutation;
};
