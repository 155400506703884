import Dashboard from "./dashboard/Dashboard";
import EnquiryDetails from "./enquiry-details/EnquiryDetails";
import { ClientEnquiryList } from "./enquiry-list/EnquiryList";
import OfferDetailsScreen from "./offer-details/OfferDetailsScreen";

export const ClientScreens = {
    Dashboard,
    EnquiryDetails,
    OfferDetails: OfferDetailsScreen,
    EnquiryList: ClientEnquiryList,
};
