import { CommercialPropertyType } from "../../../../../common/vars/valuationsAndMembershipTypes/commercialPropertyTypes";

type TranslationNSObject = {
    [key in CommercialPropertyType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const commercialPropertyTypeTranslations: Translations = {
    de: {
        "Car Dealer": "Autohändler",
        "Cold Storage": "Kühlhaus",
        "Commercial Land": "Gewerbefläche",
        "Gastronomy": "Gastronomie",
        Industrial: "Industrie",
        "Office and Commercial Building": "Büro- und Geschäftsgebäude",
        "Other Commercial": "Sonstiges Gewerbe",
        Retail: "Einzelhandel",
        "Shopping Centre": "Einkaufszentrum",
        Slaughterhouse: "Schlachthof",
        Storage: "Lagerung",
    },
    en: {
        "Car Dealer": "Car Dealer",
        "Cold Storage": "Cold Storage",
        "Commercial Land": "Commercial Land",
        "Gastronomy": "Gastronomy",
        Industrial: "Industrial",
        "Office and Commercial Building": "Office and Commercial Building",
        "Other Commercial": "Other Commercial",
        Retail: "Retail",
        "Shopping Centre": "Shopping Centre",
        Slaughterhouse: "Slaughterhouse",
        Storage: "Storage",
    },
};
