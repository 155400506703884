export type StandardSystemStandardsOffered =
    (typeof standardSystemStandardsOffered)[number];

export type StandardSystemStandardsOfferedWithOther =
    (typeof standardSystemStandardsOfferedWithOtherAndNone)[number];

/*
These are the values the field in the Enquiry can have.
To be used eg. as choices for Clients when they create an Enquiry.

If the client doesn't get to see a choice for an Enquiry Standard,
eg. in a Direct Enquiry, it remains the empty string.

"Other" is an option in the UI to indicate that a non-system-standard
is requested, which is entered manually into a different field
*/
export const standardSystemStandardsOfferedWithOtherAndNone = [
    "RICS - Red Book",
    "Tegova - Blue Book",
    "IVSC - White Book",
    "HypZert",
    "ValueV",
    "BDSF",
    "DESAG",
    "TAF",
    "AI",
    "ASA",
    "DIN 17024",
    "Other",
    "",
] as const;

/*
These are the actual standards in the system.
To be used eg. as choices for Valuers when they edit their Account.
*/
const standardSystemStandardsOffered = [
    "RICS - Red Book",
    "Tegova - Blue Book",
    "IVSC - White Book",
    "HypZert",
    "ValueV",
    "BDSF",
    "DESAG",
    "TAF",
    "AI",
    "ASA",
    "DIN 17024",
] as const;

export default standardSystemStandardsOffered;
