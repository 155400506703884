import { SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FormikSelect, FormikInput } from "../../../../common/forms";
import standardMaxOfficeDistance from "../../../../common/vars/valuationsAndMembershipTypes/standardMaxOfficeDistance";
import standardMinimumPartners from "../../../../common/vars/valuationsAndMembershipTypes/standardMinimumPartners";
import standardRequiredYearsOfExperience from "../../../../common/vars/valuationsAndMembershipTypes/standardRequiredYearsOfExperienceRequired";

const Requirements = () => {
    const { t } = useTranslation(["enquiryform", "formik", "common"]);

    return (
        <SimpleGrid
            columns={2}
            spacing={4}
        >
            <FormikSelect
                name="valuerExperience"
                label={t(
                    "newEnquiry.valuerSection.requirements.minPostQualiExperience"
                )}
                options={standardRequiredYearsOfExperience.map(
                    (experience) => ({
                        label: t(experience.label, {
                            ns: "formik",
                            defaultValue: experience.label,
                        }),
                        value: experience.value,
                    })
                )}
            />

            <FormikSelect
                name="valuerMinimumPartners"
                label={t("newEnquiry.valuerSection.requirements.minPartners")}
                options={standardMinimumPartners.map((partners) => ({
                    label: t(partners.label, {
                        ns: "formik",
                        defaultValue: partners.label,
                    }),
                    value: partners.value,
                }))}
            />

            <FormikSelect
                name="valuerMaxOfficeDistance"
                label={t(
                    "newEnquiry.valuerSection.requirements.maxDistanceProperty"
                )}
                options={standardMaxOfficeDistance.map((distance) => ({
                    label: t(distance.label, {
                        ns: "formik",
                        defaultValue: distance.label,
                    }),
                    value: distance.value,
                }))}
            />

            <FormikInput
                name="valuerMinimumPICoverPercentage"
                label={t("newEnquiry.valuerSection.requirements.minPICover")}
                placeholder={t(
                    "newEnquiry.valuerSection.requirements.minPICover"
                )}
                prefix="%"
                type="number"
                min="1"
            />
        </SimpleGrid>
    );
};

export default Requirements;
