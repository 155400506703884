import { enforceEntityPICover } from "./enforceEntityPICover";
import { translations } from "./Offer.i18n";
import { NoOffersView } from "./NoOffersView";
import { OfferMessage } from "./offer-details/OfferMessage";
import { OfferProperty } from "./offer-details/OfferProperty";
import { OfferStatus } from "./offer-details/OfferStatus";
import { offerColumns } from "./columns/OfferColumns";
import { OfferComponent } from "./OfferComponent";
import { calculateDeliveryDate } from "./offerFunctions";
import { OfferStatusText } from "./OfferStatusText";

export const Offer = {
    calculateDeliveryDate,
    NoOffersView: NoOffersView,
    TableColumns: offerColumns,
    translations: translations,
    Offer: OfferComponent,
    StatusText: OfferStatusText,
    enforceEntityPICover,
    details: {
        OfferMessage,
        OfferProperty,
        OfferStatus,
    },
};

export type PICoverParams = {
    piCoverSpecialArrangementsAvailable: boolean;
    defaultPICoverValue: number;
    defaultPICoverPercentage: number;
};
