export const offerColumnTranslations = {
    de: {
        check: "Anzeigen",
        days: "Tage",
        delivery: "Lieferung bis",
        deliveryAndTimeline: "Lieferung (Arbeitstage)",
        hasCommentTooltip:
            "Der Gutachter hat zusätzliche Informationen bereitgestellt",
        piCoverValue: "Haftpflicht [€]",
        piCoverPercentage: "Haftpflicht [%]",
        specialPi: "Sonderkonditionen HP",
        earliestAvailability: "Früheste Verfügbarkeit",
        workingTime: "Bearbeitungszeit",
        price: "Preis",
        valuer: "Gutachter",
        valuerTooltip: "Ein Mitglied Ihres Gutachter-Panels",
    },
    en: {
        check: "Check",
        days: "Days",
        delivery: "Delivery",
        deliveryAndTimeline: "Delivery (days of work)",
        hasCommentTooltip: "The valuer has provided additional information",
        piCoverValue: "PI Cover[€]",
        piCoverPercentage: "[%]",
        specialPi: "Special PI",
        earliestAvailability: "Earliest Avail.",
        workingTime: "Working Time",
        price: "Price",
        valuer: "Valuer",
        valuerTooltip: "Member of your Valuer Panel",
    },
};