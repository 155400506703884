import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../../../../../common/hooks";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useMyEntity,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import useMe from "../../../../app/useMeV2";
import DataTable from "../../../../common/components/data-table/DataTable";
import { GoogleMapV2 } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import AwaitingAccessBanner from "../../../../common/features/awaiting-access/AwaitingAccessBanner";
import Statistics from "../../../../common/features/statistics/Statistics";
import { useDevice } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../layout/Section";
import CustomizeExperience from "../../client/customize-experience/CustomizeExperience";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";
import {
    columns as caseColumns,
    caseEnquiryColumns,
} from "./data-table/CasesTable";
import {
    createCurrentOfferEnquiryData,
    createRecentMatchesData,
    columns as enquiryColumns,
    enquiryWithMatchingOfferColumns,
} from "./data-table/EnquiryTable";
import {
    allActiveCases,
    allMatchingEnquiriesStatistic,
    allOffersSentStatistic,
    allPendingOffersStatistic,
    allRejectedOffersStatistic,
    caseConversionRate,
} from "./EnquiryAndCaseStatistics";

const { REACT_APP_FEATURE_ENABLE_CASE = "false" } = process.env;

// helpers

const Dashboard = () => {
    const { data: enquiries = [], isLoading: enquiriesLoading } =
        useEnquiryGetAll();
    const { data: offers = [], isLoading: offersLoading } =
        useOfferGetAllMyOffers();
    const {
        data: entity,
        isLoading: entityLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();
    const { data: cases = [], isLoading: casesLoading } = useCaseActiveCases();
    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();
    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const navigate = useNavigate();

    const awaitingAccess = user?.accessPending === true;

    const enabledCases = REACT_APP_FEATURE_ENABLE_CASE === "true";

    const t = useSmartTranslation();

    const device = useDevice();

    const allEnquiryPropertyLocations = enquiries.flatMap((enquiry) =>
        enquiry.properties.map((property) => ({
            id: property.id,
            lat: Number(property.latitude) || 0,
            lng: Number(property.longitude) || 0,
        }))
    );

    if (userLoading || roleLoading) return <LoadingSpinner />;

    if (
        !user ||
        enquiriesLoading ||
        offersLoading ||
        entityLoading ||
        casesLoading ||
        userLoading ||
        myCertificatesIsLoading ||
        entitySampleValuationsIsLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <ProfileCompletionBanner
            user={user}
            entity={entity}
            userCertificates={myCertificates}
            sampleValuations={entitySampleValuations}
            userRoles={{
                userIsClient,
                userIsCompanyAdmin,
                userIsValuer,
            }}
        />
    );

    // statistics

    const userOffers = offers.filter((offer) => offer.fromValuerId === user.id);
    const numPendingOffers = allPendingOffersStatistic(userOffers);
    const numMatchingEnquiries = allMatchingEnquiriesStatistic(enquiries);
    const numAllOffersSent = allOffersSentStatistic(userOffers, enquiries);
    const numAllRejectedOffers = allRejectedOffersStatistic(
        userOffers,
        enquiries
    );
    const numAllActiveCases = allActiveCases(cases, enquiries);
    const percentageOfCompletedCases = caseConversionRate(cases, enquiries);
    const enquiryStatsData = [
        numPendingOffers,
        numMatchingEnquiries,
        numAllOffersSent,
        numAllRejectedOffers,
    ];
    const caseStatsData = [numAllActiveCases, percentageOfCompletedCases];

    // statistics
    const enquiryStats = (
        <Section
            title={t("domain.enquiry.enquiries")}
            content={<Statistics stats={enquiryStatsData} />}
        />
    );

    const caseStats = (
        <Section
            title={t("domain.case.cases")}
            content={<Statistics stats={caseStatsData} />}
        />
    );

    // enquiries
    const recentMatchesData = createRecentMatchesData({
        enquiries,
        offers: userOffers,
        user,
    });

    const recentMatches = (
        <Section
            collapsable
            title={t("valuerDashboard.recentMatchesWithCount", {
                count: recentMatchesData.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={recentMatchesData}
                    columns={enquiryColumns}
                    noDataText={t("valuerDashboard.noRecentMatches")}
                    onRowClick={(enquiry) => {
                        navigate(`/dashboard/enquiry/${enquiry.id}`);
                    }}
                />
            }
        />
    );

    const caseEnquiries = (
        <Section
            collapsable
            title={t("valuerDashboard.caseEnquiries", {
                count: cases.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={cases}
                    columns={caseEnquiryColumns}
                    noDataText={t("valuerDashboard.noCaseEnquiries")}
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    const currentOfferEnquiryData = createCurrentOfferEnquiryData({
        enquiries,
        offers: userOffers,
        user,
    });

    const sortedCurrentOfferEnquiryData = currentOfferEnquiryData.sort(
        (a, b) => {
            const latestOfferA = a.offers.sort(
                (x, y) =>
                    new Date(y.updatedAt).getTime() -
                    new Date(x.updatedAt).getTime()
            )[0];
            const latestOfferB = b.offers.sort(
                (x, y) =>
                    new Date(y.updatedAt).getTime() -
                    new Date(x.updatedAt).getTime()
            )[0];

            return (
                new Date(latestOfferB.updatedAt).getTime() -
                new Date(latestOfferA.updatedAt).getTime()
            );
        }
    );

    const enquiriesWithMatchingOffer = (
        <Section
            collapsable
            title={t("valuerDashboard.currentOffers", {
                count: sortedCurrentOfferEnquiryData.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={sortedCurrentOfferEnquiryData}
                    columns={enquiryWithMatchingOfferColumns}
                    noDataText={t("valuerDashboard.noCurrentOffers")}
                    onRowClick={(enquiry) => {
                        navigate(`/dashboard/enquiry/${enquiry.id}`);
                    }}
                />
            }
        />
    );

    // cases
    const ongoingCases = (
        <Section
            collapsable
            title={t("valuerDashboard.activeCasesWithCount", {
                count: cases.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={cases}
                    columns={caseColumns}
                    noDataText={t("valuerDashboard.noAcceptedStartedValuation")}
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    // account and profile
    const experienceCustomization = (
        <Section
            collapsable
            title={t(
                "valuerDashboard.customizeExperience.customizeYourExperience"
            )}
            content={
                <CustomizeExperience
                    customizeList={[
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.completeProfile.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.completeProfile.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/complete-profile",
                            },
                        },
                        ...(userIsCompanyAdmin
                            ? [
                                {
                                    title: t(
                                        "valuerDashboard.customizeExperience.addTeamMembers.title"
                                    ),
                                    subtext: t(
                                        "valuerDashboard.customizeExperience.addTeamMembers.subtext"
                                    ),
                                    link: {
                                        label: t("common.button.add"),
                                        nav: "/dashboard/account/team",
                                    },
                                },
                            ]
                            : []),
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadMarketing.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadMarketing.subtext"
                            ),
                            link: {
                                label: t("common.button.upload"),
                                nav: "/dashboard/account/documents",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadPiCover.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadPiCover.subtext"
                            ),
                            link: {
                                label: t("common.button.upload"),
                                nav: "/dashboard/account",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadValuationSample.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadValuationSample.subtext"
                            ),
                            link: {
                                label: t("common.button.upload"),
                                nav: "/dashboard/account/documents",
                            },
                        },
                    ]}
                />
            }
        />
    );

    const caseDependentStatistics = (
        <SimpleGrid
            gap={4}
            w="100%"
            gridTemplateColumns={
                (enabledCases &&
                    (device === "mobile"
                        ? "100%"
                        : "minmax(0, 50%) minmax(0, 50%)")) ||
                "100%"
            }
        >
            {enquiryStats}
            {enabledCases && caseStats}
        </SimpleGrid>
    );

    const caseDependentMatchingsAndCases = (
        <>
            {caseDependentStatistics}
            <SimpleGrid
                gap={4}
                w="100%"
                gridTemplateColumns={
                    device === "mobile"
                        ? "100%"
                        : "minmax(0, 50%) minmax(0, 50%)"
                }
            >
                {recentMatches}

                {!enabledCases && (
                    <Box
                        bgColor="white"
                        padding="1rem"
                        borderRadius={8}
                        minH="16rem"
                        w="100%"
                    >
                        <GoogleMapV2
                            height="100%"
                            width="100%"
                            markers={allEnquiryPropertyLocations}
                        />
                    </Box>
                )}

                {enabledCases && enquiriesWithMatchingOffer}
            </SimpleGrid>

            {!enabledCases && (
                <>
                    {enquiriesWithMatchingOffer}
                    {caseEnquiries}
                </>
            )}
        </>
    );

    return (
        <VStack
            w="100%"
            p={4}
            spacing={4}
        >
            {profileCompletionBanner}
            {awaitingAccess && <AwaitingAccessBanner />}
            {caseDependentMatchingsAndCases}
            {enabledCases && ongoingCases}
            {experienceCustomization}
        </VStack>
    );
};

export default Dashboard;
