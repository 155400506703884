import {
    StandardSystemStandardsOfferedWithOther,
    standardSystemStandardsOfferedWithOtherAndNone,
} from "../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import {
    EnquiryFilterFunctionType,
    EnquiryFilterPropsType,
} from "./EnquiryFilter";
import { FilterOptionType, FilterSelect } from "./FilterSelect";

const FILTERGROUP_ID = "BY_STANDARD";

const ByStandard = (props: EnquiryFilterPropsType) => {
    const {
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const activateFilter = (
        value: StandardSystemStandardsOfferedWithOther,
        filter: EnquiryFilterFunctionType
    ): void => {
        activateFilterGroup({
            id: FILTERGROUP_ID,
            filter,
            value,
            displayedData: {
                filterLabel: t(
                    "screens.platform.enquiryList.filters.byStandard.label"
                ),
                valueLabel: t(`domain.standards.systemStandard.${value}`),
            },
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === FILTERGROUP_ID
    )?.value;

    const options: FilterOptionType[] = [
        {
            id: "allStandards",
            label: t(
                "screens.platform.enquiryList.filters.byStandard.options.allStandards"
            ),
            onSelect: () => deactivateFilter(FILTERGROUP_ID),
        },
        ...standardSystemStandardsOfferedWithOtherAndNone.map((standard) => {
            const id = standard !== "" ? standard : "no_standard_set";

            return {
                id,
                label: t(`domain.standards.systemStandard.${standard}`),
                onSelect: () =>
                    activateFilter(
                        id as StandardSystemStandardsOfferedWithOther,
                        ({ valuationStandards }) =>
                            valuationStandards === standard
                    ),
            };
        }),
    ];

    return (
        <FilterSelect
            label={t("screens.platform.enquiryList.filters.byStandard.label")}
            filterOptions={options}
            value={activeFilterValue}
        />
    );
};

export { ByStandard };
