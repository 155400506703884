export type StandardValuationOccasion =
    (typeof standardValuationOccasions)[number];

const standardValuationOccasions = [
    "Acquisition/Sale",
    "Loan/Investment",
    "Divorce",
    "Inheritance",
    "Enterprise Valuation",
    "Regulatory Reasons",
    "Annual Accounts",
    "General Information",
    "Court",
    "Residual Use For Life",
    "Auction",
    "Tax",
    "Other",
] as const;

export default standardValuationOccasions;
