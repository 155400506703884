import axios from "axios";
import { goToLogin } from "../../../common/functions/auth";
import { getToken } from "../../firebase";

const { REACT_APP_API_URL } = process.env;

const postForm = async (path: string, data?: { [index: string]: unknown }) => {
    const token = await getToken();

    const formData = new FormData();

    if (data) {
        Object.keys(data).forEach((key) => {
            let dataItem = data[key];

            dataItem = Array.isArray(dataItem) ? dataItem : [dataItem];

            (dataItem as unknown[]).forEach((item) => {
                formData.append(key, item as string | Blob | File);
            });
        });
    }

    const result = await axios
        .post(`${REACT_APP_API_URL}${path}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                goToLogin();
                return undefined;
            } else {
                throw new Error(
                    error?.response?.data?.message ||
                        error?.response?.statusText ||
                        "Unknown error"
                );
            }
        });

    return result?.data !== undefined ? result?.data : undefined;
};

export default postForm;
