import { OfferType } from "../../../../../../../../types";
import { Enrichment } from "../../../enricher";
import { BEST_SECURITY } from "./enrichers";

export const bestSecurity = <T extends OfferType>(
    offers: T[]
): Enrichment<T> => {
    const piCoverHighToLow = [...offers].sort((o1, o2) => {
        const piCoverValue1 = o1.piCoverValue;
        const piCoverValue2 = o2.piCoverValue;
        if (piCoverValue1 && piCoverValue2) {
            if (piCoverValue1 > piCoverValue2) return -1;
            if (piCoverValue1 < piCoverValue2) return 1;
        }
        return 0;
    });

    const highlightedData = piCoverHighToLow
        .filter((o) => o.piCoverValue === piCoverHighToLow[0].piCoverValue)
        .map((o) => ({
            data: o,
            highlight: {
                keyword: BEST_SECURITY,
            },
        }));

    return {
        fieldName: "piCoverValue",
        highlightedData,
    };
};
