import { StackProps, Text, VStack } from "@chakra-ui/react";

type SubsectionProps = StackProps & {
    children: React.ReactNode | React.ReactNode[];
    title?: string;
};

const Subsection = (props: SubsectionProps) => {
    const { children, title, ...cssProps } = props;

    return (
        <VStack
            bg="gray.50"
            borderRadius="8"
            align="stretch"
            padding="1em"
            {...cssProps}
        >
            {title && (
                <Text
                    variant="secondary"
                    fontSize="sm"
                >
                    {title}
                </Text>
            )}
            {children}
        </VStack>
    );
};

export default Subsection;
