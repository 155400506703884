import { HStack, Text } from "@chakra-ui/react";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { EnquiryType, UserType } from "../../../../../../types";
import { OfferOnlyWithEnquiryType } from "../../../../../../types/offer/OfferOnlyWithEnquiryType";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";
import { Offer } from "../../../../domain";
import { COLORS } from "../../../../domain/offer/OfferStatusText";

type EnquiryDetailsProps = {
    users: UserType[];
    offers: OfferOnlyWithEnquiryType[];
    enquiry: EnquiryType;
};

export const valuerName: DataTableColumnType<EnquiryDetailsProps> = {
    alignment: "left",
    title: (t) => t("domain.offer.columns.valuer"),
    render: (props: EnquiryDetailsProps) => {
        const { users } = props;

        if (!users || users === undefined || users.length === 0) {
            return <Text fontSize={"sm"}>-</Text>;
        }

        const user = users[0];

        let userFullName = `${user.firstName} ${user.lastName}`;

        if (userFullName === undefined) {
            userFullName = "-";
        }

        return (
            <Text
                fontWeight="bold"
                fontSize={"sm"}
            >
                {user.firstName} {user.lastName}
            </Text>
        );
    },
};

export const delivery: DataTableColumnType<EnquiryDetailsProps> = {
    alignment: "right",
    title: (t) =>
        t("domain.offer.columns.delivery"),
    render: (props: EnquiryDetailsProps) => {
        const { offers } = props;

        if (!offers || offers.length === 0) {
            return <Text fontSize={"sm"}>-</Text>;
        }

        const offer = offers[0];

        let offerAvailability = offer.availability;

        if (offer.availability === undefined) {
            offerAvailability = "-";
        }

        const availabilityDate = new Date(offerAvailability);

        return (
            <Text fontSize={"sm"}>
                {displayDateWithCurrentLocale(availabilityDate)}
            </Text>
        );
    },
};

export const offerPrice: DataTableColumnType<EnquiryDetailsProps> = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.price"),
    render: (props: EnquiryDetailsProps) => {
        const { offers } = props;

        if (!offers || offers.length === 0) {
            return <Text fontSize={"sm"}>-</Text>;
        }

        const offer = offers[0];

        const color = COLORS[offer.status];

        let offerPrice;

        if (offer.price === undefined) {
            offerPrice = "-";
        } else {
            offerPrice = <strong>{`€${offer.price}`}</strong>;
        }

        return (
            <Text
                color={color}
                fontSize={"sm"}
            >
                {offerPrice}
            </Text>
        );
    },
};

export const valuerOfferStatus: DataTableColumnType<EnquiryDetailsProps> = {
    title: (t) => t(`domain.offer.offerStatus`),
    render: (props: EnquiryDetailsProps, t) => {
        const { offers, enquiry } = props;

        if (!offers || offers.length === 0) {
            return (
                <HStack color="gray.500">
                    <MdOutlineHourglassEmpty fontSize="18px" />
                    <Text fontSize={"sm"}>
                        {t(
                            `screens.platform.valuer.valuerEnquiryDetails.valuerStatus.awaitingOffer`
                        )}
                    </Text>
                </HStack>
            );
        }

        const offer = offers[0];

        return (
            <Offer.StatusText
                offer={{
                    ...offer,
                    enquiry,
                }}
            />
        );
    },
};

export const companyOfferColumns = {
    valuerName,
    delivery,
    valuerOfferStatus,
    offerPrice,
};
