import { Box, Flex, Text } from "@chakra-ui/react";

type InformationRowItemProps = {
    label: string;
    content: React.ReactNode | React.ReactNode[];
};

const InformationRowItem = (props: InformationRowItemProps) => {
    const { label, content } = props;

    return (
        <Flex align={"center"}>
            <Text
                maxW={"17rem"}
                w={"100%"}
                fontSize={"sm"}
                color={"gray.500"}
            >
                {label}
            </Text>

            <Box
                borderRadius={"lg"}
                p={".75rem 1rem"}
                bg={"gray.50"}
                width={"100%"}
            >
                {content}
            </Box>
        </Flex>
    );
};

export default InformationRowItem;
