import { CheckIcon, CloseIcon, Icon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/layout";

type MatchmakingInfoProps = {
    label?: string;
    condition: boolean;
};

const MatchmakingInfo = (props: MatchmakingInfoProps) => {
    const { label, condition } = props;

    const checkIcon = (
        <Icon
            as={CheckIcon}
            boxSize={"16px"}
            color={"green.500"}
        />
    );

    const crossIcon = (
        <Icon
            as={CloseIcon}
            boxSize={"12px"}
            color={"gray.500"}
        />
    );

    return (
        <Flex
            align={"center"}
            gap={2}
        >
            {condition ? checkIcon : crossIcon}

            {label && (
                <Text
                    fontSize={"sm"}
                    lineHeight={"1.2rem"}
                >
                    {label}
                </Text>
            )}
        </Flex>
    );
};

export { MatchmakingInfo };
