import {
    Box,
    Divider,
    HStack,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { StandardPropertyTypeWithMixed } from "../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { PropertyType } from "../../../../types";
import { estimatePortfolioValue } from "../../common/components/data-table/TableDataCalculations";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import Subsection from "../../layout/Subsection";
import {
    calcPropertyAreaMinMax,
    calcPropertyValueMinMax,
    calculateAveragePropertyArea,
    createPropertyTypesCount,
} from "./PropertyPortfolioCalulations";

type Props = {
    properties: PropertyType[];
};

export const PropertyPortfolioSummary = (props: Props) => {
    const { properties } = props;

    const t = useSmartTranslation();

    //amount of properties
    const noOfProperties = properties.length;
    const noOfPropertyTypes = createPropertyTypesCount(properties);
    const amountOfProperties = (
        <>
            <Subsection
                title={t(
                    "domain.property.propertyPortfolio.summary.ammountOfProperties"
                )}
            >
                <Text
                    fontWeight="bold"
                    fontSize="20px"
                >
                    {t(
                        "domain.property.propertyPortfolio.summary.noOfProperties",
                        {
                            number: noOfProperties,
                        }
                    )}
                </Text>
                <Divider />
                <VStack
                    align="left"
                    spacing={0}
                >
                    {Object.entries(noOfPropertyTypes).map(([key, value]) => {
                        const typeKey = key as StandardPropertyTypeWithMixed;
                        return (
                            <HStack
                                key={typeKey}
                                align="start"
                            >
                                <Text
                                    minW="10%"
                                    fontSize={"sm"}
                                    fontWeight="bold"
                                >
                                    {value}x
                                </Text>
                                <Text
                                    fontSize={"sm"}
                                    color="gray.500"
                                >
                                    {t(
                                        `domain.standards.propertyType.${typeKey}`
                                    )}
                                </Text>
                            </HStack>
                        );
                    })}
                </VStack>
            </Subsection>
        </>
    );

    // average property area
    const averageArea = calculateAveragePropertyArea(properties);
    const { smallest, biggest } = calcPropertyAreaMinMax(properties);

    const averagePropertyArea = (
        <Subsection
            title={t(
                "domain.property.propertyPortfolio.summary.avgPropertyArea"
            )}
        >
            <Text
                fontWeight="bold"
                fontSize="20px"
            >
                {delimitNumber(averageArea)} m²
            </Text>
            <Divider />
            <VStack
                align="left"
                spacing={0}
                w="100%"
            >
                <HStack align="start">
                    <Text
                        minW="33%"
                        fontSize="sm"
                        fontWeight="bold"
                    >
                        {delimitNumber(smallest)} m²
                    </Text>

                    <Text
                        fontSize="sm"
                        color="gray.500"
                    >
                        {t(
                            "domain.property.propertyPortfolio.summary.smallest"
                        )}
                    </Text>
                </HStack>
                <HStack align="start">
                    <Text
                        minW="33%"
                        fontSize="sm"
                        fontWeight="bold"
                    >
                        {delimitNumber(biggest)} m²
                    </Text>

                    <Text
                        fontSize="sm"
                        color="gray.500"
                    >
                        {" "}
                        {t("domain.property.propertyPortfolio.summary.biggest")}
                    </Text>
                </HStack>
            </VStack>
        </Subsection>
    );

    // estimate portfolio value
    const estimateValue = estimatePortfolioValue(properties);
    const { cheapest, mostExpensive } = calcPropertyValueMinMax(properties);
    const estPortfolioValue = (
        <Subsection
            title={t(
                "domain.property.propertyPortfolio.summary.estimatePortfolioValue"
            )}
        >
            <Text
                fontWeight="bold"
                fontSize="20px"
                color="cyan.500"
            >
                €{delimitNumber(estimateValue)}
            </Text>
            <Divider />
            <VStack
                align="left"
                spacing={0}
                w="100%"
            >
                <HStack align="start">
                    <Text
                        minW="30%"
                        fontSize="sm"
                        fontWeight="bold"
                        color="cyan.500"
                    >
                        €{delimitNumber(cheapest)}
                    </Text>

                    <Text
                        fontSize="sm"
                        color="gray.500"
                    >
                        {t(
                            "domain.property.propertyPortfolio.summary.cheapest"
                        )}
                    </Text>
                </HStack>
                <HStack align="start">
                    <Text
                        minW="30%"
                        fontSize="sm"
                        fontWeight="bold"
                        color="cyan.500"
                    >
                        €{delimitNumber(mostExpensive)}
                    </Text>

                    <Text
                        fontSize="sm"
                        color="gray.500"
                    >
                        {t(
                            "domain.property.propertyPortfolio.summary.mostExpensive"
                        )}
                    </Text>
                </HStack>
            </VStack>
        </Subsection>
    );

    return (
        <SimpleGrid
            height="100%"
            minH="10em"
            w="100%"
            columns={3}
            spacing={2}
        >
            {amountOfProperties}

            {averagePropertyArea}

            {estPortfolioValue}
        </SimpleGrid>
    );
};
