import { Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import { ArrowRight2 } from "iconsax-react";
import { Link as ReactLink } from "react-router-dom";
import { Enquiry, User } from "../..";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import {
    EnquiryType,
    EnquiryWithFromUserAndFromEntityType,
} from "../../../../../types";
import { DataTableColumnType } from "../../../common/components/data-table/DataTable";
import { estimatePortfolioValue } from "../../../common/components/data-table/TableDataCalculations";
import { TranslationFunction } from "../../../common/hooks/useSmartTranslation";
import { EditEnquiry } from "../EditEnquiry";

type Column = DataTableColumnType<EnquiryType>;

const translationNameSpace = "domain.enquiry.columns";

export const enquiryIdWithProperties: Column = {
    title: (t) => t("domain.enquiry.enquiry"),
    render: (i, t) => {
        const noOfProperties = i.properties.length;
        const firstProperty =
            noOfProperties > 0 &&
            `${i.properties[0].city}, ${i.properties[0].street}`;

        return (
            <Flex
                direction="column"
                gap={0}
                data-testid="enquiry-id"
            >
                <Heading size={"xs"}>{i.id}</Heading>
                <Text
                    color="gray.500"
                    fontSize={"xs"}
                >
                    {firstProperty}
                </Text>
                {noOfProperties > 1 ? (
                    <Text
                        color="gray.500"
                        fontSize={"xs"}
                    >
                        {t("domain.enquiry.columns.moreText", {
                            count: noOfProperties - 1,
                        })}
                    </Text>
                ) : (
                    ""
                )}
            </Flex>
        );
    },
};

export const createdAt: Column = {
    title: (t) => t(`${translationNameSpace}.createdAt`),
    render: (i) => {
        const createdAt = displayDateWithCurrentLocale(i.createdAt);

        return (
            <Heading
                size={"xs"}
                fontWeight={"400"}
            >
                {createdAt}
            </Heading>
        );
    },
};

export const valuationScopeAndStandard: Column = {
    title: (t) => t(`${translationNameSpace}.standard`),
    render: (i, t) => {
        const { type, otherType } = Enquiry.determineType(i, t);

        const standard = Enquiry.determineStandard(i, t);

        return (
            <Flex direction="column">
                <Heading size="xs">{type}</Heading>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >
                    {otherType}
                </Text>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >
                    {standard}
                </Text>
            </Flex>
        );
    },
};

export const clientPictureAndName: DataTableColumnType<EnquiryWithFromUserAndFromEntityType> =
    {
        title: (t) => t("domain.user.client"),
        render: (enquiry, t) => (
            <User.ProfilePictureWithName
                user={enquiry.fromUser}
                enquiry={enquiry}
                profileLogoSize={2.5}
                companyLogoSize={1.5}
            />
        ),
    };

export const piCoverRequirements: Column = {
    title: (t) => t(`${translationNameSpace}.piCover`),
    render: (i) => {
        const coverValue = `€${delimitNumber(i.valuerMinimumPICover)}`;

        return (
            <Flex direction="column">
                <Heading size="xs">{coverValue}</Heading>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >{`${i.valuerMinimumPICoverPercentage}%`}</Text>
            </Flex>
        );
    },
};

export const expectedDeliveryDate: Column = {
    alignment: "right",
    title: (t) => t(`${translationNameSpace}.expectedDelivery`),
    render: (i, t) => {
        const { deliveryDate, daysToDeliveryDateText } =
            Enquiry.getDeliveryDate(i, t);

        return (
            <Flex
                alignItems="end"
                direction="column"
            >
                <Heading size="xs">{deliveryDate}</Heading>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >
                    {daysToDeliveryDateText}
                </Text>
            </Flex>
        );
    },
    sorter: (a, b) =>
        new Date(a.completionDate).getTime() -
        new Date(b.completionDate).getTime(),
};

export const estPortfolioValueEnquiry: Column = {
    alignment: "right",
    title: (t: TranslationFunction) =>
        t(`${translationNameSpace}.portfolioValue`),
    tooltip: (t: TranslationFunction) =>
        t(`${translationNameSpace}.portfolioValueTooltip`),
    render: (i) => {
        const { properties } = i;
        const estPortfolioValue = estimatePortfolioValue(properties);

        const delimitedValue = `€${delimitNumber(estPortfolioValue)}`;

        return (
            <Heading
                size="xs"
                color="cyan.500"
            >
                {delimitedValue}
            </Heading>
        );
    },
    sorter: (a, b) => {
        const aEstPortfolioValue = estimatePortfolioValue(a.properties);
        const bEstPortfolioValue = estimatePortfolioValue(b.properties);

        return aEstPortfolioValue - bEstPortfolioValue;
    },
};

export const clientPublishedToMarket: Column = {
    title: (t) => t("domain.enquiry.matchmaking.openMarket"),
    render: ({ allowFullMatchmaking }, t) => (
        <Enquiry.MatchmakingInfo condition={allowFullMatchmaking === true} />
    ),
};

export const clientPublishedToValuerPanel: Column = {
    title: (t) => t("domain.enquiry.matchmaking.valuerPanel"),
    render: ({ panelRecipientIds }, t) => (
        <Enquiry.MatchmakingInfo condition={panelRecipientIds.length > 0} />
    ),
};

export const viewEnquiry: Column = {
    render: (i: EnquiryType) => {
        return (
            <IconButton
                as={ReactLink}
                to={`/dashboard/enquiry/${i.id}`}
                onClick={(e) => e.stopPropagation()}
                icon={<ArrowRight2 />}
                variant="ghost"
                color="gray.500"
                aria-label="arrow"
                data-testid="view-enquiry"
            />
        );
    },
};

export const editEnquiry: Column = {
    render: (i: EnquiryType) => {
        return <EditEnquiry enquiry={i} />;
    },
};

export const enquiryColumns = {
    enquiryIdWithProperties,
    createdAt,
    clientPublishedToMarket,
    clientPublishedToValuerPanel,
    piCoverRequirements,
    expectedDeliveryDate,
    estPortfolioValueEnquiry,
    viewEnquiry,
    editEnquiry,
    valuationScopeAndStandard,
};
