import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export const LayoutV1Wrapper = () => {
    return (
        <Box>
            <Outlet />
        </Box>
    );
};
