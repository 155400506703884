import { chakra, HStack, Icon, Text } from "@chakra-ui/react";
import { AiOutlineStop } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import { OfferOnlyWithEnquiryType } from "../../../../types/offer/OfferOnlyWithEnquiryType";
import { useAuthedUser } from "../../app/useMeV2";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { EnquiryStatus } from "../enquiry/enquiryStatus";
import { OfferStatus } from "./offerStatus";

export const COLORS: Record<OfferStatus, string> = {
    PENDING: "blue.500",
    ACCEPTED: "green.500",
    REJECTED: "orange.500",
    WITHDRAWN: "orange.500",
};

const ICONS: Record<OfferStatus, React.ComponentType> = {
    PENDING: MdOutlineHourglassEmpty,
    ACCEPTED: BsCheckCircle,
    REJECTED: FiAlertTriangle,
    WITHDRAWN: AiOutlineStop,
};

export const getColorByOfferAndEnquiryStatuses = (
    offerStatus: OfferStatus,
    enquiryStatus?: EnquiryStatus
): string => {
    if (!enquiryStatus) return COLORS[offerStatus];

    const statusMap: Record<string, string> = {
        REJECTED_ACCEPTED: "red.500",
        REJECTED_CANCELLED: "red.500",
        WITHDRAWN_ACCEPTED: "red.500",
        WITHDRAWN_CANCELLED: "red.500",
    };

    const combinedStatus = `${offerStatus}_${enquiryStatus}`;
    return statusMap[combinedStatus] || COLORS[offerStatus];
};

type PureOfferStatusTextProps = {
    offer: OfferOnlyWithEnquiryType;
    iconSize?: number;
};

const PureOfferStatusText = (props: PureOfferStatusTextProps) => {
    const {
        offer: { status: offerStatus, enquiry },
        iconSize = 4,
        ...cssProps
    } = props;

    const t = useSmartTranslation();

    const user = useAuthedUser();
    const role = user.entity.accountType;

    const color = getColorByOfferAndEnquiryStatuses(
        offerStatus,
        enquiry?.status
    );
    const icon = ICONS[offerStatus];
    const statusText = t(`domain.offer.statusWithEnquiry.${role}.${offerStatus}`);

    return (
        <HStack
            spacing={2}
            data-testid="status"
            color={color}
        >
            <Icon
                as={icon}
                boxSize={iconSize}
            />

            <Text
                color={color}
                fontSize={"sm"}
                {...cssProps}
            >
                {statusText}
            </Text>
        </HStack>
    );
};

const OfferStatusText = chakra(PureOfferStatusText);

export { OfferStatusText };
