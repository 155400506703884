import { useParams } from "react-router-dom";
import { useSmartTranslation } from "../../../common/hooks";
import SuccessScreen from "../../wizard-success/SuccessScreen";

const EnquirySuccess = () => {
    const t = useSmartTranslation("enquiryform");

    const { enquiryId, redirectUrl } = useParams();

    let navLink = `/dashboard/enquiry/${enquiryId}/overview`;
    let buttonText = t("button.showEnquiry", { ns: "common" });

    if (redirectUrl) {
        navLink = redirectUrl;
        buttonText = t("button.register", { ns: "common" });
    }

    return (
        <SuccessScreen
            bodyText={t("enquirySuccessPrompt.blurb", { enquiryId })}
            navLink={navLink}
            buttonText={buttonText}
        />
    );
};

export default EnquirySuccess;
