export const descending = (a: string, b: string) => {
    const t1 = new Date(a).getTime();
    const t2 = new Date(b).getTime();

    if (t1 > t2) return -1;
    if (t1 < t2) return 1;
    return 0;
};

export const newestToOldest = descending;

export const ascending = (a: string, b: string) => {
    const t1 = new Date(a).getTime();
    const t2 = new Date(b).getTime();

    if (t1 > t2) return 1;
    if (t1 < t2) return -1;
    return 0;
};

export const oldestToNewest = ascending;
