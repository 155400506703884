import { Button, HStack, Text } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { OfferOnlyType } from "../../../../../../types";
import { Title } from "../../../../common/components/display";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Offer } from "../../../../domain";

type OfferTitlePropsType = {
    offer: OfferOnlyType;
};

const { REACT_APP_FEATURE_ENABLE_CASE = "false" } = process.env;

const OfferTitle = (props: OfferTitlePropsType) => {
    const {
        offer: { id, caseId },
        offer,
    } = props;

    const t = useSmartTranslation();

    // render

    const subtitle = (
        <HStack>
            <Text
                fontSize="sm"
                color="gray.500"
            >
                {t("domain.offer.statusLabel")}
            </Text>
            <Offer.StatusText offer={offer} />
        </HStack>
    );

    const rightSide = REACT_APP_FEATURE_ENABLE_CASE === "true" &&
        status === "ACCEPTED" && (
            <Button
                size="sm"
                as={ReactLink}
                to={`/dashboard/case/${caseId}`}
                variant="primary"
                children={t("domain.offer.openCase")}
            />
        );

    return (
        <Title
            title={t("domain.offer.offerId", { id })}
            subtitle={subtitle}
            rightSide={rightSide}
        />
    );
};

export { OfferTitle };
