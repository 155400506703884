import { UserType } from "../../../../types";
import { translations } from "./i18n";
import { ProfilePictureWithName } from "./ProfilePictureWithName";

export type User = UserType & {
    emailVerified: boolean;
};

export const User = {
    ProfilePictureWithName,
    translations,
};
