import { useField } from "formik";
import _ from "lodash";
import { useState } from "react";
import { OfferAttachmentType } from "../../../../../types";
import { FirebaseCertificateType } from "../../../../../utils/storage/storage";
import { RequiredAttachmentsEditor } from "../offer-details/attachments/AttachmentsEditor";
import { AttachmentItem } from "../OfferEditor";
import {
    usePrepareCompanyDocumentItems,
    usePrepareUploadedDocumentItems,
} from "./util";

/*
editor handles 1 category of required, pi, sample, marketing
3 lists to deal with per category

company documents to attach
- available company documents (input)
- add / remove on a field (input)

uploaded documents to attach
- uploaded documents (input)
- add / remove on a field (input)
- delete from uploads (input)

mark existing attachments for removal
- add / remove on a field (input)
*/

type EditingEditorProps = {
    uploadsFieldname:
        | "requiredAttachments"
        | "sampleAttachments"
        | "marketingAttachments"
        | "piCertificateAttachments";

    companyDocumentsFieldname:
        | "requiredCompanyDocuments"
        | "sampleCompanyDocuments"
        | "marketingCompanyDocuments"
        | "piCertificateCompanyDocuments";
    companyDocuments: FirebaseCertificateType[];

    currentAttachmentsOnOffer: OfferAttachmentType[];

    reserveFileNames: (names: string[]) => boolean;
    releaseFileName: (names: string) => void;
};

export const EditingEditor = (props: EditingEditorProps) => {
    const {
        uploadsFieldname,

        companyDocumentsFieldname,
        companyDocuments: allCompanyDocuments,

        currentAttachmentsOnOffer,

        reserveFileNames,
        releaseFileName,
    } = props;

    // company documents

    const [, , { setValue: setCompanyDocumentsFieldValue }] = useField<
        string[]
    >(companyDocumentsFieldname);

    // don't list documents that are already on the offer
    // we'll be copying company documents, so urls won't match, but names will
    const companyDocuments = allCompanyDocuments.filter(
        ({ file: { name } }) =>
            !currentAttachmentsOnOffer.map(({ name }) => name).includes(name)
    );

    const companyDocumentItems = usePrepareCompanyDocumentItems({
        fieldname: companyDocumentsFieldname,
        companyDocuments,
        currentAttachmentsOnOffer,
    });

    // uploads

    const [, , { setValue: setUploadsFieldValue }] =
        useField<File[]>(uploadsFieldname);

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const uploadFiles = (files: FileList) => {
        const fileArr = Array.from(files);

        // files sharing a common name pool is useful because the client doesn't see categories
        if (reserveFileNames(fileArr.map(({ name }) => name)))
            setUploadedFiles(_.concat(uploadedFiles, ...fileArr));
    };

    const removeAvailableAttachment = (file: File) => {
        releaseFileName(file.name);
        setUploadedFiles(_.without(uploadedFiles, file));
    };

    const uploadedDocumentItems = usePrepareUploadedDocumentItems({
        fieldname: uploadsFieldname,
        uploadedAttachments: uploadedFiles,
        removeAvailableAttachment,
    });

    // removing current attachments

    const [
        { value: fieldForRemovingValue },
        ,
        { setValue: setFieldForRemovingValue },
    ] = useField<number[]>("requiredAttachmentsToRemove");

    const currentRequiredDocumentItems = usePrepareRequiredAttachments({
        currentAttachmentsOnOffer,
    });

    // items

    const availableAttachments = [
        ...companyDocumentItems,
        ...currentRequiredDocumentItems,
        ...uploadedDocumentItems,
    ];

    // editor

    const allChecked =
        availableAttachments.length > 0 &&
        availableAttachments.every(({ checked }) => checked);

    const onToggleAll = allChecked
        ? () => {
              // uncheck all uploads
              setUploadsFieldValue([]);

              // uncheck all companyDocs
              setCompanyDocumentsFieldValue([]);

              // mark all current attachments for removal
              setFieldForRemovingValue(
                  _.concat(
                      fieldForRemovingValue,
                      attachmentIds(currentAttachmentsOnOffer)
                  )
              );
          }
        : () => {
              // check all uploads
              setUploadsFieldValue(uploadedFiles);

              // check all companyDocs
              setCompanyDocumentsFieldValue(
                  companyDocuments.map(({ url }) => url)
              );

              // mark all current attachments for keeping
              setFieldForRemovingValue(
                  _.without(
                      fieldForRemovingValue,
                      ...attachmentIds(currentAttachmentsOnOffer)
                  )
              );
          };

    const disableToggleAll = !availableAttachments.length;

    return (
        <RequiredAttachmentsEditor
            onUpload={uploadFiles}
            onToggleAll={onToggleAll}
            allChecked={allChecked}
            disableToggleAll={disableToggleAll}
            items={availableAttachments}
        />
    );
};

type usePrepareRequiredAttachmentsParams = {
    currentAttachmentsOnOffer: OfferAttachmentType[];
};

// TODO name appropriately
/*
prepares eventhandling for attachments currently existing on the offer (not the formdata),
so toggling has to modify the deletion list
*/
const usePrepareRequiredAttachments = (
    params: usePrepareRequiredAttachmentsParams
): AttachmentItem<any>[] => {
    const { currentAttachmentsOnOffer } = params;

    // FIXME rename "attachmentsToRemove"
    const [
        { value: requiredAttachmentsToRemoveFieldValue },
        ,
        { setValue: setRequiredAttachmentsToRemoveFieldValue },
    ] = useField<number[]>("requiredAttachmentsToRemove");

    // unchecked -> add to deletion list

    const onToggle = (id: number, checked: boolean) => {
        if (checked)
            setRequiredAttachmentsToRemoveFieldValue(
                _.without(requiredAttachmentsToRemoveFieldValue, id)
            );
        else
            setRequiredAttachmentsToRemoveFieldValue(
                _.concat(requiredAttachmentsToRemoveFieldValue, id)
            );
    };

    return currentAttachmentsOnOffer.map((att) => {
        const { id, name, size } = att;
        return {
            item: att,
            label: name,
            size,
            checked: !requiredAttachmentsToRemoveFieldValue.includes(id),
            onToggle: (checked) => onToggle(id, checked),
        };
    });
};

const attachmentIds = (attachments: OfferAttachmentType[]) =>
    attachments.map(({ id }) => id);

const itemFiles = (availableAttachments: AttachmentItem<any>[]) =>
    availableAttachments.map(({ item }) => item);
