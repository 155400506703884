import { HStack, Text, VStack } from "@chakra-ui/react";
import { StandardPropertyType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { EnquiryType, UserType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import MatchableItem from "./MatchableItem";

type ValuerCompetencesProps = {
    valuer: UserType;
    enquiry: EnquiryType;
};

const ValuerCompetences = (props: ValuerCompetencesProps) => {
    const {
        valuer: {
            valuationTypes,
            specialValuationTypes,
            valuationOccasions,
            valuationPropertyTypes,
        },
        enquiry: {
            valuationType: requestedTypes,
            valuationSpecial: requestedSpecialTypes,
            valuationPurposes: requestedPurposes,
            properties,
        },
    } = props;

    // collect all requested propertytypes
    const requestedPropertyTypes: StandardPropertyType[] = [];
    properties.forEach((property) =>
        property.propertyTypes.forEach((propertyType) => {
            if (!requestedPropertyTypes.includes(propertyType))
                requestedPropertyTypes.push(propertyType);
        })
    );

    const t = useSmartTranslation();

    // render

    const valuationTypesRow = (
        <MatchableRow
            title={t(
                "screens.platform.client.offerDetails.valuerCompetences.valuationTypes"
            )}
        >
            {valuationTypes?.map((i) => (
                <MatchableItem
                    key={i}
                    label={t(`domain.standards.valuationType.${i}`)}
                    checked={requestedTypes.includes(i)}
                />
            ))}
        </MatchableRow>
    );

    const specialValuationTypesRow = (
        <MatchableRow
            title={t(
                "screens.platform.client.offerDetails.valuerCompetences.specialValuationTypes"
            )}
        >
            {specialValuationTypes?.map((i) => (
                <MatchableItem
                    key={i}
                    label={t(`domain.standards.specialValuationType.${i}`)}
                    checked={requestedSpecialTypes?.includes(i)}
                />
            ))}
        </MatchableRow>
    );

    const valuationOccasionsRow = (
        <MatchableRow
            title={t(
                "screens.platform.client.offerDetails.valuerCompetences.valuationOccasions"
            )}
        >
            {valuationOccasions?.map((i) => (
                <MatchableItem
                    key={i}
                    label={t(`domain.standards.valuationOccasion.${i}`)}
                    checked={requestedPurposes?.includes(i)}
                />
            ))}
        </MatchableRow>
    );

    const valuationPropertyTypesRow = (
        <MatchableRow
            title={t(
                "screens.platform.client.offerDetails.valuerCompetences.propertyTypes"
            )}
        >
            {valuationPropertyTypes?.map((item) => {
                const i = capitalizeFirstLetterOnly(item);
                return (
                    <MatchableItem
                        key={i}
                        label={t(`domain.standards.propertyType.${i}`)}
                        checked={requestedPropertyTypes.includes(i)}
                    />
                );
            })}
        </MatchableRow>
    );

    return (
        <VStack spacing={3}>
            {valuationTypesRow}
            {specialValuationTypesRow}
            {valuationOccasionsRow}
            {valuationPropertyTypesRow}
        </VStack>
    );
};

const capitalizeFirstLetterOnly = <T extends string>(s: T) => {
    const length = s.length;
    if (length === 0) return s;

    const firstLetter = s[0].toUpperCase();
    if (length === 1) return firstLetter as T;

    const rest = s.substring(1, length).toLowerCase();
    return (firstLetter + rest) as T;
};

type MatchableRowProps = {
    title: string;
    children: React.ReactNode | React.ReactNode[];
};

const MatchableRow = (props: MatchableRowProps) => {
    const { title, children } = props;

    return (
        <HStack
            width={"100%"}
            spacing={3}
            align={"start"}
        >
            <Text
                flex={"0 0 22%"}
                fontSize={"sm"}
                variant={"secondary"}
                lineHeight={"1.75rem"}
            >
                {title}
            </Text>
            <HStack wrap={"wrap"}>{children}</HStack>
        </HStack>
    );
};

export { ValuerCompetences };
