import { Search2Icon } from "@chakra-ui/icons";
import {
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    VStack,
} from "@chakra-ui/react";

type FilterInputPropsType = {
    label?: string;
    placeholder?: string;
    value?: string;
    onChange: (value: string) => void;
};

const FilterInput = (props: FilterInputPropsType) => {
    const { label, placeholder, value = "", onChange } = props;

    const display = (
        <InputGroup>
            <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.400" />
            </InputLeftElement>
            <Input
                type="search"
                variant="outline"
                placeholder={placeholder}
                value={value}
                onChange={(ev) => {
                    const value = ev.target.value;
                    onChange(value);
                }}
            />
        </InputGroup>
    );

    return (
        <VStack align={"start"}>
            {label && <Text>{label}</Text>}
            {display}
        </VStack>
    );
};

export { FilterInput };
