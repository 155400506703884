import { useParams } from "react-router-dom";
import { useSmartTranslation } from "../../../../common/hooks";
import { SuccessScreen } from "../success";

const EnquirySuccess = () => {
    // TODO replace use of old smart translation hook
    const t = useSmartTranslation("enquiryform");

    const { enquiryId, redirectUrl } = useParams();

    let navLink = `/dashboard/enquiry/${enquiryId}`;
    let buttonText = t("button.showEnquiry", { ns: "common" });

    if (redirectUrl) {
        navLink = redirectUrl;
        buttonText = t("button.register", { ns: "common" });
    }

    return (
        <SuccessScreen.SuccessScreen
            bodyText={t("enquirySuccessPrompt.blurb", { enquiryId })}
            navLink={navLink}
            buttonText={buttonText}
        />
    );
};

export default EnquirySuccess;
