import { HStack, Icon, Text } from "@chakra-ui/react";
import { BsBookmarkCheck, BsCheckCircle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { MdBlockFlipped, MdOutlineHourglassEmpty } from "react-icons/md";
import { EnquiryType, OfferType } from "../../../../types";
import { useAuthedUser } from "../../app/useMeV2";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { OfferStatus } from "../offer/offerStatus";
import { EnquiryStatus, EnquiryWithOfferStatus } from "./enquiryStatus";

export const COLORS: Record<EnquiryWithOfferStatus, string> = {
    PENDING: "gray.500",
    DRAFT: "gray.500",
    ACCEPTED: "green.500",
    WON: "green.500",
    CANCELLED: "red.500",
    LOST: "red.500",
    OFFER_SUBMITTED: "blue.500",
    OFFER_REJECTED: "orange.500",
};

const ICONS: Record<EnquiryWithOfferStatus, React.ComponentType> = {
    PENDING: MdOutlineHourglassEmpty,
    DRAFT: BsBookmarkCheck,
    ACCEPTED: BsCheckCircle,
    WON: BsCheckCircle,
    CANCELLED: MdBlockFlipped,
    LOST: MdBlockFlipped,
    OFFER_SUBMITTED: BsBookmarkCheck,
    OFFER_REJECTED: FiAlertTriangle,
};

const getStatusByEnquiryAndOfferStatuses = (
    enquiryStatus: EnquiryStatus,
    offerStatus?: OfferStatus
): EnquiryWithOfferStatus => {
    if (!offerStatus) return enquiryStatus;

    const statusMap: Record<string, EnquiryWithOfferStatus> = {
        PENDING_PENDING: "OFFER_SUBMITTED",
        PENDING_REJECTED: "OFFER_REJECTED",
        ACCEPTED_REJECTED: "LOST",
        ACCEPTED_ACCEPTED: "WON",
    };

    const combinedStatus = `${enquiryStatus}_${offerStatus}`;
    return statusMap[combinedStatus] || enquiryStatus;
};

type EnquiryStatusTextProps = {
    enquiry: EnquiryType;
    offer?: OfferType;
};

const EnquiryStatusText = (props: EnquiryStatusTextProps) => {
    const {
        enquiry: { status: enquiryStatus },
        offer,
    } = props;

    const t = useSmartTranslation();

    const user = useAuthedUser();
    const role = user.entity.accountType;

    const statusByRole = getStatusByEnquiryAndOfferStatuses(
        enquiryStatus,
        offer?.status
    );

    const statusText = t(
        `domain.enquiry.statusWithOffer.${role}.${statusByRole}`
    );

    const color = COLORS[statusByRole];
    const icon = ICONS[statusByRole];

    return (
        <HStack
            spacing={2}
            color={color}
        >
            <Icon
                as={icon}
                boxSize={4}
            />

            <Text
                fontSize={"sm"}
                color={color}
            >
                {statusText}
            </Text>
        </HStack>
    );
};

export default EnquiryStatusText;
