import { createContext } from "react";
import { useOfferGetAllMyOffers } from "../../../utils/api/hooks";
import useMe from "./useMeV2";

type TBusinessRelationshipsHelper = {
    previousClientEntities: number[];
};

export const BusinessRelationshipsContext = createContext<
    TBusinessRelationshipsHelper | undefined
>(undefined);

export const useBusinessRelationships = () => {
    const { data: user, isLoading: isLoadingUser } = useMe();

    const { data: offers = [], isLoading } = useOfferGetAllMyOffers();

    if (isLoadingUser || !user) return PLACEHOLDER_VALUE;

    const {
        entity: { accountType },
    } = user;

    if (accountType === "CLIENT") return PLACEHOLDER_VALUE;

    const previousClientEntities = offers
        .filter((o) => o.status === "ACCEPTED")
        .map(({ toEntityId }) => toEntityId);

    return {
        previousClientEntities,
        isLoading,
    };
};

const PLACEHOLDER_VALUE = {
    previousClientEntities: [],
    isLoading: false,
};
