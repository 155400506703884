import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { PICoverParams } from "./Offer";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { OfferOnlyType } from "../../../../types";
import { OfferOnlyWithEnquiryType } from "../../../../types/offer/OfferOnlyWithEnquiryType";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { OfferDetails } from "./offer-details/OfferDetails";
import { OfferCreationFormDataType } from "./OfferCreationFormOutput";
import {
    createInitialValues,
    Editor,
    NEW_OFFER_DRAFT,
    OfferCreationDocuments,
} from "./OfferEditor";
import WithdrawOffer from "./WithdrawOffer";

type Props = {
    piCover: PICoverParams;
    offerCreationDocuments: OfferCreationDocuments;
    createOffer: (draft: OfferCreationFormDataType) => Promise<OfferOnlyType>;
    onError: (error: Error) => void;
    readonly?: boolean;
} & (
    | {
          offer: OfferOnlyWithEnquiryType;
          editOffer: (
              draft: OfferCreationFormDataType
          ) => Promise<OfferOnlyType>;
          onWithdrawOffer: () => any;
      }
    | {
          offer: undefined;
          editOffer?: undefined;
          onWithdrawOffer?: undefined;
      }
);

export const OfferComponent = (props: Props) => {
    const {
        offer,
        readonly,
        createOffer,
        editOffer,
        onWithdrawOffer,
        onError,
        piCover: { piCoverSpecialArrangementsAvailable },
        piCover,
        offerCreationDocuments,
    } = props;

    // render

    if (!offer) {
        // TODO creation should not include requiredAttachmentsToRemove
        const initialValues = createInitialValues(
            {
                ...NEW_OFFER_DRAFT,

                requiredAttachmentsToRemove: [],
            },
            piCover
        );

        return (
            <Editor
                onSubmit={createOffer}
                initialValues={initialValues}
                piCoverSpecialArrangementsAvailable={
                    piCoverSpecialArrangementsAvailable
                }
                offerCreationDocuments={offerCreationDocuments}
            />
        );
    }

    if (readonly) {
        return <OfferDetails offer={offer} />;
    }

    return (
        <OfferDisplay
            offer={offer}
            piCover={piCover}
            offerCreationDocuments={offerCreationDocuments}
            editOffer={editOffer}
            createOffer={createOffer}
            onWithdrawOffer={onWithdrawOffer}
            onError={onError}
        />
    );
};

type OfferDisplayProps = {
    offer: OfferOnlyWithEnquiryType;
    editOffer: (draft: OfferCreationFormDataType) => Promise<OfferOnlyType>;
    createOffer: (draft: OfferCreationFormDataType) => Promise<OfferOnlyType>;
    onWithdrawOffer: () => any;
    onError: (error: Error) => void;
    piCover: PICoverParams;
    offerCreationDocuments: OfferCreationDocuments;
};

const OfferDisplay = (props: OfferDisplayProps) => {
    const {
        offer: {
            price,
            piCoverValue,
            piCoverPercentage,
            timeline,
            availability,
            status,
            comments,
        },
        offer,
        editOffer,
        createOffer,
        onWithdrawOffer,
        onError,
        piCover: { piCoverSpecialArrangementsAvailable },
        piCover,
        offerCreationDocuments,
    } = props;

    const t = useSmartTranslation();

    const [editing, setEditing] = useState<"EDIT" | "NEW_OFFER" | false>(false);

    // render

    if (editing) {
        const cancelEditing = () => setEditing(false);

        const initialValues = createInitialValues(
            {
                ...NEW_OFFER_DRAFT,

                price: delimitNumber(price),
                piCoverPercentage: delimitNumber(piCoverPercentage),
                piCoverValue: delimitNumber(piCoverValue),
                timeline: timeline.toString(),
                availability: availability,
                comments: comments || "",

                requiredAttachmentsToRemove: [],
            },
            piCover
        );

        const onSubmit = editing === "EDIT" ? editOffer : createOffer;

        return (
            <Editor
                offer={offer}
                initialValues={initialValues}
                piCoverSpecialArrangementsAvailable={
                    piCoverSpecialArrangementsAvailable
                }
                offerCreationDocuments={offerCreationDocuments}
                onSubmit={async (formdata: OfferCreationFormDataType) => {
                    const result = await onSubmit(formdata);
                    cancelEditing();
                    return result;
                }}
                onCancel={cancelEditing}
            />
        );
    }

    let buttons;

    switch (status) {
        case "ACCEPTED": {
            break;
        }
        case "PENDING": {
            const startEditing = (
                <Button
                    width={"100%"}
                    key={"startEditing"}
                    onClick={() => setEditing("EDIT")}
                    variant={"outline"}
                    colorScheme={"blue"}
                >
                    {t("common.button.edit")}
                </Button>
            );

            const withdraw = (
                <WithdrawOffer
                    offer={offer}
                    onSuccess={onWithdrawOffer}
                    onError={onError}
                    width={"100%"}
                    key={"withdraw"}
                />
            );

            buttons = [startEditing, withdraw];
            break;
        }
        case "REJECTED":
        case "WITHDRAWN": {
            const startEditing = (
                <Button
                    flexGrow={1}
                    key={"startEditing"}
                    onClick={() => setEditing("EDIT")}
                    variant={"outline"}
                    colorScheme={"blue"}
                >
                    {t("domain.offer.newOffer")}
                </Button>
            );

            buttons = [startEditing];
            break;
        }
    }

    return (
        <OfferDetails
            offer={offer}
            buttons={buttons}
        />
    );
};
