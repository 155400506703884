export type OfferStatus = (typeof offerStatus)[number];

type TranslationNSObject = {
    [key in OfferStatus]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

const offerStatus = ["ACCEPTED", "PENDING", "REJECTED", "WITHDRAWN"] as const;

export const offerStatusTranslations: Translations = {
    de: {
        ACCEPTED: "Angenommen",
        PENDING: "Unter Prüfung",
        REJECTED: "Abgelehnt",
        WITHDRAWN: "Widerrufen",
    },
    en: {
        ACCEPTED: "Accepted",
        PENDING: "Pending",
        REJECTED: "Rejected",
        WITHDRAWN: "Withdrawn",
    },
};

export const offerWithEnquiryStatusTranslatins = {
    de: {
        VALUER: {
            ACCEPTED: offerStatusTranslations.de.ACCEPTED,
            PENDING: offerStatusTranslations.de.PENDING,
            REJECTED: offerStatusTranslations.de.REJECTED,
            WITHDRAWN: offerStatusTranslations.de.WITHDRAWN,
        },
        CLIENT: {
            ACCEPTED: offerStatusTranslations.de.ACCEPTED,
            PENDING: offerStatusTranslations.de.PENDING,
            REJECTED: offerStatusTranslations.de.REJECTED,
            WITHDRAWN: offerStatusTranslations.de.WITHDRAWN,
        },
    },
    en: {
        VALUER: {
            ACCEPTED: offerStatusTranslations.en.ACCEPTED,
            PENDING: "Client review",
            REJECTED: offerStatusTranslations.en.REJECTED,
            WITHDRAWN: offerStatusTranslations.en.WITHDRAWN,
        },
        CLIENT: {
            ACCEPTED: offerStatusTranslations.en.ACCEPTED,
            PENDING: offerStatusTranslations.en.PENDING,
            REJECTED: offerStatusTranslations.en.REJECTED,
            WITHDRAWN: offerStatusTranslations.en.WITHDRAWN,
        },
    },
};
