import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import Imprint from "../../screens/public/legal/Imprint";
import Privacy from "../../screens/public/legal/Privacy";
import Terms from "../../screens/public/legal/Terms";
import ModalDynamic from "../components/ModalDynamic";
import { useSmartTranslation } from "./useSmartTranslation";

export type LegalDocument = "terms" | "privacy" | "imprint";

const useLegalModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const t = useSmartTranslation();

    const [modalType, setModalType] = useState<
    LegalDocument | undefined
    >();

    const modalHeader = {
        terms: t("legal.termsHeader"),
        imprint: t("legal.imprintExtended"),
        privacy: t("legal.privacyHeader"),
    };

    const modalBody = {
        terms: <Terms />,
        imprint: <Imprint />,
        privacy: <Privacy />,
    };

    const legalModal = modalType && (
        <ModalDynamic
            variant="small"
            isOpen={isOpen}
            header={modalHeader[modalType]}
            onClose={onClose}
        >
            {modalBody[modalType]}
        </ModalDynamic>
    );

    return {
        legalModal,
        isOpenLegalModal: isOpen,
        setLegalType: setModalType,
        openLegalModal: onOpen,
        closeLegalModal: onClose,
    };
};

export { useLegalModal };
