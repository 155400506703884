import { DownloadIcon, Search2Icon } from "@chakra-ui/icons";
import { Flex, IconButton, Spacer, Text } from "@chakra-ui/react";
import i18n from "../../../../../i18n";
import { useFileDownload } from "../../hooks/useFileDownload";
import { FileTypeIcon } from "../../icons/FileTypeIcon";

// TODO type should couple allow for view and download only if url is provided
export type DocumentItemType = {
    name: string;
    url?: string;
    size: number;
};

type PropertyDocumentItemProps = {
    documentItem: DocumentItemType;
    viewDocument?: () => void;
    downloadDocument?: boolean;
    showTypeIcon?: boolean;
};

export const File = (props: PropertyDocumentItemProps) => {
    const {
        documentItem: { name, url, size: fileSize },
        viewDocument: onViewDocument,
        downloadDocument: enableDownload,
        showTypeIcon = true,
    } = props;

    const { downloadFile, isDownloading } = useFileDownload();

    const viewDocument = onViewDocument && (
        <IconButton
            icon={<Search2Icon />}
            size="xs"
            variant="ghost"
            color="gray.500"
            aria-label="search"
            onClick={onViewDocument}
        />
    );

    const downloadDocument = enableDownload && url && (
        <IconButton
            icon={<DownloadIcon />}
            size="xs"
            variant="ghost"
            color="gray.500"
            aria-label="save"
            isDisabled={isDownloading}
            onClick={() => downloadFile(url, name)}
        />
    );

    const size = (
        <Text
            fontSize={"sm"}
            variant={"secondary"}
        >
            {filesizeToString(fileSize)}
        </Text>
    );

    return (
        <Flex
            w={"100%"}
            gap={3}
            align={"center"}
        >
            {showTypeIcon && (
                <FileTypeIcon
                    filetype={getFileTypeBasedOnName(name)}
                    size={6}
                />
            )}
            <Text
                fontSize={"sm"}
            >
                {name}
            </Text>
            <Spacer />
            {size}
            {viewDocument}
            {downloadDocument}
        </Flex>
    );
};

const filesizeToString = (size: number) => {
    let divisor, unit;

    if (size > 1000000) {
        divisor = 1000000;
        unit = "MB";
    } else {
        divisor = 1000;
        unit = "KB";
    }

    return `${(size / divisor).toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
    })} ${unit}`;
};

export const getFileTypeBasedOnName = (fileName: string) => {
    const nName = fileName.split(".");

    if (nName.length === 1) return "pdf";

    return nName[nName.length - 1];
};
