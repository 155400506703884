import { Account } from "./account/Account";
import { EnquiryList } from "./enquiry-list/EnquiryList";
import EnquirySuccess from "./EnquirySuccess";
import { Profile } from "./profile/Profile";

export const PlatformScreens = {
    EnquiryList,
    EnquirySuccess,
    Profile,
    Account,
};
