import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
    As,
    HStack,
    Icon,
    IconButton,
    Spacer,
    StackProps,
    Text,
    useClipboard,
    VStack,
} from "@chakra-ui/react";

type CopyableEntryProps = StackProps & {
    leftIcon?: As;
    label: string;
    labelColor?: string;
    caption?: string;
};

const CopyableEntry = (props: CopyableEntryProps) => {
    const {
        leftIcon,
        label,
        labelColor = "gray.900",
        caption,
        ...stackProps
    } = props;

    const { onCopy, hasCopied } = useClipboard(label);

    // render

    const icon = hasCopied ? (
        <CheckIcon color="green.500" />
    ) : (
        <CopyIcon color="gray.400" />
    );

    return (
        <HStack
            role="group"
            spacing={4}
            borderRadius={"lg"}
            padding={".5rem 1rem"}
            bg={"gray.50"}
            width={"100%"}
            transition={".25s"}
            _hover={{
                bg: "blue.50",
                color: "blue.500",
            }}
            {...stackProps}
        >
            {leftIcon && (
                <Icon
                    as={leftIcon}
                    color={"gray.400"}
                    _groupHover={{
                        color: "blue.500",
                    }}
                />
            )}

            <VStack
                align={"stretch"}
                spacing={0}
            >
                <Text
                    fontSize={"md"}
                    fontWeight={"bold"}
                    color={labelColor}
                >
                    {label}
                </Text>
                {caption && (
                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                    >
                        {caption}
                    </Text>
                )}
            </VStack>
            <Spacer />
            <IconButton
                aria-label=""
                icon={icon}
                isRound={true}
                onClick={onCopy}
                variant={"ghost"}
                _hover={{
                    bg: "white",
                    color: "blue.500",
                }}
            />
        </HStack>
    );
};

export default CopyableEntry;
