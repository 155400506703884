import { Box, Center, Circle, ResponsiveValue } from "@chakra-ui/react";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import ProfilePicture from "../../../../common/components/display/ProfilePicture";
import ProfilePictureDefault from "../../../../common/components/display/ProfilePictureDefault";
import {
    useEntityPublicEntityGetById,
    usePublicEntityProfilePicture,
    usePublicUserProfilePicture,
} from "../../../../utils/api/hooks";

type PropTypes = {
    entityId: number | string;
    userId: number | string;
    profileLogoSize?: ResponsiveValue<number | string>;
    companyLogoSize?: ResponsiveValue<number | string>;
};

const PublicUserProfilePictureWithEntityLogo = (props: PropTypes) => {
    const {
        entityId,
        userId,
        profileLogoSize = 3,
        companyLogoSize = 2,
    } = props;

    const { data: entity, isLoading: entityIsLoading } =
        useEntityPublicEntityGetById(entityId);

    const { data: profilePictureUrl, isLoading: profilePictureUrlIsLoading } =
        usePublicUserProfilePicture(userId);

    const { data: entityLogoUrl, isLoading: entityLogoUrlIsLoading } =
        usePublicEntityProfilePicture(entityId);

    if (
        entityIsLoading ||
        profilePictureUrlIsLoading ||
        entityLogoUrlIsLoading
    ) {
        return (
            <Circle
                size="3rem"
                margin="0"
            >
                <Center
                    w="100%"
                    h="100%"
                >
                    <LoadingSpinner noText />
                </Center>
            </Circle>
        );
    }

    const renderProfilePic = () => {
        if (profilePictureUrl) {
            return (
                <ProfilePicture
                    url={profilePictureUrl}
                    size={`${profileLogoSize}rem`}
                />
            );
        }

        return (
            <ProfilePictureDefault
                accountType={
                    entity?.accountType === "VALUER" ? "VALUER" : "CLIENT"
                }
                userType="USER"
                size={`${profileLogoSize}rem`}
            />
        );
    };

    const renderEntityLogo = () => {
        if (entityLogoUrl) {
            return (
                <ProfilePicture
                    url={entityLogoUrl}
                    size={`${companyLogoSize}rem`}
                />
            );
        }
        return (
            <ProfilePictureDefault
                accountType={
                    entity?.accountType === "VALUER" ? "VALUER" : "CLIENT"
                }
                userType="ENTITY"
                size={`${companyLogoSize}rem`}
            />
        );
    };

    return (
        <Box
            pos={"relative"}
            mr={".5rem"}
            mb={".5rem"}
        >
            {renderProfilePic()}

            <Box
                pos={"absolute"}
                right={"-15%"}
                bottom={"-15%"}
            >
                {renderEntityLogo()}
            </Box>
        </Box>
    );
};

export default PublicUserProfilePictureWithEntityLogo;
