import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { IDocument } from "react-doc-viewer";
import DocumentViewer from "../components/DocumentViewer";
import ModalDynamic from "../components/ModalDynamic";

export type ViewedDocument = IDocument & {
    label: string;
};

const useDocumentViewerModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [viewedDocument, setViewedDocument] = useState<ViewedDocument>();

    const documentView = viewedDocument && (
        <ModalDynamic
            size="full"
            isOpen={isOpen}
            header={viewedDocument.label}
            onClose={onClose}
        >
            <DocumentViewer
                documents={[viewedDocument]}
                height={"calc(100vh - 70px)"}
            />
        </ModalDynamic>
    );

    return {
        documentView,
        isOpenDocumentView: isOpen,
        setViewedDocument,
        openDocumentView: onOpen,
        closeDocumentView: onClose,
    };
};

export { useDocumentViewerModal };
