import { Icon, IconProps } from "@chakra-ui/react";

const WvwLogoIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 22 14"
        h="auto"
        {...props}
    >
        <path
            d="M6.79979 1.35052C6.71286 1.15407 6.85093 0.923828 7.06576 0.923828H10.9574C12.9949 1.0705 14.1592 3.12387 13.1405 4.8839L8.77434 12.5107C7.90112 13.9774 6.44574 14.2707 5.13591 13.5374C3.97161 12.8041 3.53499 11.1907 4.40822 9.87066L6.44574 6.20393C7.25309 4.57667 7.56281 3.07478 6.79979 1.35052Z"
            fill="currentColor"
        />
        <path
            d="M14.6633 1.35052C14.5763 1.15407 14.7144 0.923828 14.9292 0.923828H18.8209C20.8584 1.0705 22.0227 3.12387 21.0039 4.8839L16.6378 12.5107C15.7646 13.9774 14.3092 14.2707 12.9994 13.5374C11.8351 12.8041 11.3985 11.1907 12.2717 9.87066L14.3092 6.20393C15.1166 4.57667 15.4263 3.07478 14.6633 1.35052Z"
            fill="currentColor"
        />
        <path
            d="M5.525 3.60227C5.525 4.1383 5.3626 4.63754 5.08291 5.05629C4.73128 5.58273 3.69769 7.59595 3.13607 8.51719C2.9681 8.79271 2.5569 8.79271 2.38893 8.51719C1.82731 7.59595 0.793722 5.58273 0.442092 5.05629C0.162398 4.63754 0 4.1383 0 3.60227C0 2.12301 1.23681 0.923828 2.7625 0.923828C4.28819 0.923828 5.525 2.12301 5.525 3.60227Z"
            fill="currentColor"
        />
    </Icon>
);

export default WvwLogoIcon;
