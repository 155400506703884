import { Box, Flex, Spacer, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import LanguageToggleButton from "../../../LanguageToggleButton";
import ProgressTabs from "../../../ProgressTabs";
import Footer from "../../../Footer";
import Logo from "../../../Logo";
import { WVWTitle } from "../../../typography";
import { wvwPalette } from "../../../../../theme";
import { PageTitleBar } from "../../../../../ui/v2/common/components/PageTitleBar";

type PropTypes = {
    steps: string[];
    currentStep: string;
    children: ReactNode | ReactNode[];
};

const EnquiryContainer = (props: PropTypes) => {
    const { steps, currentStep, children } = props;

    const { t } = useTranslation("enquiryform");

    return (
        <Box
            h={window.innerHeight}
            minWidth="min-content"
            width="100%"
        >
            <VStack
                align="center"
                borderRadius="10px"
                paddingBottom=".5rem"
                w="100%"
            >
                <PageTitleBar
                    leftContent={
                        <WVWTitle
                            color="white"
                            level="1"
                            content={t("newEnquiry.sectionTitle")}
                        />
                    }
                    rightContent={<LanguageToggleButton />}
                />

                <ProgressTabs
                    currentStep={currentStep}
                    steps={steps}
                />

                <VStack
                    spacing={4}
                    w="100%"
                    maxWidth="1600px"
                    marginLeft="auto"
                    marginRight="auto"
                    paddingInline={["2rem", "2rem", "4rem", "8rem"]}
                    paddingBlock="1rem"
                >
                    {children}
                </VStack>
            </VStack>

            <Footer />
        </Box>
    );
};

export default EnquiryContainer;
