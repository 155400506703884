import { useState } from "react";
import { auth } from "../../../../utils/firebase";

export const useFileDownload = () => {
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadFile = async (url: string, fileName?: string) => {
        setIsDownloading(true);

        try {
            const token = await auth.currentUser?.getIdToken();
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");

            link.href = downloadUrl;
            link.download = fileName || "WVW";
            document.body.appendChild(link);
            link.click();

            // Cleaning stuff
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (err) {
            console.log(err);
        } finally {
            setIsDownloading(false);
        }
    };

    return {
        downloadFile,
        isDownloading,
    };
};
