import { CloseIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    HStack,
    Icon,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { ArrowRight2 } from "iconsax-react";
import { ReactNode } from "react";
import { FiCheck, FiMessageSquare, FiTrash } from "react-icons/fi";
import { Link as ReactLink } from "react-router-dom";
import { displayDateWithCurrentLocale } from "../../../../../../../../common/functions/displayDateInLocale";
import {
    OfferOnlyType,
    OfferType,
    OfferWithEnquiry,
} from "../../../../../../../../types";
import {
    useMyEntity,
    useOfferGetByEnquiryId,
} from "../../../../../../../../utils/api/hooks";
import { Offer } from "../../../../../../domain";
import useRejectOffer from "../../../../../../domain/offer/useRejectOffer";
import { DataTableColumnType } from "../../../../../components/data-table/DataTable";
import { PanelRelationshipMarker } from "../../../../../components/display";
import PublicUserProfilePictureWithEntityLogo from "../../../../../components/PublicUserProfilePictureWithEntityLogo";
import { useDevice } from "../../../../../hooks/useDevice";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../../../../hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../../MISSING_TRANSLATION";
import { EnrichedDataType } from "../../../enricher";

export type EnrichedOfferWithEnquiry = EnrichedDataType<OfferWithEnquiry>;
export type EnrichedOffer = EnrichedDataType<OfferType>;
export type EnrichedOfferTableColumn = DataTableColumnType<EnrichedOffer>;

const createOfferRenderer = (
    dataName: keyof OfferOnlyType,
    renderField: (offer: OfferOnlyType) => ReactNode
) => {
    return (o: EnrichedDataType<OfferOnlyType>) => {
        const { data: offer, highlights } = o;

        const color = highlights[dataName] ? "green.500" : "";
        const fontWeight = highlights[dataName] ? "bold" : "";
        const columnData = renderField(offer);

        const t = useSmartTranslation();

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    fontSize={"sm"}
                    color={color}
                    fontWeight={fontWeight}
                >
                    {columnData || "-"}
                </Text>
                {highlights[dataName] &&
                    highlights[dataName].map((highlight) => (
                        <Text
                            key={highlight.keyword}
                            textAlign="right"
                            variant="secondary"
                            fontSize="xs"
                        >
                            {t(
                                "common.features.enrichment.domain.offer." +
                                    highlight.keyword,
                                { defaultValue: MISSING_TRANSLATION }
                            )}
                        </Text>
                    ))}
            </VStack>
        );
    };
};

export const valuerProfile: EnrichedOfferTableColumn = {
    alignment: "left",
    title: (t) => t("domain.offer.columns.valuer"),
    render: (o: EnrichedDataType<OfferType>, t) => {
        const { data: offer } = o;

        const { data: myEntity } = useMyEntity();

        const device = useDevice();

        const isInClientPanel =
            myEntity.panelValuersIds &&
            myEntity.panelValuersIds.find(
                (panelValuers) => panelValuers === offer.fromEntityId
            );

        return (
            <HStack spacing={2}>
                <Box position={"relative"}>
                    {isInClientPanel && (
                        <PanelRelationshipMarker
                            tooltip={t("domain.offer.columns.valuerTooltip")}
                        />
                    )}

                    <PublicUserProfilePictureWithEntityLogo
                        entityId={offer.fromEntityId}
                        userId={offer.fromValuerId}
                        profileLogoSize={2.5}
                        companyLogoSize={1.5}
                    />
                </Box>
                {(device === "mobile" || device === "wide") && (
                    <VStack
                        alignItems="flex-start"
                        spacing="0"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            {offer.fromValuer.firstName}{" "}
                            {offer.fromValuer.lastName}
                        </Text>
                        <Text
                            color="gray.500"
                            fontSize={"xs"}
                        >
                            {offer.fromValuer.workingForCompanyName}
                        </Text>
                    </VStack>
                )}
            </HStack>
        );
    },
};

export const commment: EnrichedOfferTableColumn = {
    alignment: "right",
    render: (o: EnrichedDataType<OfferType>, t) => {
        // Used can click on this to view comments
        const {
            data: { comments },
        } = o;

        return (
            comments && (
                <Tooltip
                    label={t("domain.offer.columns.hasCommentTooltip")}
                    hasArrow={true}
                    shouldWrapChildren={true}
                >
                    <Icon
                        as={FiMessageSquare}
                        boxSize="24px"
                        color="gray.400"
                    />
                </Tooltip>
            )
        );
    },
};

export const piCoverValue: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.piCoverValue"),
    render: createOfferRenderer(
        "piCoverValue",
        ({ piCoverValue }) => piCoverValue && `€${piCoverValue}`
    ),
};

export const piCoverPercentage: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => "[%]",
    render: createOfferRenderer(
        "piCoverPercentage",
        ({ piCoverPercentage }) => piCoverPercentage && `${piCoverPercentage}%`
    ),
};

export const specialPi: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.specialPi"),
    render: (offer: EnrichedDataType<OfferType>) => {
        const specialPiIcon = offer.data.fromEntity
            .piCoverSpecialArrangementsAvailable ? (
            <FiCheck
                color="green"
                size="24px"
            />
        ) : (
            <CloseIcon
                boxSize="10px"
                color="gray.400"
            />
        );

        return <Box>{specialPiIcon}</Box>;
    },
};

export const availability: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.earliestAvailability"),
    render: createOfferRenderer("availability", ({ availability }) => {
        if (!availability) return null;
        const availabilityDate = new Date(availability);
        return displayDateWithCurrentLocale(availabilityDate);
    }),
};

export const workingTime: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.workingTime"),
    render: (o: EnrichedDataType<OfferType>, t: TranslationFunction) =>
        createOfferRenderer(
            "timeline",
            ({ timeline }) =>
                timeline && `${timeline} ${t("domain.offer.columns.days")}`
        )(o),
};

export const EARLIEST_DELIVERY_FIELDNAME = "earliestDelivery";

export const delivery: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.delivery"),
    render: (enrichedOffer: EnrichedDataType<OfferType>, t) => {
        const { highlights, data: offer } = enrichedOffer;

        // basically C&P from createRenderFunction
        const dataName = EARLIEST_DELIVERY_FIELDNAME;

        const color = highlights[dataName] ? "green.500" : "";
        const fontWeight = highlights[dataName] ? "bold" : "";

        const earliestAvailability = Offer.calculateDeliveryDate(offer);

        const columnData = displayDateWithCurrentLocale(earliestAvailability);

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    color={color}
                    fontWeight={fontWeight}
                    fontSize={"sm"}
                >
                    {columnData || "-"}
                </Text>
                {highlights[dataName] &&
                    highlights[dataName].map((highlight) => (
                        <Text
                            key={highlight.keyword}
                            textAlign="right"
                            variant="secondary"
                            fontSize="xs"
                        >
                            {t(
                                "common.features.enrichment.domain.offer." +
                                    highlight.keyword,
                                { defaultValue: MISSING_TRANSLATION }
                            )}
                        </Text>
                    ))}
            </VStack>
        );
    },
};

// TODO refactor createRenderFunction to be able to be used here
export const deliveryAndTimeline: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.deliveryAndTimeline"),
    render: (enrichedOffer: EnrichedDataType<OfferType>, t) => {
        const {
            highlights,
            data: { timeline },
            data: offer,
        } = enrichedOffer;

        // basically C&P from createRenderFunction
        const dataName = EARLIEST_DELIVERY_FIELDNAME;

        const color = highlights[dataName] ? "green.500" : "";
        const fontWeight = highlights[dataName] ? "bold" : "";

        const earliestAvailability = Offer.calculateDeliveryDate(offer);

        const earliestAvailabilityText =
            displayDateWithCurrentLocale(earliestAvailability);
        const timelineText = `(${timeline} ${t("domain.offer.columns.days")})`;

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    color={color}
                    fontWeight={fontWeight}
                    fontSize={"sm"}
                >
                    {earliestAvailabilityText || "-"}
                </Text>
                <Text
                    textAlign="right"
                    color={color}
                    fontWeight={fontWeight}
                    fontSize={"sm"}
                >
                    {timelineText || "-"}
                </Text>
                {highlights[dataName] &&
                    highlights[dataName].map((highlight) => (
                        <Text
                            key={highlight.keyword}
                            textAlign="right"
                            variant="secondary"
                            fontSize="xs"
                        >
                            {t(
                                "common.features.enrichment.domain.offer." +
                                    highlight.keyword,
                                { defaultValue: MISSING_TRANSLATION }
                            )}
                        </Text>
                    ))}
            </VStack>
        );
    },
};

export const price: EnrichedOfferTableColumn = {
    alignment: "right",
    title: (t) => t("domain.offer.columns.price"),
    render: createOfferRenderer(
        "price",
        ({ price }) => price != undefined && <strong>{`€${price}`}</strong>
    ),
};

export const view: EnrichedOfferTableColumn = {
    render: (o: EnrichedDataType<OfferType>, t: TranslationFunction) => {
        return (
            <Button
                size="sm"
                as={ReactLink}
                to={`/dashboard/enquiry/${o.data.enquiryId}/offer/${o.data.id}`}
                variant="primary"
                onClick={(e) => e.stopPropagation()}
                children={t("domain.offer.columns.check")}
                rightIcon={<ArrowRight2 size="16" />}
            />
        );
    },
};

export const deleteOffer: DataTableColumnType<
    EnrichedDataType<OfferWithEnquiry>
> = {
    render: (o) => {
        const { data: offer } = o;

        const { refresh: refreshOffers } = useOfferGetByEnquiryId(
            Number(offer.enquiryId)
        );

        const { rejectOffer, hasPermissions, confirmationModal } =
            useRejectOffer({
                offer,
                refresh: refreshOffers,
                enquiry: offer.enquiry,
            });

        return (
            <Button
                isDisabled={!hasPermissions}
                variant="outline"
                height="32px"
                onClick={(e) => {
                    rejectOffer();
                    e.stopPropagation();
                }}
                borderColor="red"
                iconSpacing="0"
                rightIcon={
                    <FiTrash
                        color="red"
                        size="16px"
                    />
                }
            >
                {confirmationModal}
            </Button>
        );
    },
};

export const enrichedOfferColumns = {
    valuerProfile,
    commment,
    piCoverValue,
    piCoverPercentage,
    specialPi,
    availability,
    workingTime,
    delivery,
    deliveryAndTimeline,
    price,
    view,
    deleteOffer,
};
