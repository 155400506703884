import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";

export const translations = {
    de: {
        enquiryTitle: {
            detailsAvailableAfterOfferAcceptance:
                "Kundendetails verfügbar nach Angebotsannahme",
        },
        companyOffers: {
            title: "Team-Zuordnung",
            noData: "Keine Teammitglieder zugeordnet",
            selectedValuers: "Zugeordnete Wertgutachter",
            noUnassignedValuers: "Keine weiteren Wertgutachter vorhanden",
            filterFunction: {
                qualifiedOnly: "Qualifikation",
                inRangeOnly: "Distanz",
                display: "Anzeige beschränken auf geeignete",
            },
            headers: {
                valuer: "Wertgutachter",
                officeLocation: "Bürostandort",
                qualification: "Qualifikation",
                inRange: "Distanz",
                offersPending: "Lfd. Angebote",
            },
            submissionNotice: "Bitte bachten: Zugeordnete Wertgutachter können die Anfrage einsehen und ein Angebot abgeben. Sie können diese Zuordnung nicht zurück nehmen, jedoch können Sie die Angebote der Kollegen widerrufen.",
        },
        myOffer: {
            title: "Mein Angebot",
        },
        valuerStatus: {
            awaitingOffer: "Angebot ausstehend",
        },
        offerSubmission: {
            noValuationCapabilities: "Für die Abgabe eines Angebots müssen Sie in Ihrem Profil angeben, dass sie Gutachten erstellen."
        },
    },
    en: {
        enquiryTitle: {
            detailsAvailableAfterOfferAcceptance:
                "Client details available after offer acceptance",
        },
        companyOffers: {
            title: "Team Assignments",
            noData: "No Matching Team Members",
            selectedValuers: "Selected Valuers",
            noUnassignedValuers: "No Unassigned Valuers",
            filterFunction: {
                qualifiedOnly: "Qualified Only",
                inRangeOnly: "In-Range Only",
                display: "Display",
            },
            headers: {
                valuer: "Valuer",
                officeLocation: "Office Location",
                qualification: "Qualification",
                inRange: "In-Range",
                offersPending: "Offers Pending",
            },
            submissionNotice: "Please note! Valuers assigned to enquiry will be able to submit an offer and can not be unassigned. You will still have the ability to withdraw your employee's offers.",
        },
        myOffer: {
            title: "My Offer",
        },
        valuerStatus: {
            awaitingOffer: "Awaiting Offer",
        },
        offerSubmission: {
            noValuationCapabilities: "Valuation capabilities are required to submit an offer."
        },
    },
};
