import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { Building } from "iconsax-react";
import { useMemo } from "react";
import { FiMail, FiPhone, FiSmartphone } from "react-icons/fi";
import { EnquiryType, EntityType, UserType } from "../../../../../../types";
import useMe from "../../../../app/useMeV2";
import {
    CopyableEntry,
    GoogleMapV2,
    MapMarkerType,
    PanelRelationshipMarker,
} from "../../../../common/components/display";
import PublicUserProfilePictureWithEntityLogo from "../../../../common/components/PublicUserProfilePictureWithEntityLogo";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { calculateDistance } from "../../../../domain/enquiry/enquiryFunctions";
import { useDevice } from "../../../../common/hooks/useDevice";

type ValuerDetailsProps = {
    valuer: UserType;
    entity: EntityType;
    enquiry: EnquiryType;
};

const ValuerDetails = (props: ValuerDetailsProps) => {
    const {
        valuer: {
            id: valuerId,
            email,
            street,
            postalCode,
            city,
            officeTel,
            officeTelDialCode,
            mobile,
            mobileDialCode,
            firstName,
            lastName,
            orgPosition,
            longitude,
            latitude,
        },
        entity,
        enquiry: { valuerMaxOfficeDistance: requiredDistance, properties },
    } = props;

    const t = useSmartTranslation();

    const device = useDevice();

    const { data: meData } = useMe();

    const propertiesDistance = useMemo(() => {
        const distance = properties
            .map((property) =>
                calculateDistance(
                    { latitude, longitude },
                    {
                        latitude: property.latitude!,
                        longitude: property.longitude!,
                    }
                )
            )
            .sort();

        return distance;
    }, [latitude, longitude, properties]);

    const isInClientPanel =
        meData?.entity.panelValuersIds &&
        meData.entity.panelValuersIds.find(
            (panelValuers) => panelValuers === entity.id
        );

    const markers: MapMarkerType[] = [
        {
            id: valuerId,
            lat: +latitude,
            lng: +longitude,
        },
    ];

    // render

    const banner = (
        <HStack
            borderRadius={"lg"}
            padding={"1rem"}
            bg={"gray.50"}
            spacing={4}
            mb={2}
        >
            <Box position={"relative"}>
                {isInClientPanel && (
                    <PanelRelationshipMarker
                        tooltip={t("domain.offer.columns.valuerTooltip")}
                        size={"2rem"}
                        right={"-.3rem"}
                    />
                )}

                <PublicUserProfilePictureWithEntityLogo
                    entityId={entity.id}
                    userId={valuerId}
                    profileLogoSize={5}
                    companyLogoSize={3}
                />
            </Box>
            <Box>
                <Heading size={"lg"}>
                    {firstName} {lastName}
                </Heading>
                <Text
                    fontSize={"xl"}
                    color={"gray.500"}
                >
                    {orgPosition && `${orgPosition}${t("domain.client.at")}`}
                    {entity.name}
                </Text>
            </Box>
        </HStack>
    );

    const addressMatch =
        requiredDistance !== "none"
            ? propertiesDistance[properties.length - 1] <= +requiredDistance
            : true;

    const entries = (
        <VStack width={"100%"}>
            <CopyableEntry
                leftIcon={Building}
                label={`${street}, ${postalCode} ${city}`}
                caption={t(
                    `screens.platform.client.offerDetails.valuerDetails.${
                        propertiesDistance.length > 1
                            ? "propertyClosestDistance"
                            : "propertyDistance"
                    }`,
                    { distance: propertiesDistance[0].toFixed(1) }
                )}
                {...(addressMatch && {
                    bg: "green.50",
                    labelColor: "green.500",
                    _hover: {},
                })}
            />
            <CopyableEntry
                leftIcon={FiMail}
                label={email}
            />
            {officeTel && (
                <CopyableEntry
                    leftIcon={FiPhone}
                    label={`${officeTelDialCode} ${officeTel}`}
                />
            )}
            {mobile && (
                <CopyableEntry
                    leftIcon={FiSmartphone}
                    label={`${mobileDialCode} ${mobile}`}
                />
            )}
        </VStack>
    );

    const map = (
        <Box
            width={"100%"}
            h={device === "tablet" ? "15rem" : "auto"}
            borderRadius={"lg"}
            overflow={"hidden"}
        >
            <GoogleMapV2
                height={"100%"}
                markers={markers}
            />
        </Box>
    );

    return (
        <>
            {banner}
            <HStack
                spacing={2}
                align={"normal"}
                flexFlow={device === "tablet" ? "column" : "row"}
            >
                {entries}
                {map}
            </HStack>
        </>
    );
};

export { ValuerDetails };
