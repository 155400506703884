import { SimpleGrid, VStack } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../../../../../common/hooks";
import {
    EntityType,
    OfferOnlyType,
    OfferType,
    UserType,
} from "../../../../../../types";
import {
    useEnquiry,
    useEntityPublicEntityGetById,
    useMyEntity,
    useMyValuerPanels,
    useOfferCreate,
    useOfferEdit,
    useOfferGetAllMyOffers,
    useOfferGetMyOfferByEnquiryId,
    usePublicUser,
    useUsersByEntity,
} from "../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { Placeholder } from "../../../../common/components/Placeholder";
import { useDevice } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Offer, PICoverParams, User } from "../../../../domain";
import {
    EnquiryMatchInfo,
    EnquiryMatchInfoWithValuerAndOffer,
} from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import matchEnquiryWithValuer from "../../../../domain/enquiry/enquiryRequirements/matchEnquiryWithValuer";
import { EnquiryWithRecipientInfo } from "../../../../domain/enquiry/Title";
import { OfferCreationFormDataType } from "../../../../domain/offer/OfferCreationFormOutput";
import { OfferCreationDocuments } from "../../../../domain/offer/OfferEditor";
import { Section } from "../../../../layout";
import { ValuerEnquiryTitle } from "./EnquiryTitle";
import MultiPropertyLayout from "./MultiPropertyLayout";
import SinglePropertyLayout from "./SinglePropertyLayout";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type EnquiryDetailsProps = {
    user: User;
};

const EnquiryDetails = (props: EnquiryDetailsProps) => {
    const { user } = props;

    const t = useSmartTranslation();

    const { createErrorToast } = useToast();

    const { enquiryId } = useParams();

    const {
        entityCertificates = [],
        entityCertificatesIsLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const { data: offers = [], isLoading: offersLoading } =
        useOfferGetAllMyOffers();

    const {
        data: offer,
        isLoading: myOfferLoading,
        refresh: refreshMyOffer,
    } = useOfferGetMyOfferByEnquiryId(enquiryId);

    const {
        usersList = [],
        isLoading: usersLoading,
        refresh: refreshUsersList,
    } = useUsersByEntity(user?.entityId);

    const {
        data: clientData,
        isLoading: clientDataIsLoading,
        refresh: refreshClientData,
    } = usePublicUser(Number(enquiry?.fromUserId));

    const {
        data: panelValuerList,
        isLoading: isLoadingPanelValuerList,
        refresh: refreshPanelValuerList,
    } = useMyValuerPanels();

    const { data: recipientEntityData, isLoading: recipientEntityDataLoading } =
        useEntityPublicEntityGetById(enquiry?.recipient);

    const [matchInfo, setMatchinfo] = useState<EnquiryMatchInfo | undefined>();

    useEffect(() => {
        if (enquiry) {
            const getMatchInfo = async () => {
                const matchInfo = await matchEnquiryWithValuer(
                    enquiry.id,
                    user.id
                );
                setMatchinfo(matchInfo);
            };

            try {
                getMatchInfo();
            } catch (e) {
                createErrorToast(t("common.error.error"));
            }
        }
    }, [enquiry]);

    const [companyMatchInfoList, setCompanyMatchInfoList] = useState<
        EnquiryMatchInfoWithValuerAndOffer[] | undefined
    >([]);

    useEffect(() => {
        if (enquiry && usersList.length > 0) {
            const getMatchInfo = async () => {
                try {
                    const unmatchedValuersWithUserExclusion = usersList.filter(
                        (valuer) =>
                            valuer.id !== user.id &&
                            !enquiry.toUserIds.includes(valuer.id)
                    );

                    const unmatchedValuersWithValuationCapabilities = unmatchedValuersWithUserExclusion.filter(
                        (valuer) =>
                            valuer.doesValuations
                    );

                    const matchInfoList = await Promise.all(
                        unmatchedValuersWithValuationCapabilities.map(
                            async (valuer) => {
                                const valuerIdNumber = Number(valuer.id);
                                const matchInfo = await matchEnquiryWithValuer(
                                    enquiry.id,
                                    valuerIdNumber
                                );

                                const offersList = offers.filter(
                                    (offer) =>
                                        offer.fromValuerId === valuerIdNumber
                                );

                                const fullMatchInfo = {
                                    ...matchInfo,
                                    valuerId: valuerIdNumber,
                                    offers: offersList,
                                };

                                return fullMatchInfo;
                            }
                        )
                    );
                    setCompanyMatchInfoList(matchInfoList);
                } catch (e) {
                    createErrorToast(t("common.error.error"));
                }
            };

            getMatchInfo();
        }
    }, [enquiry, usersList]);

    if (
        enquiryLoading ||
        entityCertificatesIsLoading ||
        entitySampleValuationsIsLoading ||
        myOfferLoading ||
        clientDataIsLoading ||
        !clientData ||
        isLoadingPanelValuerList ||
        !panelValuerList ||
        recipientEntityDataLoading ||
        usersLoading ||
        offersLoading
    )
        return <LoadingSpinner />;

    // actually: if there's no enquiry we should show a 404 or so
    if (!enquiry || !matchInfo || !companyMatchInfoList)
        return <LoadingSpinner />;

    const onUpdate = () => {
        refreshEnquiry();
        refreshMyOffer();
        refreshClientData();
        refreshPanelValuerList();
        refreshUsersList();
    };
    const onError = (error: Error) => createErrorToast(error.message);

    const offerCreationDocuments = {
        offerDocuments: entityCertificates.filter(
            (i) => i.type.toLowerCase() === "offer"
        ),
        marketingDocuments: entityCertificates.filter(
            (i) => i.type.toLowerCase() === "marketing"
        ),
        piCertificateDocuments: entityCertificates.filter(
            (cert) => cert.type.toLowerCase() === "pi"
        ),
        sampleDocuments: entitySampleValuations,
    };

    return (
        <EnquiryDetailsScreen
            clientData={clientData}
            myOffer={offer}
            offers={offers}
            enquiry={{
                ...enquiry,
                recipientInfo: recipientEntityData,
            }}
            matchInfo={matchInfo}
            companyMatchInfoList={companyMatchInfoList}
            user={user}
            valuerId={user}
            panelValuerList={panelValuerList}
            offerCreationDocuments={offerCreationDocuments}
            usersList={usersList}
            onUpdate={onUpdate}
            onError={onError}
        />
    );
};

type EnquiryDetailsScreenProps = {
    clientData: UserType;
    myOffer?: OfferType;
    offers: OfferOnlyType[];
    enquiry: EnquiryWithRecipientInfo;
    matchInfo: EnquiryMatchInfo;
    companyMatchInfoList: EnquiryMatchInfoWithValuerAndOffer[];
    user: UserType;
    valuerId: User;
    panelValuerList: EntityType[];
    usersList: UserType[];
    offerCreationDocuments: OfferCreationDocuments;
    onUpdate: () => any;
    onError: (error: Error) => any;
};

const EnquiryDetailsScreen = (props: EnquiryDetailsScreenProps) => {
    const {
        clientData,
        myOffer,
        offers,
        offerCreationDocuments,
        matchInfo,
        companyMatchInfoList,
        enquiry,
        user,
        usersList,
        user: {
            entity: {
                piCoverSpecialArrangementsAvailable,
                piCoverValue,
                piCoverPercentage,
            },
        },
        panelValuerList,
        onUpdate,
        onError,
    } = props;

    const device = useDevice();

    const t = useSmartTranslation();

    const hasMultipleProperties = enquiry.properties.length > 1;
    const offerIsReadonly = enquiry.status !== "PENDING";
    const userDoesntDoValuations = !user.doesValuations;

    // enquiry
    const title = (
        <ValuerEnquiryTitle
            client={clientData}
            enquiry={enquiry}
            offer={myOffer}
            panelValuerList={panelValuerList}
            onUpdate={onUpdate}
            onError={onError}
        />
    );

    const { updateAsync: createOffer } = useOfferCreate({
        onSuccess: onUpdate,
        onError,
    });

    const { editAsync: editOffer } = useOfferEdit({
        onSuccess: onUpdate,
        onError,
    });

    const piCover: PICoverParams = {
        defaultPICoverValue: piCoverValue,
        defaultPICoverPercentage: piCoverPercentage,
        piCoverSpecialArrangementsAvailable,
    };

    const createFromDraft = (draft: OfferCreationFormDataType) => {
        const dto = Offer.enforceEntityPICover(
            {
                ...draft,
                fromValuerId: user.id,
                fromEntityId: user.entityId,
                toEntityId: enquiry.fromEntityId,
                enquiryId: enquiry.id,
            },
            piCover
        );

        return createOffer(dto);
    };

    let editFromDraft;
    let myOfferEditor;

    if (myOffer) {
        editFromDraft = (draft: OfferCreationFormDataType) => {
            const dto = Offer.enforceEntityPICover(
                {
                    ...draft,
                    offerId: myOffer.id,
                },
                piCover
            );

            return editOffer(dto);
        };
        myOfferEditor = (
            <Section
                collapsable
                title={t(`${translationNS}.myOffer.title`)}
                content={
                    <Offer.Offer
                        offer={{
                            ...myOffer,
                            enquiry,
                        }}
                        piCover={piCover}
                        offerCreationDocuments={offerCreationDocuments}
                        readonly={offerIsReadonly}
                        createOffer={createFromDraft}
                        editOffer={editFromDraft}
                        onWithdrawOffer={onUpdate}
                        onError={onError}
                    />
                }
            />
        );
    } else {
        if (offerIsReadonly || userDoesntDoValuations) {
            myOfferEditor = (
                <Placeholder
                    noDataText={
                        userDoesntDoValuations
                            ? t(
                                `${translationNS}.offerSubmission.noValuationCapabilities`
                            )
                            : undefined
                    }
                />
            );
        } else {
            myOfferEditor = (
                <Section
                    collapsable
                    title={t(`${translationNS}.myOffer.title`)}
                    content={
                        <Offer.Offer
                            offer={myOffer}
                            piCover={piCover}
                            offerCreationDocuments={offerCreationDocuments}
                            createOffer={createFromDraft}
                            onError={onError}
                        />
                    }
                />
            );
        }
    }

    // render

    return (
        <VStack
            w={"100%"}
            p={4}
            spacing={4}
        >
            {title}
            <SimpleGrid
                width={"100%"}
                spacing={2}
                gridTemplateColumns={
                    device === "mobile" || device === "tablet"
                        ? "minmax(0, 55%) minmax(0, 45%)"
                        : "minmax(0, 40%) minmax(0, 1fr) minmax(0, 30%)"
                }
            >
                {hasMultipleProperties ? (
                    <MultiPropertyLayout
                        enquiry={enquiry}
                        matchInfo={matchInfo}
                        companyMatchInfoList={companyMatchInfoList}
                        device={device}
                        myOffer={myOfferEditor}
                        offers={offers}
                        usersList={usersList}
                        onUpdate={onUpdate}
                        user={user}
                    />
                ) : (
                    <SinglePropertyLayout
                        enquiry={enquiry}
                        matchInfo={matchInfo}
                        companyMatchInfoList={companyMatchInfoList}
                        device={device}
                        myOffer={myOfferEditor}
                        offers={offers}
                        usersList={usersList}
                        onUpdate={onUpdate}
                        user={user}
                    />
                )}
            </SimpleGrid>
        </VStack>
    );
};

export default EnquiryDetails;
