import { Box, Link as ChakraLink, Flex, HStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link as ReactLink } from "react-router-dom";
import { ThemeV2Wrapper } from "../../app/ThemeV2Wrapper";
import { wvwPalette } from "../../theme/theme-v2";
import Logo from "./display/Logo";

type Props = {
    leftContent?: ReactNode;
    rightContent?: ReactNode;
};

export const PageTitleBar = ({ leftContent, rightContent }: Props) => {
    return (
        <ThemeV2Wrapper>
            <Box
                position={"sticky"}
                top={0}
                bg={wvwPalette.wvwBackground.menubarMain}
                width="100%"
                zIndex={9}
            >
                <Flex
                    w="100%"
                    alignItems="center"
                    paddingInline="2rem"
                    justifyContent="space-between"
                    marginLeft="auto"
                    marginRight="auto"
                >
                    <HStack>
                        <ChakraLink
                            paddingBlock=".8rem"
                            as={ReactLink}
                            to="/dashboard"
                        >
                            <Logo />
                        </ChakraLink>

                        {leftContent}
                    </HStack>

                    {rightContent}
                </Flex>
            </Box>
        </ThemeV2Wrapper>
    );
};
