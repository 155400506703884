import { PropertyType } from "../../../../types";
import { GoogleStreetView } from "../../common/components/display/GoogleStreetView";

type Props = {
    property: PropertyType;
};

export const PropertyStreetView = (props: Props) => {
    const { property } = props;

    const location = {
        lat: Number(property.latitude),
        lng: Number(property.longitude),
    };

    return (
        <GoogleStreetView
            height="20rem"
            location={location}
        />
    );
};
