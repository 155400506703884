export const displayDateInLocale = (date: Date | string, locale: string) => {
    if (locale === "") throw new Error("No locale provided");

    const dateString = new Date(date).toLocaleDateString(locale);

    const speratorRegex = /[^0-9]/g;
    const seperatorIndex = dateString.search(speratorRegex);
    const seperator = dateString[seperatorIndex];

    return dateString
        .split(speratorRegex)
        .map((i) => i.length === 1 ? `0${i}` : i)
        .join(seperator);
};

export const displayDateWithCurrentLocale = (date: Date | string) => {
    const locale = localStorage.getItem("i18nextLng") || "";

    return displayDateInLocale(date, locale);
};
