import ArchiveEnquiry from "./ArchiveEnquiry";
import CancelEnquiry from "./CancelEnquiry";
import { enquiryColumns } from "./columns/EnquiryColumns";
import { enquiryWithOfferColumns } from "./columns/EnquiryWithOfferColumns";
import {
    calculateDistance,
    determineStandard,
    determineType,
    getDeliveryDate,
} from "./enquiryFunctions";
import { EnquiryRequirements } from "./enquiryRequirements/EnquiryRequirements";
import EnquiryStatusText from "./EnquiryStatusText";
import { translations } from "./Enquiry.i18n";
import { includeEntityInUser } from "./includeEntityInUser";
import { MatchmakingInfo } from "./MatchmakingInfo";
import { Title } from "./Title";
import { ValuationPurposes } from "./ValuationPurposes";

export const Enquiry = {
    Title,
    Requirements: EnquiryRequirements,
    TableColumns: {
        Enquiry: enquiryColumns,
        EnquiryWithOffer: enquiryWithOfferColumns,
    },
    MatchmakingInfo,
    translations,
    getDeliveryDate,
    archive: ArchiveEnquiry,
    cancel: CancelEnquiry,
    calculateDistance,
    determineStandard,
    determineType,
    includeEntityInUser,
    StatusText: EnquiryStatusText,
};

export const valuationPurposes = {
    ValuationPurposes,
};
