import { Box } from "@chakra-ui/react";
import { UserType } from "../../../../../types";
import { GoogleMapV2, MapMarkerType } from "../../../common/components/display";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import InformationRowItem from "./InformationRowItem";
import RenderFieldData from "./RenderFieldData";

type PropsType = {
    user: UserType;
    userIsValuer: boolean;
};

const ProfileOfficeDetails = (props: PropsType) => {
    const {
        user: {
            id: userId,
            latitude,
            longitude,
            street,
            postalCode,
            city,
            country,
            officeTelDialCode,
            officeTel,
            officeFaxDialCode,
            officeFax,
        },
        userIsValuer,
    } = props;

    const t = useSmartTranslation();

    const markers: MapMarkerType[] = [
        {
            id: userId,
            lat: +latitude,
            lng: +longitude,
        },
    ];

    return (
        <>
            <InformationRowItem
                label={t("profile.profileDetails.location")}
                content={
                    <RenderFieldData
                        field="location"
                        data={[street, postalCode, city, country]}
                        config={{ joinWith: ", ", required: true }}
                    />
                }
            />

            <InformationRowItem
                label={t("profile.profileDetails.officeTelNumber")}
                content={
                    <RenderFieldData
                        field="officeTelNumber"
                        data={[officeTelDialCode, officeTel]}
                    />
                }
            />

            <InformationRowItem
                label={t("profile.profileDetails.officeFaxNumber")}
                content={
                    <RenderFieldData
                        field="officeFaxNumber"
                        data={[officeFaxDialCode, officeFax]}
                    />
                }
            />

            {userIsValuer && (
                <Box mt={2}>
                    <GoogleMapV2
                        height={"20rem"}
                        markers={markers}
                    />
                </Box>
            )}
        </>
    );
};

export default ProfileOfficeDetails;
