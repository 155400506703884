import { Flex, Box, Heading, VStack, Text, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import {
    useNotificationById,
    useNotificationMarkAsRead,
} from "../../utils/api/hooks";
import SiteLoading from "../../common/components/SiteLoading";
import { ThemeV2Wrapper } from "../../ui/v2/app/ThemeV2Wrapper";

const ProccessNotification = () => {
    const { t } = useTranslation(["account", "common"]);

    const { notificationId = "" } = useParams();

    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);

    const { notification, error, isLoading } =
        useNotificationById(notificationId);

    const { update } = useNotificationMarkAsRead({
        onSuccess: () => navigate(notification?.actionLink || "/dashboard"),
        onError: () => setIsError(true),
    });

    useEffect(() => {
        if (isLoading) return;

        update(notificationId);
    }, [isLoading]);

    return (
        <ThemeV2Wrapper>
            <>
                {isLoading && <SiteLoading />}

                {error && isError && (
                    <VStack>
                        <Heading size="sm">
                            {t("error.weAreSorry", { ns: "common" })}
                        </Heading>

                        <Text>{t("error.generic", { ns: "common" })}</Text>

                        <Button
                            variant="primaryYellow"
                            onClick={() => {
                                navigate("/dashboard");
                            }}
                        >
                            {t("button.goToDash", { ns: "common" })}
                        </Button>
                    </VStack>
                )}
            </>
        </ThemeV2Wrapper>
    );
};

export default ProccessNotification;
