export const accountTranslations = {
    de: {
        companyDetails: {
            contactPerson: "Ansprechpartner",
            city: "Stadt",
            companyLogo: "Firmenlogo",
            contactInfo: "Kontaktinformation",
            country: "Land",
            dialCode: "Vorwahl",
            billingAddress: "Rechnungsadresse",
            email: "E-Mail Adresse (gesch.)",
            howManyPartners: "Wie viele Partner hat Ihr Unternehmen?",
            location: "Adresse der Niederlassung",
            name: "Name des Unternehmens",
            noNameProvided: "Kein Name angegeben",
            numberOfValuers: "Anzahl der Gutachter in Ihrem Unternehmen",
            otherInformation: "Weitere Informationen",
            phoneNumber: "Telefonnummer (gesch.)",
            postalCode: "Postleitzahl",
            sectionTitle: "Angaben zum Unternehmen",
            street: "Straße",
            yearsOfExperience: "Erfahrung (in Jahren)",
            website: "Webseite des Unternehmens",
            vatNumber: "USt-ID",
        },
        heading: {
            billingAddress: "Rechnungsadresse",
            companyDetails: "Firmendetails",
            contactInfo: "Kontaktinformation Unternehmen",
            otherInformation: "Weitere Informationen",
            piCover: "Haftpflichtdeckung",
        },
        valuationCapabilities: {
            sectionTitle: "Bewertungsleistungen",
            otherSpecialReports: "Weitere Sondergutachten",
            piCover: {
                insuranceCoverConditions: "Angaben zur Haftpflichtdeckung",
                piCover: "Haftpflichtsumme",
                maxOf: "Maximaler Deckungsbetrag",
                noLimit: "Keine Begrenzung",
                or: "{{coverValue}} oder {{coverPercentage}}",
                or_complex:
                    "<0>{{coverValue}}</0> <1>oder</1> <2>{{coverPercentage}}</2>",
                orPercentage: "Oder maximaler Anteil des Wertes",
                p_of_value: "% des Wertes",
                maximum: "maximal",
                specialArrangementsAvailable:
                    "Sonderkonditionen für die Haftpflichtdeckung sind möglich",
                validUntil: "gültig bis {{date}}",
                validUntil_complex: "gültig bis <2>{{date}}</2>",
                ValidUntil: "Die Angaben sind gültig bis",
            },
            propertyTypes: "Immobilienkategorien",
            specialReports: "Sondergutachten",
            otherSpecialValuationTypes: {
                enter: "Sonderbericht angeben",
                sectionTitle: "Weitere Sonderberichtstypen",
            },
        },
        teamDetails: {
            sectionTitle: "Nutzer",
            blurb: 'Sie haben die folgenden Kollegen eingeladen. Die Anmeldung Ihrer Kollegen steht aus. Wenn Sie sie als Administrator einsetzen, können sie die $t(wvw,  { "ns": "common" })-Kontodaten bearbeiten.',
            button: {
                deleteUser: "Nutzer löschen",
                excelUpload: "Upload Excel-Datei",
                inviteMembers: "Mitglieder einladen",
                revokeAccess: "Zugang widerrufen",
            },
            deleteUser: {
                text: "Sind Sie sicher, dass Sie diesen Nutzer löschen wollen?",
                title: "Nutzer löschen",
            },
            revokeAccess: {
                text: "Sind Sie sicher, dass Sie den Zugang für diesen Nutzer widerrufen wollen?",
                title: "Zugang widerrufen",
            },
        },
        customerPanelDetails: {
            inviteCustomer: "Kunden einladen",
            customerCompanyName: "Name des Unternehmens des Kunden",
            customerExplainerText:
                "Wenn eine Einladung gesendet wird, können neue Kunden der Plattform beitreten, während bestehende Nutzer eingeladen werden, direkt Ihrem Kunden-Panel beizutreten.",
            email: "E-Mail Adresse",
            requirements: "Anforderungen an den Gutachter",
            name: "Name",
            sectionTitle: "Kunden",
            button: {
                requestCustomerInvite: "Kunden-Einladung anfordern",
            },
        },
        accountSectionLink: {
            companyDetails: "Angaben zum Unternehmen",
            customers: "Kunden",
            integration: "Integration",
            panelValuers: "Panel Wertgutachter",
            sharedDocs: "Geteilte Dokumente",
            team: "Team",
        },
    },
    en: {
        companyDetails: {
            contactPerson: "Contact Person",
            city: "City",
            companyLogo: "Company Logo",
            contactInfo: "Contact Information",
            country: "Country",
            billingAddress: "Billing Address",
            dialCode: "Dial Code",
            email: "Office Email",
            howManyPartners: "How many partners does your company have?",
            location: "Office Location",
            name: "Company Name",
            noNameProvided: "No name provided",
            numberOfValuers: "Number of Valuers",
            otherInformation: "Other Information",
            phoneNumber: "Office Phone",
            postalCode: "Postal Code",
            sectionTitle: "Company Details",
            street: "Street",
            yearsOfExperience: "Years of Experience",
            website: "Company Website",
            vatNumber: "VAT Number",
        },
        heading: {
            billingAddress: "Billing address",
            companyDetails: "Company details",
            contactInfo: "Contact information",
            otherInformation: "Other information",
            piCover: "PI cover",
        },
        valuationCapabilities: {
            sectionTitle: "Valuation Capabilities",
            otherSpecialReports: "Other Special Reports",
            piCover: {
                insuranceCoverConditions: "Insurance Cover Conditions",
                piCover: "PI Cover",
                maxOf: "Maximum absolute value",
                noLimit: "No limit",
                or: "{{coverValue}} or {{coverPercentage}}",
                or_complex:
                    "<0>{{coverValue}}</0> <1>or</1> <2>{{coverPercentage}}</2>",
                orPercentage: "Or maximum percentage of value",
                p_of_value: "% of value",
                maximum: "maximum",
                specialArrangementsAvailable:
                    "Special PI Arrangements Available",
                validUntil: "valid until {{date}}",
                validUntil_complex: "valid until <2>{{date}}</2>",
                ValidUntil: "Valid until",
            },
            propertyTypes: "Property Types",
            specialReports: "Special Reports",
            otherSpecialValuationTypes: {
                enter: "Enter Special Report",
                sectionTitle: "Other Special Reports",
            },
        },
        teamDetails: {
            sectionTitle: "Users",
            blurb: 'Invite your team members to increase your lead generation. Appointing them as an admin allows them to edit the $t(wvw,  { "ns": "common" }) Account data.',
            button: {
                deleteUser: "Delete User",
                excelUpload: "Excel Upload",
                inviteMembers: "Invite Members",
                revokeAccess: "Revoke Access",
            },
            deleteUser: {
                text: "Are you sure you want to delete this user?",
                title: "Delete User",
            },
            revokeAccess: {
                text: "Are you sure you want to revoke access for this user?",
                title: "Revoke Access",
            },
        },
        customerPanelDetails: {
            inviteCustomer: "Invite Customer",
            customerCompanyName: "Customer Company Name",
            customerExplainerText:
                "When an invite is sent, new customers can join the platform, while existing users are directly invited to join your Customer Panel.",
            email: "Email address",
            requirements: "Valuer Requirements",
            name: "Name",
            sectionTitle: "Customers",
            button: {
                requestCustomerInvite: "Request Customer Invite",
            },
        },
        accountSectionLink: {
            companyDetails: "Company Details",
            customers: "Customers",
            integration: "Integration",
            panelValuers: "Panel Valuers",
            sharedDocs: "Shared Documents",
            team: "Team",
        },
    },
};
