export const translations = {
    de: {
        adminMenu: {
            uploadedContracts: "Hochgeladene Verträge",
            caseReports: "Geschäftsfallberichte",
        },
        activeCasesWithCount: "Aktive Geschäftsfälle: {{count}}",
        customizeExperience: {
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext:
                    "Es fehlen Pflichtangaben zu Ihrem Unternehmen, bitte füllen Sie alle relevanten Felder aus.",
            },
            completeProfile: {
                title: "Profil vervollständigen",
                subtext:
                    "Fügen Sie gegebenenfalls Teammitglieder zu Ihrem Unternehmensprofil hinzu.",
            },
            customizeYourExperience: "Passen Sie Ihr Nutzungserlebnis an",
            uploadMarketing: {
                title: "Marketingmaterial hochladen",
                subtext:
                    "Marketingmaterial erhöht die Transparenz bei Ihren Kunden und kann sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
            uploadPiCover: {
                title: "Unterlagen Haftpflichtversicherung hochladen",
                subtext:
                    "Diese Nachweisdokumente erhöhen die Transparenz bei Ihren Kunden und können sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
            uploadValuationSample: {
                title: "Musterwertgutachten hochladen",
                subtext:
                    "Musterwertgutachten erhöhen die Transparenz bei Ihren Kunden und können sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
        },
        noAcceptedStartedValuation:
            "Keine akzeptierten und begonnenen Bewertungen.",
        noRecentMatches: "Keine aktuellen Anfragen.",
        noCaseEnquiries: "Aktuell keine angenommenen Angebote.",
        noCurrentOffers: "Keine aktuellen Angebote.",
        recentMatchesWithCount: "Aktuelle Anfragen: {{count}}",
        caseEnquiries: "Angenommene Angebote: {{count}}",
        currentOffers: "Aktuell abgegebene Angebote: {{count}}",
        statistics: {
            cases: {
                active: "Aktiv",
                conversionRate: "Konversionsrate",
            },
            enquiries: {
                allOffersSent: "Abgegebene Angebote",
                matches: "Anfragen",
                rejectedOffers: "Abgelehnte Angebote",
                underReview: "Entscheidung ausstehend",
            },
        },
    },
    en: {
        adminMenu: {
            uploadedContracts: "Uploaded Contracts",
            caseReports: "Case Reports",
        },
        activeCasesWithCount: "Active Cases: {{count}}",
        customizeExperience: {
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            customizeYourExperience: "Customize Your Experience",
            uploadMarketing: {
                title: "Upload marketing material",
                subtext:
                    "Uploading marketing material increases transparency among clients and can have positive impact on your offers acceptance.",
            },
            uploadPiCover: {
                title: "Upload PI cover",
                subtext:
                    "Uploading those documents increases transparency among clients and can have positive impact on your offers acceptance.",
            },
            uploadValuationSample: {
                title: "Upload valuation sample",
                subtext:
                    "Uploading valuation samples increases transparency among clients and can have positive impact on your offers acceptance.",
            },
        },
        noAcceptedStartedValuation: "No accepted and started valuations.",
        noRecentMatches: "No recent matches.",
        noCaseEnquiries: "No case enquiries.",
        noCurrentOffers: "No current offers.",
        recentMatchesWithCount: "Recent Matches: {{count}}",
        caseEnquiries: "Case Enquiries: {{count}}",
        currentOffers: "Current Offers: {{count}}",
        statistics: {
            cases: {
                active: "Active",
                conversionRate: "Conversion Rate",
            },
            enquiries: {
                allOffersSent: "All Offers Sent",
                matches: "Matches",
                rejectedOffers: "Rejected Offers",
                underReview: "Under Review",
            },
        },
    },
};
