import { CheckIcon } from "@chakra-ui/icons";
import { As, HStack, Icon, Spacer, Text } from "@chakra-ui/react";

type MatchableItemProps = {
    label: string;
    checked?: boolean;
    leftIcon?: As;
    rightSection?: React.ReactNode;
};

const MatchableItem = (props: MatchableItemProps) => {
    const { label, checked, leftIcon, rightSection } = props;

    // render
    const icon = leftIcon && (
        <Icon
            as={leftIcon}
            boxSize={"1.5rem"}
            color={"gray.400"}
            mr={".3rem"}
        />
    );

    const checkIcon = checked && (
        <Icon
            as={CheckIcon}
            color={"green.500"}
        />
    );

    return (
        <HStack
            padding={".5rem .75rem"}
            bg={checked ? "green.50" : "gray.50"}
            borderRadius={"lg"}
        >
            {icon}
            <Text
                fontSize={"sm"}
                fontWeight={"bold"}
                color={checked ? "green.500" : "gray.900"}
            >
                {label}
            </Text>
            {checkIcon}

            {rightSection && (
                <>
                    <Spacer />
                    {rightSection}
                </>
            )}
        </HStack>
    );
};

export default MatchableItem;
