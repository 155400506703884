import { CloseIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    HStack,
    IconButton,
    Text,
    VStack,
} from "@chakra-ui/react";
import { ArrowRight2 } from "iconsax-react";
import { ReactNode } from "react";
import { FiCheck } from "react-icons/fi";
import { Link as ReactLink } from "react-router-dom";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { OfferOnlyType, OfferType } from "../../../../../types";
import { DataTableColumnType } from "../../../common/components/data-table/DataTable";
import PublicUserProfilePictureWithEntityLogo from "../../../common/components/PublicUserProfilePictureWithEntityLogo";
import { useDevice } from "../../../common/hooks/useDevice";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../../common/hooks/useSmartTranslation";
import { Offer } from "../Offer";

type Column = DataTableColumnType<OfferOnlyType>;

const createOfferRenderer =
    (renderField: (offer: OfferOnlyType) => ReactNode) =>
    (offer: OfferOnlyType) => {
        const columnData = renderField(offer);

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    fontSize={"sm"}
                >
                    {columnData || "-"}
                </Text>
            </VStack>
        );
    };

export const valuerProfile: DataTableColumnType<OfferType> = {
    alignment: "left",
    title: (t) => t("domain.offer.columns.valuer"),
    render: (o: OfferType) => {
        const offer = o;

        const device = useDevice();

        return (
            <HStack spacing={2}>
                <PublicUserProfilePictureWithEntityLogo
                    entityId={offer.fromEntityId}
                    userId={offer.fromValuerId}
                    profileLogoSize={2.5}
                    companyLogoSize={1.5}
                />
                {(device === "mobile" || device === "wide") && (
                    <VStack
                        alignItems="flex-start"
                        spacing="0"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            {offer.fromValuer.firstName}{" "}
                            {offer.fromValuer.lastName}
                        </Text>
                        <Text
                            color="gray.500"
                            fontSize={"xs"}
                        >
                            {offer.fromValuer.workingForCompanyName}
                        </Text>
                    </VStack>
                )}
            </HStack>
        );
    },
};

export const piCoverValue: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t("domain.offer.columns.piCoverValue"),
    render: createOfferRenderer(
        ({ piCoverValue }) => piCoverValue && `€${piCoverValue}`
    ),
};

export const piCoverPercentage: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => "[%]",
    render: createOfferRenderer(
        ({ piCoverPercentage }) => piCoverPercentage && `${piCoverPercentage}%`
    ),
};

export const specialPi: DataTableColumnType<OfferType> = {
    alignment: "right",
    title: (t: TranslationFunction) => t("domain.offer.columns.specialPi"),
    render: (offer: OfferType) => {
        const specialPiIcon = offer.fromEntity
            .piCoverSpecialArrangementsAvailable ? (
            <FiCheck
                color="green"
                size="24px"
            />
        ) : (
            <CloseIcon
                boxSize="10px"
                color="gray.400"
            />
        );

        return <Box>{specialPiIcon}</Box>;
    },
};

export const availability: Column = {
    alignment: "right",
    title: (t: TranslationFunction) =>
        t("domain.offer.columns.earliestAvailability"),
    render: createOfferRenderer(({ availability }) => {
        if (!availability) return null;
        const availabilityDate = new Date(availability);
        return displayDateWithCurrentLocale(availabilityDate);
    }),
};

export const workingTime: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t("domain.offer.columns.workingTime"),
    render: (o: OfferOnlyType, t: TranslationFunction) =>
        createOfferRenderer(
            ({ timeline }) =>
                timeline && `${timeline} ${t("domain.offer.columns.days")}`
        )(o),
};

export const delivery: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t("domain.offer.columns.delivery"),
    render: createOfferRenderer((offer) => {
        const earliestAvailability = Offer.calculateDeliveryDate(offer);

        return displayDateWithCurrentLocale(earliestAvailability);
    }),
};

export const deliveryAndTimeline: Column = {
    alignment: "right",
    title: (t: TranslationFunction) =>
        t("domain.offer.columns.deliveryAndTimeline"),
    render: (offer) => {
        const t = useSmartTranslation();

        const earliestAvailability = Offer.calculateDeliveryDate(offer);
        const earliestAvailabilityText =
            displayDateWithCurrentLocale(earliestAvailability);

        const timelineText = `(${offer.timeline} ${t(
            "domain.offer.columns.days"
        )})`;

        return (
            <VStack spacing="0">
                <Text
                    textAlign="right"
                    fontSize={"sm"}
                >
                    {earliestAvailabilityText}
                </Text>
                <Text
                    textAlign="right"
                    fontSize={"sm"}
                >
                    {timelineText}
                </Text>
            </VStack>
        );
    },
};

export const price: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t("domain.offer.columns.price"),
    render: createOfferRenderer(
        ({ price }) => price && <strong>{`€${price}`}</strong>
    ),
};

export const viewCase: Column = {
    render: (offer: OfferOnlyType, t: TranslationFunction) => {
        return (
            <Button
                size="sm"
                as={ReactLink}
                onClick={(e) => e.stopPropagation()}
                to={`/dashboard/case/${offer.caseId}`}
                variant="primary"
                children={t("common.button.view")}
            />
        );
    },
};

export const view: Column = {
    render: (offer: OfferOnlyType, t: TranslationFunction) => {
        return (
            <IconButton
                as={ReactLink}
                to={`/dashboard/enquiry/${offer.enquiryId}/offer/${offer.id}`}
                onClick={(e) => e.stopPropagation()}
                icon={<ArrowRight2 />}
                variant="ghost"
                color="gray.500"
                aria-label="arrow"
                data-testid="view-offer"
            />
        );
    },
};

export const offerColumns = {
    valuerProfile,
    piCoverValue,
    piCoverPercentage,
    specialPi,
    availability,
    workingTime,
    delivery,
    deliveryAndTimeline,
    price,
    viewCase,
    view,
};
