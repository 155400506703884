import { accountTranslations } from "../../../screens/platform/account/Account.i18n";
import { enquiryListTranslations } from "../../../screens/platform/enquiry-list/EnquiryList.i18n";
import { MenuBar } from "../../../screens/platform/screen/menu-bar";

export const breadcrumbTranslations = {
    de: {
        dashboard: MenuBar.translations.de.dashboard,
        enquiries: enquiryListTranslations.de.title.ACTIVE,
        archived: enquiryListTranslations.de.title.ARCHIVED,
        draft: enquiryListTranslations.de.title.DRAFT,
        account: accountTranslations.de.accountSectionLink.companyDetails,
        team: accountTranslations.de.accountSectionLink.team,
        "valuer-panel": accountTranslations.de.accountSectionLink.customers,
        documents: accountTranslations.de.accountSectionLink.sharedDocs,
        integration: accountTranslations.de.accountSectionLink.integration,
    },
    en: {
        dashboard: MenuBar.translations.en.dashboard,
        enquiries: enquiryListTranslations.en.title.ACTIVE,
        archived: enquiryListTranslations.en.title.ARCHIVED,
        draft: enquiryListTranslations.en.title.DRAFT,
        account: accountTranslations.en.accountSectionLink.companyDetails,
        team: accountTranslations.en.accountSectionLink.team,
        "valuer-panel": accountTranslations.en.accountSectionLink.customers,
        documents: accountTranslations.en.accountSectionLink.sharedDocs,
        integration: accountTranslations.en.accountSectionLink.integration,
    },
};
