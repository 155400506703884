import { Flex, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { WvwIcon } from "../../../../../common/components/display";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { EntityType } from "../../../../../types";
import FormSectionProfileEdit from "../../../profile/components/FormSectionProfileEdit";

type PropTypes = {
    entity: EntityType;
};

const CompanyDetailsPiCover = (props: PropTypes) => {
    const {
        entity: {
            piCoverCurrency,
            piCoverSpecialArrangementsAvailable,
            piCoverPercentage,
            piCoverPercentageUnlimited,
            piCoverValue,
            piCoverValueUnlimited,
            piCoverValidityDate,
        },
    } = props;

    const { t } = useTranslation("account");

    const currencySymbol =
        piCoverCurrency ||
        t("unitSymbol.currency", {
            ns: "common",
        });

    const noLimit = t("valuationCapabilities.piCover.noLimit");

    let coverValue = "-";
    let coverPercentage = "-";

    if (piCoverValueUnlimited) {
        coverValue = `${currencySymbol} (${noLimit})`;
    } else {
        if (piCoverValue) {
            coverValue = `${currencySymbol} ${delimitNumber(
                piCoverValue.toString()
            )}`;
        }
    }

    if (piCoverPercentageUnlimited) {
        coverPercentage = `(${noLimit})%`;
    } else {
        if (piCoverPercentage) {
            coverPercentage = `${t(
                "valuationCapabilities.piCover.maximum"
            )} ${piCoverPercentage}${t(
                "valuationCapabilities.piCover.p_of_value"
            )},`;
        }
    }

    return (
        <FormSectionProfileEdit header={t("heading.piCover")}>
            <Flex
                alignItems="flex-start"
                w="100%"
            >
                <Text w="17rem">
                    {t("valuationCapabilities.piCover.insuranceCoverConditions")}
                </Text>

                <HStack
                    h="2rem"
                    spacing="1"
                >
                    <Text>
                        {!piCoverValidityDate && (
                            <>
                                <WvwIcon icon="warning" />{" "}
                            </>
                        )}
                        {t("valuationCapabilities.piCover.or", {
                            coverValue,
                            coverPercentage,
                            interpolation: { escapeValue: false },
                        })}
                    </Text>

                    {piCoverValidityDate ? (
                        <Text>
                            {t("valuationCapabilities.piCover.validUntil", {
                                date: displayDateWithCurrentLocale(
                                    piCoverValidityDate
                                ),
                            })}
                        </Text>
                    ) : (
                        <div />
                    )}
                </HStack>
            </Flex>

            <Flex
                alignItems="flex-start"
                w="100%"
            >
                <Text w="17rem">
                    {t(
                        "valuationCapabilities.piCover.specialArrangementsAvailable"
                    )}
                </Text>

                <Text>
                    {piCoverSpecialArrangementsAvailable
                        ? t("yes", { ns: "common" })
                        : t("no", { ns: "common" })}
                </Text>
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default CompanyDetailsPiCover;
