import { ChakraProvider } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ReactNode } from "react";
import theme from "../theme/theme-v2";

type Props = {
    children?: ReactNode;
};

export const ThemeV2Wrapper = ({ children }: Props) => {
    return (
        <ChakraProvider theme={theme}>
            {children == undefined && <Outlet />}

            {children !== undefined && children}
        </ChakraProvider>
    );
};
