export const caseColumns = {
    de: {
        case: "Fall",
        client: "Kunde",
        expectedDelivery: "Lieferung bis",
        noOfProperties: "Anzahl Immobilien: {{count}}",
        portfolioValue: "Gesamtwert",
        portfolioValueTooltip: "Vom Kunden geschätzter Immobilienwert",
        position: {
            at: "{{position}} bei {{company}}",
        },
        piCover: "Haftpflichtdeckung",
        selectedValuer: "Gewählter Gutachter",
        scope: "Umfang",
        moreText: "+{{count}} weitere",
    },
    en: {
        case: "Case",
        client: "Client",
        expectedDelivery: "Expected Delivery",
        noOfProperties: "No. of Properties: {{count}}",
        portfolioValue: "Total Value",
        portfolioValueTooltip: "Total property value estimated by client",
        position: {
            at: "{{position}} at {{company}}",
        },
        piCover: "PI Cover",
        selectedValuer: "Selected valuer",
        scope: "Scope",
        moreText: "+ {{count}} more",
    },
};

export const propertyColumns = {
    de: {
        address: "Adresse",
        propertyType: "Art",
        area: "Fläche",
        estimateValue: "Gesamtwert",
        estimateValueTooltip: "Vom Kunden geschätzter Immobilienwert",
        moreText: "+ {{number}} weitere",
    },
    en: {
        address: "Address",
        propertyType: "Property Type",
        area: "Area",
        estimateValue: "Total Value",
        estimateValueTooltip: "Total property value estimated by client",
        moreText: "+ {{number}} more",
    },
};
