import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import { FieldInputProps, useField } from "formik";
import { useContext } from "react";
import delimitNumber from "../../../../common/functions/delimitNumber";
import parseDelimitedNumber from "../../../../common/functions/parseDelimitedNumber";
import { LabelAndInput } from "../components/display";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { FormikValidationContext } from "./FormikForm";

type PropTypes = {
    name: string;
    label?: string;
    readOnly?: boolean;
    disabled?: boolean;
    placeholder?: string;
    prefix?: string;
    tooltip?: string;
    leftinternal?: string | JSX.Element;
    rightinternal?: string | JSX.Element;
    maxW?: string;
    subtext?: string;
    suffix?: string;
    width?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void;
    variant?: string;
    size?: string;
};

const FormikCurrencyInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        prefix,
        leftinternal,
        rightinternal,
        disabled,
        readOnly,
        maxW,
        name,
        label,
        subtext,
        suffix,
        width,
        onChange,
        variant,
        size = "md",
        tooltip,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const isFieldRequired = useContext(FormikValidationContext);

    const isRequired = isFieldRequired(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? " *" : ""}`}
            tooltip={tooltip}
            input={
                <div
                    style={{
                        alignSelf: "top",
                        maxWidth: maxW,
                        width,
                    }}
                >
                    <InputGroup variant={variant}>
                        {prefix && (
                            <InputLeftAddon
                                h="3rem"
                                minW="4rem"
                            >
                                {prefix}
                            </InputLeftAddon>
                        )}

                        {leftinternal && (
                            <InputLeftElement>{leftinternal}</InputLeftElement>
                        )}

                        <Input
                            borderRadius="8"
                            bg="white"
                            isDisabled={disabled}
                            // isReadOnly={readonly}
                            readOnly={readOnly}
                            cursor={readOnly ? "not-allowed" : undefined}
                            isInvalid={!!meta.touched && !!meta.error}
                            maxW={maxW}
                            w={width}
                            size={size}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                            onChange={(e) => {
                                let value = parseDelimitedNumber(
                                    e.target.value
                                );

                                if (value !== "") {
                                    value = delimitNumber(value);
                                }

                                field.onChange({ target: { name, value } });

                                onChange?.(value, field);
                            }}
                        />

                        {rightinternal && (
                            <InputRightElement>
                                {rightinternal}
                            </InputRightElement>
                        )}

                        {suffix && (
                            <InputRightAddon
                                h="3rem"
                                minW="4rem"
                            >
                                {suffix}
                            </InputRightAddon>
                        )}
                    </InputGroup>

                    {subtext && (
                        <Text
                            fontSize={"sm"}
                            color="gray.500"
                        >
                            {subtext}
                        </Text>
                    )}

                    {meta.touched && meta.error && (
                        <Text
                            fontSize={"sm"}
                            color="red"
                        >
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </div>
            }
        />
    );
};

FormikCurrencyInput.defaultProps = {
    label: "",
    disabled: false,
    placeholder: "",
    prefix: null,
    suffix: null,
    maxW: "100%",
    width: "100%",
    onChange: () => {},
};

export default FormikCurrencyInput;
