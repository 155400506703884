import { Box } from "@chakra-ui/layout";
import { ResponsiveValue } from "@chakra-ui/react";
import { User } from ".";
import { EnquiryType } from "../../../../types";
import { PanelRelationshipMarker } from "../../common/components/display";
import ProfilePicture from "../../common/components/display/ProfilePicture";
import PublicUserProfilePictureWithEntityLogo from "../../common/components/PublicUserProfilePictureWithEntityLogo";
import { useExposeClientData } from "./useExposeClientData";

type ClientProfilePictureProps = {
    client: User;
    enquiry: EnquiryType | undefined;
    profileLogoSize?: ResponsiveValue<number | string>;
    companyLogoSize?: ResponsiveValue<number | string>;
};

export const ClientProfilePicture = (props: ClientProfilePictureProps) => {
    const {
        client: { entityId, id },
        client,
        enquiry,
        profileLogoSize,
        companyLogoSize,
    } = props;

    const { exposeClientData, isInPanel } = useExposeClientData({
        client,
        enquiry,
    });

    return exposeClientData ? (
        <Box position={"relative"}>
            {isInPanel && <PanelRelationshipMarker />}
            <PublicUserProfilePictureWithEntityLogo
                entityId={entityId}
                userId={id}
                profileLogoSize={profileLogoSize}
                companyLogoSize={companyLogoSize}
            />
        </Box>
    ) : (
        <ProfilePicture />
    );
};
