import { Button } from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import { usePermissions } from "../../app/usePermissions";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useEnquiryCancel from "./useEnquiryCancelV2";

type ParamsType = {
    enquiry: EnquiryType;
    userIsClient: boolean;
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const CancelEnquiry = (params: ParamsType) => {
    const {
        enquiry: { status },
        enquiry,
        userIsClient,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const canI = usePermissions();

    const hasPermissions = canI(enquiry, "ENQUIRY", "EDIT");

    const { cancelEnquiry, confirmationModal } = useEnquiryCancel({
        onSuccess,
        onError,
        enquiry,
    });

    const canCancel = userIsClient && status === "PENDING" && hasPermissions;

    return (
        <Button
            isDisabled={!canCancel}
            onClick={() => cancelEnquiry()}
            variant={"outline"}
            colorScheme={"blue"}
        >
            {t("domain.enquiry.ACTIVITIES.cancel.NAME")}
            {confirmationModal}
        </Button>
    );
};

export default CancelEnquiry;
