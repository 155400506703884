import {
    Box,
    Flex,
    FormControl,
    FormHelperText,
    Spacer,
    Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import { DocumentCloud } from "iconsax-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import storage from "../../utils/storage/storage";
import { FileUploadInput } from "../inputs";
import LoadingSpinner from "../components/LoadingSpinner";
import WvwDeleteIconButton from "../components/WvwDeleteIconButton";

type PropTypes = {
    name: string;
};

type DocumentType = {
    name: string;
    url: string;
    size: number;
}[];

const FormikFileUpload = (props: PropTypes) => {
    const { t } = useTranslation(["formik", "common"]);

    const { name } = props;

    const [field, meta] = useField(props);

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState<DocumentType>(
        field.value
    );

    return (
        <FormControl>
            <FileUploadInput
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                fileTypes={[".pdf", ".gif", ".jpeg", ".jpg", ".png"]}
                noRename
                onChange={(files) => {
                    if (files && files.length) {
                        setLoadingUpload(true);

                        storage
                            .uploadEnquiryDocumentsPropertyDocuments(files)
                            .then((documents) => {
                                const newDocs = [
                                    ...uploadedDocuments,
                                    ...documents,
                                ];

                                setUploadedDocuments(newDocs);

                                field.onChange({
                                    target: { name, value: newDocs },
                                });
                            })
                            .finally(() => setLoadingUpload(false));
                    }
                }}
            />

            {uploadedDocuments && (
                <Box marginBlock="1rem">
                    {uploadedDocuments.map((d) => (
                        <Flex key={d.name}>
                            <Box
                                marginInlineEnd="1rem"
                                paddingTop="0.4rem"
                            >
                                <DocumentCloud />
                            </Box>

                            <FormHelperText>{d.name}</FormHelperText>

                            <Spacer />

                            <WvwDeleteIconButton
                                onClick={() => {
                                    // Add functionality to delete uploaded docs from firebase & db
                                    const newDocs = uploadedDocuments.filter(
                                        (document) => document.url !== d.url
                                    );

                                    field.onChange({
                                        target: { name, value: newDocs },
                                    });

                                    setUploadedDocuments(newDocs);
                                }}
                                color="black"
                            />
                        </Flex>
                    ))}
                </Box>
            )}

            {loadingUpload && <LoadingSpinner />}

            {meta.touched && meta.error && (
                <Text color="red">
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </FormControl>
    );
};

export default FormikFileUpload;
