import { Box, Heading, Tab, TabList, Tabs, VStack } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRole } from "../../../../../common/hooks";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";

const { REACT_APP_FEATURE_ENABLE_DMS = "false" } = process.env;

const ProfileScreen = () => {
    const t = useSmartTranslation();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { userIsIndividual, userIsValuer, roleLoading } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    let tabs = [
        {
            label: t("profile.profileSectionLink.profile"),
            url: "",
        },
        ...(userIsValuer && REACT_APP_FEATURE_ENABLE_DMS === "true"
            ? [
                  {
                      label: t("profile.profileSectionLink.templates"),
                      url: "templates",
                  },
              ]
            : []),
    ];

    const index = tabs.findIndex(
        (tab) => pathname.split("/").pop() === tab.url
    );

    // render

    const title = (
        <Heading
            color="blue.700"
            fontSize={"2xl"}
        >
            {t("profile.profileSectionLink.profile")}
        </Heading>
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
            p={4}
        >
            {title}

            <Tabs
                index={index !== -1 ? index : 0}
                padding="0"
                width="100%"
            >
                <TabList>
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.url}
                            fontSize={"sm"}
                            onClick={() => navigate(tab.url)}
                        >
                            {tab.label}
                        </Tab>
                    ))}
                </TabList>

                <Box pt={4}>
                    <Outlet />
                </Box>
            </Tabs>
        </VStack>
    );
};

export default ProfileScreen;
