import { Badge, Box, Divider, HStack, VStack } from "@chakra-ui/layout";
import { Button, DrawerHeader, IconButton } from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";
import useDrawer from "../../../../common/hooks/useDrawer";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { EnquiryFilterElement, EnquiryFilterPropsType } from "./EnquiryFilter";

type FiltersProps = EnquiryFilterPropsType & {
    filters: EnquiryFilterElement[];
    clearAllFilters: () => void;
};

const Filters = (props: FiltersProps) => {
    const {
        activateFilter,
        deactivateFilter,
        clearAllFilters,
        filters,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const displayFilters = filters.map((Filter, idx) => (
        <Filter
            key={idx}
            activateFilter={activateFilter}
            deactivateFilter={deactivateFilter}
            activeFilters={activeFilters}
        />
    ));

    const filtersBadge = activeFilters.length > 0 && (
        <Badge
            color={"gray.900"}
            bg={"orange.500"}
            fontSize={"xs"}
            fontWeight={"700"}
            w={"14px"}
            h={"16px"}
            p={0}
            textAlign={"center"}
        >
            {activeFilters.length}
        </Badge>
    );

    const { drawer, openDrawer } = useDrawer({
        header: (
            <HStack
                justifyContent={"space-between"}
                pr={14}
            >
                <HStack
                    spacing={2}
                    justifyContent={"space-between"}
                >
                    <DrawerHeader
                        fontSize={"xl"}
                        color={"blue.700"}
                        pr={0}
                    >
                        {t("screens.platform.enquiryList.filters.title")}
                    </DrawerHeader>
                    {filtersBadge}
                </HStack>

                {activeFilters.length > 0 && (
                    <Button
                        variant={"link"}
                        colorScheme={"blue"}
                        size={"sm"}
                        fontWeight={"400"}
                        onClick={clearAllFilters}
                    >
                        {t(
                            "screens.platform.enquiryList.filters.ACTIVITIES.clearAll.NAME"
                        )}
                    </Button>
                )}
            </HStack>
        ),
        content: (
            <VStack
                align={"stretch"}
                spacing={4}
            >
                <Divider borderColor={"gray.100"} />

                {displayFilters}
            </VStack>
        ),
        size: "md",
    });

    const drawerButton = (
        <Box
            role="group"
            position={"relative"}
            onClick={openDrawer}
        >
            <IconButton
                aria-label=""
                variant={"ghost"}
                color={"gray.400"}
                icon={<BsFilter size={"1.5rem"} />}
                _groupHover={{
                    bg: "gray.100",
                }}
            />
            {filtersBadge && (
                <Box
                    position={"absolute"}
                    right={2}
                    top={-1}
                    cursor={"pointer"}
                >
                    {filtersBadge}
                </Box>
            )}
        </Box>
    );

    return (
        <>
            {drawerButton}
            {drawer}
        </>
    );
};

export { Filters };
