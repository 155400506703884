/* eslint-disable react/jsx-props-no-spreading */
import {
    Checkbox,
    Text,
    Box,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string,
    onChange?: (value: boolean, field?: FieldInputProps<any>) => void,
    label: React.ReactNode | string,
};

const FormikCheckbox = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        label,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <Box width="100%">
            <Checkbox
                {...props}
                alignItems="top"
                isChecked={field.value}
                onChange={e => {
                    field.onChange({
                        target: {
                            name,
                            value: !field.value,
                        },
                    });

                    onChange?.(e.target.checked, field);
                }}
            >
                {label}
            </Checkbox>

            {
                meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )
            }
        </Box>
    );
};

FormikCheckbox.defaultProps = {
    onChange: undefined,
};

export default FormikCheckbox;
