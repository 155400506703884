import {
    Divider,
    Flex,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from "@chakra-ui/react";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../../common/functions/displayTime";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { InternalSection } from "../../../layout";
import { calculateDeliveryDate } from "../offerFunctions";
import { AttachmentList } from "./attachments/OfferAttachment";

import { OfferOnlyWithEnquiryType } from "../../../../../types/offer/OfferOnlyWithEnquiryType";
import { OfferMessage } from "./OfferMessage";
import { OfferProperty } from "./OfferProperty";
import { OfferStatus } from "./OfferStatus";

type OfferDetailsProps = {
    offer: OfferOnlyWithEnquiryType;
    buttons?: React.ReactNode | React.ReactNode[];
};

export const OfferDetails = (props: OfferDetailsProps) => {
    const {
        offer: {
            updatedAt,
            piCoverValue,
            piCoverPercentage,
            timeline,
            availability,
            price,
            comments: message,
            requiredAttachments,
            piCertificateAttachments,
            sampleAttachments,
            marketingAttachments,
        },
        offer,
        buttons,
    } = props;

    const t = useSmartTranslation();

    const earliestAvailability = displayDateWithCurrentLocale(availability);
    const delivery = displayDateWithCurrentLocale(calculateDeliveryDate(offer));

    const requiredAttachmentList = (
        <AttachmentList
            title={t("domain.offer.requiredAttachments")}
            attachments={requiredAttachments}
            collapsable
            mt={4}
        />
    );

    const otherAttachmentTabs = (
        <Tabs
            width={"100%"}
            variant={"rounded"}
            isFitted
        >
            <TabList>
                <Tab>{t("domain.offer.piCertificateAttachments")}</Tab>
                <Tab>{t("domain.offer.sampleAttachments")}</Tab>
                <Tab>{t("domain.offer.marketingAttachments")}</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <AttachmentList
                        attachments={piCertificateAttachments}
                        mb={"1rem"}
                    />
                </TabPanel>
                <TabPanel>
                    <AttachmentList
                        attachments={sampleAttachments}
                        mb={"1rem"}
                    />
                </TabPanel>
                <TabPanel>
                    <AttachmentList
                        attachments={marketingAttachments}
                        mb={"1rem"}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );

    const otherAttachments = (
        <InternalSection
            title={t("domain.offer.otherAttachments")}
            content={otherAttachmentTabs}
            collapsable
        />
    );

    return (
        <VStack spacing={1}>
            <OfferStatus offer={offer} />
            <OfferProperty
                label={t("domain.offer.piCoverAbsolute")}
                value={`€ ${delimitNumber(piCoverValue!)}`}
            />
            <OfferProperty
                label={t("domain.offer.piCoverPercentage")}
                value={`${piCoverPercentage!}%`}
            />
            <OfferProperty
                label={t("domain.offer.timeline")}
                value={`${timeline} ${t("common.units.days")}`}
            />
            <OfferProperty
                label={t("domain.offer.availability")}
                value={earliestAvailability}
            />
            <OfferProperty
                label={t("domain.offer.deliveryDate")}
                value={delivery}
                color={"green.500"}
            />
            <Divider
                orientation={"horizontal"}
                variant={"dashed"}
                background={"gray.500"}
                mt={2}
            />
            <OfferProperty
                label={t("domain.offer.price")}
                value={`€ ${delimitNumber(price)}`}
                color={"green.500"}
                valueSize={"1.5em"}
            />
            <SubmissionTimestamp submissionDate={updatedAt} />
            <OfferMessage message={message} />

            <Flex
                mt={"1rem"}
                w={"100%"}
                gap={".5rem"}
            >
                {buttons}
            </Flex>

            {requiredAttachmentList}
            <Divider
                orientation={"horizontal"}
                background={"gray.500"}
                mt={2}
                mb={4}
            />
            {otherAttachments}
        </VStack>
    );
};

type SubmissionTimestampProps = {
    submissionDate: string;
};

const SubmissionTimestamp = (props: SubmissionTimestampProps) => {
    const { submissionDate } = props;

    const date = displayDateWithCurrentLocale(submissionDate);
    const time = displayTimeInCurrentLocale(new Date(submissionDate));

    const t = useSmartTranslation();

    return (
        <Flex
            w={"100%"}
            mb={8}
        >
            <Spacer />
            <Text
                fontSize={"sm"}
                color={"gray.600"}
            >
                {`${t("domain.offer.submittedAt")} ${date}, ${time}`}
            </Text>
        </Flex>
    );
};
