import { Box, HStack, VStack } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { WVWTitle } from "../../../../../common/components/typography";
import {
    FormikCheckbox,
    FormikCurrencyInput,
    FormikInput,
} from "../../../../../common/forms";
import { useSmartTranslation } from "../../../../../common/hooks";
import EditCompanyPiDocs from "../../documents/common/EditCompanyPiDocs";

/**
 * Formik form section for editing the current user's company pi cover
 */
const CompanyDetailsPiCoverEdit = () => {
    const t = useSmartTranslation("account");

    const formik = useFormikContext();

    const values = formik.values as any;

    return (
        <VStack
            width="100%"
            align="left"
        >
            <Box marginBottom="0.25rem">
                <WVWTitle
                    content={t("heading.piCover")}
                    level="2"
                    color="black"
                />
            </Box>

            <FormikCheckbox
                name="piCoverSpecialArrangementsAvailable"
                label={t(
                    "valuationCapabilities.piCover.specialArrangementsAvailable"
                )}
            />

            <HStack
                align="end"
                alignItems="baseline"
                spacing="6"
                w="100%"
                flexWrap={{
                    base: "wrap",
                    lg: "nowrap",
                }}
            >
                <VStack
                    align="stretch"
                    w="100%"
                >
                    <FormikCurrencyInput
                        disabled={values?.piCoverValueUnlimited}
                        name="piCoverValue"
                        label={t("valuationCapabilities.piCover.maxOf")}
                        placeholder="-"
                        prefix={t("unitSymbol.currency", { ns: "common" })}
                    />

                    <FormikCheckbox
                        name="piCoverValueUnlimited"
                        label={t("valuationCapabilities.piCover.noLimit")}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue("piCoverValue", 0, true);
                            }
                        }}
                    />
                </VStack>

                <VStack
                    align="stretch"
                    w="100%"
                >
                    <FormikCurrencyInput
                        disabled={values?.piCoverPercentageUnlimited}
                        name="piCoverPercentage"
                        label={t("valuationCapabilities.piCover.orPercentage")}
                        placeholder="-"
                        prefix="%"
                    />

                    <FormikCheckbox
                        name="piCoverPercentageUnlimited"
                        label={t("valuationCapabilities.piCover.noLimit")}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue(
                                    "piCoverPercentage",
                                    0,
                                    true
                                );
                            }
                        }}
                    />
                </VStack>

                <VStack
                    align="stretch"
                    w="100%"
                >
                    <FormikInput
                        noSubmitOnEnter
                        name="piCoverValidityDate"
                        label={t("valuationCapabilities.piCover.ValidUntil")}
                        type="date"
                    />
                </VStack>
            </HStack>

            <EditCompanyPiDocs />
        </VStack>
    );
};

export default CompanyDetailsPiCoverEdit;
