import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PageWithTitleLayout from "../../../../../common/components/display/PageWithTitleLayout";
import { useSmartTranslation } from "../../../../../common/hooks";
import {
    useNotificationList,
    useNotificationMarkAsRead,
} from "../../../../../utils/api/hooks";
import { Notification } from "../../../domain";

export const NotificationsScreen = () => {
    const t = useSmartTranslation();

    const navigate = useNavigate();

    const {
        notificationList = [],
        error,
        isLoading,
    } = useNotificationList(1000);

    const { update: markAsRead } = useNotificationMarkAsRead({
        onSuccess: (response) =>
            navigate(response.data.actionLink || "/dashboard"),
    });

    if (isLoading) {
        return null;
    }

    return (
        <Box
            paddingBlock="1rem"
            paddingInline="2rem"
        >
            <PageWithTitleLayout
                allowUnapprovedUser
                title={t("notifications.panelTitle")}
            >
                <VStack
                    align="left"
                    spacing="4"
                >
                    <>
                        {error && (
                            <VStack>
                                <Heading size="sm">
                                    {t("common.error.weAreSorry")}
                                </Heading>

                                <Text>{t("notifications.loadingFailed")}</Text>
                            </VStack>
                        )}

                        {notificationList.length === 0 && (
                            <Text>{t("notifications.noUnreads")}</Text>
                        )}

                        {notificationList.map((notification) => (
                            <Notification.Display
                                notification={notification}
                                markAsRead={markAsRead}
                                key={notification.id}
                            />
                        ))}
                    </>
                </VStack>
            </PageWithTitleLayout>
        </Box>
    );
};
