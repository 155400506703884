export type StandardMaxOfficeDistance =
    (typeof standardMaxOfficeDistance)[number]["value"];

const standardMaxOfficeDistance = [
    { label: "No Requirement", value: "none" },
    { label: "Less than 5 Kilometers", value: "5" },
    { label: "Less than 10 Kilometers", value: "10" },
    { label: "Less than 20 Kilometers", value: "20" },
    { label: "Less than 50 Kilometers", value: "50" },
    { label: "Less than 100 Kilometers", value: "100" },
    { label: "Less than 150 Kilometers", value: "150" },
    { label: "Less than 200 Kilometers", value: "200" },
    { label: "Less than 250 Kilometers", value: "250" },
    { label: "Less than 300 Kilometers", value: "300" },
] as const;

export default standardMaxOfficeDistance;
