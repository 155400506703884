export type EnquiryStatus = (typeof enquiryStatus)[number];

type TranslationNSObject = {
    [key in EnquiryStatus]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const enquiryStatusTranslations: Translations = {
    de: {
        ACCEPTED: "Angebot angenommen",
        CANCELLED: "Abgebrochen",
        DRAFT: "Entwurf",
        PENDING: "Warten auf Angebote",
    },
    en: {
        ACCEPTED: "Offer accepted",
        CANCELLED: "Cancelled",
        DRAFT: "Draft",
        PENDING: "Awaiting offers",
    },
};

export const enquiryStatus = [
    "ACCEPTED",
    "CANCELLED",
    "DRAFT",
    "PENDING",
] as const;

export const EnquiryWithOfferStatusTranslations = {
    de: {
        VALUER: {
            ...enquiryStatusTranslations.de,
            WON: "Zuschlag",
            LOST: "Vergeben",
            OFFER_SUBMITTED: "Angebot eingereicht",
            OFFER_REJECTED: "Angebot abgelehnt",
        },
        CLIENT: {
            ...enquiryStatusTranslations.de,
            WON: "Zuschlag",
            LOST: "Vergeben",
            OFFER_SUBMITTED: "Angebot eingereicht",
            OFFER_REJECTED: "Angebot abgelehnt",
        },
    },
    en: {
        VALUER: {
            ...enquiryStatusTranslations.en,
            WON: "Won",
            LOST: "Lost",
            OFFER_SUBMITTED: "Offer submitted",
            OFFER_REJECTED: "Offer rejected",
        },
        CLIENT: {
            ...enquiryStatusTranslations.en,
            WON: "Won",
            LOST: "Lost",
            OFFER_SUBMITTED: "Offer submitted",
            OFFER_REJECTED: "Offer rejected",
        },
    },
};

export type EnquiryWithOfferStatus = (typeof enquiryWithOfferStatus)[number];

export const enquiryWithOfferStatus = [
    ...enquiryStatus,
    "LOST",
    "WON",
    "OFFER_SUBMITTED",
    "OFFER_REJECTED",
] as const;
