import { QuestionOutlineIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Text, Tooltip } from "@chakra-ui/react";

type OfferPropertyProps = {
    label?: string;
    value: string | number;
    color?: string;
    valueSize?: string;
    tooltip?: string;
    valuerTooltip?: string;
};

export const OfferProperty = (props: OfferPropertyProps) => {
    const {
        label,
        value,
        color,
        valueSize = "sm",
        tooltip,
        valuerTooltip,
    } = props;

    return (
        <Flex
            w={"100%"}
            align={"center"}
            justifyContent={"space-between"}
        >
            <Text
                fontSize={"sm"}
                color={"gray.500"}
            >
                {label}
                {tooltip && (
                    <Tooltip
                        hasArrow
                        placement="right"
                        label={tooltip}
                    >
                        <QuestionOutlineIcon
                            ml={2}
                            color={"gray.500"}
                        />
                    </Tooltip>
                )}
            </Text>
            <Text
                color={color}
                fontSize={valueSize}
                fontWeight={"bold"}
            >
                {valuerTooltip && (
                    <Tooltip
                        hasArrow
                        placement="left"
                        label={valuerTooltip}
                    >
                        <WarningTwoIcon
                            mr={2}
                            fontSize={"16px"}
                            color={"gray.400"}
                            verticalAlign={"text-top"}
                        />
                    </Tooltip>
                )}
                {value}
            </Text>
        </Flex>
    );
};
