import { Box, Text } from "@chakra-ui/layout";
import { useSmartTranslation } from "../hooks/useSmartTranslation";

type PlaceholderProps = {
    noDataText?: string;
    height?: string;
};

export const Placeholder = (props: PlaceholderProps) => {
    const t = useSmartTranslation();

    const { noDataText = t("common.noData"), height } = props;

    return (
        <Box
            backgroundColor="gray.50"
            borderRadius="8px"
            w="100%"
            h={height}
            alignContent="center"
        >
            <Text
                paddingBlock="2rem"
                textAlign="center"
                variant="secondary"
                w="100%"
                fontSize="sm"
            >
                {noDataText}
            </Text>
        </Box>
    );
};
