import { Box } from "@chakra-ui/react";
import { PropertyDocumentType } from "../../../../../../types";
import {
    File,
    getFileTypeBasedOnName,
} from "../../../../common/components/display/File";
import { useDocumentViewerModal } from "../../../../common/hooks/useDocumentViewerModal";

type PropertyDocumentsListProps = {
    documents: PropertyDocumentType[] | undefined;
};

const PropertyDocumentList = (props: PropertyDocumentsListProps) => {
    const { documents } = props;

    const { documentView, openDocumentView, setViewedDocument } =
        useDocumentViewerModal();

    return (
        <>
            {documents?.map((item) => (
                <Box
                    key={item.name}
                    padding=".5rem 1rem"
                    bg={"gray.50"}
                    transition={".25s"}
                    color="gray.900"
                    _hover={{
                        bg: "blue.50",
                        color: "blue.500",
                    }}
                >
                    <File
                        documentItem={item}
                        viewDocument={() => {
                            setViewedDocument({
                                uri: item.url,
                                fileType: getFileTypeBasedOnName(item.name),
                                label: item.name,
                            });
                            openDocumentView();
                        }}
                        downloadDocument
                    />
                </Box>
            ))}

            {documentView}
        </>
    );
};

export default PropertyDocumentList;
