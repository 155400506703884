import { Icon, IconProps } from "@chakra-ui/react";

const CertificateIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 25 25"
        {...props}
    >
        <path
            d="m4.32129 3.48413c-1.11 0-2 .89016-2 2.00035v10.00172c0 .5305.21071 1.0393.58579 1.4145.37507.3751.88378.5859 1.41421.5859h8.00001v5.0008l3-3.0005 3 3.0005v-5.0008h2c.5304 0 1.0391-.2108 1.4142-.5859.3751-.3752.5858-.884.5858-1.4145v-7.0012-2.00035-1.00017c0-.53053-.2107-1.03932-.5858-1.41446s-.8838-.58589-1.4142-.58589h-4zm8.00001 2.00035 3 2.00034 3-2.00034v3.5006l3 1.50022-3 1.5003v3.5006l-3-2.0003-3 2.0003v-3.5006l-3.00001-1.5003 3.00001-1.50022zm-8.00001 0h5v2.00034h-5zm0 4.00069h3v2.00033h-3zm0 4.00073h5v2.0003h-5z"
            fill="#a0aec0"
        />
    </Icon>
);

export default CertificateIcon;
