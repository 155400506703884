import {
    Box,
    Circle,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Building } from "iconsax-react";
import { FiMail, FiPhone } from "react-icons/fi";
import { MdOutlineFolderShared } from "react-icons/md";
import PublicCompanyProfilePicture from "../../../../../../common/components/PublicCompanyProfilePicture";
import PublicUserProfilePicture from "../../../../../../common/components/PublicUserProfilePicture";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { EnquiryType, EntityType, UserType } from "../../../../../../types";
import { CopyableEntry } from "../../../../common/components/display";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { experienceMatchRequirement } from "../../../../domain/standards/years-of-experience/YearsOfExperience";
import Subsection from "../../../../layout/Subsection";

const MAX_VALUERS_COUNT = 5;

type ValuerCompanyDetailsProps = {
    entity: EntityType;
    enquiry: EnquiryType;
    entityUsers: UserType[];
};

const ValuerCompanyDetails = (props: ValuerCompanyDetailsProps) => {
    const {
        entity: {
            id: entityId,
            name,
            street,
            postalCode,
            city,
            companyEmail,
            contactNumber,
            contactNumberDialCode,
            piCoverValue,
            piCoverValueUnlimited,
            piCoverPercentage,
            piCoverPercentageUnlimited,
            piCoverSpecialArrangementsAvailable,
            valuerYearsOfExperience,
        },
        enquiry: {
            valuerMinimumPICover: requiredPICover,
            valuerMinimumPICoverPercentage: requiredPICoverPercentage,
            valuerExperience: requiredExperience,
        },
        entityUsers,
    } = props;

    const t = useSmartTranslation();

    // render

    const banner = (
        <HStack
            borderRadius={"lg"}
            padding={"1rem"}
            bg={"gray.50"}
            spacing={4}
            mb={2}
        >
            <PublicCompanyProfilePicture
                size="5rem"
                companyId={entityId}
            />
            <Box>
                <Heading size={"lg"}>{name}</Heading>
            </Box>
        </HStack>
    );

    const maxValuerDisplayed = entityUsers.slice(0, MAX_VALUERS_COUNT);

    const valuersList = maxValuerDisplayed.length > 0 && (
        <HStack
            minH={"3.5rem"}
            spacing={0}
            borderRadius={"lg"}
            padding={".5rem 1rem"}
            bg={"green.50"}
            justifyContent={"space-between"}
        >
            <HStack spacing={4}>
                <Icon
                    as={MdOutlineFolderShared}
                    color={"gray.400"}
                />
                <Text
                    fontSize={"md"}
                    fontWeight={"bold"}
                    color={"green.500"}
                >
                    {entityUsers.length}{" "}
                    {entityUsers.length === 1
                        ? t("domain.user.valuer")
                        : t("domain.user.valuers")}
                </Text>
            </HStack>
            <HStack spacing={0}>
                {maxValuerDisplayed.map((user) => (
                    <Box
                        key={user.id}
                        ml={-2}
                    >
                        <PublicUserProfilePicture
                            userId={user.id}
                            entityId={user.entityId}
                            size={"2em"}
                        />
                    </Box>
                ))}

                {entityUsers.length > MAX_VALUERS_COUNT && (
                    <Circle
                        size={"2rem"}
                        margin="0"
                        bg={"gray.200"}
                        ml={-2}
                    >
                        <Text fontSize={"xs"}>
                            +{entityUsers.length - maxValuerDisplayed.length}
                        </Text>
                    </Circle>
                )}
            </HStack>
        </HStack>
    );

    const entries = (
        <VStack
            width={"100%"}
            align={"stretch"}
        >
            <CopyableEntry
                leftIcon={Building}
                label={`${street}, ${postalCode} ${city}`}
            />
            <CopyableEntry
                leftIcon={FiMail}
                label={companyEmail}
            />
            <CopyableEntry
                leftIcon={FiPhone}
                label={`${contactNumberDialCode} ${contactNumber}`}
            />
            {valuersList}
        </VStack>
    );

    const experienceMatch = experienceMatchRequirement(
        valuerYearsOfExperience!,
        requiredExperience
    );
    const piCoverMatch =
        piCoverValueUnlimited || piCoverValue >= requiredPICover;
    const piCoverPercentageMatch =
        piCoverPercentageUnlimited ||
        piCoverPercentage >= requiredPICoverPercentage;

    const coverAmount = piCoverValueUnlimited ? t('common.noLimit') : delimitNumber(piCoverValue!);

    const coverPercentage = piCoverPercentageUnlimited ? t('common.noLimit') : piCoverPercentage!;

    const additionalInfo = (
        <SimpleGrid
            width={"100%"}
            columns={2}
            gap={2}
        >
            <MatchInfo
                match={experienceMatch}
                title={t(
                    "screens.platform.client.offerDetails.valuerCompanyDetails.experience"
                )}
                content={`${valuerYearsOfExperience} ${t(
                    "common.units.years"
                )}`}
            />
            <MatchInfo
                match={piCoverMatch}
                title={t(
                    "screens.platform.client.offerDetails.valuerCompanyDetails.piCoverAbsolute"
                )}
                content={`€ ${coverAmount}`}
            />
            <MatchInfo
                match={piCoverPercentageMatch}
                title={t(
                    "screens.platform.client.offerDetails.valuerCompanyDetails.piCoverPercentage"
                )}
                content={`${coverPercentage}%`}
            />
            <Subsection
                title={t(
                    "screens.platform.client.offerDetails.valuerCompanyDetails.piAgreement"
                )}
            >
                <Text fontWeight="bold">
                    {piCoverSpecialArrangementsAvailable
                        ? t("common.yes")
                        : t("common.no")}
                </Text>
            </Subsection>
        </SimpleGrid>
    );

    return (
        <>
            {banner}
            <HStack
                spacing={2}
                align={"stretch"}
            >
                {entries}
                {additionalInfo}
            </HStack>
        </>
    );
};

type MatchInfoProps = {
    title: string;
    content: string;
    match: boolean;
};

const MatchInfo = (props: MatchInfoProps) => {
    const { title, content, match } = props;
    return (
        <Subsection
            title={title}
            bg={match ? "green.50" : "gray.50"}
        >
            <Text
                fontWeight="bold"
                color={match ? "green.500" : "gray.900"}
            >
                {content}
            </Text>
        </Subsection>
    );
};

export { ValuerCompanyDetails };
