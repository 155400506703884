import { Button, HStack, Link, Text, VStack } from "@chakra-ui/react";
import { StandardSystemStandardsOffered } from "../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { UserType } from "../../../../../types";
import { FirebaseCertificateType } from "../../../../../utils/storage/storage";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import InformationRowItem from "./InformationRowItem";

type PropsType = {
    user: UserType;
    certificates: FirebaseCertificateType[];
};

const ProfileValuationStandards = (props: PropsType) => {
    const {
        user: { systemStandardsOffered = [], otherStandardsOffered = [] },
        certificates,
    } = props;

    const t = useSmartTranslation();

    const systemStandards = (
        <InformationRowItem
            label={t("profile.valuationCompetence.valuationStandards")}
            content={
                <StandardsList
                    standards={systemStandardsOffered}
                    certificates={certificates}
                    hasTranslations
                />
            }
        />
    );

    const otherStandards = otherStandardsOffered && (
        <InformationRowItem
            label={t("profile.valuationCompetence.otherValuationStandards")}
            content={
                <StandardsList
                    standards={otherStandardsOffered}
                    certificates={certificates}
                />
            }
        />
    );

    return (
        <>
            {systemStandards}
            {otherStandards}
        </>
    );
};

type StandardsListProps = {
    standards: string[] | StandardSystemStandardsOffered[];
    certificates: FirebaseCertificateType[];
    hasTranslations?: boolean;
};

const StandardsList = (props: StandardsListProps) => {
    const { certificates, standards, hasTranslations = false } = props;

    const t = useSmartTranslation();

    return (
        <VStack spacing={2}>
            {standards.length === 0 && (
                <Text
                    color="gray.500"
                    fontSize={"sm"}
                >
                    {t("common.n/a")}
                </Text>
            )}

            {standards?.sort()?.map((i) => {
                const files = certificates.filter(
                    (certificate) =>
                        certificate.type.toLowerCase() === i.toLowerCase()
                );

                return (
                    <HStack
                        key={i}
                        w="100%"
                    >
                        <Text
                            w="10rem"
                            fontSize={"sm"}
                        >
                            {hasTranslations
                                ? t(`domain.standards.systemStandard.${i}`, {
                                      defaultValue: MISSING_TRANSLATION,
                                  })
                                : i}
                        </Text>

                        {files.map((file) => (
                            <Button
                                key={file.file.fullPath}
                                as={Link}
                                href={file.url}
                                isExternal
                                variant={"link"}
                                target="_blank"
                                color="blue.500"
                                size={"sm"}
                            >
                                {file.label}
                            </Button>
                        ))}

                        {files.length === 0 && (
                            <Text
                                fontSize={"sm"}
                                color={"gray.500"}
                            >
                                {t(
                                    "profile.membershipsAndQualifications.noCertificate"
                                )}
                            </Text>
                        )}
                    </HStack>
                );
            })}
        </VStack>
    );
};

export default ProfileValuationStandards;
