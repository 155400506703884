import { useMutation } from 'react-query';
import { OfferEditDto } from '../dto/offer-edit';
import { offerEdit } from '../endpoints';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useOfferEdit = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: edit, mutateAsync: editAsync } = useMutation(
        (dto: OfferEditDto) => offerEdit(dto),
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError,
        },
    );

    return {
        edit,
        editAsync
    };
};

export default useOfferEdit;
