import { OfferType } from "../../../../../../../../types";
import { Offer } from "../../../../../../domain";
import { Enrichment } from "../../../enricher";
import { EARLIEST_DELIVERY_FIELDNAME } from "../display/EnrichedOfferColumns";
import { EARLIEST_DELIVERY } from "./enrichers";

export const earliestDelivery = <T extends OfferType>(
    offers: T[]
): Enrichment<T> => {
    const offersWithDeliverySoonToLate = [...offers]
        .map((offer) => ({
            offer,
            delivery: Offer.calculateDeliveryDate(offer).valueOf(),
        }))
        .sort(({ delivery: deliveryDate1 }, { delivery: deliveryDate2 }) => {
            if (deliveryDate1 < deliveryDate2) return -1;
            if (deliveryDate1 > deliveryDate2) return 1;
            return 0;
        });

    const highlightedData = offersWithDeliverySoonToLate
        .filter(
            (offer) =>
                offer.delivery === offersWithDeliverySoonToLate[0].delivery
        )
        .map(({ offer }) => ({
            data: offer,
            highlight: {
                keyword: EARLIEST_DELIVERY,
            },
        }));

    return {
        fieldName: EARLIEST_DELIVERY_FIELDNAME,
        highlightedData,
    };
};
