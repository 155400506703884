import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DirectEnquiryDataType } from "./types";
import { usePublicCreateEnquiry, usePublicEntity } from "../../utils/api/hooks";
import DirectEnquiryOverview from "./DirectEnquiryOverview";
import DirectEnquiryRegistration from "./DirectEnquiryRegistration";
import DirectPropertyDescription from "./DirectPropertyDescription";
import DirectValuationType from "./DirectValuationType";
import SiteLoading from "../../common/components/SiteLoading";
import ProcessFlowStateful from "../../common/components/display/ProcessFlowStateful";
import useSigninState from "../../common/hooks/useSigninState";

const { REACT_APP_GOOGLE_RECAPTCHA_KEY = "" } = process.env;

const CreateDirectEnquiry = () => {
    const navigate = useNavigate();
    const { clientType = "individual", token = "" } = useParams();
    const { t } = useTranslation("enquiryform");
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [enquiryData, setEnquiryData] = useState({} as DirectEnquiryDataType);

    const { signedIn } = useSigninState();

    const { data: targetValuer, isLoading } = usePublicEntity(token);

    const { update: createPublicEnquiry } = usePublicCreateEnquiry({
        onSuccess: (data) => {
            const enquiryId = data.data.id;
            if (signedIn) {
                navigate(`/enquiry-success/${enquiryId}`);
            } else {
                const registerUrl = encodeURIComponent("/register/client");

                navigate(`/enquiry-success/${enquiryId}/${registerUrl}`);
            }
        },
    });

    const onContinue = (values: DirectEnquiryDataType, goNext: () => void) => {
        setEnquiryData({
            ...enquiryData,
            ...values,
            fromEntityId: targetValuer?.id || -1,
        });

        goNext();
    };

    const onDone = async (data: DirectEnquiryDataType) => {
        const recaptchaToken = await recaptchaRef.current?.executeAsync();

        const clientTypeToCreate =
            clientType === "individual" ? "INDIVIDUAL" : "COMPANY";

        createPublicEnquiry({
            ...data,
            toEntityId: targetValuer?.id || -1,
            recaptchaToken: recaptchaToken || "",
            clientTypeToCreate,
        });
    };

    if (isLoading) {
        return (
            <SiteLoading
                noLogo
                text={t("loading", { ns: "common" })}
            />
        );
    }

    const steps = [
        <DirectValuationType
            enquiryData={enquiryData}
            onContinue={onContinue}
        />,
        <DirectPropertyDescription
            enquiryData={enquiryData}
            updateProperties={properties => setEnquiryData({
                ...enquiryData,
                properties,
            })}
            onContinue={onContinue}
        />,
        <DirectEnquiryRegistration
            enquiryData={enquiryData}
            onContinue={onContinue}
        />,
        <DirectEnquiryOverview
            enquiryData={enquiryData}
            onContinue={onContinue}
            valuerName={targetValuer?.name as string}
        />,
    ];

    return (
        <>
            <ProcessFlowStateful
                steps={steps}
                onProcessEnd={() => onDone(enquiryData)}
            />

            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                size="invisible"
            />
        </>
    );
};

export default CreateDirectEnquiry;
