import { ButtonGroup, Divider, HStack, Text } from "@chakra-ui/react";
import { BsGlobe, BsPersonFillCheck } from "react-icons/bs";
import {
    EnquiryType,
    EntityType,
    OfferType,
    UserType,
} from "../../../../types";
import { useAuthedUser } from "../../app/useMeV2";
import { Title as TitleBase } from "../../common/components/display";
import { Breadcrumb } from "../../common/features/breadcrumbs/Breadcrumb";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { Icons } from "../../common/icons/Icons";
import CancelEnquiry from "./CancelEnquiry";
import { Enquiry } from "./Enquiry";
import EnquiryStatusText from "./EnquiryStatusText";
import { MatchmakingInfoTag } from "./MatchmakingInfoTag";

export type EnquiryWithRecipientInfo = EnquiryType & {
    recipientInfo?: EntityType;
};

type Params = {
    enquiry: EnquiryWithRecipientInfo;
    userIsClient: boolean;
    clientInfo?: React.ReactNode;
    offer?: OfferType;
    onUpdate: () => any;
    onError: (error: Error) => any;
};

const Title = (params: Params) => {
    const {
        enquiry: { id },
        enquiry,
        userIsClient,
        onUpdate,
        onError,
        clientInfo,
        offer,
    } = params;

    const t = useSmartTranslation();

    const user = useAuthedUser();

    // TODO: remove "routes" after change the route enquiry -> enquiries
    const breadcrumbs = (
        <Breadcrumb.Breadcrumbs
            routes={[
                {
                    path: "/dashboard",
                    breadcrumb: t("menubar.dashboard"),
                },
                {
                    path: "/dashboard/enquiry",
                    breadcrumb: t("domain.enquiry.enquiries"),
                    props: {
                        customPath: "/dashboard/enquiries",
                    },
                },
                {
                    path: "/dashboard/enquiry/:enquiryId",
                    breadcrumb: ({ match }) => (
                        <>
                            {t("domain.enquiry.enquiryId", {
                                id: match.params.enquiryId,
                            })}
                        </>
                    ),
                },
            ]}
        />
    );

    const cancelButton = userIsClient && (
        <CancelEnquiry
            onSuccess={onUpdate}
            onError={onError}
            enquiry={enquiry}
            userIsClient={userIsClient}
        />
    );

    const archiveButton = (
        <Enquiry.archive
            enquiry={enquiry}
            onSuccess={onUpdate}
            onError={onError}
        />
    );

    const matchMaking = (
        <HStack spacing={3}>
            <Text
                fontSize={"sm"}
                color={"gray.500"}
            >
                {t("domain.enquiry.published")}
            </Text>
            <Matchmaking enquiry={enquiry} />
            <Divider
                orientation="vertical"
                h={7}
                ms={3}
            />
        </HStack>
    );

    // render

    const subtitle = (
        <HStack spacing={6}>
            {matchMaking}
            <HStack>
                <Text
                    fontSize="sm"
                    color="gray.500"
                >
                    {t("domain.enquiry.statusLabel")}
                </Text>
                <EnquiryStatusText
                    enquiry={enquiry}
                    offer={offer}
                />
            </HStack>
        </HStack>
    );

    const rightSide = (
        <HStack spacing={4}>
            <ButtonGroup variant="outline">
                {cancelButton}
                {archiveButton}
            </ButtonGroup>
            {clientInfo && (
                <>
                    <Divider
                        orientation="vertical"
                        minH="3em"
                        h="100%"
                    />
                    {clientInfo}
                </>
            )}
        </HStack>
    );

    return (
        <>
            {breadcrumbs}
            <TitleBase
                title={t("domain.enquiry.enquiryId", { id })}
                subtitle={subtitle}
                rightSide={rightSide}
            />
        </>
    );
};

type MatchmakingProps = {
    enquiry: EnquiryWithRecipientInfo;
};

const Matchmaking = (props: MatchmakingProps) => {
    const {
        enquiry: { panelRecipientIds, allowFullMatchmaking, recipientInfo },
    } = props;

    const t = useSmartTranslation();

    const openMarket = allowFullMatchmaking && (
        <MatchmakingInfoTag
            label={t("domain.enquiry.matchmaking.openMarket")}
            icon={BsGlobe}
        />
    );

    const panel = !!panelRecipientIds.length && (
        <MatchmakingInfoTag
            label={t("domain.enquiry.matchmaking.valuerPanel")}
            icon={Icons.PersonGroupAdd}
        />
    );

    const direct = recipientInfo && (
        <MatchmakingInfoTag
            label={t("domain.enquiry.matchmaking.exclusively", {
                name: recipientInfo.name,
            })}
            icon={BsPersonFillCheck}
        />
    );

    return (
        <HStack spacing={2}>
            {direct}
            {panel}
            {openMarket}
        </HStack>
    );
};

// TODO: old one
type MatchmakingByRoleProps = {
    userIsClient: boolean;
    enquiry: EnquiryWithRecipientInfo;
    user?: UserType;
};

const MatchmakingByRole = (props: MatchmakingByRoleProps) => {
    const {
        userIsClient,
        enquiry: { panelRecipientIds, allowFullMatchmaking },
        enquiry,
        user,
    } = props;

    const t = useSmartTranslation();

    const clientMatchmaking = (
        <>
            <Enquiry.MatchmakingInfo
                label={t("domain.enquiry.matchmaking.openMarket")}
                condition={allowFullMatchmaking!}
            />
            <Enquiry.MatchmakingInfo
                label={t("domain.enquiry.matchmaking.valuerPanel")}
                condition={!!panelRecipientIds.length}
            />
            {enquiry.recipientInfo && (
                <Enquiry.MatchmakingInfo
                    label={t("domain.enquiry.matchmaking.exclusively", {
                        name: enquiry.recipientInfo.name,
                    })}
                    condition
                />
            )}
        </>
    );

    const isInValuerPanel = enquiry.panelRecipientIds.includes(user?.entityId!);
    const isInMatchmaking = enquiry.matchmakingEntityIds.includes(
        user?.entityId!
    );
    const valuerMatchmaking = (
        <>
            {enquiry.recipientInfo && (
                <Enquiry.MatchmakingInfo
                    label={t("domain.enquiry.matchmaking.exclusively", {
                        name: enquiry.recipientInfo.name,
                    })}
                    condition
                />
            )}

            {isInValuerPanel && !enquiry.recipientInfo && (
                <Enquiry.MatchmakingInfo
                    label={t("domain.enquiry.matchmaking.valuerPanel")}
                    condition={true}
                />
            )}

            {isInMatchmaking && !isInValuerPanel && (
                <Enquiry.MatchmakingInfo
                    label={t("domain.enquiry.matchmaking.openMarket")}
                    condition={true}
                />
            )}
        </>
    );

    return userIsClient ? clientMatchmaking : valuerMatchmaking;
};

export { Title };
