export const enquiryListTranslations = {
    de: {
        filters: {
            ACTIVITIES: {
                apply: {
                    NAME: "Ausführen",
                },
                clear: {
                    NAME: "Zurücksetzen"
                },
                clearAll: {
                    NAME: "Alle zurücksetzen"
                },
            },
            title: "Filter",
            results: "Ergebnisse: {{count}}",
            byOutlet: {
                label: "Anfrage-Radius",
                options: {
                    allOutlets: "Alle Anfragen",
                    openMarket: "Matchmaking",
                    valuerPanel: "Panel-Anfragen",
                    exclusively: "Direkte Anfragen",
                },
            },
            byStandard: {
                label: "Standard",
                options: {
                    allStandards: "Alle Standards",
                },
            },
            byStatus: {
                label: "Status",
                options: {
                    allStatuses: "Alle Status",
                },
            },
            byValuationType: {
                label: "Umfang",
                options: {
                    allValuationTypes: "Alle Umfänge",
                    // alternative: Bewertungstyp
                },
            },
            byPropertyAndClient: {
                label: "Suche",
                VALUER: {
                    placeholder: "Suche nach Bewertungsobjekt oder Kunde...",
                },
                CLIENT: {
                    placeholder: "Suche nach Bewertungsobjekt...",
                },
            },
        },
        title: {
            ACTIVE: "Anfragen",
            ARCHIVED: "Ignorierte",
            DRAFT: "Entwürfe",
        },
    },
    en: {
        filters: {
            ACTIVITIES: {
                apply: {
                    NAME: "Apply",
                },
                clear: {
                    NAME: "Clear"
                },
                clearAll: {
                    NAME: "Clear all"
                },
            },
            title: "Filters",
            results: "Results: {{count}}",
            byOutlet: {
                label: "Enquiry outlet",
                options: {
                    allOutlets: "All outlets",
                    openMarket: "Open Market",
                    valuerPanel: "Valuer Panel",
                    exclusively: "Direct enquiry",
                },
            },
            byStandard: {
                label: "Standard",
                options: {
                    allStandards: "All standards",
                },
            },
            byStatus: {
                label: "Status",
                options: {
                    allStatuses: "All statuses",
                },
            },
            byValuationType: {
                label: "Scope",
                options: {
                    allValuationTypes: "All scopes",
                },
            },
            byPropertyAndClient: {
                label: "Search",
                VALUER: {
                    placeholder: "Search by property or client...",
                },
                CLIENT: {
                    placeholder: "Search by property...",
                },
            },
        },
        title: {
            ACTIVE: "Enquiries",
            ARCHIVED: "Ignored",
            DRAFT: "Drafts",
        },
    },
};
