import { WarningTwoIcon } from "@chakra-ui/icons";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { StandardSpecialValuationType } from "../../../../../common/vars/valuationsAndMembershipTypes/standardSpecialValuationTypes";
import { StandardValuationType } from "../../../../../common/vars/valuationsAndMembershipTypes/standardValuationTypes";
import { UserType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import InformationRowItem from "./InformationRowItem";

type PropsType = {
    user: UserType;
};

const ProfileValuationTypes = (props: PropsType) => {
    const {
        user: {
            valuationTypes = [],
            specialValuationTypes = [],
            otherValuationTypes = [],
        },
    } = props;

    const t = useSmartTranslation();

    const valuations = (
        <InformationRowItem
            label={t("profile.valuationCompetence.valuationScope")}
            content={
                <ValuationList
                    valuations={valuationTypes}
                    translationKey={"valuationType"}
                    required
                />
            }
        />
    );

    const specialValuations = (
        <InformationRowItem
            label={t("profile.valuationCompetence.specificValuationScopes")}
            content={
                <ValuationList
                    valuations={specialValuationTypes}
                    translationKey={"specialValuationType"}
                />
            }
        />
    );

    const otherValuations = (
        <InformationRowItem
            label={t("profile.valuationCompetence.otherValuationScopes")}
            content={<ValuationList valuations={otherValuationTypes} />}
        />
    );

    return (
        <>
            {valuations}
            {specialValuations}
            {otherValuations}
        </>
    );
};

type ValuationListProps = {
    valuations:
        | StandardValuationType[]
        | StandardSpecialValuationType[]
        | string[];
    translationKey?: string;
    required?: boolean;
};

const ValuationList = (props: ValuationListProps) => {
    const { valuations, translationKey, required = false } = props;

    const t = useSmartTranslation();

    return (
        <>
            {valuations.length === 0 && (
                <HStack fontSize={"sm"}>
                    {required && <WarningTwoIcon color={"gray.400"} />}
                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                    >
                        {t("common.n/a")}
                    </Text>
                </HStack>
            )}

            <VStack
                align={"start"}
                spacing={0}
            >
                {valuations?.sort()?.map((valuation) => (
                    <Text
                        key={valuation}
                        fontSize={"sm"}
                    >
                        {translationKey
                            ? t(
                                  `domain.standards.${translationKey}.${valuation}`,
                                  {
                                      defaultValue: MISSING_TRANSLATION,
                                  }
                              )
                            : valuation}
                    </Text>
                ))}
            </VStack>
        </>
    );
};

export default ProfileValuationTypes;
