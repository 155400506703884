import { Box, Flex, Text } from "@chakra-ui/react";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../../common/functions/displayTime";
import { OfferOnlyWithEnquiryType } from "../../../../../types/offer/OfferOnlyWithEnquiryType";
import { useAuthedUser } from "../../../app/useMeV2";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { OfferStatus as OfferStatusType } from "../offerStatus";
import {
    OfferStatusText,
    getColorByOfferAndEnquiryStatuses,
} from "../OfferStatusText";

type OfferStatusProps = {
    offer: OfferOnlyWithEnquiryType;
};

export const OfferStatus = (props: OfferStatusProps) => {
    const {
        offer: { caseId, status, updatedAt, enquiry },
        offer,
    } = props;

    const t = useSmartTranslation();

    const user = useAuthedUser();
    const role = user.entity.accountType;

    const date = `(${displayDateWithCurrentLocale(
        updatedAt
    )}, ${displayTimeInCurrentLocale(new Date(updatedAt))})`;

    const DESCRIPTIONS: Record<OfferStatusType, string> = {
        ACCEPTED: t(`domain.offer.statusContent.${role}.ACCEPTED`, {
            caseId,
        }),
        PENDING: t(`domain.offer.statusContent.${role}.PENDING`),
        REJECTED: t(`domain.offer.statusContent.${role}.REJECTED`),
        WITHDRAWN: t(`domain.offer.statusContent.${role}.WITHDRAWN`),
    };

    const bgColor = getColorByOfferAndEnquiryStatuses(
        status,
        enquiry?.status
    ).split(".")[0];

    return (
        <Box
            borderRadius={8}
            bg={`${bgColor}.50`}
            w={"100%"}
            p={4}
            mb={2}
        >
            <Flex
                align={"center"}
                gap={2}
                mb={1}
            >
                <OfferStatusText
                    offer={offer}
                    iconSize={6}
                    fontSize={"md"}
                    fontWeight={"700"}
                    color={"gray.900"}
                />
                <Text>{date}</Text>
            </Flex>
            <Text
                fontSize={"0.8em"}
                color={"gray.500"}
            >
                {DESCRIPTIONS[status]}
            </Text>
        </Box>
    );
};
