import { Button, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useRole } from "../../../../../common/hooks";
import { useMyEntity } from "../../../../../utils/api/hooks";
import CompanyDetailsEdit from "../../../../../views/dashboard/account/index/common/CompanyDetailsEdit";
import { Title } from "../../../common/components/display";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { Section } from "../../../layout";
import CompanyDetailsData from "./CompanyDetailsData";
import CompanyDetailsPiCover from "./CompanyDetailsPiCover";

const CompanyDetails = () => {
    const t = useSmartTranslation();

    const { userIsCompanyAdmin, userIsValuer } = useRole();

    const [editing, setEditing] = useState(false);

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    if (entityLoading || !entity) {
        return <LoadingSpinner />;
    }

    if (editing) {
        return (
            <CompanyDetailsEdit
                entity={entity}
                onComplete={() => setEditing(false)}
            />
        );
    }

    // render

    const header = (
        <Title
            title={t("account.heading.companyDetails")}
            rightSide={
                userIsCompanyAdmin && (
                    <Button
                        variant="outline"
                        colorScheme="blue"
                        size={"sm"}
                        leftIcon={<MdEdit />}
                        onClick={() => setEditing(true)}
                    >
                        {t("common.button.edit")}
                    </Button>
                )
            }
        />
    );

    const details = (
        <Section
            title={t("account.heading.companyDetails")}
            content={<CompanyDetailsData entity={entity} />}
        />
    );

    const piCover = userIsValuer && (
        <Section
            title={t("account.heading.piCover")}
            content={<CompanyDetailsPiCover entity={entity} />}
        />
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
        >
            {header}
            {details}
            {piCover}
        </VStack>
    );
};

export default CompanyDetails;
