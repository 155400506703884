import { PICoverParams } from "./Offer";
import { OfferCreateDto } from "../../../../utils/api/dto";
import { OfferEditDto } from "../../../../utils/api/dto/offer-edit";

export const enforceEntityPICover = <T extends OfferEditDto | OfferCreateDto>(
    dto: T,
    piCover: PICoverParams
): T => {
    const {
        defaultPICoverPercentage,
        defaultPICoverValue,
        piCoverSpecialArrangementsAvailable,
    } = piCover;

    if (piCoverSpecialArrangementsAvailable) return dto;

    return {
        ...dto,
        piCoverPercentage: defaultPICoverPercentage,
        piCoverValue: defaultPICoverValue,
    };
};
