import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { Building } from "iconsax-react";
import { FiMail, FiPhone, FiSmartphone } from "react-icons/fi";
import { UserType } from "../../../../../../types";
import { CopyableEntry } from "../../../../common/components/display";
import useDrawer from "../../../../common/hooks/useDrawer";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { EnquiryWithRecipientInfo } from "../../../../domain/enquiry/Title";
import { ClientProfilePicture } from "../../../../domain/user/ClientProfilePicture";

type TitleClientDataProps = {
    client: UserType;
    enquiry: EnquiryWithRecipientInfo;
};

const TitleClientData = (props: TitleClientDataProps) => {
    const { client, enquiry } = props;

    const t = useSmartTranslation();

    const { drawer, openDrawer } = useDrawer({
        title: t("domain.user.previewDrawerTitle"),
        content: (
            <ClientDrawerContent client={client}>
                <TitleClient client={client}>
                    <ClientProfilePicture
                        client={client}
                        enquiry={enquiry}
                        profileLogoSize={2.5}
                        companyLogoSize={1.5}
                    />
                </TitleClient>
            </ClientDrawerContent>
        ),
        size: "md",
    });

    return (
        <Box
            onClick={openDrawer}
            p={2}
            borderRadius={8}
            _hover={{
                cursor: "pointer",
                bg: "blue.50",
            }}
        >
            <TitleClient client={client}>
                <ClientProfilePicture
                    client={client}
                    enquiry={enquiry}
                    profileLogoSize={2.5}
                    companyLogoSize={1.5}
                />
            </TitleClient>
            {drawer}
        </Box>
    );
};

type TitleClientProps = {
    client: UserType;
    children: React.ReactNode;
};

const TitleClient = (props: TitleClientProps) => {
    const {
        client: {
            firstName,
            lastName,
            orgPosition,
            entity: { name: company },
        },
        children,
    } = props;

    const t = useSmartTranslation();

    return (
        <HStack>
            {children}
            <VStack
                align={"flex-start"}
                spacing="0"
            >
                <Text fontWeight="bold">
                    {firstName} {lastName}
                </Text>
                <Text
                    fontSize={"0.8em"}
                    variant="secondary"
                >
                    {orgPosition}
                    {orgPosition && company && ` ${t("domain.client.at")} `}
                    {company}
                </Text>
            </VStack>
        </HStack>
    );
};

type ClientDrawerContentProps = {
    client: UserType;
    children: React.ReactNode | React.ReactNode[];
};

const ClientDrawerContent = (props: ClientDrawerContentProps) => {
    const {
        client: {
            street,
            postalCode,
            city,
            email,
            officeTel,
            officeTelDialCode,
            mobile,
            mobileDialCode,
        },
        children,
    } = props;

    return (
        <VStack
            width={"100%"}
            spacing={4}
            align={"stretch"}
        >
            <Divider
                mb={3}
                color={"gray.100"}
            />
            {children}
            <CopyableEntry
                leftIcon={Building}
                label={`${street}, ${postalCode} ${city}`}
            />
            <CopyableEntry
                leftIcon={FiMail}
                label={email}
            />
            {officeTel && (
                <CopyableEntry
                    leftIcon={FiPhone}
                    label={`${officeTelDialCode} ${officeTel}`}
                />
            )}
            <CopyableEntry
                leftIcon={FiSmartphone}
                label={`${mobileDialCode} ${mobile}`}
            />
        </VStack>
    );
};

export default TitleClientData;
