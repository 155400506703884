import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

type PropTypes = {
    variant?: string;
    size?: string;
    children: ReactNode | ReactNode[];
    isOpen: boolean;
    header: ReactElement | string;
    onClose: () => void;
};

const ModalDynamic = (props: PropTypes) => {
    const { variant, size, children, isOpen, header, onClose } = props;

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            variant={variant}
            size={size}
        >
            <ModalOverlay />

            <ModalBody>
                <ModalContent borderRadius={size === "full" ? 0 : "1rem"}>
                    <ModalCloseButton />

                    <ModalHeader
                        color="blue.700"
                        fontWeight="bold"
                        fontSize="xl"
                        paddingBottom="0"
                        paddingInline="1rem"
                    >
                        {header}
                    </ModalHeader>

                    <Box
                        p="1rem"
                        pt="0"
                    >
                        {children}
                    </Box>
                </ModalContent>
            </ModalBody>
        </Modal>
    );
};

ModalDynamic.defaultProps = {
    variant: "large",
};

export default ModalDynamic;
