import { Icon, IconProps } from "@chakra-ui/react";

type FileTypeIconPropsType = {
    filetype: string;
    size?: number;
};

const FileTypeIcon = (props: FileTypeIconPropsType) => {
    const { filetype, size = 20 } = props;

    let icon;
    switch (filetype) {
        case "jpeg":
        case "jpg":
        case "svg":
        case "png":
            icon = ImageIcon;
            break;
        case "pdf":
            icon = PdfIcon;
            break;
        case "xls":
        case "xlsx":
            icon = SheetIcon;
            break;
        case "doc":
        case "docx":
            icon = DocIcon;
            break;
        default:
            icon = DefaultIcon;
    }

    return (
        <Icon
            as={icon}
            boxSize={size}
            color={"gray.900"}
        />
    );
};

const PdfIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill={"none"}
            {...props}
        >
            <path
                d="M4 3C4 1.89543 4.89543 1 6 1H15L21 6.97717V21C21 22.1046 20.1046 23 19 23H6C4.89543 23 4 22.1046 4 21V3Z"
                fill="#E2E8F0"
            />
            <path
                d="M15 1L21 6.97717V10.0156L15.4478 6.816L15 1Z"
                fill="#CBD5E0"
            />
            <path
                d="M15.0001 1L21.0001 6.97717H16C15.4477 6.97717 15 6.52945 15 5.97716L15.0001 1Z"
                fill="#A0AEC0"
            />
            <g clipPath="url(#clip0_5820_17642)">
                <path
                    d="M2.75997 20.3333C-0.651923 22.5315 1.65349 23.8569 3.3513 22.3658C4.77551 21.115 8.32656 11.1241 8.9284 8.21812C9.53025 5.31211 6.26501 5.23564 7.16298 8.14165C7.80495 10.2192 9.61049 15.2843 12.9808 17.395C16.3512 19.5057 17.3948 17.7838 16.8728 17.0126C16.1407 15.9312 13.608 15.8303 7.5 18.1493"
                    stroke="#C53030"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5820_17642">
                    <path
                        d="M4 1H21V23H6C4.89543 23 4 22.1046 4 21V1Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </Icon>
    );
};

const DocIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill={"none"}
            {...props}
        >
            <path
                d="M3.5 3C3.5 1.89543 4.39543 1 5.5 1H14.5L20.5 6.97717V21C20.5 22.1046 19.6046 23 18.5 23H5.5C4.39543 23 3.5 22.1046 3.5 21V3Z"
                fill="#4299E1"
            />
            <path
                d="M14.5 1L20.5 6.97717V10.0156L14.9478 6.816L14.5 1Z"
                fill="#3182CE"
            />
            <path
                d="M14.5001 1L20.5001 6.97717H15.5C14.9477 6.97717 14.5 6.52945 14.5 5.97716L14.5001 1Z"
                fill="#90CDF4"
            />
            <rect
                x="7"
                y="14"
                width="10"
                height="1"
                rx="0.5"
                fill="white"
            />
            <rect
                x="7"
                y="16"
                width="10"
                height="1"
                rx="0.5"
                fill="white"
            />
            <path
                d="M7 18.5C7 18.2239 7.22386 18 7.5 18H11.5C11.7761 18 12 18.2239 12 18.5C12 18.7761 11.7761 19 11.5 19H7.5C7.22386 19 7 18.7761 7 18.5Z"
                fill="white"
            />
        </Icon>
    );
};

const SheetIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill={"none"}
            {...props}
        >
            <path
                d="M4 3C4 1.89543 4.89543 1 6 1H15L21 6.97717V21C21 22.1046 20.1046 23 19 23H6C4.89543 23 4 22.1046 4 21V3Z"
                fill="#48BB78"
            />
            <path
                d="M15 1L21 6.97717V10.0156L15.4478 6.816L15 1Z"
                fill="#38A169"
            />
            <path
                d="M15.0001 1L21.0001 6.97717H16C15.4477 6.97717 15 6.52945 15 5.97716L15.0001 1Z"
                fill="#9AE6B4"
            />
            <rect
                x="7"
                y="14"
                width="5"
                height="1"
                rx="0.5"
                fill="white"
            />
            <rect
                x="7"
                y="16"
                width="5"
                height="1"
                rx="0.5"
                fill="white"
            />
            <rect
                x="13"
                y="14"
                width="5"
                height="1"
                rx="0.5"
                fill="white"
            />
            <rect
                x="13"
                y="16"
                width="5"
                height="1"
                rx="0.5"
                fill="white"
            />
            <rect
                x="13"
                y="18"
                width="5"
                height="1"
                rx="0.5"
                fill="white"
            />
            <path
                d="M7 18.5C7 18.2239 7.22386 18 7.5 18H11.5C11.7761 18 12 18.2239 12 18.5V18.5C12 18.7761 11.7761 19 11.5 19H7.5C7.22386 19 7 18.7761 7 18.5V18.5Z"
                fill="white"
            />
        </Icon>
    );
};

const ImageIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill={"none"}
            {...props}
        >
            <path
                d="M4 3C4 1.89543 4.89543 1 6 1H15L21 6.97717V21C21 22.1046 20.1046 23 19 23H6C4.89543 23 4 22.1046 4 21V3Z"
                fill="#0BC5EA"
            />
            <path
                d="M15 1L21 6.97717V10.0156L15.4478 6.816L15 1Z"
                fill="#00A3C4"
            />
            <path
                d="M15.0001 1L21.0001 6.97717H16C15.4477 6.97717 15 6.52945 15 5.97716L15.0001 1Z"
                fill="#9DECF9"
            />
            <rect
                x="8"
                y="12"
                width="3"
                height="1"
                rx="0.5"
                fill="white"
            />
            <rect
                x="9"
                y="12"
                width="2"
                height="1"
                rx="0.5"
                transform="rotate(90 9 12)"
                fill="white"
            />
            <rect
                width="3"
                height="1"
                rx="0.5"
                transform="matrix(1 0 0 -1 8 19)"
                fill="white"
            />
            <rect
                width="2"
                height="1"
                rx="0.5"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 9 19)"
                fill="white"
            />
            <rect
                width="3"
                height="1"
                rx="0.5"
                transform="matrix(-1 0 0 1 17 12)"
                fill="white"
            />
            <rect
                width="2"
                height="1"
                rx="0.5"
                transform="matrix(4.37114e-08 1 1 -4.37114e-08 16 12)"
                fill="white"
            />
            <rect
                x="17"
                y="19"
                width="3"
                height="1"
                rx="0.5"
                transform="rotate(180 17 19)"
                fill="white"
            />
            <rect
                x="16"
                y="19"
                width="2"
                height="1"
                rx="0.5"
                transform="rotate(-90 16 19)"
                fill="white"
            />
        </Icon>
    );
};

const DefaultIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill={"none"}
            {...props}
        >
            <path
                d="M3.5 3C3.5 1.89543 4.39543 1 5.5 1H14.5L20.5 6.97717V21C20.5 22.1046 19.6046 23 18.5 23H5.5C4.39543 23 3.5 22.1046 3.5 21V3Z"
                fill="#A0AEC0"
            />
            <path
                d="M14.5 1L20.5 6.97717V10.0156L14.9478 6.816L14.5 1Z"
                fill="#718096"
            />
            <path
                d="M14.5001 1L20.5001 6.97717H15.5C14.9477 6.97717 14.5 6.52945 14.5 5.97716L14.5001 1Z"
                fill="#CBD5E0"
            />
        </Icon>
    );
};

export { FileTypeIcon };
