import { ChevronRightIcon } from "@chakra-ui/icons";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    HStack,
    Icon,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import useBreadcrumbs, {
    BreadcrumbData,
    Options as BreadcrumbsOptions,
    BreadcrumbsRoute,
} from "use-react-router-breadcrumbs";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";
import { Icons } from "../../icons/Icons";
import { MISSING_TRANSLATION } from "../../MISSING_TRANSLATION";

type BreadcrumbsData = BreadcrumbData & {
    [x: string]: unknown;
};

type BreadcrumbsProps = {
    routes?: BreadcrumbsRoute[];
    options?: BreadcrumbsOptions;
};

const Breadcrumbs = (props: BreadcrumbsProps) => {
    const { routes = [], options = { excludePaths: ["/"] } } = props;

    const t = useSmartTranslation();

    const breadcrumbsData = useBreadcrumbs(routes, {
        ...options,
        defaultFormatter: (string) => {
            return t(`breadcrumbs.${string}`, {
                defaultValue: MISSING_TRANSLATION,
            });
        },
    }) as BreadcrumbsData[];

    // render

    const startIcon = (
        <Icon
            as={Icons.WvwLogo}
            boxSize={5}
            color={"gray.400"}
        />
    );

    const breadcrumbs = breadcrumbsData.length && (
        <Breadcrumb
            spacing={2}
            separator={<ChevronRightIcon color="gray.500" />}
            fontSize={"sm"}
            color={"gray.500"}
        >
            {breadcrumbsData.map(
                ({ breadcrumb, key, match, customPath }, idx) => (
                    <BreadcrumbItem key={key}>
                        {idx === breadcrumbsData.length - 1 ? (
                            breadcrumb
                        ) : (
                            <BreadcrumbLink
                                as={ReactLink}
                                to={customPath || match.pathname}
                            >
                                {breadcrumb}
                            </BreadcrumbLink>
                        )}
                    </BreadcrumbItem>
                )
            )}
        </Breadcrumb>
    );

    return (
        <HStack width={"100%"}>
            {startIcon}
            {breadcrumbs}
        </HStack>
    );
};

export { Breadcrumbs };
