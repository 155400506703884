import { WarningTwoIcon } from "@chakra-ui/icons";
import { HStack, Text } from "@chakra-ui/react";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { EntityType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import InformationRowItem from "./InformationRowItem";
import { NO_DATA } from "../../../common/NO_DATA";

type CompanyDetailsPiCoverProps = {
    entity: EntityType;
};

const CompanyDetailsPiCover = (props: CompanyDetailsPiCoverProps) => {
    const {
        entity: {
            piCoverCurrency,
            piCoverSpecialArrangementsAvailable,
            piCoverPercentage,
            piCoverPercentageUnlimited,
            piCoverValue,
            piCoverValueUnlimited,
            piCoverValidityDate,
        },
    } = props;

    const t = useSmartTranslation();

    const currencySymbol = piCoverCurrency || "€";

    const noLimit = t("account.valuationCapabilities.piCover.noLimit");

    let coverValue = NO_DATA;
    let coverPercentage = NO_DATA;

    if (piCoverValueUnlimited) {
        coverValue = `${currencySymbol} (${noLimit})`;
    } else {
        if (piCoverValue) {
            coverValue = `${currencySymbol} ${delimitNumber(
                piCoverValue.toString()
            )}`;
        }
    }

    if (piCoverPercentageUnlimited) {
        coverPercentage = `(${noLimit})%`;
    } else {
        if (piCoverPercentage) {
            coverPercentage = `${t(
                "account.valuationCapabilities.piCover.maximum"
            )} ${piCoverPercentage}${t(
                "account.valuationCapabilities.piCover.p_of_value"
            )},`;
        }
    }

    // render

    const coverConditions = (
        <InformationRowItem
            label={t(
                "account.valuationCapabilities.piCover.insuranceCoverConditions"
            )}
            content={
                <HStack spacing={1}>
                    <Text
                        fontSize={"sm"}
                        fontWeight={"700"}
                    >
                        {!piCoverValidityDate && (
                            <WarningTwoIcon color={"gray.400"} />
                        )}
                        {t("account.valuationCapabilities.piCover.or", {
                            coverValue,
                            coverPercentage,
                            interpolation: { escapeValue: false },
                        })}
                    </Text>

                    {piCoverValidityDate && (
                        <Text
                            fontSize={"sm"}
                            fontWeight={"700"}
                        >
                            {t(
                                "account.valuationCapabilities.piCover.validUntil",
                                {
                                    date: displayDateWithCurrentLocale(
                                        piCoverValidityDate
                                    ),
                                }
                            )}
                        </Text>
                    )}
                </HStack>
            }
        />
    );

    const specialArrangements = (
        <InformationRowItem
            label={t(
                "account.valuationCapabilities.piCover.specialArrangementsAvailable"
            )}
            content={
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {piCoverSpecialArrangementsAvailable
                        ? t("common.yes")
                        : t("common.no")}
                </Text>
            }
        />
    );

    return (
        <>
            {coverConditions}
            {specialArrangements}
        </>
    );
};

export default CompanyDetailsPiCover;
