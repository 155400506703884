import { useField } from "formik";
import _ from "lodash";
import { OfferAttachmentType } from "../../../../../types";
import { FirebaseCertificateType } from "../../../../../utils/storage/storage";
import { AttachmentItem, AttachmentMenu } from "../OfferEditor";

type usePrepareCompanyDocumentItemsParams = {
    fieldname: string;
    companyDocuments: FirebaseCertificateType[];
    currentAttachmentsOnOffer: OfferAttachmentType[];
};

export const usePrepareCompanyDocumentItems = (
    params: usePrepareCompanyDocumentItemsParams
): AttachmentItem<string>[] => {
    const { fieldname, companyDocuments } = params;

    const [{ value: fieldValue }, , { setValue: setFieldValue }] =
        useField<string[]>(fieldname);

    // items
    const onToggle = (doc: FirebaseCertificateType, checked: boolean) => {
        const { url } = doc;

        if (checked) setFieldValue(_.concat(fieldValue, url));
        else setFieldValue(_.without(fieldValue, url));
    };

    return companyDocuments.map((doc) => {
        const {
            url,
            file: { name },
            meta: { size },
        } = doc;

        return {
            item: url,
            label: name,
            size,
            url,
            checked: fieldValue.includes(url),
            onToggle: (checked) => onToggle(doc, checked),
        };
    });
};

type usePrepareUploadedDocumentItemsParams = {
    fieldname: string;
    uploadedAttachments: File[];
    removeAvailableAttachment: (file: File) => void;
};

export const usePrepareUploadedDocumentItems = (
    params: usePrepareUploadedDocumentItemsParams
): AttachmentItem<File>[] => {
    const { fieldname, uploadedAttachments, removeAvailableAttachment } =
        params;

    const [{ value: fieldValue }, , { setValue: setFieldValue }] =
        useField<File[]>(fieldname);

    const onToggle = (file: File, checked: boolean) => {
        if (checked) setFieldValue(_.concat(fieldValue, file));
        else setFieldValue(_.without(fieldValue, file));
    };

    const deleteUploadedDocument = (file: File) => {
        setFieldValue(_.without(fieldValue, file));
        removeAvailableAttachment(file);
    };

    return uploadedAttachments.map((file) => {
        const { name, size } = file;
        return {
            item: file,
            label: name,
            size,
            checked: fieldValue.map(({ name }) => name).includes(file.name),
            onToggle: (checked) => onToggle(file, checked),
            menu: (
                <AttachmentMenu
                    onDeleteAvailableAttachment={() =>
                        deleteUploadedDocument(file)
                    }
                />
            ),
        };
    });
};
