import { useAuthedUser } from "../../../../app/useMeV2";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../../../common/hooks/useSmartTranslation";
import { User } from "../../../../domain";
import {
    EnquiryFilterFunctionType,
    EnquiryFilterPropsType,
} from "./EnquiryFilter";
import { FilterOptionType, FilterSelect } from "./FilterSelect";

const FILTERGROUP_ID = "BY_SOURCE";

const ByOutlet = (props: EnquiryFilterPropsType) => {
    const {
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const user = useAuthedUser();

    const label = t("screens.platform.enquiryList.filters.byOutlet.label");

    const activateFilter = (
        value: string,
        filter: EnquiryFilterFunctionType
    ): void => {
        activateFilterGroup({
            id: FILTERGROUP_ID,
            filter,
            value,
            displayedData: {
                filterLabel: label,
                valueLabel: t(
                    `screens.platform.enquiryList.filters.byOutlet.options.${value}` as any
                ),
            },
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === FILTERGROUP_ID
    )?.value;

    const options: FilterOptionType[] = [
        {
            id: "allOutlets",
            label: t(
                "screens.platform.enquiryList.filters.byOutlet.options.allOutlets"
            ),
            onSelect: () => deactivateFilter(FILTERGROUP_ID),
        },
        {
            id: "exclusively",
            label: t(
                "screens.platform.enquiryList.filters.byOutlet.options.exclusively"
            ),
            onSelect: () =>
                activateFilter("exclusively", ({ mode }) => mode === "DIRECT"),
        },
        {
            id: "valuerPanel",
            label: t(
                "screens.platform.enquiryList.filters.byOutlet.options.valuerPanel"
            ),
            onSelect: () =>
                activateFilter(
                    "valuerPanel",
                    ({ mode, allowFullMatchmaking }) =>
                        mode === "NORMAL" && !allowFullMatchmaking
                ),
        },
        createMatchmakingFilter(activateFilter, t, user),
    ];

    return (
        <FilterSelect
            label={label}
            filterOptions={options}
            value={activeFilterValue}
        />
    );
};

const createMatchmakingFilter = (
    activateFilter: (value: string, filter: EnquiryFilterFunctionType) => void,
    t: TranslationFunction,
    user: User
) => {
    let filter: EnquiryFilterFunctionType;

    if (user.entity.accountType === "VALUER")
        filter = ({ allowFullMatchmaking, panelRecipientIds }) =>
            allowFullMatchmaking === true &&
            !panelRecipientIds.includes(user.entityId);
    else filter = ({ allowFullMatchmaking }) => allowFullMatchmaking === true;

    return {
        id: "openMarket",
        label: t(
            "screens.platform.enquiryList.filters.byOutlet.options.openMarket"
        ),
        onSelect: () => {
            activateFilter("openMarket", filter);
        },
    };
};

export { ByOutlet };
