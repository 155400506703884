import { useBreakpointValue } from "@chakra-ui/react";

export type DeviceBreakpoints = "mobile" | "tablet" | "desktop" | "wide";

export const useDevice = () => {
    const device = useBreakpointValue<DeviceBreakpoints>(
        { base: "mobile", xl: "tablet", "2xl": "desktop", "3xl": "wide" },
        { ssr: false }
    );

    return device;
};
