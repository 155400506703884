import { Button } from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import useEnquirySetDormant from "./useEnquirySetDormant";

type ParamsType = {
    enquiry: EnquiryType;
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const ArchiveEnquiry = (params: ParamsType) => {
    const { enquiry, onSuccess, onError } = params;

    const {
        update: archiveEnquiry,
        canExecute: canArchive,
        label,
        confirmationModal,
    } = useEnquirySetDormant({
        onSuccess,
        onError,
        enquiry,
    });

    return (
        <Button
            isDisabled={!canArchive}
            onClick={() => archiveEnquiry()}
            variant={"outline"}
            colorScheme={"blue"}
        >
            {label}
            {confirmationModal}
        </Button>
    );
};

export default ArchiveEnquiry;
