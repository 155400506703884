import { Text, VStack } from "@chakra-ui/react";
import toTitleCase from "../../../../../common/functions/toTitleCase";
import { UserType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import InformationRowItem from "./InformationRowItem";

type PropsType = {
    user: UserType;
};

const ProfilePropertyTypes = (props: PropsType) => {
    const {
        user: { valuationPropertyTypes = [] },
    } = props;

    const t = useSmartTranslation();

    return (
        <InformationRowItem
            label={t("profile.valuationCompetence.propertyTypes")}
            content={
                <VStack align={"start"}>
                    {valuationPropertyTypes.length === 0 && (
                        <Text
                            color="gray.500"
                            fontSize={"sm"}
                        >
                            {t("common.n/a")}
                        </Text>
                    )}

                    {valuationPropertyTypes.sort().map((valuation) => (
                        <Text
                            key={valuation}
                            fontSize={"sm"}
                        >
                            {t(
                                `domain.standards.propertyType.${toTitleCase(
                                    valuation
                                )}`,
                                {
                                    defaultValue: MISSING_TRANSLATION,
                                }
                            )}
                        </Text>
                    ))}
                </VStack>
            }
        />
    );
};

export default ProfilePropertyTypes;
