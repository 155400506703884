import { VStack } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FlowContext } from "../../../../../common/components/display/ProcessFlowRouted";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { FormikForm } from "../../../../../common/forms";
import parseDelimitedNumber from "../../../../../common/functions/parseDelimitedNumber";
import { useRole } from "../../../../../common/hooks";
import { UpdateEntityDto } from "../../../../../utils/api/dto";
import { useMyEntity } from "../../../../../utils/api/hooks";
import CompanyDetailsDetailsEdit from "../../../account/index/common/CompanyDetailsDetailsEdit";
import ProfileCompletionFormFooter from "./ProfileCompletionFormFooter";

const validationSchema = Yup.object().shape({
    companyEmail: Yup.string().email("errors.invalidEmail"),
    contactNumberDialCode: Yup.string().test(
        "contactNumber",
        "errors.required",
        function (value) {
            if (value === undefined) {
                if (this.parent.contactNumber === undefined) return true;

                return false;
            }

            return true;
        }
    ),
    contactNumber: Yup.number()
        .typeError("errors.enterNumber")
        .test("contactNumber", "errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.contactNumberDialCode === undefined)
                    return true;

                return false;
            }

            return true;
        }),
});

/**
 * Form for editing the current user's company details in the profile completion wizard.
 */
const ProfileCompletionBilling = () => {
    const { goNext } = useContext(FlowContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const { userIsCompanyAdmin, userIsValuer, roleLoading } = useRole();

    const {
        data: entity,
        updateEntity,
        isLoading: entityLoading,
    } = useMyEntity({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate("/dashboard", { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (entityLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    const initialFormData = {
        name: entity.name || "",
        contactFirstName: entity.contactFirstName || "",
        contactLastName: entity.contactLastName || "",
        contactNumberDialCode: entity.contactNumberDialCode || "",
        contactNumber: entity.contactNumber || "",
        street: entity.street || "",
        city: entity.city || "",
        postalCode: entity.postalCode || "",
        country: entity.country || "",
        vatNumber: entity.vatNumber || "",
        websiteAddress: entity.websiteAddress || "",
        companyEmail: entity.companyEmail || "",
        valuerYearsOfExperience: entity.valuerYearsOfExperience || "",
        valuerNumberOfPartners: Number(entity.valuerNumberOfPartners) || "",
        piCoverValue: entity.piCoverValue || 0,
        piCoverValueUnlimited: entity.piCoverValueUnlimited || false,
        piCoverPercentage: entity.piCoverPercentage || 0,
        piCoverPercentageUnlimited: entity.piCoverPercentageUnlimited || false,
        piCoverValidityDate: entity.piCoverValidityDate || "",
        piCoverSpecialArrangementsAvailable:
            entity.piCoverSpecialArrangementsAvailable || false,
    };

    const update = (values: any) => {
        const {
            valuerNumberOfPartners,
            piCoverValue,
            piCoverValueUnlimited,
            piCoverPercentage,
            piCoverPercentageUnlimited,
            piCoverValidityDate,
            piCoverSpecialArrangementsAvailable,
        } = values;

        const valuerNumberOfPartnersAsString =
            valuerNumberOfPartners.toString();

        let update: UpdateEntityDto = {
            name: values.name,
            contactFirstName: values.contactFirstName,
            contactLastName: values.contactLastName,
            contactNumberDialCode: values.contactNumberDialCode,
            contactNumber: values.contactNumber,
            street: values.street,
            city: values.city,
            postalCode: values.postalCode,
            country: values.country,
            vatNumber: values.vatNumber,
            websiteAddress: values.websiteAddress,
            companyEmail: values.companyEmail,
        };

        if (userIsValuer) {
            const piCoverValueasNumber = Number(
                parseDelimitedNumber(piCoverValue.toString())
            );

            const piCoverPercentageAsNumber = Number(
                parseDelimitedNumber(piCoverPercentage.toString())
            );

            update = {
                ...update,
                valuerYearsOfExperience: values.valuerYearsOfExperience,
                valuerNumberOfPartners: valuerNumberOfPartnersAsString,
                piCoverValue: piCoverValueasNumber,
                piCoverValueUnlimited,
                piCoverPercentage: piCoverPercentageAsNumber,
                piCoverPercentageUnlimited,
                piCoverValidityDate,
                piCoverSpecialArrangementsAvailable,
            };
        }

        updateEntity(update);
    };

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={initialFormData}
            onSubmit={(values) => {
                update(values);
            }}
        >
            <VStack spacing={4}>
                {userIsCompanyAdmin && <CompanyDetailsDetailsEdit />}

                <ProfileCompletionFormFooter
                    back={false}
                    handleSaveAndClose={() => setSaveAndClose(true)}
                />
            </VStack>
        </FormikForm>
    );
};

export default ProfileCompletionBilling;
