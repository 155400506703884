import { OfferType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import { offerColumns } from "../../../../domain/offer/columns/OfferColumns";

type OfferTableProps = {
    offers: OfferType[];
};

const AcceptedOfferTable = (props: OfferTableProps) => {
    const { offers } = props;

    const acceptedOffer = offers.filter((offer) => offer.status === "ACCEPTED");

    return (
        <>
            <DataTable
                fullSectionWidth
                data={acceptedOffer}
                selectedRows={acceptedOffer}
                variant="AcceptedOfferVariant"
                columns={[
                    offerColumns.valuerProfile,
                    offerColumns.price,
                    offerColumns.availability, 
                    offerColumns.deliveryAndTimeline,
                    offerColumns.piCoverValue,
                    offerColumns.piCoverPercentage,
                    offerColumns.specialPi,
                    offerColumns.view,
                ]}
                stickyColumns={2}
            />
        </>
    );
};

export { AcceptedOfferTable };
