import {
    array,
    InferType,
    mixed,
    number,
    object,
    ObjectSchema,
    string
} from "yup";
import parseDelimitedNumber from "../../../../common/functions/parseDelimitedNumber";
import { OfferCreateDto } from "../../../../utils/api/dto";

export const stringMustBeInteger = (val: string) =>
    val.replace(/[0-9]/g, "").length == 0;

const fileArray = array().of(mixed<File>().required()).required();
const stringArray = array().of(string().required()).required();

const requiredDelimitedNumber = mixed<number>()
    .transform((s: string) => Number(parseDelimitedNumber(s)))
    .required("forms.errors.required");

export const OfferCreationFormData: ObjectSchema<
    Omit<
        OfferCreateDto,
        | "enquiryId"
        | "toEntityId"
        | "fromValuerId"
        | "fromEntityId"
        | "piCoverCurrency"
    >
> = object({
    /*
    we're using currencyInputs which provide strings; because of this, we have to
    use the mixed() schema and manually validate price, piValue and piPercentage,
    instead of relying on number().
    
    we're using the schema to cast the formdata, so the output types have to be correct
    for being used in a dto, ie. the delimited numbers must be transformed to numbers
    */
    price: requiredDelimitedNumber,
    piCoverValue: requiredDelimitedNumber,
    piCoverPercentage: requiredDelimitedNumber,
    timeline: string()
        .required("forms.errors.required")
        .test(
            "mustBeNumber",
            "forms.errors.enterActualNumber",
            stringMustBeInteger
        ),
    availability: string().required("forms.errors.required"),
    comments: string().optional(),

    requiredAttachments: fileArray,
    requiredCompanyDocuments: stringArray,

    sampleAttachments: fileArray,
    sampleCompanyDocuments: stringArray,

    marketingAttachments: fileArray,
    marketingCompanyDocuments: stringArray,

    piCertificateAttachments: fileArray,
    piCertificateCompanyDocuments: stringArray,

    // FIXME this should not be on the creation
    requiredAttachmentsToRemove: array().of(number().required()).required(),
});

export type OfferCreationFormDataType = InferType<typeof OfferCreationFormData>;
