import { Box } from "@chakra-ui/react";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { useState } from "react";
import MapMarkerIcon from "../../../../../assets/icons/MapMarkerIcon";

type PropTypes = {
    height?: string;
    markers: MapMarkerType[];
    selectedMarker?: MapMarkerType;
    width?: string;
    onClick?: (marker: MapMarkerType) => void;
    withNumbers?: boolean;
};

export type MapMarkerType = {
    id: string | number;
    lat: number;
    lng: number;
};

type MarkerProps = {
    marker: MapMarkerType;
    onClick?: (marker: MapMarkerType) => void;
    selected: boolean;
    number?: number;
};

const { REACT_APP_GOOGLEMAPS_API_KEY = "", REACT_APP_GOOGLEMAP_MAP_ID = "" } =
    process.env;

const DEFAULT_ZOOM = 15;

const MapMarker = (props: MarkerProps) => {
    const { marker, onClick, selected, number } = props;

    const { lat, lng } = marker;

    const [hovered, setHovered] = useState(false);

    const isClickable = Boolean(onClick);

    const position = { lat, lng };

    let color: "blue" | "yellow" = "yellow";
    let zIndex: 10000000 | undefined = undefined;

    let cursor: "grab" | "pointer" = "grab";
    let size: "md" | "lg" = "md";

    if (isClickable) {
        cursor = "pointer";
        size = hovered ? "lg" : "md";
    } else {
        cursor = "grab";
        size = "md";
    }

    if (selected) {
        color = "blue";
        zIndex = 10000000;
        size = "lg";
    } else {
        color = "yellow";
        zIndex = undefined;
    }

    return (
        <AdvancedMarker
            onClick={() => onClick?.(marker)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            position={position}
            zIndex={zIndex}
        >
            <Box
                marginBottom="-15px"
                cursor={cursor}
            >
                <MapMarkerIcon
                    color={color}
                    size={size}
                    number={number}
                />
            </Box>
        </AdvancedMarker>
    );
};

export const GoogleMapV2 = (props: PropTypes) => {
    const {
        height = "40rem",
        markers,
        width = "100%",
        onClick,
        selectedMarker,
        withNumbers,
    } = props;

    const bounds = new google.maps.LatLngBounds();

    markers.forEach((i) => {
        bounds.extend(i);
    });

    const [mapBoundsLoading, setMapBoundsLoading] = useState(true);

    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const defaultBounds = {
        east: northEast.lng(),
        north: northEast.lat(),
        south: southWest.lat(),
        west: southWest.lng(),
    };

    const isSelected = (marker: MapMarkerType) => {
        if (!selectedMarker) return false;

        return marker.id === selectedMarker.id;
    };

    return (
        <Box
            h={height}
            w={width}
            borderRadius={"lg"}
            overflow={"hidden"}
        >
            <APIProvider apiKey={REACT_APP_GOOGLEMAPS_API_KEY}>
                <Map
                    defaultBounds={defaultBounds}
                    mapId={REACT_APP_GOOGLEMAP_MAP_ID}
                    onBoundsChanged={(newBounds) => {
                        const zoom = newBounds.map.getZoom();

                        if (!mapBoundsLoading || !zoom) return;

                        // Prevent inital over-zooming of the map
                        if (zoom > DEFAULT_ZOOM) {
                            newBounds.map.setZoom(DEFAULT_ZOOM);
                        }

                        setMapBoundsLoading(false);
                    }}
                >
                    {markers.map((marker, index) => {
                        const selected = isSelected(marker);
                        const number = withNumbers ? index + 1 : undefined;

                        return (
                            <MapMarker
                                key={marker.id}
                                marker={marker}
                                onClick={onClick}
                                selected={selected}
                                number={number}
                            />
                        );
                    })}
                </Map>
            </APIProvider>
        </Box>
    );
};

GoogleMapV2.defaultProps = {
    height: "40rem",
    width: "100%",
    onClick: undefined,
    selectedMarker: undefined,
};
