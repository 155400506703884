import { Grid, VStack } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../../../../../common/hooks";
import { EnquiryType, OfferType, UserType } from "../../../../../../types";
import {
    useEnquiry,
    useEntityPublicEntityGetById,
    useOfferGetById,
    usePublicUser,
} from "../../../../../../utils/api/hooks";
import storage, {
    FirebaseCertificateType,
} from "../../../../../../utils/storage/storage";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { Breadcrumbs } from "../../../../common/features/breadcrumbs/Breadcrumbs";
import { useDevice } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Enquiry, Property } from "../../../../domain";
import { EnquiryMatchInfo } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import matchEnquiryWithValuer from "../../../../domain/enquiry/enquiryRequirements/matchEnquiryWithValuer";
import { AttachmentList } from "../../../../domain/offer/offer-details/attachments/OfferAttachment";
import { Section } from "../../../../layout";
import { OfferDetails } from "./OfferDetails";
import { OfferTitle } from "./OfferTitle";
import { ValuerCompanyDetails } from "./ValuerCompanyDetails";
import { ValuerCompetences } from "./ValuerCompetences";
import { ValuerDetails } from "./ValuerDetails";
import { ValuerQualifications } from "./ValuerQualifications";

const OfferDetailsScreenLoader = () => {
    const { enquiryId, offerId } = useParams();

    const t = useSmartTranslation();

    const { createErrorToast } = useToast();

    const [matchInfo, setMatchinfo] = useState<EnquiryMatchInfo | undefined>();
    const [certificates, setCertificates] = useState<FirebaseCertificateType[]>(
        []
    );

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const {
        data: offer,
        isLoading: offerLoading,
        refresh: refreshOffer,
    } = useOfferGetById(offerId);

    const { data: entity, isLoading: entityIsLoading } =
        useEntityPublicEntityGetById(offer?.fromEntityId);

    const { data: enquiryCreator, isLoading: enquiryCreatorLoading } =
        usePublicUser(Number(enquiry?.fromUserId));

    useEffect(() => {
        if (!enquiry || !offer) return;

        const getMatchInfo = async () => {
            const matchInfo = await matchEnquiryWithValuer(
                enquiry.id,
                offer.fromValuer.id
            );
            setMatchinfo(matchInfo);
        };

        const getCertificates = async () => {
            const certificateList = await storage.getUserCertificates(
                offer.fromValuer.id
            );
            setCertificates(certificateList);
        };

        try {
            getMatchInfo();
            getCertificates();
        } catch (e) {
            createErrorToast(t("common.error.error"));
        }
    }, [enquiry, offer]);

    if (
        enquiryLoading ||
        offerLoading ||
        !enquiry ||
        !offer ||
        !matchInfo ||
        !certificates ||
        !entity ||
        entityIsLoading ||
        !enquiryCreator ||
        enquiryCreatorLoading
    )
        return <LoadingSpinner />;

    const onUpdate = () => {
        refreshOffer();
        refreshEnquiry();
    };

    const onError = (error: Error) => createErrorToast(error.message);

    return (
        <OfferDetailsScreen
            offer={offer}
            enquiry={enquiry}
            matchInfo={matchInfo}
            certificates={certificates}
            entityUsers={entity.users}
            enquiryCreator={enquiryCreator}
            onUpdate={onUpdate}
            onError={onError}
        />
    );
};

type OfferDetailsScreenProps = {
    offer: OfferType;
    enquiry: EnquiryType;
    matchInfo: EnquiryMatchInfo | undefined;
    certificates: FirebaseCertificateType[];
    entityUsers: UserType[];
    enquiryCreator: UserType;
    onUpdate: () => any;
    onError: (error: Error) => any;
};

const OfferDetailsScreen = (props: OfferDetailsScreenProps) => {
    const {
        offer: {
            fromValuer: valuer,
            fromEntity: entity,
            requiredAttachments,
            piCertificateAttachments,
            sampleAttachments,
            marketingAttachments,
        },
        offer,
        enquiry,
        certificates,
        entityUsers,
        enquiryCreator,
        onUpdate,
        onError,
    } = props;

    const t = useSmartTranslation();

    const device = useDevice();

    // TODO: remove "routes" after change the route enquiry -> enquiries
    const breadcrumbs = (
        <Breadcrumbs
            routes={[
                {
                    path: "/dashboard",
                    breadcrumb: t("menubar.dashboard"),
                },
                {
                    path: "/dashboard/enquiry",
                    breadcrumb: t("domain.enquiry.enquiries"),
                    props: {
                        customPath: "/dashboard/enquiries",
                    },
                },
                {
                    path: "/dashboard/enquiry/:enquiryId",
                    breadcrumb: ({ match }) => (
                        <>
                            {t("domain.enquiry.enquiryId", {
                                id: match.params.enquiryId,
                            })}
                        </>
                    ),
                },
                {
                    path: "/dashboard/enquiry/:enquiryId/offer/:offerId",
                    breadcrumb: ({ match }) => (
                        <>
                            {t("domain.offer.offerId", {
                                id: match.params.offerId,
                            })}
                        </>
                    ),
                },
            ]}
            options={{
                disableDefaults: true,
            }}
        />
    );

    const title = <OfferTitle offer={offer} />;

    const offerDetails = (
        <Section
            collapsable
            title={t("screens.platform.client.offerDetails.offerDetailsTitle")}
            content={
                <OfferDetails
                    offer={offer}
                    enquiry={enquiry}
                    enquiryCreator={enquiryCreator}
                    onUpdate={onUpdate}
                    onError={onError}
                />
            }
        />
    );

    const offerRequiredAttachments = (
        <Section
            collapsable
            title={t("domain.offer.requiredAttachments")}
            content={<AttachmentList attachments={requiredAttachments} />}
        />
    );

    const offerOtherAttachments = (
        <Section
            collapsable
            title={t("domain.offer.otherAttachments")}
            content={
                <Tabs
                    width={"100%"}
                    variant={"rounded"}
                    isFitted
                >
                    <TabList>
                        <Tab>{t("domain.offer.piCertificateAttachments")}</Tab>
                        <Tab>{t("domain.offer.sampleAttachments")}</Tab>
                        <Tab>{t("domain.offer.marketingAttachments")}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <AttachmentList
                                attachments={piCertificateAttachments}
                            />
                        </TabPanel>
                        <TabPanel>
                            <AttachmentList attachments={sampleAttachments} />
                        </TabPanel>
                        <TabPanel>
                            <AttachmentList
                                attachments={marketingAttachments}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            }
        />
    );

    const requestedValuation = (
        <Section
            collapsable
            initiallyCollapsed={true}
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<Enquiry.Requirements enquiry={enquiry} />}
        />
    );
    const propertyPortfolio = (
        <Section
            collapsable
            title={t("domain.property.propertyPortfolio.title")}
            content={
                <Property.PropertyPortfolioSummary
                    properties={enquiry.properties}
                />
            }
        />
    );

    const valuerDetails = (
        <Section
            collapsable
            title={t(
                "screens.platform.client.offerDetails.valuerDetails.title"
            )}
            content={
                <ValuerDetails
                    valuer={valuer}
                    entity={entity}
                    enquiry={enquiry}
                />
            }
        />
    );
    const valuerQualifications = (
        <Section
            collapsable
            title={t(
                "screens.platform.client.offerDetails.valuerQualifications.title"
            )}
            content={
                <ValuerQualifications
                    valuer={valuer}
                    enquiry={enquiry}
                    certificates={certificates}
                />
            }
        />
    );
    const valuerCompetences = (
        <Section
            collapsable
            title={t(
                "screens.platform.client.offerDetails.valuerCompetences.title"
            )}
            content={
                <ValuerCompetences
                    valuer={valuer}
                    enquiry={enquiry}
                />
            }
        />
    );
    const valuerCompanyDetails = (
        <Section
            collapsable
            title={t(
                "screens.platform.client.offerDetails.valuerCompanyDetails.title"
            )}
            content={
                <ValuerCompanyDetails
                    entity={entity}
                    enquiry={enquiry}
                    entityUsers={entityUsers}
                />
            }
        />
    );

    return (
        <VStack
            w={"100%"}
            p={4}
            spacing={4}
        >
            {breadcrumbs}
            {title}
            <Grid
                width={"100%"}
                gap={2}
                gridTemplateColumns={
                    device === "mobile"
                        ? "100%"
                        : "minmax(0, 50%) minmax(0, 50%)"
                }
            >
                <VStack>
                    {offerDetails}
                    {offerRequiredAttachments}
                    {offerOtherAttachments}
                    {requestedValuation}
                    {propertyPortfolio}
                </VStack>

                <VStack>
                    {valuerDetails}
                    {valuerQualifications}
                    {valuerCompetences}
                    {valuerCompanyDetails}
                </VStack>
            </Grid>
        </VStack>
    );
};

export default OfferDetailsScreenLoader;
