import { Box } from "@chakra-ui/layout";
import { useEffect, useRef, useState } from "react";
import { useGoogleStreetViewGetPano } from "../../../../../utils/api/hooks";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";
import { Placeholder } from "../Placeholder";

type Props = {
    height?: string;
    location: LatLng;
};

type LatLng = {
    lat: number;
    lng: number;
};

let streetView: google.maps.StreetViewPanorama;

export const GoogleStreetView = (props: Props) => {
    const { height = "30rem", location } = props;

    const t = useSmartTranslation();

    const streetViewBox = useRef<HTMLDivElement>(null);

    const [currentLoc, setCurrentLoc] = useState(location);

    const { update: getPano } = useGoogleStreetViewGetPano({
        onSuccess: (pano) => {
            const panoLocation = pano.data.location!;

            if (panoLocation.latLng) {
                const panoLatLng = {
                    lat: panoLocation.latLng.lat(),
                    lng: panoLocation.latLng.lng(),
                };

                const heading = google.maps.geometry.spherical.computeHeading(
                    panoLatLng,
                    location
                );

                streetView.setPov({
                    heading,
                    pitch: 0,
                });

                streetView.setPano(panoLocation.pano);
                streetView.setVisible(true);

                setStreetViewFound(true);
            } else {
                setStreetViewFound(false);
            }
        },
    });

    const [streetViewFound, setStreetViewFound] = useState(false);

    useEffect(() => {
        if (!streetViewBox.current) return;

        streetView = new google.maps.StreetViewPanorama(streetViewBox.current);
    }, [streetViewBox.current]);

    useEffect(() => {
        if (JSON.stringify(currentLoc) !== JSON.stringify(location)) {
            setCurrentLoc(location);
        }
    }, [location]);

    useEffect(() => {
        getPano(currentLoc);
    }, [currentLoc]);

    return (
        <>
            {!streetViewFound && (
                <Placeholder
                    height={height}
                    noDataText={t(
                        "common.components.streetView.noStreetViewAvailable"
                    )}
                />
            )}

            {/* This must be in the dom, even if not displayed, for the streetViewPano initialization */}
            <Box
                ref={streetViewBox}
                h={height}
                w="100%"
                display={streetViewFound ? undefined : "none"}
            />
        </>
    );
};
