import { Box, Button, Select, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";

export type FilterOptionType = {
    id: string;
    label: string;
    onSelect: () => void;
};

type FilterSelectPropsType = {
    label: string;
    filterOptions: FilterOptionType[];
    value?: string;
};

// TODO generic: typeof filterOptions[number]["id"]
const FilterSelect = (props: FilterSelectPropsType) => {
    const { label, filterOptions, value } = props;

    const [activeOption, setActiveOption] = useState<string>();

    const t = useSmartTranslation();

    // TODO: without effect value doesn't unselect after "Clear all" in drawer
    useEffect(() => {
        setActiveOption(value || filterOptions[0].id);
    }, [value]);

    const clearButton = value && (
        <Button
            position={"absolute"}
            right={9}
            bottom={".8rem"}
            variant={"link"}
            colorScheme={"blue"}
            size={"xs"}
            fontWeight={"400"}
            onClick={() => {
                filterOptions[0].onSelect();
            }}
        >
            {t("screens.platform.enquiryList.filters.ACTIVITIES.clear.NAME")}
        </Button>
    );

    const display = (
        <Box position={"relative"}>
            <Select
                onChange={(ev) => {
                    const id = ev.target.value;
                    setActiveOption(id);
                    filterOptions
                        .find(({ id: filterId }) => filterId === id)!
                        .onSelect();
                }}
                value={activeOption}
                fontSize={"sm"}
            >
                {filterOptions.map(({ id, label }) => (
                    <option
                        key={id}
                        value={id}
                    >
                        {label}
                    </option>
                ))}
            </Select>
            {clearButton}
        </Box>
    );

    return (
        <VStack
            align={"stretch"}
            gap={2}
        >
            <Text
                fontSize={"xs"}
                color={"gray.500"}
            >
                {label}
            </Text>
            {display}
        </VStack>
    );
};

export { FilterSelect };
