import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import MaintenanceMode from "./maintenance-mode/MaintenanceMode";
import Router from "./Router";
import theme from "./theme";
import theme2 from "./ui/v2/theme/theme-v2";
import "@fontsource/inter/200.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/900.css";
import AppV2 from "./ui/v2/app/AppV2";
import TestingRouter from "./TestingRouter";

const {
    REACT_APP_FEATURE_ENABLE_UI_V2 = "false",
    REACT_APP_MAINTENANCE_MODE = "true",
    REACT_APP_TESTING_MODE = "false",
} = process.env;

const inMaintenanceMode = REACT_APP_MAINTENANCE_MODE === "true";

const appTheme = REACT_APP_FEATURE_ENABLE_UI_V2 === "true" ? theme2 : theme;

const queryClient = new QueryClient();

const { ToastContainer } = createStandaloneToast();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
            <ChakraProvider theme={appTheme}>
                {REACT_APP_TESTING_MODE === "false" && (
                    <>
                        {inMaintenanceMode && <MaintenanceMode />}

                        {!inMaintenanceMode &&
                            (REACT_APP_FEATURE_ENABLE_UI_V2 === "true" ? (
                                <AppV2 />
                            ) : (
                                <Router />
                            ))}
                    </>
                )}

                {REACT_APP_TESTING_MODE === "true" && <TestingRouter />}
            </ChakraProvider>
        </DndProvider>

        <ToastContainer />
    </QueryClientProvider>
);

export default App;
