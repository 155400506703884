import { Box, chakra, VStack } from "@chakra-ui/react";
import { OfferAttachmentType } from "../../../../../../types";
import {
    File,
    getFileTypeBasedOnName,
} from "../../../../common/components/display/File";
import { Placeholder } from "../../../../common/components/Placeholder";
import { useDocumentViewerModal } from "../../../../common/hooks/useDocumentViewerModal";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { InternalSection } from "../../../../layout";

type AttachmentListProps = {
    attachments: OfferAttachmentType[];
    title?: string;
    collapsable?: boolean;
};

const PureAttachmentList = (props: AttachmentListProps) => {
    const { attachments, title, collapsable, ...cssProps } = props;

    const t = useSmartTranslation();

    const { documentView, setViewedDocument, openDocumentView } =
        useDocumentViewerModal();

    const createViewDocument = (attachment: OfferAttachmentType) => () => {
        const { fileUrl, name } = attachment;

        const fileType = getFileTypeBasedOnName(name);

        setViewedDocument({
            uri: fileUrl,
            fileType: fileType,
            label: name,
        });
        openDocumentView();
    };

    const content = attachments.length ? (
        <VStack
            width={"100%"}
            align={"stretch"}
            spacing={"0.1rem"}
            borderRadius="8"
            overflow={"hidden"}
        >
            {attachments.map((attachment) => (
                <Attachment
                    attachment={attachment}
                    key={attachment.id}
                    viewDocument={createViewDocument(attachment)}
                />
            ))}
            {documentView}
        </VStack>
    ) : (
        <Placeholder noDataText={t("domain.offer.noAttachmentsPlaceholder")} />
    );

    return (
        <InternalSection
            title={title}
            content={content}
            collapsable={collapsable}
            {...cssProps}
        />
    );
};

export const AttachmentList = chakra(PureAttachmentList);

type AttachmentProps = {
    attachment: OfferAttachmentType;
    viewDocument: () => void;
};

const Attachment = (props: AttachmentProps) => {
    const { attachment, viewDocument } = props;

    const transformedAttachment = {
        ...attachment,
        url: attachment.fileUrl,
    };

    return (
        <Box
            padding=".5rem 1rem"
            bg={"gray.50"}
            transition={".25s"}
            color="gray.900"
            _hover={{
                bg: "blue.50",
                color: "blue.500",
            }}
        >
            <File
                documentItem={transformedAttachment}
                viewDocument={viewDocument}
                downloadDocument
            />
        </Box>
    );
};
