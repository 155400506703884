import { BrowserRouter } from "react-router-dom";
import SiteLoading from "../../../common/components/SiteLoading";
import { useSmartTranslation } from "../common/hooks/useSmartTranslation";
import { GetRoutes } from "./routes/routes";
import {
    BusinessRelationshipsContext,
    useBusinessRelationships,
} from "./useBusinessRelationships";
import useMe, { UserContext } from "./useMeV2";

const AppV2 = () => {
    const { pathname } = window.location;

    const t = useSmartTranslation();

    const {
        data: user,
        isLoading: isLoadingUser,
        fbError,
        error: backendError,
    } = useMe();

    const {
        previousClientEntities,
        isLoading: isLoadingBusinessRelationships,
    } = useBusinessRelationships();

    const error = fbError || backendError;

    if (error) {
        return <div>error</div>;
    }

    if (isLoadingUser || isLoadingBusinessRelationships) {
        if (pathname.includes("/direct-enquiry-form/")) {
            return (
                <SiteLoading
                    noLogo
                    text={t("common.loading")}
                />
            );
        }

        return <SiteLoading />;
    }

    return (
        <UserContext.Provider value={user}>
            <BusinessRelationshipsContext.Provider
                value={{ previousClientEntities }}
            >
                <BrowserRouter>
                    <GetRoutes />
                </BrowserRouter>
            </BusinessRelationshipsContext.Provider>
        </UserContext.Provider>
    );
};

export default AppV2;
