import {
    Button,
    Divider,
    Flex,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import { FormikValues, useField, useFormikContext } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { useToast } from "../../../../common/hooks";
import { OfferOnlyType } from "../../../../types";
import { FirebaseCertificateType } from "../../../../utils/storage/storage";
import FormikSubmitButton from "../../common/forms/FormikSubmitButton";
import { Forms } from "../../common/forms/Forms";
import { useLegalModal } from "../../common/hooks/useLegalModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { InternalSection } from "../../layout";
import { Offer, PICoverParams } from "./Offer";
import {
    OfferCreationFormData,
    OfferCreationFormDataType,
} from "./OfferCreationFormOutput";
import { EditingEditor } from "./offereditor/EditingEditor";

// TODO this should be related to the form output type OfferCreationFormDataType
export type OfferEditorFormInitialValuesType = {
    price: string;
    piCoverValue: string;
    piCoverPercentage: string;
    timeline: string;
    availability: string;
    comments: string;

    requiredAttachments: File[];
    requiredCompanyDocuments: string[];

    sampleAttachments: File[];
    sampleCompanyDocuments: string[];

    marketingAttachments: File[];
    marketingCompanyDocuments: string[];

    piCertificateAttachments: File[];
    piCertificateCompanyDocuments: string[];

    // FIXME rename "attachmentsToRemove" because it'll be just one list for all removals
    requiredAttachmentsToRemove?: number[];
};

export const NEW_OFFER_DRAFT: OfferEditorFormInitialValuesType = {
    price: "",
    piCoverValue: "",
    piCoverPercentage: "",
    timeline: "",
    availability: "",
    comments: "",

    requiredAttachments: [],
    requiredCompanyDocuments: [],

    sampleAttachments: [],
    sampleCompanyDocuments: [],

    marketingAttachments: [],
    marketingCompanyDocuments: [],

    piCertificateAttachments: [],
    piCertificateCompanyDocuments: [],
};

export const createInitialValues = (
    template: OfferEditorFormInitialValuesType,
    piCoverParams: PICoverParams
) => {
    const { defaultPICoverPercentage, defaultPICoverValue } = piCoverParams;

    const {
        piCoverPercentage: previousPiCoverPercentage,
        piCoverValue: previousPiCoverValue,
    } = template;

    const initialValues = {
        ...template,
    };

    if (previousPiCoverPercentage === "" && previousPiCoverValue === "") {
        initialValues.piCoverValue = defaultPICoverValue.toString();
        initialValues.piCoverPercentage = defaultPICoverPercentage.toString();
    }

    return initialValues;
};

export type OfferCreationDocuments = {
    offerDocuments: FirebaseCertificateType[];
    marketingDocuments: FirebaseCertificateType[];
    piCertificateDocuments: FirebaseCertificateType[];
    sampleDocuments: FirebaseCertificateType[];
};

type AttachmentMenuProps = {
    onDeleteAvailableAttachment: () => void;
};

export const AttachmentMenu = (props: AttachmentMenuProps) => {
    const { onDeleteAvailableAttachment } = props;

    const t = useSmartTranslation();

    return (
        <Menu>
            {({ isOpen }) => {
                return (
                    <>
                        <MenuButton
                            isActive={isOpen}
                            as={IconButton}
                            variant={"ghost"}
                            size={"sm"}
                        >
                            {"\u22EE"}
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => onDeleteAvailableAttachment()}
                            >
                                {t("domain.offer.deleteUploadedAttachment")}
                            </MenuItem>
                        </MenuList>
                    </>
                );
            }}
        </Menu>
    );
};

export type AttachmentItem<T> = {
    item: T;
    label: string;
    url?: string;
    size: number;
    checked: boolean;
    onToggle: (checked: boolean) => void;
    menu?: React.ReactNode;
};

type Props = {
    offer?: OfferOnlyType;
    initialValues: OfferEditorFormInitialValuesType;
    piCoverSpecialArrangementsAvailable?: boolean;
    offerCreationDocuments: OfferCreationDocuments;
    onSubmit: (draft: OfferCreationFormDataType) => Promise<OfferOnlyType>;
    onCancel?: () => void;
};

export const Editor = (props: Props) => {
    const { onSubmit, initialValues: baseValues } = props;

    const onFormSubmit = (values: FormikValues) => {
        const formOutput: OfferCreationFormDataType =
            OfferCreationFormData.cast(values);
        return onSubmit(formOutput);
    };

    const initialValues = {
        ...baseValues,
        deliveryDate: "",
    };

    return (
        <Forms.FormikForm
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            validationSchema={OfferCreationFormData}
        >
            <EditorInternal {...props} />
        </Forms.FormikForm>
    );
};

const EditorInternal = (props: Props) => {
    const {
        offer,
        onCancel,
        piCoverSpecialArrangementsAvailable,
        offerCreationDocuments: {
            offerDocuments,
            piCertificateDocuments,
            marketingDocuments,
            sampleDocuments,
        },
    } = props;

    const t = useSmartTranslation();

    const currentRequiredAttachments = offer?.requiredAttachments || [];
    const currentSampleAttachments = offer?.sampleAttachments || [];
    const currentMarketingAttachments = offer?.marketingAttachments || [];
    const currentPiCertificateAttachments =
        offer?.piCertificateAttachments || [];

    const [filenames, setFilenames] = useState<string[]>([
        ...[
            ...currentRequiredAttachments,
            ...currentSampleAttachments,
            ...currentMarketingAttachments,
            ...currentPiCertificateAttachments,
        ].map(({ name }) => name),
        ...[
            ...offerDocuments,
            ...piCertificateDocuments,
            ...marketingDocuments,
            ...sampleDocuments,
        ].map(({ file: { name } }) => name),
    ]);

    const { createErrorToast } = useToast();

    const reserveFileNames = (names: string[]) => {
        const conflictingName = names.find((name) => filenames.includes(name));

        // check currently uploaded documents and currently required documents
        if (conflictingName !== undefined) {
            createErrorToast(
                t("domain.offer.nameAlreadyInUse", { name: conflictingName })
            );
            return false;
        }

        setFilenames(_.concat(filenames, names));
        return true;
    };

    const releaseFileName = (name: string) =>
        setFilenames(_.without(filenames, name));

    const requiredAttachmentsDisplay = (
        <InternalSection
            collapsable
            title={t("domain.offer.requiredAttachments")}
            content={
                <EditingEditor
                    uploadsFieldname={"requiredAttachments"}
                    companyDocumentsFieldname={"requiredCompanyDocuments"}
                    companyDocuments={offerDocuments}
                    currentAttachmentsOnOffer={currentRequiredAttachments}
                    reserveFileNames={reserveFileNames}
                    releaseFileName={releaseFileName}
                />
            }
            mt={2}
        />
    );

    const otherAttachmentEditors = (
        <Tabs
            width={"100%"}
            variant={"rounded"}
            isFitted
        >
            <TabList>
                <Tab>{t("domain.offer.piCertificateAttachments")}</Tab>
                <Tab>{t("domain.offer.sampleAttachments")}</Tab>
                <Tab>{t("domain.offer.marketingAttachments")}</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <EditingEditor
                        uploadsFieldname={"piCertificateAttachments"}
                        companyDocumentsFieldname={
                            "piCertificateCompanyDocuments"
                        }
                        companyDocuments={piCertificateDocuments}
                        currentAttachmentsOnOffer={
                            currentPiCertificateAttachments
                        }
                        reserveFileNames={reserveFileNames}
                        releaseFileName={releaseFileName}
                    />
                </TabPanel>
                <TabPanel>
                    <EditingEditor
                        uploadsFieldname={"sampleAttachments"}
                        companyDocumentsFieldname={"sampleCompanyDocuments"}
                        companyDocuments={sampleDocuments}
                        currentAttachmentsOnOffer={currentSampleAttachments}
                        reserveFileNames={reserveFileNames}
                        releaseFileName={releaseFileName}
                    />
                </TabPanel>
                <TabPanel>
                    <EditingEditor
                        uploadsFieldname={"marketingAttachments"}
                        companyDocumentsFieldname={"marketingCompanyDocuments"}
                        companyDocuments={marketingDocuments}
                        currentAttachmentsOnOffer={currentMarketingAttachments}
                        reserveFileNames={reserveFileNames}
                        releaseFileName={releaseFileName}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
    const otherAttachmentsDisplay = (
        <InternalSection
            collapsable
            title={t("domain.offer.otherAttachments")}
            content={otherAttachmentEditors}
        />
    );

    const cancelEditing = onCancel && (
        <Button
            w={"20%"}
            onClick={() => onCancel()}
        >
            {t("common.button.cancel")}
        </Button>
    );

    const submitButtonWidth = cancelEditing ? "80%" : "100%";
    const submitOffer = (
        <FormikSubmitButton
            width={submitButtonWidth}
            variant={"primary"}
            mustBeClicked={true}
            content={t("common.button.submit")}
        />
    );

    return (
        <VStack
            gap={"1em"}
            align={"stretch"}
        >
            <HStack alignItems={"baseline"}>
                <Forms.FormikCurrencyInput
                    name={"piCoverValue"}
                    readOnly={!piCoverSpecialArrangementsAvailable}
                    label={t("domain.offer.piCoverAbsolute")}
                    tooltip={t("domain.offer.piCoverAbsoluteTooltip")}
                    leftinternal={"€"}
                />
                <Forms.FormikCurrencyInput
                    name={"piCoverPercentage"}
                    readOnly={!piCoverSpecialArrangementsAvailable}
                    label={t("domain.offer.piCoverPercentage")}
                    tooltip={t("domain.offer.piCoverPercentageTooltip")}
                    rightinternal={"%"}
                />
                <Forms.FormikCurrencyInput
                    name={"price"}
                    label={t("domain.offer.price_net")}
                    leftinternal={"€"}
                    variant={"Price"}
                />
            </HStack>
            <HStack alignItems={"baseline"}>
                <Forms.FormikCurrencyInput
                    name={"timeline"}
                    label={t("domain.offer.timeline")}
                    rightinternal={t("common.units.days")}
                />
                <Forms.FormikInput
                    type={"date"}
                    name={"availability"}
                    label={t("domain.offer.availability")}
                />
                <DeliveryDateField />
            </HStack>
            <OfferMessageEditor />
            {requiredAttachmentsDisplay}
            <Divider
                orientation={"horizontal"}
                background={"gray.500"}
                mt={2}
                mb={4}
            />
            {otherAttachmentsDisplay}
            <Flex
                grow={1}
                w={"100%"}
                gap={".5rem"}
            >
                {cancelEditing}
                {submitOffer}
            </Flex>
            {<SubmissionDisclaimer />}
        </VStack>
    );
};

const SubmissionDisclaimer = () => {
    const { legalModal, openLegalModal, setLegalType } = useLegalModal();

    return (
        <Text fontSize={"sm"}>
            <Trans
                ns={"v2"}
                i18nKey={"domain.offer.creationDisclaimer"}
                components={[
                    <Button
                        variant="link"
                        color={"blue.500"}
                        fontWeight={"normal"}
                        size={"sm"}
                        onClick={() => {
                            setLegalType("terms");
                            openLegalModal();
                        }}
                    />,
                    <Button
                        variant="link"
                        color={"blue.500"}
                        fontWeight={"normal"}
                        size={"sm"}
                        onClick={() => {
                            setLegalType("privacy");
                            openLegalModal();
                        }}
                    />,
                ]}
            />
            {legalModal}
        </Text>
    );
};

// following https://formik.org/docs/examples/dependent-fields
const DeliveryDateField = () => {
    const {
        values: { timeline, availability },
        touched,
        setFieldValue,
    } = useFormikContext<OfferCreationFormDataType>();

    const t = useSmartTranslation();

    useEffect(() => {
        let newValue = "";
        if (timeline !== "" && availability !== "") {
            const deliveryDate = Offer.calculateDeliveryDate({
                timeline,
                availability,
            });
            newValue = displayDateWithCurrentLocale(deliveryDate);
        }

        setFieldValue("deliveryDate", newValue);
    }, [timeline, availability, touched, setFieldValue]);

    return (
        <Forms.FormikInput
            readonly={true}
            name={"deliveryDate"}
            label={t("domain.offer.deliveryDate")}
        />
    );
};

const COMMENTS_MAX_LENGTH = 250;

const OfferMessageEditor = () => {
    const t = useSmartTranslation();

    const [field] = useField<"comments">("comments");

    const chars = field.value.length;
    const atCharLimit = chars === COMMENTS_MAX_LENGTH;
    const counterColor = atCharLimit ? "red.500" : undefined;
    const counter = (
        <Text
            fontSize={"sm"}
            color={counterColor}
        >
            {chars}/{COMMENTS_MAX_LENGTH}
        </Text>
    );

    return (
        <VStack
            w={"100%"}
            align={"stretch"}
        >
            <Flex justifyContent={"space-between"}>
                <Text fontSize={"sm"}>{t("domain.offer.comments")}</Text>
                {counter}
            </Flex>
            <Textarea
                h={"8rem"}
                placeholder={t("domain.offer.commentsPlaceholder")}
                bg="white"
                borderRadius="10"
                maxLength={COMMENTS_MAX_LENGTH}
                size={"sm"}
                {...field}
            />
        </VStack>
    );
};
