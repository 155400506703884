import {
    GridItem,
    Heading,
    ListItem,
    SimpleGrid,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import { getDaysUntilDate } from "../../../../../common/functions";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { EnquiryType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import Subsection from "../../../layout/Subsection";
import { EnquiryMatchInfo } from "./EnquiryMatchInfo";
import { ValuerQualifications } from "./ValuerRequirements";

type Props = {
    enquiry: EnquiryType;
    matchInfo?: EnquiryMatchInfo;
};

export const EnquiryRequirements = (props: Props) => {
    const {
        enquiry: {
            valuationType,
            valuationStandards,
            completionDate,
            valuationSpecial,
            valuationPurposes,
            additionalRequirements = "-",
        },
        enquiry,
        matchInfo,
    } = props;

    const t = useSmartTranslation();

    const typeAndStandard = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.scopeAndStandard")}
        >
            <Heading size="md">
                {t(`domain.standards.valuationType.${valuationType}`)}
            </Heading>
            <Text
                fontWeight={"bold"}
                fontSize={"sm"}
            >
                {t(
                    valuationStandards
                        ? `domain.standards.systemStandard.${valuationStandards}`
                        : "common.n/a"
                )}
            </Text>
        </Subsection>
    );
    const expectedDelivery = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.expectedDelivery")}
        >
            <Heading size="md">
                {displayDateWithCurrentLocale(completionDate)}
            </Heading>
            <DaysLeft completionDate={completionDate} />
        </Subsection>
    );

    const specificScopes = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.specificScopes")}
        >
            <UnorderedList>
                {valuationSpecial.length ? (
                    valuationSpecial.map((spec) => (
                        <ListItem
                            key={spec}
                            fontSize={"sm"}
                            fontWeight="bold"
                        >
                            {t(`domain.standards.specialValuationType.${spec}`)}
                        </ListItem>
                    ))
                ) : (
                    <ListItem
                        fontSize={"sm"}
                        fontWeight="bold"
                    >
                        {t("common.n/a")}
                    </ListItem>
                )}
            </UnorderedList>
        </Subsection>
    );

    const purposes = (
        <Subsection title={t("domain.enquiry.valuationRequirements.purpose")}>
            <UnorderedList>
                {valuationPurposes.map((purp) => (
                    <ListItem
                        key={purp}
                        fontSize={"sm"}
                        fontWeight="bold"
                    >
                        {t(`domain.standards.valuationOccasion.${purp}`)}
                    </ListItem>
                ))}
            </UnorderedList>
        </Subsection>
    );

    const qualifications = (
        <Subsection>
            <ValuerQualifications
                enquiry={enquiry}
                matchInfo={matchInfo}
            />
        </Subsection>
    );

    const additional = (
        <Subsection
            title={t(
                "domain.enquiry.valuationRequirements.additionalRequirements"
            )}
        >
            <Text fontSize={"sm"}>{additionalRequirements}</Text>
        </Subsection>
    );

    // FIXME container - grid?

    return (
        <SimpleGrid
            columns={2}
            gap={2}
        >
            {typeAndStandard}
            {expectedDelivery}
            {specificScopes}
            {purposes}
            <GridItem colSpan={2}>{qualifications}</GridItem>
            <GridItem colSpan={2}>{additional}</GridItem>
        </SimpleGrid>
    );
};

// helpers

type DaysLeftProps = {
    completionDate: string;
};

const DaysLeft = (props: DaysLeftProps) => {
    const { completionDate } = props;
    const t = useSmartTranslation();
    const daysLeft = getDaysUntilDate(new Date(completionDate));
    let color;

    if (daysLeft >= 20) color = "green.500";
    else if (daysLeft >= 10) color = "orange.500";
    else color = "red.500";
    return (
        <Text
            fontWeight="bold"
            fontSize={"sm"}
            color={color}
        >
            {t("domain.enquiry.daysLeft", {
                count: daysLeft,
            })}
        </Text>
    );
};
