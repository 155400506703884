import { Icon } from "@chakra-ui/icons";
import { As, Tag, TagLabel } from "@chakra-ui/react";

type MatchmakingInfoTagProps = {
    label: string;
    icon?: As;
};

const MatchmakingInfoTag = (props: MatchmakingInfoTagProps) => {
    const { label, icon } = props;

    return (
        <Tag
            size={"lg"}
            bg={"gray.100"}
            borderRadius={"lg"}
        >
            {icon && (
                <Icon
                    as={icon}
                    boxSize={"16px"}
                    color={"gray.600"}
                    me={1}
                />
            )}
            <TagLabel
                fontSize={"sm"}
                color={"gray.600"}
            >
                {label}
            </TagLabel>
        </Tag>
    );
};

export { MatchmakingInfoTag };
