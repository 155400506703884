import { EnquiryType } from "../../../types";
import { useAuthedUser } from "./useMeV2";

type DbObject = EnquiryType | undefined;
type Domain = "ENQUIRY";
type Operation = 'EDIT';

export const usePermissions = () => {
    const user = useAuthedUser();

    const canI = (
        dbObject: DbObject,
        domain: Domain,
        operation: Operation
    ): boolean => {
        const userId = user.id;

        if (!dbObject) return false;

        if (domain === "ENQUIRY") {
            if (operation === "EDIT") {
                return dbObject.fromUserId === userId;
            }
        }

        return false;
    };

    return canI;
};
