import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import {
    EnquiryStatus,
    enquiryStatus,
} from "../../../../domain/enquiry/enquiryStatus";
import {
    EnquiryFilterFunctionType,
    EnquiryFilterPropsType,
} from "./EnquiryFilter";
import { FilterOptionType, FilterSelect } from "./FilterSelect";

const FILTERGROUP_ID = "BY_STATUS";

const ByStatus = (props: EnquiryFilterPropsType) => {
    const {
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const activateFilter = (
        value: EnquiryStatus,
        filter: EnquiryFilterFunctionType
    ): void => {
        activateFilterGroup({
            id: FILTERGROUP_ID,
            filter,
            value,
            displayedData: {
                filterLabel: t(
                    "screens.platform.enquiryList.filters.byStatus.label"
                ),
                valueLabel: t(`domain.enquiry.status.${value}`),
            },
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === FILTERGROUP_ID
    )?.value;

    const statuses = enquiryStatus.filter((status) => status !== "DRAFT");

    const options: FilterOptionType[] = [
        {
            id: "allStandards",
            label: t(
                "screens.platform.enquiryList.filters.byStatus.options.allStatuses"
            ),
            onSelect: () => deactivateFilter(FILTERGROUP_ID),
        },
        ...statuses.map((statusStandard) => ({
            id: statusStandard,
            label: t(`domain.enquiry.status.${statusStandard}`),
            onSelect: () =>
                activateFilter(
                    statusStandard,
                    ({ status }) => status === statusStandard
                ),
        })),
    ];

    return (
        <FilterSelect
            label={t("screens.platform.enquiryList.filters.byStatus.label")}
            filterOptions={options}
            value={activeFilterValue}
        />
    );
};

export { ByStatus };
