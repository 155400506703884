import { Text } from "@chakra-ui/react";
import { UserType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import InformationRowItem from "./InformationRowItem";

type PropsType = {
    user: UserType;
};

const ProfileOperationalArea = (props: PropsType) => {
    const {
        user: { maxOperatingDistance },
    } = props;

    const t = useSmartTranslation();

    return (
        <InformationRowItem
            label={t("profile.profileDetails.valuerMaxOperationDistance")}
            content={
                maxOperatingDistance !== null ? (
                    <Text fontSize={"sm"}>
                        {t(
                            `domain.standards.maxOperatingDistance.${maxOperatingDistance}`,
                            { defaultValue: MISSING_TRANSLATION }
                        )}
                    </Text>
                ) : (
                    <Text fontSize={"sm"}>
                        {t(
                            "profile.fieldNotProvided.valuerMaxOperationDistance"
                        )}
                    </Text>
                )
            }
        />
    );
};

export default ProfileOperationalArea;
