import { Button, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";

const MAX_CHARS_TO_DISPLAY = 180;

type OfferMessageProps = {
    message?: string;
};

export const OfferMessage = (props: OfferMessageProps) => {
    const { message } = props;

    const t = useSmartTranslation();

    const [showAll, setShowAll] = useState<boolean>(false);

    let visibleText;
    let showMoreOrLess;

    if (message) {
        const textExceedsCharLimit = message.length > MAX_CHARS_TO_DISPLAY;

        if (showAll) visibleText = message;
        else
            visibleText = textExceedsCharLimit
                ? message.substring(0, MAX_CHARS_TO_DISPLAY) + "..."
                : message;

        if (textExceedsCharLimit) {
            const label = t(showAll ? "common.showLess" : "common.showMore");

            showMoreOrLess = (
                <Button
                    variant={"link"}
                    color={"blue.500"}
                    fontWeight={"normal"}
                    onClick={() => setShowAll(!showAll)}
                >
                    {label}
                </Button>
            );
        }
    } else visibleText = t("domain.offer.noCommentsPlaceholder");

    return (
        <VStack
            w={"100%"}
            align={"stretch"}
        >
            <Text
                fontSize={"sm"}
                color={"gray.500"}
            >
                {t("domain.offer.comments")}
            </Text>
            <Text fontSize={"sm"}>{visibleText}</Text>
            {showMoreOrLess}
        </VStack>
    );
};
