import { Heading, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import { Button, Select } from "@chakra-ui/react";
import { clamp } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRole, useToast } from "../../../../../../common/hooks";
import { OfferWithEnquiry, PropertyType } from "../../../../../../types";
import {
    useEnquiry,
    useEntityPublicEntityGetById,
    useOfferGetByEnquiryId,
    usePublicUser,
} from "../../../../../../utils/api/hooks";
import { useAuthedUser } from "../../../../app/useMeV2";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { Placeholder } from "../../../../common/components/Placeholder";
import { useDevice } from "../../../../common/hooks/useDevice";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../../../common/hooks/useSmartTranslation";
import { Enquiry, Offer, Property } from "../../../../domain";
import { OfferHistoryTable } from "../../../../domain/offer/OfferHistoryTable";
import useRejectMultipleOffers from "../../../../domain/offer/useRejectMultipleOffers";
import { Section } from "../../../../layout";
import { AcceptedOfferTable } from "./AcceptedOfferTable";
import { EnrichedOfferTable } from "./EnrichedOfferTable";
import TitleClientData from "./TitleClientData";

const EnquiryDetails = () => {
    const t = useSmartTranslation();

    const { createErrorToast } = useToast();

    const { enquiryId } = useParams();

    const { roleLoading, userIsClient, userIsCompanyAdmin } = useRole();

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const { data: entityData, isLoading: entityDataLoading } =
        useEntityPublicEntityGetById(enquiry?.recipient);

    const { data: clientData, isLoading: clientDataLoading } = usePublicUser(
        Number(enquiry?.fromUserId)
    );

    const user = useAuthedUser();

    const properties = enquiry?.properties || [];

    const hasMultipleProperties = properties.length > 1;

    const device = useDevice();

    // offers

    const {
        data: offers,
        isLoading: offersLoading,
        refresh: refreshOffers,
    } = useOfferGetByEnquiryId(Number(enquiryId));

    const [checkedOffers, setCheckedOffers] = useState<OfferWithEnquiry[]>([]);

    const onError = (error: Error) => createErrorToast(error.message);

    const onUpdateEnquiry = () => {
        refreshEnquiry();
    };

    const { rejectOffers, confirmationModal: offerRejectionConfirmationModal } =
        useRejectMultipleOffers({
            onError,
            refresh: refreshOffers,
            offers: checkedOffers,
            enquiry,
        });

    const offerActivities = createOfferActivities(rejectOffers);

    const [activeOfferActivity, setActiveOfferActivity] =
        useState<OfferActivityType>(offerActivities["REJECT"]);

    const [selectedProperty, setSelectedProperty] = useState<
        PropertyType | undefined
    >(undefined);

    const selectedPropertyIndex = properties.findIndex(
        (i) => i.id === selectedProperty?.id
    );

    useEffect(() => {
        if (hasMultipleProperties) return;
        if (properties.length === 0) return;

        setSelectedProperty(properties[0]);
    }, [properties, hasMultipleProperties]);

    if (
        roleLoading ||
        !enquiry ||
        enquiryLoading ||
        !offers ||
        offersLoading ||
        entityDataLoading ||
        !clientData ||
        clientDataLoading
    )
        return <LoadingSpinner />;

    const offersWithEnquiry: OfferWithEnquiry[] = offers.map((i) => ({
        ...i,
        enquiry,
    }));

    // enquiry

    const titleClient = userIsCompanyAdmin &&
        enquiry.fromUserId !== user.id && (
            <TitleClientData
                client={clientData}
                enquiry={enquiry}
            />
        );

    const title = (
        <Enquiry.Title
            enquiry={{
                ...enquiry,
                recipientInfo: entityData,
            }}
            userIsClient={userIsClient}
            onUpdate={onUpdateEnquiry}
            onError={onError}
            clientInfo={titleClient}
        />
    );

    const requestedValuationSection = (
        <Section
            collapsable
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<Enquiry.Requirements enquiry={enquiry} />}
        />
    );

    // offers

    const activeOffers = offersWithEnquiry.filter(
        (o) => o.status === "PENDING"
    );

    const toggleOfferChecked = (offer: OfferWithEnquiry, check: boolean) => {
        if (!check) {
            const idx = checkedOffers.findIndex((o) => o.id === offer.id);
            if (idx !== -1) {
                const newChecked = [...checkedOffers];
                newChecked.splice(idx, 1);
                setCheckedOffers(newChecked);
            }
        } else {
            if (checkedOffers.find((o) => o.id === offer.id)) return;
            setCheckedOffers([...checkedOffers, offer]);
        }
    };

    const offersFooter = checkedOffers.length && (
        <HStack>
            <Text fontSize={"sm"}>
                {t(
                    "screens.platform.client.enquiryDetails.activeOffers.footer.selected",
                    {
                        count: checkedOffers.length,
                    }
                )}
            </Text>
            <Select
                width={"140px"}
                value={activeOfferActivity.key}
                size={"sm"}
                onChange={(e) =>
                    setActiveOfferActivity(
                        createOfferActivities(rejectOffers)[e.target.value]
                    )
                }
            >
                {createOptions(offerActivities, t)}
            </Select>
            <Button
                size={"sm"}
                variant={"outline"}
                onClick={() => activeOfferActivity.execute(checkedOffers)}
            >
                {t(
                    "screens.platform.client.enquiryDetails.activeOffers.footer.apply"
                )}
            </Button>
            {offerRejectionConfirmationModal}
        </HStack>
    );

    const hasAcceptedOffers = offersWithEnquiry.some(
        (offer) => offer.status === "ACCEPTED"
    );

    const offersSectionTitle = hasAcceptedOffers
        ? t("screens.platform.client.enquiryDetails.acceptedOffer.title")
        : t("screens.platform.client.enquiryDetails.activeOffers.title", {
              count: activeOffers.length,
          });

    const offersSectionContent = offersWithEnquiry.length ? (
        hasAcceptedOffers ? (
            <AcceptedOfferTable offers={offers} />
        ) : (
            <EnrichedOfferTable
                enquiry={enquiry}
                offers={activeOffers}
                checkedOffers={checkedOffers}
                toggleOfferChecked={toggleOfferChecked}
                footerContent={offersFooter}
            />
        )
    ) : enquiry.status === "DRAFT" ? (
        <Placeholder
            noDataText={t(
                "screens.platform.client.enquiryDetails.activeOffers.draftEnquiryTitle"
            )}
        />
    ) : (
        <Offer.NoOffersView />
    );

    const offersSection = (
        <Section
            collapsable
            title={offersSectionTitle}
            content={offersSectionContent}
        />
    );

    // offer history

    const historicOffers = offers.filter((o) =>
        ["REJECTED", "WITHDRAWN"].includes(o.status)
    );

    const historyContent = <OfferHistoryTable offers={historicOffers} />;

    const offerHistorySection = (
        <Section
            collapsable
            title={t(
                "screens.platform.client.enquiryDetails.offerHistory.title"
            )}
            content={historyContent}
        />
    );

    // properties

    const propertyPortfolioSection = hasMultipleProperties && (
        <Section
            collapsable
            title={t("domain.property.propertyPortfolio.title", {
                count: properties.length,
            })}
            content={
                <>
                    <Property.PortfolioTable
                        properties={properties}
                        selectedProperty={selectedProperty}
                        onRowClick={(property) => setSelectedProperty(property)}
                    />

                    <Property.PropertyDrawer
                        properties={properties}
                        selectedProperty={selectedProperty}
                        onClose={() => setSelectedProperty(undefined)}
                        onNextProperty={() => {
                            const nextIndex = clamp(
                                selectedPropertyIndex + 1,
                                0,
                                properties.length - 1
                            );

                            setSelectedProperty(properties[nextIndex]);
                        }}
                        onPreviousProperty={() => {
                            const nextIndex = clamp(
                                selectedPropertyIndex - 1,
                                0,
                                properties.length - 1
                            );

                            setSelectedProperty(properties[nextIndex]);
                        }}
                    />
                </>
            }
        />
    );

    const propertyDetails = !hasMultipleProperties && selectedProperty && (
        <Section
            collapsable
            title={t("domain.property.propertyDetails.title")}
            content={
                <>
                    <Property.PropertiesMap
                        properties={enquiry.properties}
                        selectedProperty={selectedProperty}
                    />

                    <Heading
                        size="md"
                        my=".5em"
                    >
                        {`${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`}
                    </Heading>
                    <Property.PropertyDetails property={selectedProperty} />
                </>
            }
        />
    );

    // render

    return (
        <VStack
            w={"100%"}
            p={4}
            spacing={4}
        >
            {title}
            <SimpleGrid
                width={"100%"}
                spacing={2}
                gridTemplateColumns={
                    device === "mobile"
                        ? "100%"
                        : "minmax(0, 39%) minmax(0, 61%)"
                }
            >
                <VStack>
                    {requestedValuationSection}
                    {propertyPortfolioSection}
                    {propertyDetails}
                </VStack>
                <VStack>
                    {offersSection}
                    {offerHistorySection}
                </VStack>
            </SimpleGrid>
        </VStack>
    );
};

type OfferActivityType = {
    key: string;
    label: (t: TranslationFunction) => string;
    execute: ((offers: OfferWithEnquiry[]) => void) | (() => void);
};

const createOfferActivities = (
    rejectOffers: () => void
): Record<string, OfferActivityType> => {
    return {
        REJECT: {
            key: "REJECT",
            label: (t) => t("domain.offer.ACTIVITIES.reject.NAME"),
            execute: () => rejectOffers(),
        },
    };
};

const createOptions = (
    options: Record<string, OfferActivityType>,
    t: TranslationFunction
) => {
    return Object.entries(options).map(([key, option]) => (
        <option
            key={key}
            value={key}
        >
            {option.label(t)}
        </option>
    ));
};

export default EnquiryDetails;
