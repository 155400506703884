import { Box, Checkbox, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { AttachmentItem } from "../../OfferEditor";
import { AvailableAttachment } from "../../offereditor/AvailableAttachment";

export type RequiredAttachmentsEditorProps = {
    onUpload: (filelist: FileList) => void;
    onToggleAll: () => void;
    allChecked: boolean;
    disableToggleAll: boolean;
    items: AttachmentItem<any>[];
};

export const RequiredAttachmentsEditor = (
    props: RequiredAttachmentsEditorProps
) => {
    const { onUpload, onToggleAll, allChecked, disableToggleAll, items } =
        props;

    const t = useSmartTranslation();

    // select all

    const selectAll = (
        <Checkbox
            onChange={onToggleAll}
            isChecked={allChecked}
            isDisabled={disableToggleAll}
        >
            {
                <Text
                    color={"blue.500"}
                    fontSize={"sm"}
                >
                    {t("common.selectAll")}
                </Text>
            }
        </Checkbox>
    );

    const identifier = String(Math.random());

    // upload
    const uploadButton = (
        <>
            <label htmlFor={identifier}>
                <HStack
                    p={".5rem"}
                    borderRadius={3}
                    color={"blue.500"}
                    cursor={"pointer"}
                    _hover={{ bg: "gray.200" }}
                >
                    <FiUpload />
                    <Text fontSize={"sm"}>
                        {t("common.inputs.fileUpload.button.uploadFile")}
                    </Text>
                </HStack>
            </label>
            <input
                type={"file"}
                id={identifier}
                multiple
                name={"requiredAttachmentsTmp"}
                hidden
                onChange={(ev) => {
                    if (ev.target.files) {
                        onUpload(ev.target.files);

                        // delete files from input state after adding
                        ev.target.value = "";
                    }
                }}
            />
        </>
    );

    const requiredAttachmentItems = items.map((item) => {
        const { label, url, size, onToggle, checked, menu } = item;
        return (
            <AvailableAttachment
                key={label}
                label={label}
                url={url}
                size={size}
                onToggle={onToggle}
                checked={checked}
                menu={menu}
            />
        );
    });

    return (
        <VStack
            w={"100%"}
            align={"stretch"}
        >
            <Flex
                px={4}
                justifyContent={"space-between"}
            >
                {selectAll}
                {uploadButton}
            </Flex>
            <VStack
                align={"stretch"}
                spacing={".1rem"}
                borderRadius="8"
                overflow={"hidden"}
            >
                {requiredAttachmentItems}
            </VStack>
        </VStack>
    );
};
