import { StandardYearsOfExperience } from "../../../../../common/vars/valuationsAndMembershipTypes/standardYearsOfExperience";
import { yearsOfExperienceTranslations } from "./yearsOfExperienceTranslations";

export const YearsOfExperience = {
    translations: yearsOfExperienceTranslations,
};

export const experienceMatchRequirement = (
    experience: StandardYearsOfExperience,
    requiredExperience: StandardYearsOfExperience
): boolean => {
    return +experience >= +requiredExperience;
};
