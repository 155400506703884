import { Heading, Text, VStack } from "@chakra-ui/react";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { PropertyType } from "../../../../../../types";
import { TranslationFunction } from "../../../hooks/useSmartTranslation";
import { NO_DATA } from "../../../NO_DATA";
import { DataTableColumnType } from "../DataTable";

type ColumnType = DataTableColumnType<PropertyType>;

const translationNameSpace =
    "common.components.dataTable.columns.propertyColumns";

export const propertyIndex: ColumnType = {
    title: () => "#",
    render: (p, t, rowIndex) => (
        <Text
            fontSize={"xs"}
            variant={"secondary"}
        >
            {++rowIndex}
        </Text>
    ),
};

export const propertyAddress: ColumnType = {
    title: (t) => t(`${translationNameSpace}.address`),
    render: (p) => {
        const { street, city, postalCode } = p;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Heading size="xs">{street}</Heading>
                <Text
                    fontSize="xs"
                    variant="secondary"
                >
                    {`${postalCode} ${city}`}
                </Text>
            </VStack>
        );
    },
};

export const propertyType: ColumnType = {
    title: (t) => t(`${translationNameSpace}.propertyType`),
    render: (p, t) => {
        const { propertyTypes } = p;

        const isMixedProperty = propertyTypes.length > 1;

        const propertyType = t(
            `domain.standards.propertyType.${
                isMixedProperty ? "Mixed" : propertyTypes[0]
            }`
        );

        return <Text fontSize={"sm"}>{propertyType}</Text>;
    },
};

export const area: ColumnType = {
    title: (t) => t(`${translationNameSpace}.area`),
    render: (p) => {
        const { areaOverall, areaGrossInternal } = p;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Heading
                    size={"xs"}
                    fontWeight={"400"}
                >{`${delimitNumber(areaGrossInternal)} m²`}</Heading>
                <Text
                    fontSize="xs"
                    variant="secondary"
                >
                    {`${
                        areaOverall !== null
                            ? delimitNumber(areaOverall)
                            : NO_DATA
                    } m²`}
                </Text>
            </VStack>
        );
    },
};

export const estimateValue: ColumnType = {
    title: (t) => t(`${translationNameSpace}.estimateValue`),
    tooltip: (t: TranslationFunction) =>
        t(`${translationNameSpace}.estimateValueTooltip`),
    render: (p) => {
        const { estimateValue } = p;
        return (
            <Text
                fontSize={"sm"}
                fontWeight="bold"
                color="cyan.500"
            >
                {`€${delimitNumber(estimateValue)}`}
            </Text>
        );
    },
};

export const propertyColumns = {
    propertyIndex,
    propertyAddress,
    propertyType,
    area,
    estimateValue,
};
