import { Heading, Text, VStack } from "@chakra-ui/layout";
import { NotificationType } from "../../../../../types";

type Props = {
    notification: NotificationType;
    markAsRead: (id: number) => void;
};

export const NotificationDisplay = (props: Props) => {
    const { notification, markAsRead } = props;

    const { id, title, message, read } = notification;

    let bgColor: "green.50" | "white" = "green.50";
    let borderColor: "green.100" | "gray.200" = "green.100";

    if (read) {
        bgColor = "white";
        borderColor = "gray.200";
    }

    return (
        <VStack
            as="button"
            onClick={() => markAsRead(id)}
            align="left"
            bgColor={bgColor}
            padding="1rem"
            border="1px solid"
            borderColor={borderColor}
            borderRadius="10"
            w="100%"
        >
            <Heading
                size="xs"
                textAlign="left"
            >
                {title}
            </Heading>

            <Text textAlign="left">{message}</Text>
        </VStack>
    );
};
