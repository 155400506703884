import { WarningTwoIcon } from "@chakra-ui/icons";
import { Heading, HStack, Link, Text } from "@chakra-ui/react";
import toUrl from "../../../../../common/functions/toUrl";
import { useRole, useStandards } from "../../../../../common/hooks";
import { EntityType } from "../../../../../types";
import ProfilePictureCompany from "../../../common/components/display/ProfilePictureCompany";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import RenderFieldData from "../profile/RenderFieldData";
import InformationRowItem from "./InformationRowItem";

type CompanyDetailsDataProps = {
    entity: EntityType;
};

const CompanyDetailsData = (props: CompanyDetailsDataProps) => {
    const {
        entity: {
            valuerNumberOfPartners,
            valuerYearsOfExperience,
            websiteAddress = "",
            companyEmail,
            contactFirstName,
            contactLastName,
            contactNumberDialCode,
            contactNumber,
            street,
            postalCode,
            city,
            country,
            name,
        },
    } = props;

    const t = useSmartTranslation();

    const { userIsValuer } = useRole();
    const { yearsOfExperience } = useStandards();

    // render

    const header = (
        <HStack
            spacing={4}
            mb={4}
        >
            <ProfilePictureCompany size="5rem" />

            <Heading size={"lg"}>
                {!name && (
                    <>
                        <WarningTwoIcon color={"gray.400"} />
                        {t("account.companyDetails.noNameProvided")}
                    </>
                )}

                {name !== "" && name}
            </Heading>
        </HStack>
    );

    const website = (
        <InformationRowItem
            label={t("account.companyDetails.website")}
            content={
                websiteAddress ? (
                    <Link
                        color="blue.500"
                        href={toUrl(websiteAddress)}
                        isExternal
                        textOverflow="elipsis"
                        noOfLines={1}
                    >
                        {websiteAddress}
                    </Link>
                ) : (
                    <Text
                        fontSize={"sm"}
                        fontWeight={"700"}
                    >
                        {t("profile.fieldNotProvided.website")}
                    </Text>
                )
            }
        />
    );

    const valuerData = userIsValuer && (
        <>
            <InformationRowItem
                label={t("account.companyDetails.yearsOfExperience")}
                content={
                    <RenderFieldData
                        field="yearsOfExperience"
                        data={[
                            yearsOfExperience.find(
                                (i) => i.value === valuerYearsOfExperience
                            )?.label,
                        ]}
                        config={{ required: true }}
                    />
                }
            />

            <InformationRowItem
                label={t("account.companyDetails.numberOfValuers")}
                content={
                    <RenderFieldData
                        field="numberOfPartners"
                        data={[valuerNumberOfPartners]}
                        config={{ required: true }}
                    />
                }
            />
        </>
    );

    const contact = (
        <InformationRowItem
            label={t("account.companyDetails.contactPerson")}
            content={
                <RenderFieldData
                    field="contactPerson"
                    data={[contactFirstName, contactLastName]}
                />
            }
        />
    );

    const email = (
        <InformationRowItem
            label={t("account.companyDetails.email")}
            content={
                <RenderFieldData
                    field="email"
                    data={[companyEmail]}
                    config={{ required: userIsValuer }}
                />
            }
        />
    );

    const phone = (
        <InformationRowItem
            label={t("account.companyDetails.phoneNumber")}
            content={
                <RenderFieldData
                    field="phoneNumber"
                    data={[contactNumberDialCode, contactNumber]}
                />
            }
        />
    );

    const location = (
        <InformationRowItem
            label={t("account.companyDetails.location")}
            content={
                <RenderFieldData
                    field="location"
                    data={[street, postalCode, city, country]}
                    config={{ joinWith: ", ", required: true }}
                />
            }
        />
    );

    return (
        <>
            {header}
            {website}
            {valuerData}
            {contact}
            {email}
            {phone}
            {location}
        </>
    );
};

export default CompanyDetailsData;
