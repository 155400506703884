import { Divider, Flex, Text, VStack } from "@chakra-ui/react";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { EnquiryType, OfferType, UserType } from "../../../../../../types";
import { useAuthedUser } from "../../../../app/useMeV2";
import { usePermissions } from "../../../../app/usePermissions";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Offer } from "../../../../domain";
import AcceptOffer from "../../../../domain/offer/AcceptOffer";
import { calculateDeliveryDate } from "../../../../domain/offer/offerFunctions";
import RejectOffer from "../../../../domain/offer/RejectOffer";
import Subsection from "../../../../layout/Subsection";

type OfferDetailsProps = {
    offer: OfferType;
    enquiry: EnquiryType;
    enquiryCreator: UserType;
    onUpdate: () => any;
    onError: (error: Error) => any;
};

const OfferDetails = (props: OfferDetailsProps) => {
    const {
        offer: {
            piCoverValue,
            piCoverPercentage,
            timeline,
            availability,
            price,
            status,
            comments: message,
            fromEntity: { piCoverSpecialArrangementsAvailable },
        },
        offer,
        enquiry,
        enquiryCreator,
        onUpdate,
    } = props;

    const t = useSmartTranslation();

    const user = useAuthedUser();

    const canI = usePermissions();

    const hasPermissions = canI(enquiry, "ENQUIRY", "EDIT");

    const earliestAvailability = displayDateWithCurrentLocale(availability);
    const delivery = displayDateWithCurrentLocale(calculateDeliveryDate(offer));

    // render

    const statusBanner = status !== "PENDING" && (
        <Offer.details.OfferStatus offer={offer} />
    );

    const priceInfo =
        status === "PENDING" ? (
            <>
                <Divider
                    orientation={"horizontal"}
                    variant={"dashed"}
                    background={"gray.500"}
                    my={2}
                />
                <Offer.details.OfferProperty
                    label={t("domain.offer.valuationPrice")}
                    tooltip={t("domain.offer.priceDisclaimer")}
                    value={`€ ${delimitNumber(price)}`}
                    valueSize="4xl"
                    color="green.500"
                />
            </>
        ) : (
            <Offer.details.OfferProperty
                label={t("domain.offer.valuationPrice")}
                tooltip={t("domain.offer.priceDisclaimer")}
                value={`€ ${delimitNumber(price)}`}
            />
        );

    const buttons = status === "PENDING" && hasPermissions && (
        <Flex
            w={"100%"}
            gap={1}
        >
            <AcceptOffer
                offer={offer}
                onSuccess={onUpdate}
            />

            <RejectOffer
                offer={offer}
                enquiry={enquiry}
                onSuccess={onUpdate}
            />
        </Flex>
    );

    const noPermissionDisclaimer = !hasPermissions && (
        <Subsection>
            <Text width={"100%"}>
                {t("domain.offer.ACTIVITIES.accept.disclaimer", {
                    company: user.entity.name,
                    user: `${enquiryCreator.firstName} ${enquiryCreator.lastName}`,
                })}
            </Text>
        </Subsection>
    );

    const deliveryWarningTooltip =
        (calculateDeliveryDate(offer) > new Date(enquiry.completionDate)! &&
            t("domain.offer.deliveryDateWarningTooltip")) ||
        undefined;

    const piCoverWarningTooltip =
        (enquiry.valuerMinimumPICover > piCoverValue! &&
            t("domain.offer.piCoverAbsoluteWarningTooltip")) ||
        undefined;

    return (
        <VStack
            align={"stretch"}
            spacing={1}
        >
            <Offer.details.OfferMessage message={message} />
            <Divider
                orientation={"horizontal"}
                variant={"dashed"}
                background={"gray.500"}
                my={2}
            />
            <Offer.details.OfferProperty
                label={t("domain.offer.timeline")}
                value={`${timeline} ${t("common.units.days")}`}
            />
            <Offer.details.OfferProperty
                label={t("domain.offer.availability")}
                value={earliestAvailability}
            />
            <Offer.details.OfferProperty
                label={t("domain.offer.deliveryDate")}
                value={delivery}
                valuerTooltip={deliveryWarningTooltip}
            />
            <Offer.details.OfferProperty
                label={t("domain.offer.piCoverAbsolute")}
                value={`€ ${delimitNumber(piCoverValue!)}`}
                valuerTooltip={piCoverWarningTooltip}
            />
            <Offer.details.OfferProperty
                label={t("domain.offer.piCoverPercentage")}
                value={`${piCoverPercentage!}%`}
            />
            <Offer.details.OfferProperty
                label={t("domain.offer.piAgreement.label")}
                tooltip={t("domain.offer.piAgreement.tooltip")}
                value={
                    piCoverSpecialArrangementsAvailable
                        ? t("common.yes")
                        : t("common.no")
                }
            />
            {priceInfo}
            {statusBanner}
            {noPermissionDisclaimer}
            {buttons}
        </VStack>
    );
};

export { OfferDetails };
