import { SpecialPropertyType } from "../../../../../common/vars/valuationsAndMembershipTypes/specialPropertyTypes";

type TranslationNSObject = {
    [key in SpecialPropertyType]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const specialPropertyTypeTranslations: Translations = {
    de: {
        "Bed and breakfast": "Übernachtung und Frühstück",
        Cinema: "Kino",
        Datacentre: "Rechenzentrum",
        "Dentist's Surgery": "Zahnarztpraxis",
        "Medical Practice": "Arztpraxis",
        "Medical/Chemical Laboratory": "Medizinisches/Chemisches Labor",
        "Electric Plant": "Elektrizitätswerk",
        "Fishing Lake": "Angelsee",
        "Historical Building": "Baudenkmal",
        Golf: "Golf",
        "Health and Fitness": "Gesundheit und Fitness",
        Hotel: "Hotel",
        Kindergarten: "Kindergarten",
        "Leisure Facilities/ Caravan Park/ Cabin":
            "Freizeiteinrichtungen/ Caravanpark/ Hütte",
        "Mining and Mineral": "Bergbau und Mineralien",
        "Other Special Real Estate": "Sonstige Spezialimmobilien",
        Pharmacy: "Apotheke",
        "Trade Retail/ Post Office/ Hot Food Shop":
            "Einzelhandel/ Postamt/ Hot Food Shop",
        "Yacht Harbour": "Jachthafen",
    },
    en: {
        "Bed and breakfast": "Bed and Breakfast",
        Cinema: "Cinema",
        Datacentre: "Datacentre",
        "Dentist's Surgery": "Dentist's Surgery",
        "Medical Practice": "Medical Practice",
        "Medical/Chemical Laboratory": "Medical/Chemical Laboratory",
        "Electric Plant": "Electric Plant",
        "Fishing Lake": "Fishing Lake",
        "Historical Building": "Historical Building",
        Golf: "Golf",
        "Health and Fitness": "Health and Fitness",
        Hotel: "Hotel",
        Kindergarten: "Kindergarten",
        "Leisure Facilities/ Caravan Park/ Cabin":
            "Leisure Facilities/ Caravan Park/ Cabin",
        "Mining and Mineral": "Mining and Mineral",
        "Other Special Real Estate": "Other Special Real Estate",
        Pharmacy: "Pharmacy",
        "Trade Retail/ Post Office/ Hot Food Shop":
            "Trade Retail/ Post Office/ Hot Food Shop",
        "Yacht Harbour": "Yacht Harbour",
    },
};
