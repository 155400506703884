import { extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/inter';

export const wvwPalette = {
    wvwBackground: {
        main: '#f7fafc',
    },
    wvwYellow: {
        light: '#ffb101',
        main: '#ffa201',
        dark: '#ff9002',
    },
    wvwBlue: {
        light: '#3496bf',
        main: '#1f6c9d',
        dark: '#0f4b83',
    },
    wvwGradients: {
        blue: 'linear-gradient(90deg, #10528F 25.98%, #3396BF 100%)',
        orange: 'linear-gradient(90deg, #FF8F00 0%, #FFB300 100%)',
    },
};

const theme = extendTheme({
    colors: {
        blue: 'rgba(43, 108, 176, 1)',
        blue80: 'rgba(43, 108, 176, .80)',
        blue60: 'rgba(43, 108, 176, .60)',
        blue40: 'rgba(43, 108, 176, .40)',
        blue20: 'rgba(43, 108, 176, .20)',
        blue10: 'rgba(43, 108, 176, .10)',
        blue05: 'rgba(43, 108, 176, .05)',
        darkWvwGreen: wvwPalette.wvwBlue.dark,
        wvwGreen: wvwPalette.wvwBlue.main,
        wvwGreen80: wvwPalette.wvwBlue.light,
        wvwGreen60: '#55ACD1',
        wvwGreen40: '#3097D8',
        wvwGreen20: '#45A2DD',
        wvwGreen10: '#5AADE1',
        wvwGreen05: '#6FB8E5',
        red: 'rgba(233, 74, 68)',
        red80: 'rgba(233, 74, 68, .80)',
        red60: 'rgba(233, 74, 68, .60)',
        red40: 'rgba(233, 74, 68, .40)',
        red20: 'rgba(233, 74, 68, .20)',
        red10: 'rgba(233, 74, 68, .10)',
        red05: 'rgba(233, 74, 68, .05)',
        orange: '#ff8e0a',
        orange10: '#ffdaaf',
        wvwGrey: 'rgba(62, 62, 64)',
        wvwGrey80: 'rgba(62, 62, 64, 0.8)',
        wvwGrey60: 'rgba(62, 62, 64, 0.6)',
        wvwGrey40: 'rgba(62, 62, 64, 0.4)',
        wvwGrey20: '#f5f5f5',
        wvwGrey10: 'rgba(62, 62, 64, 0.1)',
        wvwGrey05: 'rgba(62, 62, 64, 0.05)',
        wvwGrey01: 'rgba(62, 62, 64, 0.01)',
        white: '#ffffff',
        green: 'rgba(46, 205, 122)',
        green80: 'rgba(46, 205, 122, .80)',
        green60: 'rgba(46, 205, 122, .60)',
        green40: 'rgba(46, 205, 122, .40)',
        green20: 'rgba(46, 205, 122, .20)',
        green10: 'rgba(46, 205, 122, .10)',
        wvwYellow: wvwPalette.wvwYellow.main,
        wvwYellow80: 'rgba(250, 202, 22, .80)',
        wvwYellow60: wvwPalette.wvwYellow.light,
        wvwYellow40: 'rgba(250, 202, 22, .40)',
        wvwYellow20: 'rgba(250, 202, 22, .20)',
        wvwYellow10: 'rgba(250, 202, 22, .10)',
        purple: 'rgba(121, 87, 159)',
        purple80: 'rgba(121, 87, 159, .8)',
        purple60: 'rgba(121, 87, 159, .6)',
        purple40: 'rgba(121, 87, 159, .4)',
        purple20: 'rgba(121, 87, 159, .2)',
        purple10: 'rgba(121, 87, 159, .1)',
    },
    components: {
        Button: {
            variants: {
                primary: {
                    width: 'fit-content',
                    padding: '.5rem',
                    border: '2px solid',
                    borderColor: 'none',
                    color: 'white',
                    bg: wvwPalette.wvwGradients.blue,
                    _hover: {
                        bg: 'linear-gradient(120deg, #10528F 10%, #3396BF 100%)',
                    },
                },
                primaryYellow: {
                    width: 'fit-content',
                    paddingInline: '1rem',
                    color: 'wvwGrey',
                    bg: wvwPalette.wvwGradients.orange,
                    _hover: {
                        bg: 'linear-gradient(120deg, #FF8F00 0%, #FFB300 100%)',
                    },
                },
                primaryGreen: {
                    width: 'fit-content',
                    padding: '.5rem',
                    border: '2px solid',
                    borderColor: 'green',
                    color: 'white',
                    bg: 'green',
                    _hover: {
                        borderColor: '#12BC61',
                        bg: '#12BC61',
                        color: 'white',
                    },
                },
                primaryWhite: {
                    width: 'fit-content',
                    padding: '.5rem',
                    border: '2px solid',
                    borderColor: 'white',
                    color: 'wvwGreen',
                    bg: 'white',
                    _hover: {
                        bg: 'wvwGreen',
                        color: 'white',
                    },
                    _active: {
                        bg: 'wvwGreen',
                        color: 'white',
                    },
                },
                danger: {
                    width: 'fit-content',
                    padding: '.5rem',
                    border: '2px solid',
                    borderColor: 'red',
                    color: 'white',
                    bg: 'red',
                    _hover: {
                        bg: '#DD423C',
                        borderColor: '#DD423C',
                    },
                },
                outline: {
                    width: 'fit-content',
                    border: '2px solid',
                    borderColor: 'wvwGreen',
                    color: 'wvwGreen',
                    _hover: {
                        color: 'wvwGreen',
                    },
                },
                outline1: {
                    width: 'fit-content',
                    border: '2px solid',
                    bgColor: 'white',
                    borderColor: 'wvwYellow',
                    color: 'wvwYellow',
                    _hover: {
                        color: 'white',
                        bg: 'wvwYellow',
                    },
                },
                link: {
                    color: 'wvwGreen',
                    bg: 'none',
                    fontWeight: 'normal',
                    _hover: {
                        textDecoration: 'underline',
                    },
                },
                cloudSelect: {
                    color: 'black',
                    bg: 'none',
                    border: '1px solid #ebebeb',
                },
                cloudSelectActive: {
                    bgColor: '#edf2f7',
                    borderColor: '#edf2f7',
                    color: 'wvwGreen',
                },
            },
        },
        Switch: {
            baseStyle: {
                track: {
                    bg: 'wvwGrey10',
                    _checked: {
                        bg: wvwPalette.wvwBlue.light,
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    marginTop: '.3rem',
                    border: '1px solid',
                    borderColor: 'wvwGreen',
                    _checked: {
                        iconColor: 'white',
                        bg: 'wvwGreen',
                        borderColor: 'wvwGreen',
                    },
                    errorBorderColor: 'red',
                },
            },
            variants: {
                outline: {
                    container: {
                        width: '100%',
                        height: '2.5rem',
                        border: '1px solid #e2e8f0',
                        borderRadius: '8',
                        paddingInline: '1rem',
                        paddingBlock: '2rem',
                        _checked: {
                            bgColor: '#edf2f7',
                            borderColor: '#edf2f7',
                        },
                    },
                },
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    border: '1px solid',
                    _checked: {
                        bg: 'white',
                        borderColor: 'wvwGreen',
                        color: 'wvwGreen',
                    },
                    errorBorderColor: 'red',
                },
                label: {
                    fontwWight: 'bold',
                    _checked: {
                        color: 'wvwGreen',
                    },
                },
            },
            variants: {
                outline: {
                    container: {
                        width: '100%',
                        border: '1px solid #e2e8f0',
                        borderRadius: '8',
                        paddingBlock: '.5rem',
                        paddingInline: '1rem',
                        _checked: {
                            bgColor: '#edf2f7',
                            borderColor: '#edf2f7',
                        },
                    },
                },
            },
        },
        Tabs: {
            variants: {
                wvwGreen: {
                    tab: {
                        color: 'wvwGrey60',
                        _selected: {
                            color: 'wvwGreen',
                            fontWeight: 'bold',
                        },
                    },
                },
                wvwGreenUnderline: {
                    tab: {
                        width: '100%',
                        color: 'wvwGrey60',
                        borderBottom: '2px solid #cacacb',
                        _selected: {
                            color: 'wvwGreen',
                            borderBottom: '2px solid #016064',
                            fontWeight: 'bold',
                        },
                    },
                },
            },
        },
        UnorderedList: {
            colorScheme: {
                wvwGreen: {
                    text: {
                        color: 'wvwGreen',
                    },
                },
            },
        },
        Select: {
            defaultProps: {
                background: 'white',
                size: 'sm',
                borderRadius: '15',
                focusBorderColor: 'wvwGreen',
                errorBorderColor: 'red',
            },
        },
        Text: {
            baseStyle: {
                fontSize: "initial",
            },
            variants: {
                main: {
                    color: "black",
                },
                secondary: {
                    color: "gray.500",
                },
            },
        },
        Input: {
            defaultProps: {
                focusBorderColor: 'wvwGreen',
                errorBorderColor: 'red',
                bg: "white",
            },
        },
        Toast: {
            variants: {
                error: {
                    bg: 'red',
                    color: 'white',
                },
                success: {
                    bg: 'green',
                    color: 'white',
                },
            },
        },
        Accordion: {
            variants: {
                primary: {
                    root: {
                        border: 'transparent',
                    },
                    container: {
                        bg: 'none',
                        borderRadius: '10',
                        marginBlock: '.5rem',
                    },
                    button: {
                        borderRadius: '10',
                        w: 'fit-content',
                        bg: 'wvwGrey05',
                        paddingBlock: '1rem',
                        _hover: {
                            bg: 'wvwGrey10',
                        },
                    },
                },
                white: {
                    root: {
                        border: 'transparent',
                    },
                    container: {
                        bg: 'none',
                        borderBottom: '1px solid #f7f8fb',
                        marginBlock: '.5rem',
                    },
                    button: {
                        bg: 'white',
                        borderRadius: '10',
                        paddingBlock: '1rem',
                        _hover: {
                            bg: 'wvwGrey05',
                        },
                    },
                },
            },
        },
        Modal: {
            variants: {
                xs: {
                    dialog: {
                        maxWidth: '20%',
                    },
                },
                small: {
                    dialog: {
                        maxWidth: '40%',
                    },
                },
                medium: {
                    dialog: {
                        maxWidth: '65%',
                    },
                },
                large: {
                    dialog: {
                        maxWidth: '80%',
                    },
                },
            },
        },
        Menu: {
            defaultProps: {
                button: {
                    _hover: 'none',
                },
            },
            variants: {
                primary: {
                    button: {
                        bg: 'none',
                        _hover: 'none',
                    },
                },
                secondary: {
                    button: {
                        color: 'wvwGrey80',
                        bg: 'wvwGrey05',
                        padding: '0',
                        _hover: {
                            bg: 'wvwGreen10',
                            color: 'wvwGreen',
                        },
                    },
                },
                dynamic: {
                    list: {
                        button: {
                            bg: 'none',
                            _hover: 'none',
                        },
                        bg: 'rgb(245,245,245)',
                        _hover: 'none',
                        item: {
                            bg: 'rgb(245,245,245)',
                            _hover: 'none',
                        },
                    },
                },
            },
        },
    },
    styles: {
        global: {
            body: {
                minHeight: '100vh',
                bg: wvwPalette.wvwBackground.main,
                color: 'wvwGrey',
            },
        },
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
});

export default theme;
