import { StandardValuationOccasion } from "../../../../../common/vars/valuationsAndMembershipTypes/standardValuationOccasions";

type TranslationNSObject = {
    [key in StandardValuationOccasion]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const valuationOccasionTranslations: Translations = {
    de: {
        "Acquisition/Sale": "Ankauf/Verkauf",
        "Annual Accounts": "Jahresabschlüsse",
        Auction: "Versteigerung",
        Court: "Gericht",
        "Divorce": "Scheidung",
        "Inheritance": "Erbschaft",
        "Enterprise Valuation": "Unternehmensbewertung",
        "General Information": "Allgemeine Informationen",
        Other: "Andere",
        "Loan/Investment": "Darlehen/Investition",
        "Regulatory Reasons": "Regulatorische Gründe",
        "Residual Use For Life": "Verkürzte Restnutzungsdauer",
        Tax: "Steuer",
    },
    en: {
        "Acquisition/Sale": "Acquisition/Sale",
        "Annual Accounts": "Annual Accounts",
        Auction: "Auction",
        Court: "Court",
        "Divorce": "Divorce",
        "Inheritance": "Inheritance",
        "Enterprise Valuation": "Enterprise Valuation",
        "General Information": "General Information",
        Other: "Other",
        "Loan/Investment": "Loan/Investment",
        "Regulatory Reasons": "Regulatory Reasons",
        "Residual Use For Life": "Residual Use For Life",
        Tax: "Tax",
    },
};
