import { SmallCloseIcon } from "@chakra-ui/icons";
import { Button, chakra } from "@chakra-ui/react";
import { OfferOnlyType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useOfferWithdraw from "./useWithdrawOfferV2";

type ParamsType = {
    offer: OfferOnlyType;
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const PureWithdrawOffer = (params: ParamsType) => {
    const { offer, onSuccess, onError, ...cssProps } = params;

    const t = useSmartTranslation();

    const { update: withdrawOffer, confirmationModal } = useOfferWithdraw({
        onSuccess,
        onError,
        offer,
    });

    return (
        <Button
            {...cssProps}
            onClick={() => withdrawOffer()}
            leftIcon={<SmallCloseIcon />}
            variant={"outline"}
            colorScheme={"red"}
        >
            {t("domain.offer.ACTIVITIES.withdraw.NAME")}
            {confirmationModal}
        </Button>
    );
};

const WithdrawOffer = chakra(PureWithdrawOffer);

export default WithdrawOffer;
