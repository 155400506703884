import { offerColumnTranslations } from "./columns/OfferColumns.i18n";
import {
    offerStatusTranslations,
    offerWithEnquiryStatusTranslatins
} from "./offerStatus";

export const translations = {
    de: {
        ACTIVITIES: {
            accept: {
                NAME: "Annehmen",
                confirmationTitle: "Annahme des Angebots",
                attachedFiles: {
                    label: "Beigefügte Dokumente",
                    confirmation:
                        "Ich habe die beigefügten Dokumente gelesen und akzeptiere die darin enthaltenen AGB des Wertgutachters.",
                },
                requiredConfirmations: {
                    label: "Ich bestätige hiermit:",
                    confirmation:
                        "Ich habe die <0>AGB</0> und <1>Datenschutzerklärung</1> der WVW Plattform gelesen und akzeptiere sie.",
                },
                disclaimer:
                    "You see this offer as an admin of {{company}}. Only user {{user}} (owner) can accept or reject it.",
                legalNote: {
                    label: "Rechtliche Hinweise",
                    description:
                        "Die Angebotsannahme begründet keinen Vertrag. Ein entsprechender Vertrag muss separat mit dem Wertgutachter geschlossen werden. Kommt es nach Angebotsannahme zu keinem Vertragsverhältnis und der Wertgutachter hat keine Leistungen erbracht, so weist die WVW Plattform die Vorauszahlung zurück.",
                    payment:
                        "Eine Zahlung erfolgt erst bei Eingang des Wertgutachtens.",
                    confirmation:
                        "Ich habe die oben genannten rechtlichen Hinweise gelesen und akzeptiere sie.",
                },
            },
            reject: {
                NAME: "Ablehnen",
                confirmationTitle: "Ablehnung des Angebots",
                confirmationBlurb:
                    "Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            rejectAll: {
                NAME: "Ablehnen",
                confirmationBlurb:
                    "Diese Angebote wirklich ablehnen? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            withdraw: {
                NAME: "Zurückziehen",
                confirmationTitle: "Angebot zurückziehen",
                confirmationBlurb:
                    "Dieses Angebot wirklich zurückziehen? ? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
        },
        awaitingOffers: {
            waitingForOffers: "Warte auf Angebote...",
            waitingForOffersBlurb1:
                "Wenn Angebote von Gutachtern eingereicht werden, sehen Sie dies hier.",
            waitingForOffersBlurb2:
                "Wir benachrichtigen Sie auch per E-Mail und das Benachrichtigungssystem auf der Plattform.",
        },
        availability: "Früheste Verfügbarkeit",
        columns: offerColumnTranslations.de,
        comments: "Nachricht",
        commentsPlaceholder: "Optionale Nachricht an den Kunden",
        creationDisclaimer:
            "Mit Abgabe Ihres Angebots stimmen Sie unseren <0>AGB</0> und unserer <1>Datenschutzrichtlinie</1> zu.",
        deleteUploadedAttachment: "Löschen",
        deliveryDate: "Fertigstellung",
        deliveryDateWarningTooltip:
            "Dieser Wert liegt nach dem geforderten Lieferdatum.",
        marketingAttachments: "Marketing",
        nameAlreadyInUse:
            "Eine Datei mit Namen '{{ name }}' ist bereits vorhanden",
        newOffer: "Neues Angebot",
        noAttachmentsPlaceholder: "Keine Anhänge",
        noCommentsPlaceholder: "[Keine Nachricht]",
        offers: "Angebote",
        offerId: "Angebot {{ id }}",
        offerStatus: "Angebotsstatus",
        openCase: "Fall ansehen",
        otherAttachments: "Andere Anhänge",
        piAgreement: {
            label: "Sonderkonditionen HP",
            tooltip: "Sonderkonditionen für die Haftpflichtdeckung.",
        },
        piCertificateAttachments: "HP Nachweise",
        piCoverAbsolute: "Haftpflicht [€]",
        piCoverAbsoluteTooltip: "Deckungssumme pro Bewertungsobjekt",
        piCoverAbsoluteWarningTooltip:
            "Dieser Wert ist geringer als die geforderte Haftpflichtdeckung.",
        piCoverPercentage: "Haftpflicht [%]",
        piCoverPercentageTooltip: "Deckungsanteil pro Bewertungsobjekt",
        price: "Preis (netto)",
        priceDisclaimer:
            "Der Nettopreis bezieht sich auf die Erstellung des Wertgutachtens.  Es fallen gegebenenfalls Mehrwertsteuer und Servicegebühren an.",
        price_net: "Preis (netto)",
        priceValidation: "Muss mindestens 1 betragen",
        requiredAttachments: "Notwendige Anhänge",
        valuationPrice: "Preis für Wertgutachten",
        netTotalPrice: "Zwischensumme",
        grossTotalPrice: "Gesamtsumme",
        vatPrice: "Mehrwertsteuer (19%)",
        serviceFee: {
            label: "Bearbeitungsgebühr",
            tooltip:
                "Für die Nutzung der WVW Plattform wird derzeit keine Gebühr erhoben.",
        },
        sampleAttachments: "Beispiele",
        submit: "Abgeben",
        status: offerStatusTranslations.de,
        statusWithEnquiry: offerWithEnquiryStatusTranslatins.de,
        statusLabel: "Status:",
        statusContent: {
            VALUER: {
                ACCEPTED:
                    "Der Kunde hat Ihr Angebot angenommen. Geschäftsfall {{caseId}} wurde erzeugt.",
                PENDING: "Ihr Angebot liegt dem Kunden vor.",
                REJECTED:
                    "Der Kunde hat Ihr Angebot leider abgelehnt. Sie können ein neues Angebot stellen.",
                WITHDRAWN:
                    "Sie haben Ihr Angebot für diese Anfrage zurückgezogen. Sie können ein neues Angebot stellen.",
            },
            CLIENT: {
                ACCEPTED:
                    "Sie haben dieses Angebot angenommen. Geschäftsfall {{caseId}} wurde erzeugt.",
                PENDING: "Das Angebot ist unter Prüfung.",
                REJECTED: "Sie haben dieses Angebot abgelehnt.",
                WITHDRAWN: "Der Gutachter hat dieses Angebot zurückgezogen.",
            },
        },
        submittedAt: "Eingereicht",
        timeline: "Arbeitszeit",
    },
    en: {
        ACTIVITIES: {
            accept: {
                NAME: "Accept",
                confirmationTitle: "Offer acceptation",
                attachedFiles: {
                    label: "Attached Documents",
                    confirmation:
                        "I have read the attached documents and accept the valuer's terms and conditions expressed therein.",
                },
                requiredConfirmations: {
                    label: "I hereby confirm that",
                    confirmation:
                        "I have read and accept the WVW platform's <0>terms and conditions</0> and <1>privacy</1> statement.",
                },
                disclaimer:
                    "You see this offer as an admin of {{company}}. Only user {{user}} (owner) can accept or reject it.",
                legalNote: {
                    label: "Legal Note",
                    description:
                        "Acceptance of the offer does not constitute a contract. A corresponding contract must be concluded separately with the valuer. If no contractual relationship is established after acceptance of the offer and the valuer has not provided any services, the WVW platform will reject any advance payment.",
                    payment:
                        "Payment will only be made once the valuation report is received.",
                    confirmation:
                        "I have read and accept the above legal note.",
                },
            },
            reject: {
                NAME: "Reject",
                confirmationTitle: "Offer rejection",
                confirmationBlurb:
                    "Are you sure you want to reject this offer? This action cannot be undone.",
            },
            rejectAll: {
                NAME: "Reject",
                confirmationBlurb:
                    "Reject all these offers? This action cannot be undone.",
            },
            withdraw: {
                NAME: "Withdraw",
                confirmationTitle: "Withdraw offer",
                confirmationBlurb:
                    "Withdraw this offer? This action cannot be undone.",
            },
        },
        awaitingOffers: {
            waitingForOffers: "Waiting for offers...",
            waitingForOffersBlurb1:
                "When any offers are submitted by the valuers you will see them here.",
            waitingForOffersBlurb2:
                "We will also notify you by email and notification system.",
        },
        availability: "Earliest Availability",
        columns: offerColumnTranslations.en,
        comments: "Message",
        commentsPlaceholder: "Optional message to the client",
        creationDisclaimer:
            "By submitting an offer you agree with our <0>Terms & Conditions</0> and the <1>Privay Policy</1>.",
        deleteUploadedAttachment: "Delete",
        deliveryDate: "Delivery Date",
        deliveryDateWarningTooltip:
            "This value exceeds the requested delivery date.",
        marketingAttachments: "Marketing",
        nameAlreadyInUse: "A file with name '{{ name }} is already present",
        newOffer: "New Offer",
        noAttachmentsPlaceholder: "No attachments",
        noCommentsPlaceholder: "[No message]",
        offers: "Offers",
        offerId: "Offer {{id}}",
        offerStatus: "Offer Status",
        otherAttachments: "Other Attachments",
        openCase: "Open Case",
        piAgreement: {
            label: "Special PI Aggreement",
            tooltip: "Valuation company offers special PI agreements",
        },
        piCertificateAttachments: "PI",
        piCoverAbsolute: "PI Cover [€]",
        piCoverAbsoluteTooltip: "Absolute coverage per valuation object",
        piCoverAbsoluteWarningTooltip:
            "This value is less than the requested PI cover.",
        piCoverPercentage: "PI Cover [%]",
        piCoverPercentageTooltip: "Relative coverage per valuation object",
        price: "Price (net)",
        priceDisclaimer:
            "The net price refers to valuation report creation and may be subject to taxation and service fees as applicable.",
        price_net: "Price (net)",
        priceValidation: "Muss be at least 1",
        requiredAttachments: "Required Attachments",
        valuationPrice: "Price for valuation",
        netTotalPrice: "Net total",
        grossTotalPrice: "Gross total",
        vatPrice: "VAT (19%)",
        serviceFee: {
            label: "Service fee",
            tooltip: "The WVW service is currently free to use.",
        },
        sampleAttachments: "Samples",
        submit: "Submit",
        submittedAt: "Submitted",
        status: offerStatusTranslations.en,
        statusWithEnquiry: offerWithEnquiryStatusTranslatins.en,
        statusLabel: "Status:",
        statusContent: {
            VALUER: {
                ACCEPTED:
                    "Your offer accepted by client. Case {{caseId}} was created.",
                PENDING: "Your offer is pending.",
                REJECTED:
                    "We're sorry, it seems that client decided to reject your offer, you can try again",
                WITHDRAWN:
                    "You have withdrawn your offer for this Enquiry. You can submit new offer.",
            },
            CLIENT: {
                ACCEPTED:
                    "You have accepted this offer. Case {{caseId}} was created.",
                PENDING: "This offer is pending.",
                REJECTED: "You have rejected this offer.",
                WITHDRAWN: "The valuer has withdrawn this offer.",
            },
        },
        timeline: "Delivery Time",
    },
};
