import {
    Box,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { ArrangeVertical } from "iconsax-react";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../hooks/useSmartTranslation";
import { Placeholder } from "../Placeholder";
import SelectArray, { OptionType } from "../SelectArray";
import { CheckedColumn } from "./CheckedColumn";

export type DataTableColumnType<T> = {
    alignment?: string;
    title?: (t: TranslationFunction) => string;
    tooltip?: (t: TranslationFunction) => string;
    dataIndex?: string;
    maxWidth?: string;
    sorter?: (a: T, b: T) => number;
    render?: (data: T, t: TranslationFunction, rowIndex: number) => ReactNode;
};

export type DataTableFilterType<T> = {
    placeholder: string;
    options: OptionType[];
    filterName: string;
    filterFunction: (i: T, v: string | undefined) => boolean;
};

// FIXME how to use sorting from props in state
// FIXME we could probably make a type from string-identifiers on columns and use that in the SortOrder
type SortOrder = {
    column: number | undefined;
    direction?: "default" | "asc" | "desc";
};

type PropTypes<T> = {
    columns: DataTableColumnType<T>[];
    data: T[];
    noOfEntriesToShow?: number;
    noDataText?: string;
    tableWidth?: string;
    tableWithRadius?: boolean;
    initialSorting?: SortOrder;
    filters?: DataTableFilterType<T>[];
    hoverable?: boolean;
    selectedRows?: T[];
    onRowClick?: (item: T) => void;
    onToggleRowChecked?: (item: T, checked: boolean) => void;
    checkedRows?: T[];
    isChecked?: (i: T) => boolean;
    footerContent?: ReactNode | ReactNode[];
    variant?: string;
    stickyColumns?: number;
    fullSectionWidth?: boolean;
};

type TableRowProps<T> = {
    columns: DataTableColumnType<T>[];
    item: T;
    isSelected: boolean | undefined;
    isChecked: boolean | undefined;
    rowIndex: number;
    onToggleRowChecked?: (item: T, checked: boolean) => void;
    onRowClick?: (item: T) => void;
    columnOffsets?: Record<number, number>;
    stickyColumns?: number;
};

const getStickyProps = (
    index: number,
    stickyColumns: number | undefined,
    offsets: Record<number, number> | undefined
) => {
    if (!stickyColumns || index >= stickyColumns || !offsets) return {};

    const isLastColumn = stickyColumns - 1 === index;

    return {
        className: "sticky-cell",
        left: offsets[index],
        ...(isLastColumn
            ? {
                  borderRight: "1px solid var(--chakra-colors-gray-200)",
              }
            : {}),
    };
};

const TableRow = <T extends Record<string, any>>(props: TableRowProps<T>) => {
    const {
        columns,
        item,
        isSelected,
        onRowClick,
        isChecked,
        onToggleRowChecked,
        columnOffsets,
        stickyColumns,
        rowIndex,
    } = props;

    const t = useSmartTranslation();

    let checkedColumn;
    if (onToggleRowChecked && isChecked !== undefined) {
        const onToggle = (newCheckedState: boolean) =>
            onToggleRowChecked(item, newCheckedState);
        checkedColumn = (
            <Td
                key={"checked"}
                onClick={(e) => e.stopPropagation()}
                className="checkbox-cell sticky-cell"
                px={2}
            >
                <Box display="flex">
                    {CheckedColumn.render(isChecked, onToggle)}
                </Box>
            </Td>
        );
    }

    return (
        <Tr
            aria-selected={isSelected}
            onClick={() => onRowClick?.(item)}
            cursor={onRowClick && "pointer"}
        >
            {checkedColumn}
            {columns.map((col, index) => (
                <Td
                    key={index}
                    maxWidth={col.maxWidth}
                    px={2}
                    {...getStickyProps(index, stickyColumns, columnOffsets)}
                >
                    <Box
                        justifyContent={col.alignment}
                        display="flex"
                    >
                        {col.render && col.render(item, t, rowIndex)}

                        {!col.render && item?.[col.dataIndex || ""]}
                    </Box>
                </Td>
            ))}
        </Tr>
    );
};

const DataTable = <T extends Record<string, any>>(props: PropTypes<T>) => {
    const {
        columns,
        data,
        noOfEntriesToShow,
        noDataText,
        tableWidth,
        tableWithRadius = false,
        initialSorting = {
            // FIXME why do I have to put defaults when I have defaultProps
            column: undefined,
            direction: "default",
        },
        filters,
        hoverable,
        selectedRows,
        onRowClick,
        onToggleRowChecked,
        checkedRows,
        isChecked,
        footerContent,
        variant,
        stickyColumns,
        fullSectionWidth,
    } = props;

    const t = useSmartTranslation();

    const [sortedData, setSortedData] = useState<T[]>(data);

    const [sort, setSort] = useState<SortOrder>(initialSorting);

    const [filterState, setFilterState] = useState<{
        [index: string]: string | undefined;
    }>(
        filters?.reduce(
            (res, i) => ({ ...res, [i.filterName]: undefined }),
            {}
        ) || {}
    );

    const [columnOffsets, setColumnOffsets] = useState<Record<number, number>>(
        {}
    );
    const columnRefs = useRef<Record<number, HTMLTableCellElement | null>>({});

    useEffect(() => {
        if (!stickyColumns || !columns) return;

        const offsets: Record<number, number> = {};
        let cumulateOffset = 0;

        for (let i = 0; i < stickyColumns; i++) {
            const currentColumn = columnRefs.current[i];
            if (currentColumn) {
                offsets[i] = cumulateOffset;
                cumulateOffset += currentColumn.offsetWidth;
            }
        }

        setColumnOffsets(offsets);
    }, [stickyColumns, columns]);

    useEffect(() => {
        const { column, direction } = sort;

        let newData = [...data];

        if (column !== undefined && columns[column] && columns[column].sorter) {
            const { sorter } = columns[column];
            newData = [...data].sort(sorter);

            if (direction === "desc") {
                newData = newData.reverse();
            }
        }

        filters?.forEach((i) => {
            const filterValue = filterState?.[i.filterName];
            if (filterValue) {
                newData = newData.filter((row) =>
                    i.filterFunction(row, filterValue)
                );
            }
        });

        setSortedData([...newData]);
    }, [data, sort, filterState]);

    if (variant && (hoverable || onRowClick)) {
        throw new Error("API doesn't support this combination");
    }

    const sortColumn = (index: number) => {
        let sortDirection = sort.direction;

        if (sort.column !== index) {
            setSort({
                column: index,
                direction: "asc",
            });

            return;
        }

        switch (sortDirection) {
            case "asc":
                sortDirection = "desc";
                break;
            case "desc":
                sortDirection = "default";
                break;
            default:
                sortDirection = "asc";
                break;
        }

        setSort({
            column: index,
            direction: sortDirection,
        });
    };

    if (!sortedData.length) {
        return <Placeholder noDataText={noDataText} />;
    }

    let checkedColumn;
    if (onToggleRowChecked && checkedRows) {
        checkedColumn = (
            <Th
                key={"checked"}
                className="checkbox-cell sticky-cell"
                px={2}
            >
                <Box
                    alignItems="center"
                    display="flex"
                >
                    {CheckedColumn.title(checkedRows.length, data.length)}
                </Box>
            </Th>
        );
    }

    const tableWrapperProps = fullSectionWidth
        ? {
              ml: "-1em",
              mr: "-1em",
              minW: "calc(100% + 2em)",
              maxW: "calc(100% + 2em)",
          }
        : { w: "100%" };

    const footer = footerContent && (
        <HStack
            width={"100%"}
            padding={".75em 1em 0"}
        >
            {footerContent}
        </HStack>
    );

    return (
        <>
            {filters && (
                <SelectArray
                    selectList={filters.map((i) => ({
                        placeholder: i.placeholder,
                        options: i.options,
                        value: filterState[i.filterName],
                        onChange: (selectedValue) =>
                            setFilterState({
                                ...filterState,
                                [i.filterName]: selectedValue,
                            }),
                    }))}
                />
            )}

            <VStack
                align={"start"}
                {...tableWrapperProps}
            >
                <TableContainer
                    maxWidth={tableWidth}
                    w="100%"
                >
                    <Table
                        variant={
                            hoverable || onRowClick
                                ? "hoverable"
                                : variant
                                ? variant
                                : "default"
                        }
                        sx={{
                            borderCollapse: "separate",
                        }}
                        {...(tableWithRadius && {
                            borderRadius: 8,
                            overflow: "hidden",
                        })}
                    >
                        <Thead>
                            <Tr bg={"gray.50"}>
                                {checkedColumn}
                                {columns.map((col, index) => (
                                    <Th
                                        key={index}
                                        maxW={col.maxWidth}
                                        p={2}
                                        ref={(el) =>
                                            (columnRefs.current[index] = el)
                                        }
                                        {...getStickyProps(
                                            index,
                                            stickyColumns,
                                            columnOffsets
                                        )}
                                    >
                                        <Box
                                            alignItems="center"
                                            justifyContent={col.alignment}
                                            display="flex"
                                        >
                                            <Text
                                                _hover={{
                                                    cursor: col.sorter
                                                        ? "pointer"
                                                        : "normal",
                                                }}
                                                onClick={() =>
                                                    sortColumn(index)
                                                }
                                                variant="secondary"
                                                fontSize={"xs"}
                                            >
                                                {col.title &&
                                                    (col.tooltip ? (
                                                        <Tooltip
                                                            hasArrow
                                                            label={col.tooltip(
                                                                t
                                                            )}
                                                        >
                                                            {col.title(t)}
                                                        </Tooltip>
                                                    ) : (
                                                        col.title(t)
                                                    ))}
                                                {!col.title && ""}
                                            </Text>

                                            {col.sorter && (
                                                <IconButton
                                                    aria-label="Sort"
                                                    icon={
                                                        <ArrangeVertical size="18" />
                                                    }
                                                    color="blue.500"
                                                    size="xs"
                                                    variant="ghost"
                                                    _hover={{
                                                        color: "wvwGrey80",
                                                    }}
                                                    onClick={() =>
                                                        sortColumn(index)
                                                    }
                                                />
                                            )}
                                        </Box>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {sortedData
                                ?.slice(0, noOfEntriesToShow)
                                .map((i, index) => (
                                    <TableRow
                                        columns={columns}
                                        item={i}
                                        key={index}
                                        isSelected={selectedRows?.includes(i)}
                                        onRowClick={onRowClick}
                                        onToggleRowChecked={onToggleRowChecked}
                                        isChecked={isChecked?.(i)}
                                        columnOffsets={columnOffsets}
                                        stickyColumns={stickyColumns}
                                        rowIndex={index}
                                    />
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                {footer}
            </VStack>
        </>
    );
};

DataTable.defaultProps = {
    noDataText: undefined,
    tableWidth: "100%",
    initialSorting: {
        column: undefined,
        direction: "default",
    },
    filters: undefined,
    hoverable: false,
    selectedRows: undefined,
    variant: undefined,
};

export default DataTable;
