import { CheckIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Divider,
    Heading,
    HStack,
    Icon,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Trans } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import { Offer } from "./Offer";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { OfferType } from "../../../../types";
import { OfferRespondDto } from "../../../../utils/api/dto";
import { offerRespond } from "../../../../utils/api/endpoints";
import useMutationHook from "../../../../utils/api/hooks/useMutationHook";
import ModalDynamic from "../../common/components/ModalDynamic";
import { LegalDocument, useLegalModal } from "../../common/hooks/useLegalModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { AttachmentList } from "./offer-details/attachments/OfferAttachment";
import { calculateGrossPrice, calculateVat } from "./offerFunctions";

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
    refresh?: () => void;
    offer: OfferType;
};

const useAcceptOffer = (params: ParamsType) => {
    const {
        offer: { id, price, requiredAttachments },
        onSuccess,
        onError,
        refresh,
    } = params;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [checkedConfirmations, setCheckedConfirmations] = useState<
        (string | number)[]
    >([]);

    const { update: acceptOffer } = useMutationHook({
        mutationFunction: (dto: OfferRespondDto) => offerRespond(dto),
        refreshes: refresh ? [refresh] : [],
        onSuccess,
        onError,
    });

    const { legalModal, openLegalModal, setLegalType } = useLegalModal();

    const onConfirmAcceptation = () => {
        onClose();
        acceptOffer({
            offerId: id,
            status: "ACCEPTED",
        });
    };

    const allConfirmationsChecked = checkedConfirmations.length === 3;

    // render

    const priceInfo = <PriceInfo price={price} />;

    const legalNote = <LegalNote />;

    const attachedFiles = requiredAttachments.length > 0 && (
        <AttachmentList attachments={requiredAttachments} />
    );

    const requiredConfirmations = (
        <RequiredConfirmations
            checkedConfirmations={checkedConfirmations}
            legalModal={legalModal}
            setLegalType={setLegalType}
            openLegalModal={openLegalModal}
            setCheckedConfirmations={setCheckedConfirmations}
        />
    );

    const buttons = (
        <HStack spacing={4}>
            <Button
                leftIcon={<CheckIcon />}
                variant={"solid"}
                colorScheme={"green"}
                width={"100%"}
                isDisabled={!allConfirmationsChecked}
                onClick={onConfirmAcceptation}
            >
                {t("domain.offer.ACTIVITIES.accept.NAME")}
            </Button>

            <Button
                width={"100%"}
                variant={"default"}
                onClick={onClose}
            >
                {t("common.button.cancel")}
            </Button>
        </HStack>
    );

    const acceptationModal = (
        <ModalDynamic
            size="3xl"
            isOpen={isOpen}
            header={t("domain.offer.ACTIVITIES.accept.confirmationTitle")}
            onClose={onClose}
        >
            <VStack
                align={"stretch"}
                spacing={4}
            >
                {priceInfo}
                {legalNote}
                {attachedFiles}
                {requiredConfirmations}
                {buttons}
            </VStack>
        </ModalDynamic>
    );

    return {
        acceptOffer: onOpen,
        acceptationModal,
    };
};

type RowInfoProps = {
    title?: string;
    children: React.ReactNode | React.ReactNode[];
};

const RowInfo = (props: RowInfoProps) => {
    const { title, children } = props;

    return (
        <VStack align={"stretch"}>
            <Heading
                size={"sm"}
                color={"blue.700"}
            >
                {title}
            </Heading>
            {children}
        </VStack>
    );
};

type PriceInfoProps = {
    price: number;
};

const PriceInfo = (props: PriceInfoProps) => {
    const { price } = props;

    const t = useSmartTranslation();

    return (
        <RowInfo>
            <Box
                bg={"gray.50"}
                borderRadius={"8"}
                padding={"1rem"}
            >
                <Offer.details.OfferProperty
                    label={t("domain.offer.valuationPrice")}
                    value={`€ ${delimitNumber(price)}`}
                />
                <Offer.details.OfferProperty
                    label={t("domain.offer.serviceFee.label")}
                    tooltip={t("domain.offer.serviceFee.tooltip")}
                    value={"€ 0"}
                />
                <Divider
                    orientation={"horizontal"}
                    variant={"dashed"}
                    background={"gray.500"}
                    my={2}
                />
                <Offer.details.OfferProperty
                    label={t("domain.offer.netTotalPrice")}
                    value={`€ ${delimitNumber(price)}`}
                />
                <Offer.details.OfferProperty
                    label={t("domain.offer.vatPrice")}
                    value={`€ ${calculateVat(price)}`}
                />
                <Offer.details.OfferProperty
                    label={t("domain.offer.grossTotalPrice")}
                    value={`€ ${calculateGrossPrice(price)}`}
                    color={"green.500"}
                    valueSize={"lg"}
                />
            </Box>
        </RowInfo>
    );
};

const LegalNote = () => {
    const t = useSmartTranslation();

    return (
        <RowInfo title={t("domain.offer.ACTIVITIES.accept.legalNote.label")}>
            <Box
                bg={"gray.50"}
                borderRadius={"8"}
                padding={"1rem"}
            >
                <HStack
                    spacing={4}
                    align={"start"}
                >
                    <Icon
                        as={AiOutlineWarning}
                        boxSize={"1.5rem"}
                        color={"gray.500"}
                    />
                    <VStack
                        align={"stretch"}
                        spacing={0}
                        color={"gray.700"}
                    >
                        <Text fontSize={"sm"}>
                            {t(
                                "domain.offer.ACTIVITIES.accept.legalNote.description"
                            )}
                        </Text>
                        <Text fontSize={"sm"}>
                            {t(
                                "domain.offer.ACTIVITIES.accept.legalNote.payment"
                            )}
                        </Text>
                    </VStack>
                </HStack>
            </Box>
        </RowInfo>
    );
};

type RequiredConfirmationsProps = {
    checkedConfirmations: (string | number)[];
    legalModal: React.ReactNode;
    setLegalType: (type: LegalDocument) => void;
    openLegalModal: () => void;
    setCheckedConfirmations: (value: Array<string | number>) => void;
};

const RequiredConfirmations = (props: RequiredConfirmationsProps) => {
    const {
        checkedConfirmations,
        legalModal,
        setCheckedConfirmations,
        setLegalType,
        openLegalModal,
    } = props;

    const t = useSmartTranslation();

    const checkboxStyles = {
        spacing: 3,
        padding: ".5rem 1rem",
        transition: ".25s",
        _hover: {
            bg: "blue.50",
        },
    };

    return (
        <RowInfo
            title={t(
                "domain.offer.ACTIVITIES.accept.requiredConfirmations.label"
            )}
        >
            <CheckboxGroup onChange={setCheckedConfirmations}>
                <VStack
                    spacing={".1rem"}
                    align={"stretch"}
                    borderRadius={"8"}
                    overflow={"hidden"}
                >
                    <Checkbox
                        value={"require_1"}
                        {...checkboxStyles}
                        bg={
                            checkedConfirmations.includes("require_1")
                                ? "blue.50"
                                : "gray.50"
                        }
                    >
                        <Text fontSize={"sm"}>
                            {t(
                                "domain.offer.ACTIVITIES.accept.legalNote.confirmation"
                            )}
                        </Text>
                    </Checkbox>
                    <Checkbox
                        value={"require_2"}
                        {...checkboxStyles}
                        bg={
                            checkedConfirmations.includes("require_2")
                                ? "blue.50"
                                : "gray.50"
                        }
                    >
                        <Text fontSize={"sm"}>
                            <Trans
                                ns="v2"
                                i18nKey="domain.offer.ACTIVITIES.accept.requiredConfirmations.confirmation"
                                components={[
                                    <Button
                                        variant="link"
                                        color={"blue.500"}
                                        fontWeight={"normal"}
                                        size={"sm"}
                                        onClick={() => {
                                            setLegalType("terms");
                                            openLegalModal();
                                        }}
                                    />,
                                    <Button
                                        variant="link"
                                        color={"blue.500"}
                                        fontWeight={"normal"}
                                        size={"sm"}
                                        onClick={() => {
                                            setLegalType("privacy");
                                            openLegalModal();
                                        }}
                                    />,
                                ]}
                            />
                        </Text>
                    </Checkbox>
                    <Checkbox
                        value={"require_3"}
                        {...checkboxStyles}
                        bg={
                            checkedConfirmations.includes("require_3")
                                ? "blue.50"
                                : "gray.50"
                        }
                    >
                        <Text fontSize={"sm"}>
                            {t(
                                "domain.offer.ACTIVITIES.accept.attachedFiles.confirmation"
                            )}
                        </Text>
                    </Checkbox>
                </VStack>
            </CheckboxGroup>
            {legalModal}
        </RowInfo>
    );
};

export default useAcceptOffer;
