import { useNavigate } from "react-router-dom";
import { OfferType } from "../../../../types";
import DataTable from "../../common/components/data-table/DataTable";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { offerColumns } from "./columns/OfferColumns";

type OfferHistoryTable = {
    offers: OfferType[];
};

const OfferHistoryTable = (props: OfferHistoryTable) => {
    const { offers } = props;
    const t = useSmartTranslation();

    const navigate = useNavigate();

    return (
        <>
            <DataTable
                fullSectionWidth
                data={offers}
                columns={[
                    offerColumns.valuerProfile,
                    offerColumns.price,
                    offerColumns.availability,
                    offerColumns.deliveryAndTimeline,
                    offerColumns.piCoverValue,
                    offerColumns.piCoverPercentage,
                    offerColumns.specialPi,
                    offerColumns.view,
                ]}
                noDataText={t(
                    "screens.platform.client.enquiryDetails.offerHistory.noExistingOfferHistory"
                )}
                stickyColumns={1}
                onRowClick={(offer) => {
                    navigate(
                        `/dashboard/enquiry/${offer.enquiryId}/offer/${offer.id}`
                    );
                }}
            />
        </>
    );
};

export { OfferHistoryTable };
