export const translations = {
    de: {
        admin: "Admin",
        at: " bei ",
        client: "Kunde",
        companyAdmin: "Firmen-Administration",
        institutionalClient: "Institutioneller Kunde",
        obscuredClient: "Anonymisierter Kunde",
        privateClient: "Privatkunde",
        previewDrawerTitle: "Ansicht des Kunden",
        valuer: "Gutachter",
        valuers: "Gutachter",
    },
    en: {
        admin: "Admin",
        at: " at ",
        client: "Client",
        companyAdmin: "Company Admin",
        institutionalClient: "Institutional Client",
        obscuredClient: "Obscured Client",
        privateClient: "Private End Customer",
        previewDrawerTitle: "Client Information",
        valuer: "valuer",
        valuers: "valuers",
    },
};
