import { EditIcon } from "@chakra-ui/icons";
import { Button, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useRole } from "../../../../../common/hooks";
import { EntityType, UserType } from "../../../../../types";
import { useMyEntity } from "../../../../../utils/api/hooks";
import ProfileDetailsEdit from "../../../../../views/dashboard/profile/components/ProfileDetailsEdit";
import useMe from "../../../app/useMeV2";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import PublicUserProfilePictureWithEntityLogo from "../../../common/components/PublicUserProfilePictureWithEntityLogo";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { Section } from "../../../layout";
import ProfileContactDetails from "./ProfileContactDetails";
import ProfileOfficeDetails from "./ProfileOfficeDetails";
import ProfileOperationalArea from "./ProfileOperationalArea";
import ProfilePropertyTypes from "./ProfilePropertyTypes";
import ProfileValuationOccasions from "./ProfileValuationOccasions";
import ProfileValuationStandards from "./ProfileValuationStandards";
import ProfileValuationTypes from "./ProfileValuationTypes";

const ProfileDetails = () => {
    const t = useSmartTranslation();

    const { userIsValuer, userIsCompany, roleLoading } = useRole();

    const [editing, setEditing] = useState(false);

    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    if (
        !user ||
        userLoading ||
        roleLoading ||
        entityLoading ||
        myCertificatesIsLoading
    ) {
        return <LoadingSpinner />;
    }

    if (editing) {
        return <ProfileDetailsEdit openEditor={() => setEditing(false)} />;
    }

    // render

    const contactDetails = (
        <Section
            title={t("profile.heading.contactDetails")}
            content={
                <>
                    <HStack
                        mb={4}
                        justify={"space-between"}
                    >
                        <UserDetails
                            entity={entity}
                            user={user}
                            userIsCompany={userIsCompany}
                        />
                        <Button
                            variant="outline"
                            colorScheme="blue"
                            leftIcon={<EditIcon />}
                            onClick={() => setEditing(true)}
                        >
                            {t("common.button.edit")}
                        </Button>
                    </HStack>
                    <ProfileContactDetails user={user} />
                </>
            }
        />
    );

    const officeDetails = (
        <Section
            title={t("profile.heading.officeLocation")}
            content={
                <ProfileOfficeDetails
                    user={user}
                    userIsValuer={userIsValuer}
                />
            }
        />
    );

    const operationalArea = (
        <Section
            title={t("profile.heading.operationalArea")}
            content={<ProfileOperationalArea user={user} />}
        />
    );

    const valuationStandards = (
        <Section
            title={t("profile.heading.valuationStandards")}
            content={
                <ProfileValuationStandards
                    user={user}
                    certificates={myCertificates}
                />
            }
        />
    );

    const valuationTypes = (
        <Section
            title={t("profile.heading.valuationScope")}
            content={<ProfileValuationTypes user={user} />}
        />
    );

    const valuationOccasions = (
        <Section
            title={t("profile.heading.valuationOccasions")}
            content={<ProfileValuationOccasions user={user} />}
        />
    );

    const propertyTypes = (
        <Section
            title={t("profile.heading.propertyTypes")}
            content={<ProfilePropertyTypes user={user} />}
        />
    );

    const doesValuations = userIsValuer && user.doesValuations && (
        <>
            {operationalArea}
            {valuationStandards}
            {valuationTypes}
            {valuationOccasions}
            {propertyTypes}
        </>
    );

    return (
        <VStack gap={4}>
            {contactDetails}
            {officeDetails}
            {doesValuations}
        </VStack>
    );
};

type UserDetailsProps = {
    user: UserType;
    entity: EntityType;
    userIsCompany: boolean;
};

const UserDetails = (props: UserDetailsProps) => {
    const {
        user: { id: userId, firstName, lastName, orgPosition },
        entity: { id: entityId, name: companyName },
        userIsCompany,
    } = props;

    const t = useSmartTranslation();

    return (
        <HStack spacing={4}>
            <PublicUserProfilePictureWithEntityLogo
                entityId={entityId}
                userId={userId}
                profileLogoSize={6}
                companyLogoSize={3}
            />
            <VStack
                align="left"
                spacing="0"
            >
                <Heading size={"lg"}>{`${firstName} ${lastName}`}</Heading>

                {userIsCompany && (
                    <Text>
                        {orgPosition &&
                            t("profile.position.at", {
                                position: orgPosition,
                                company: companyName,
                                interpolation: {
                                    escapeValue: false,
                                },
                            })}
                    </Text>
                )}
            </VStack>
        </HStack>
    );
};

export default ProfileDetails;
