import { useContext } from "react";
import { UserContext } from "../useMeV2";
import { routes_unauthorized } from "./unauthorized";
import { routes_unverified } from "./unverified";
import { RoutesUser } from "./user";

export const GetRoutes = () => {
    const user = useContext(UserContext);

    if (!user) return routes.unauthorized;
    if (!user.emailVerified) return routes.unverified;
    return routes.user;
};

const routes = {
    unauthorized: routes_unauthorized,
    unverified: routes_unverified,
    user: <RoutesUser />,
};
