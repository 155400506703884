export const translations = {
    de: {
        properties: "Immobilien",
        propertyDetails: {
            area: "Fläche (innen/a.)",
            buildYear: "Baujahr",
            condition: "Zustand",
            documents: "Zusätzliche Dokumente: {{count}}",
            estimateValue: "Geschätzter Wert",
            propertyType: "Art der Immobilie",
            parking: "Parkplätze",
            // ToDo: Please check if the key below is still used.
            propertyCondition: "Zustand der Immobilie",
            specialFeatures: "Besonderheiten",
            title: "Immobilie",
        },
        propertyPortfolio: {
            title: "Immobilien-Portfolio",
            summary: {
                ammountOfProperties: "Anzahl enthaltener Immobilien",
                avgPropertyArea: "Durchschn. Bruttoinnenfläche",
                biggest: "Größte Fläche",
                cheapest: "Niedrigster Einzelwert",
                estimatePortfolioValue: "Gesamtwert",
                estimatePortfolioValueTooltip: "Vom Kunden geschätzter Immobilienwert",
                mostExpensive: "Höchster Einzelwert",
                noOfProperties: "{{number}} Immobilien",
                smallest: "Kleinste Fläche",
            },
        },
    },
    en: {
        properties: "Properties",
        propertyDetails: {
            area: "Area (int/ext)",
            buildYear: "Build Year",
            condition: "Condition",
            documents: "Supporting documents: {{count}}",
            estimateValue: "Estimated Value",
            parking: "Parking",
            propertyType: "Property Type",
            propertyCondition: "Property Condition",
            specialFeatures: "Special Features",
            title: "Property",
        },
        propertyPortfolio: {
            title: "Property Portfolio",
            summary: {
                ammountOfProperties: "Amount of Properties",
                avgPropertyArea: "Avg. Gross Internal Area",
                biggest: "Biggest",
                cheapest: "Cheapest",
                estimatePortfolioValue: "Total Value",
                estimatePortfolioValueTooltip: "Total property value estimated by client",
                mostExpensive: "Most Expensive",
                noOfProperties: "{{number}} Properties",
                smallest: "Smallest",
            },
        },
    },
};
