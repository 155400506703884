import { EnquiryWithFromUserAndFromEntityType } from "../../../../types";

const includeEntityInUser = (enquiry: EnquiryWithFromUserAndFromEntityType) => {
    const { fromUser, fromEntity } = enquiry;

    return {
        ...enquiry,
        fromUser: {
            ...fromUser,
            entity: fromEntity,
        },
    };
};

export { includeEntityInUser };
