import { useDisclosure } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { EnquiryType } from "../../../../types";
import { SetDormantDto } from "../../../../utils/api/dto";
import { enquirySetDormant } from "../../../../utils/api/endpoints";
import { useAuthedUser } from "../../app/useMeV2";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    enquiry: EnquiryType;
};

const useEnquirySetDormant = (params: ParamsType) => {
    const {
        enquiry: { id, status, dormant },
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const user = useAuthedUser();
    const userIsClient = user.entity.accountType === "CLIENT";

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { mutate: update } = useMutation(
        (dto: SetDormantDto) => enquirySetDormant(dto),
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError,
        }
    );

    const canArchive = userIsClient
        ? dormant ||
          ((status === "ACCEPTED" || status === "CANCELLED") && !dormant)
        : true;

    const newDormant = !dormant;

    const onConfirmDormant = () => {
        onClose();
        update({
            dormant: newDormant,
            enquiryId: id,
        });
    };

    let activity:
        | "domain.enquiry.ACTIVITIES.archive"
        | "domain.enquiry.ACTIVITIES.unarchive" = `domain.enquiry.ACTIVITIES.${
        newDormant ? "archive" : "unarchive"
    }`;

    const label = t(`${activity}.NAME`);

    const confirmationModal = (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirmation={onConfirmDormant}
            onClose={onClose}
            title={label}
            blurb={t(`${activity}.confirmationBlurb`)}
            confirmationButtonLabel={t(`${activity}.NAME`)}
            confirmationButtonVariant={"neutral"}
        />
    );

    return {
        canExecute: canArchive,
        update: onOpen,
        label,
        confirmationModal,
    };
};

export default useEnquirySetDormant;
