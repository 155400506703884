export const profileTranslations = {
    de: {
        accountdetails: "🇩🇪Details zum Nutzerkonto1️⃣3️⃣",
        contactDetails: "Kontaktdaten",
        city: "🇩🇪Stadt1️⃣3️⃣",
        close: "🇩🇪Schließen1️⃣3️⃣",
        company: "🇩🇪Unternehmen1️⃣3️⃣",
        country: "🇩🇪Land1️⃣3️⃣",
        completeProfile: "Profil vervollständigen",
        done: "🇩🇪Fertig1️⃣3️⃣",
        edit: "Profildetails bearbeiten",
        emailAddress: "🇩🇪E-Mail Adresse1️⃣3️⃣",
        expertise: "🇩🇪Fachgebiete1️⃣3️⃣",
        location: "🇩🇪Standort1️⃣3️⃣",
        name: "Name",
        phone: "🇩🇪Telefon1️⃣3️⃣",
        phonenumber: "🇩🇪Telefonnummer1️⃣3️⃣",
        street: "🇩🇪Straße1️⃣3️⃣",
        uploadcertificates: "Bescheinigungen Hochladen",
        valuerListError:
            "🇩🇪Es ist ein Fehler beim Laden Ihrer Liste von Gutachtern aufgetreten1️⃣3️⃣",
        whichvaluation: "🇩🇪Welche Bewertung können Sie anbieten?1️⃣3️⃣",
        doesValuations: "Fertigen Sie Wertgutachten an?",
        experience: {
            lessThanOne: "Weniger als 1 Jahr",
            oneToThree: "1-3 Jahren",
            threeToFive: "3-5 Jahren",
            moreThanFive: "5+ Jahren",
        },
        heading: {
            contactDetails: "Ihre Kontaktdaten",
            membershipsAndQualifications:
                "Mitgliedschaften und Qualifikationen",
            officeLocation: "Arbeitsstandort",
            otherQualifications: "Weitere Qualifikationen",
            otherValuationStandards: "Andere Bewertungsstandards",
            operationalArea: "Einsatzgebiet",
            otherValuationOccasions: "Andere Bewertungsanlässe",
            otherValuationScopes: "Sondergutachten",
            personalQualifications: "Persönliche Qualifikationen",
            propertyTypes: "Immobilienarten",
            specificValuationScopes: "Spezielle Bewertungsumfänge",
            valuationOccasions: "Bewertungsanlässe",
            valuationStandards: "Bewertungsstandards",
            valuationScope: "Bewertungsumfänge",
        },
        profileSectionLink: {
            profile: "Profil",
            sharedDocs: "Geteilte Dokumente",
            team: "Team",
            templates: "Vorlagen",
        },
        profession: "Beruf",
        position: {
            at: "{{position}} bei {{company}}",
        },
        profileDetails: {
            address: "Adresse",
            city: "Stadt",
            confirmPassword: "Passwort bestätigen",
            country: "Land",
            doesValuations: "Fertigen Sie Wertgutachten an?",
            dialCode: "Vorwahl",
            email: "E-Mail",
            exactLocation: "Standort bestätigen",
            exactLocationSubtitle:
                'Markieren Sie auf der Karte den Standort Ihres Arbeitsplatzes. Klicken Sie "$t(button.confirmLocation, { "ns":"common"})", wenn die rote Markierung den Ort korrekt anzeigt.',
            faxNumber: "Faxnummer",
            firstName: "Vorname",
            invoiceAddress: "Rechnungsadresse",
            lastName: "Nachname",
            location: "Adresse",
            mobileNumber: "Mobilnummer",
            message: "Nachricht",
            name: "Name",
            noPhone: "Keine Telefonnummer angegeben.",
            officeFaxNumber: "Büro-Faxnummer",
            officeTelNumber: "Büro-Telefonnummer",
            otherInfo: "Weitere Informationen",
            phone: "Telefonnummer (gesch.)",
            postalCode: "Postleitzahl",
            profession: "Beruf",
            professionPlaceholder:
                "d. h. Administrator, Gutachter, Privat usw.",
            profilePicture: "Profilbild",
            roleInCompany: "Rolle im Unternehmen",
            roleInCompanyPlaceholder: "d.h. CEO, CFO, Gutachter usw.",
            sectionTitle: "Profil",
            street: "Straße",
            telNumber: "Telefonnummer",
            town: "Stadt",
            valuerMaxOperationDistance:
                "Maximale Wegstrecke zum Bewertungsobjekt",
            wizardTitle: "Erstellen Sie Ihr Profil",
        },
        profileCompletionWizard: {
            steps: {
                personal: "Persönliche Daten",
                company: "Firmendetails",
                qualifications: "Persönliche Qualifikationen",
                valuationOffer: "Angebotene Wertgutachten",
                extraDocs: "Zusätzliche Dokumente",
            },
            landing: {
                title: "Profil vervollständigen, um das volle Potenzial freizuschalten.",
                subtitle:
                    "Schalten Sie das volle Potenzial der WVW-Plattform frei, indem Sie Ihr Profil in 2-3 Minuten aktualisieren.",
                keyBenefits: {
                    title: "Hauptvorteile",
                    benefits: {
                        improvedMatchmaking:
                            "Verbesserte Vermittlung mit den richtigen Kunden durch die Nutzung detaillierter geschäftlicher Kontextinformationen und Qualifikationen.",
                        enhancedTransparency:
                            "Größere Transparenz zieht neue Kunden an, die Ihre Dienste bisher nicht genutzt haben.",
                        increasedVisibility:
                            "Detaillierte Unternehmensinformationen können die Entscheidung von Kunden beim Vergleich von Wertgutachtern positiv beeinflussen.",
                    },
                },
                securityDisclaimer:
                    "Bitte beachten Sie, dass Ihre Angaben und hochgeladenen Dokumente umfassend gesichert sind. Erfahren Sie mehr über unsere Sicherheitsmaßnahmen in der",
                completeProfile: "Profil vervollständigen",
                stepsTitle: "Profilvervollständigungsprozess",
            },
            recommended: "Empfohlen",
            useCompanyDetails: "Verwenden Sie Firmendaten",
            extraDocs: {
                valuationSamples: {
                    title: "Bewertungsbeispiele",
                    subtitle:
                        "Bitte achten Sie darauf, in den Beispiel-Dokumenten keine Zugangsdaten oder andere sensible Daten Ihrer Kunden preiszugeben!",
                },
                marketingAwards: {
                    title: "Marketingmaterialien & Auszeichnungen",
                    subtitle: "Teilen Sie Werbematerial mit Ihren Kunden.",
                },
            },
        },
        membershipsAndQualifications: {
            sectionTitle: "Qualifikationen",
            certificate_one: "Bescheinigung",
            certificate_other: "Bescheinigungen",
            noCertificate: "Keine Bescheinigung",
            memberships: "Mitgliedschaften",
            otherMemberships: "Andere Mitgliedschaften",
            otherMembershipCertificates: "Andere Mitgliedschaftsnachweise",
            qualifications: "Qualifikationen",
            otherQualifications: "Andere Qualifikationen",
            otherQualificationCertificates: "Andere Qualifikationsnachweise",
            edit: {
                sectionTitle: "Mitgliedschaften und Qualifikationen bearbeiten",
                addQualification: "Qualifikation hinzufügen",
                addMembership: "Mitgliedschaft hinzufügen",
                addOtherValuationOccasion:
                    "Fügen Sie einen weiteren Bewertungsanlass hinzu",
                addValuationStandard: "Bewertungsstandard hinzufügen",
                addValuationScope: "Bewertungsumfang hinzufügen",
            },
        },
        subHeading: {
            membershipsAndQualifications:
                "Wählen Sie diejenigen Standards aus, entsprechend derer Sie arbeiten und Wertgutachten erstellen. Laden Sie entsprechende Zertifikate und Nachweise hoch.",
            officeDetails:
                "Die Hauptadresse des Ortes, an dem Sie Ihr Unternehmen betreiben, z. Büro adresse.",
            otherQualifications:
                "Geben Sie eine Beschreibung oder den Namen anderer Qualifikationen an.",
            otherValuationStandards:
                "Geben Sie eine Beschreibung oder einen Namen für andere Bewertungsstandards an.",
            operationalArea:
                "Maximaler Anfahrtsweg von Ihrem Arbeitsort zu einem Bewertungsobjekt, für das Sie Aufträge annehmen möchten?",
            otherValuationScopes:
                "Benennen oder umschreiben Sie Sonderumfänge, die Sie anbieten.",
            propertyTypes:
                "Wählen Sie alle Immobilientypen aus, die Sie bewerten können.",
            specificValuationScopes:
                "Wählen Sie optional die von Ihnen betreuten Sonderbewertungsumfänge aus.",
            valuationStandards:
                "Wählen Sie die Standards aus, entsprechend derer Sie arbeiten und Wertgutachten erstellen. Laden Sie entsprechende Zertifikate und Nachweise hoch.",
            valuationScopes:
                "Wählen Sie mindestens einen Bewertungsumfang aus, den Sie bedienen.",
            workLocation:
                "Die Adresse des Ortes an dem Sie regelmäßig geschäftlich tätig sind, z.B. Ihre Büro-Adresse",
        },
        valuationCompetence: {
            sectionTitle: "Bewertungskompetenz",
            valuationStandards: "Bewertungsstandards",
            otherValuationStandards: "Andere Bewertungsstandards",
            propertyTypes: "Immobilienarten",
            valuationScope: "Bewertungsumfänge",
            specificValuationScopes: "Spezielle Bewertungsumfänge",
            otherValuationScopes: "Andere Bewertungsumfänge",
            valuationOccasions: "Bewertungsanlässe",
            otherValuationOccasions: "Sonstige Bewertungsanlässe",
            edit: {
                sectionTitle: "Bewertungskompetenz bearbeiten",
            },
        },
        teamDetails: {
            sectionTitle: "Team",
            blurb: "Bleiben Sie mit Ihren Team-Kollegen in Verbindung.",
        },
        sharedDocuments: {
            sectionTitle: "Dokumente",
            blurb: "Ansicht, Upload und Bearbeitung der geteilten Dokumente Ihres Unternehmens, z.B. Standardvertrag, AGB, usw.",
            document: {
                name: "Name",
                size: "Dateigröße",
                uploadDate: "Upload Datum",
            },
            noDocs: "Keine Dokumente hinterlegt",
            marketing: "Verkaufsförderung",
            offer: "Notwendige Vertragsunterlagen",
            piDocs: 'Nachweisdokumente zur $t(valuationCapabilities.piCover.piCover,  { "ns": "account" })',
            valuationSamples:
                'Beispiel-$t(valuation,  { "count": 2, "ns": "common" })',
        },
        fieldNotProvided: {
            contactPerson: "Ansprechpartner nicht angegeben",
            email: 'Keine $t(Email,  { "ns": "common" }) angegeben',
            faxNumber: "Keine Faxnummer angegeben",
            location: "Kein Standort angegeben",
            mobileNumber: "Keine Mobilnummer angegeben",
            numberOfPartners: "Keine Teilhaber angegeben",
            officeFaxNumber: "Keine Büro-Faxnummer angegeben",
            officeTelNumber: "Keine Büro-Telefonnummer angegeben",
            otherSpecialReports: "Keine sonstigen Sondergutachten angegeben",
            phoneNumber: "Keine Telefonnummer angegeben",
            propertyTypes: "Keine Immobilienkategorien angegeben",
            roleInCompany: "Keine Rolle im Unternehmen angegeben",
            specialReports: "Keine Sondergutachten angegeben",
            telNumber: "Keine Telefonnummer angegeben",
            valuerMaxOperationDistance:
                "Keine maximale Wegstrecke für Begutachtungen angegeben",
            website: "Keine Webseite angegeben",
            yearsOfExperience: "Keine Erfahrung (in Jahren) angegeben",
        },
    },
    en: {
        accountdetails: "🇬🇧Account Details1️⃣3️⃣",
        contactDetails: "Contact details",
        city: "🇬🇧City1️⃣3️⃣",
        close: "🇬🇧Close1️⃣3️⃣",
        company: "🇬🇧Company1️⃣3️⃣",
        country: "🇬🇧Country1️⃣3️⃣",
        completeProfile: "Complete Profile",
        done: "🇬🇧Done1️⃣3️⃣",
        edit: "Edit Profile Details",
        emailAddress: "🇬🇧Email Address1️⃣3️⃣",
        expertise: "🇬🇧Areas of Expertise1️⃣3️⃣",
        location: "🇬🇧Location1️⃣3️⃣",
        name: "Name",
        phone: "🇬🇧Phone1️⃣3️⃣",
        phonenumber: "🇬🇧Phone Number1️⃣3️⃣",
        street: "🇬🇧Street1️⃣3️⃣",
        uploadcertificates: "🇬🇧Upload Certificates1️⃣3️⃣",
        valuerListError:
            "🇬🇧There has been an error loading your valuers list1️⃣3️⃣",
        whichvaluation: "🇬🇧Which valuation can you offer?1️⃣3️⃣",
        doesValuations: "Do you do valuations?",

        heading: {
            contactDetails: "Contact details",
            membershipsAndQualifications: "Memberships and Qualifications",
            officeLocation: "Office location",
            otherQualifications: "Other Qualifications",
            otherValuationStandards: "Other Valuation Standards",
            operationalArea: "Operational area",
            otherValuationOccasions: "Other Valuation Occasions",
            otherValuationScopes: "Other Valuation Scopes",
            personalQualifications: "Personal qualifications",
            propertyTypes: "Property types",
            specificValuationScopes: "Specific Valuation Scopes",
            valuationOccasions: "Valuation occasions",
            valuationStandards: "Valuation standards",
            valuationScope: "Valuation scope",
        },
        experience: {
            lessThanOne: "Less than 1 year",
            oneToThree: "1-3 years",
            threeToFive: "3-5 years",
            moreThanFive: "5+ years",
        },
        profileSectionLink: {
            profile: "Profile",
            sharedDocs: "Shared Documents",
            team: "Team",
            templates: "Templates",
        },
        profession: "Profession",
        position: {
            at: "{{position}} at {{company}}",
        },

        profileCompletionWizard: {
            steps: {
                personal: "Personal details",
                company: "Company details",
                qualifications: "Personal qualifications",
                valuationOffer: "Valuation offer",
                extraDocs: "Extra documents",
            },
            landing: {
                title: " Complete your profile to unlock full potential.",
                subtitle:
                    "Unlock the full potential of the WVW platform by taking 2-3 minutes to update your profile.",
                keyBenefits: {
                    title: "Key Benefits",
                    benefits: {
                        improvedMatchmaking:
                            "Improved matchmaking with the right clients by leveraging detailed business contextual information and qualifications.",
                        enhancedTransparency:
                            "Enhanced transparency attracts new clients who have not used your services before.",
                        increasedVisibility:
                            "Detailed company information can positively influence clients' decisions when comparing valuers.",
                    },
                },
                securityDisclaimer:
                    "Please note, all provided details and uploaded documents are completely secured. Learn more about our security in",
                completeProfile: "Complete Profile",
                stepsTitle: "Completing profile process",
            },
            recommended: "Recommended",
            useCompanyDetails: "Use company details",
            extraDocs: {
                valuationSamples: {
                    title: "Valuation samples",
                    subtitle:
                        "Please note! Do not share in the samples any credentials or other sensitive data of your clients",
                },
                marketingAwards: {
                    title: "Marketing materials & awards",
                    subtitle:
                        "Share any marketing materials you have with your potential clients.",
                },
            },
        },

        profileDetails: {
            address: "Address",
            city: "City",
            confirmPassword: "Confirm Password",
            country: "Country",
            dialCode: "Dial Code",
            doesValuations: "Do you do valuations?",
            email: "Email",
            exactLocation: "Exact Location",
            exactLocationSubtitle:
                'Click the map to specify the location of your office. Click "$t(button.confirmLocation, { "ns":"common"})" to confirm the location is correct.',
            faxNumber: "Fax Number",
            firstName: "First Name",
            invoiceAddress: "Invoice Address",
            lastName: "Last Name",
            location: "Office Location",
            mobileNumber: "Mobile Number",
            message: "Message",
            name: "Name",
            noPhone: "No phone number provided.",
            officeFaxNumber: "Office Fax Number",
            officeTelNumber: "Office Telephone Number",
            otherInfo: "Other information",
            phone: "Office Phone",
            postalCode: "Postal Code",
            profession: "Profession",
            professionPlaceholder: "i.e. Admin, Valuer, Private etc.",
            profilePicture: "Profile Picture",
            roleInCompany: "Role in Company",
            roleInCompanyPlaceholder: "i.e. CEO, CFO, Valuer, etc.",
            sectionTitle: "Profile Details",
            street: "Street",
            telNumber: "Telephone Number",
            town: "Town",
            valuerMaxOperationDistance: "Maximum Distance to Customer Property",
            wizardTitle: "Create Your Profile",
        },
        membershipsAndQualifications: {
            sectionTitle: "Qualifications",
            certificate_one: "Certificate",
            certificate_other: "Certificates",
            noCertificate: "No Certificate",
            memberships: "Memberships",
            otherMemberships: "Other Memberships",
            otherMembershipCertificates: "Other Membership Certificates",
            qualifications: "Qualifications",
            otherQualifications: "Other Qualifications",
            otherQualificationCertificates: "Other Qualification Certificates",
            edit: {
                sectionTitle: "Edit Memberships and Qualifications",
                addQualification: "Add a qualification",
                addMembership: "Add a membership",
                addOtherValuationOccasion: "Add another valuation occasion",
                addValuationStandard: "Add a valuation standard",
                addValuationScope: "Add a valuation scope",
            },
        },
        subHeading: {
            membershipsAndQualifications:
                "Choose the standards according to which you operate and generate reports and upload appropriate certificates document or scan",
            officeDetails:
                "The main address of the place where you conduct your business, eg. office address.",
            otherQualifications:
                "Provide description or name of other qualifications.",
            otherValuationStandards:
                "Provide description or name of other valuation standards.",
            operationalArea:
                "Maximum travel distance from your work location to a property that you are willing to accept orders for?",
            otherValuationScopes:
                "Provide description or name of other valuation scopes.",
            propertyTypes: "Select all property types you are able to value.",
            specificValuationScopes:
                "Optionally select any specific valuation scope you service.",
            valuationStandards:
                "Choose the standards according to wich you operate and generate reports and upload appropriate certificates document or scan.",
            valuationScopes: "Select at least one valuation scope you service.",
            workLocation:
                "The main address of the place where you conduct your business on a daily basis, eg. office address.",
        },
        valuationCompetence: {
            sectionTitle: "Valuation Competence",
            valuationStandards: "Valuation Standards",
            otherValuationStandards: "Other Valuation Standards",
            propertyTypes: "Property Types",
            valuationScope: "Valuation Scope",
            specificValuationScopes: "Specific Valuation Scopes",
            otherValuationScopes: "Other Valuation Scopes",
            valuationOccasions: "Valuation Occasions",
            otherValuationOccasions: "Other Valuation Occasions",
            edit: {
                sectionTitle: "Edit Valuation Competence",
            },
        },
        teamDetails: {
            sectionTitle: "Team",
            blurb: "Stay in touch with your colleagues.",
        },
        sharedDocuments: {
            sectionTitle: "Documents",
            blurb: "View, upload and edit any shared company documents here.",
            document: {
                name: "Name",
                size: "Size",
                uploadDate: "Upload Date",
            },
            noDocs: "No documents uploaded",
            marketing: "Marketing",
            offer: "Offer Documents",
            piDocs: '$t(valuationCapabilities.piCover.piCover,  { "ns": "account" }) Documents',
            valuationSamples: "Valuation Samples",
        },
        fieldNotProvided: {
            contactPerson: "Contact person not provided",
            email: "No email provided",
            faxNumber: "No fax number provided",
            location: "No location provided",
            mobileNumber: "No mobile number provided",
            numberOfPartners: "No partners provided",
            officeFaxNumber: "No office fax number provided",
            officeTelNumber: "No office telephone number provided",
            otherSpecialReports: "No other special reports provided",
            phoneNumber: "No phone number provided",
            propertyTypes: "No property types provided",
            roleInCompany: "No role in company provided",
            specialReports: "No special reports provided",
            telNumber: "No telephone number provided",
            valuerMaxOperationDistance:
                "No maximum operating distance provided",
            website: "No website provided",
            yearsOfExperience: "No years of experience provided",
        },
    },
};
