import { Icon, Text, VStack } from "@chakra-ui/react";
import { Lock1 } from "iconsax-react";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";

const AwaitingAccessBanner = () => {
    const t = useSmartTranslation();

    return (
        <VStack
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="2rem"
            bg="white"
            borderRadius={8}
            spacing={2}
        >
            <Icon
                as={Lock1}
                boxSize={"3rem"}
                color={"gray.500"}
            />

            <Text
                color="gray.500"
                textAlign="center"
            >
                {t("awaitingAccessBanner.awaitingApprovalBlurbOne")}
            </Text>

            <Text
                color="gray.500"
                textAlign="center"
            >
                {t("awaitingAccessBanner.awaitingApprovalBlurbTwo")}
            </Text>
        </VStack>
    );
};

export default AwaitingAccessBanner;
