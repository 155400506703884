import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Spacer, Text, VStack } from "@chakra-ui/layout";
import { chakra, Collapse, IconButton, useDisclosure } from "@chakra-ui/react";
import { ReactNode } from "react";

type SectionProps = InternalSectionProps;

const Section = (props: SectionProps) => (
    <Box
        width="100%"
        padding="1em"
        bg="white"
        borderRadius="8"
    >
        <InternalSection {...props} />
    </Box>
);

type InternalSectionProps = {
    title?: string;
    subtitle?: string;
    content: ReactNode;
    collapsable?: boolean;
    initiallyCollapsed?: boolean;
};

const PureInternalSection = (props: InternalSectionProps) => {
    const {
        title,
        subtitle,
        content: contentRaw,
        collapsable = false,
        initiallyCollapsed = false,
        ...cssProps
    } = props;

    const { isOpen, onToggle } = useDisclosure({
        defaultIsOpen: !initiallyCollapsed,
    });

    let toggleExpansionState;
    let content;

    if (collapsable) {
        content = (
            <Collapse
                in={isOpen}
                transition={{
                    exit: { delay: 0 },
                    enter: { delay: 0, duration: 0.2 },
                }}
                className={"section-collapse"}
            >
                {contentRaw}
            </Collapse>
        );

        let ToggleIcon;
        let ariaLabel;

        if (isOpen) {
            ToggleIcon = ChevronUpIcon;
            ariaLabel = "Collapse section";
        } else {
            ToggleIcon = ChevronDownIcon;
            ariaLabel = "Expand section";
        }

        toggleExpansionState = (
            <IconButton
                size={"24px"}
                background={"transparent"}
                aria-label={ariaLabel}
                onClick={onToggle}
                variant={"ghost"}
                icon={
                    <ToggleIcon
                        boxSize={"24px"}
                        color={"gray.400"}
                    />
                }
            />
        );
    } else content = contentRaw;

    const showHeader = title || subtitle || collapsable;

    return (
        <VStack
            width={"100%"}
            align={"stretch"}
            {...cssProps}
        >
            {showHeader && (
                <Flex
                    width="100%"
                    alignItems="center"
                    mb={isOpen ? "1em" : "0"}
                >
                    <VStack align={"start"}>
                        {title && (
                            <Heading
                                size="md"
                                variant="blue"
                            >
                                {title}
                            </Heading>
                        )}
                        {subtitle && <Text variant="grey">{subtitle}</Text>}
                    </VStack>
                    <Spacer />
                    {toggleExpansionState}
                </Flex>
            )}
            {content}
        </VStack>
    );
};

const InternalSection = chakra(PureInternalSection);

export { InternalSection, Section };
