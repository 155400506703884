import { User } from ".";
import { EnquiryType } from "../../../../types";
import { useBusinessRelationships } from "../../app/useBusinessRelationships";
import { useAuthedUser } from "../../app/useMeV2";

type useExposeClientDataParams = {
    client: User;
    enquiry: EnquiryType | undefined;
};

export const useExposeClientData = (params: useExposeClientDataParams) => {
    const {
        client: {
            entity: { panelValuersIds },
            entityId: clientEntityId,
        },
        enquiry,
    } = params;

    const { entityId } = useAuthedUser();

    const { previousClientEntities } = useBusinessRelationships();

    const isInPanel = panelValuersIds.includes(entityId);

    const existingRelationship =
        previousClientEntities.includes(clientEntityId);

    const directEnquiry = enquiry ? enquiry.recipient === entityId : false;

    const isInUserCompany = entityId === clientEntityId;

    return {
        exposeClientData:
            isInPanel ||
            existingRelationship ||
            directEnquiry ||
            isInUserCompany,
        isInPanel,
        existingRelationship,
        directEnquiry,
        isInUserCompany,
    };
};
