import { Text, VStack } from "@chakra-ui/react";
import { UserType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import InformationRowItem from "./InformationRowItem";

type PropsType = {
    user: UserType;
};

const ProfileValuationOccasions = (props: PropsType) => {
    const {
        user: { valuationOccasions = [], otherValuationOccasions = [] },
    } = props;

    const t = useSmartTranslation();

    const emptyData = !valuationOccasions.length &&
        !otherValuationOccasions.length && (
            <Text
                color="gray.500"
                fontSize={"sm"}
            >
                {t("common.n/a")}
            </Text>
        );

    return (
        <InformationRowItem
            label={t("profile.valuationCompetence.valuationOccasions")}
            content={
                <VStack align={"start"}>
                    {emptyData}

                    {valuationOccasions.sort().map((valuation) => (
                        <Text
                            key={valuation}
                            fontSize={"sm"}
                        >
                            {t(
                                `domain.standards.valuationOccasion.${valuation}`,
                                { defaultValue: MISSING_TRANSLATION }
                            )}
                        </Text>
                    ))}

                    {otherValuationOccasions.sort().map((valuation) => (
                        <Text
                            key={valuation}
                            fontSize={"sm"}
                        >
                            {valuation}
                        </Text>
                    ))}
                </VStack>
            }
        />
    );
};

export default ProfileValuationOccasions;
