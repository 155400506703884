import { AwaitingAccess } from "../../ui/v2/common/features/awaiting-access/AwaitingAccess";
import { breadcrumbTranslations } from "../../ui/v2/common/features/breadcrumbs/Breadcrumbs.i18n";
import { LanguageToggle } from "../../ui/v2/common/features/languageToggle/LanguageToggle";
import { Notifications } from "../../ui/v2/common/features/notifications/Notifications";
import { Forms } from "../../ui/v2/common/forms/Forms";
import { common } from "../../ui/v2/common/i18n";
import { FileUpload } from "../../ui/v2/common/inputs/fileupload/FileUpload";
import {
    Case,
    Enquiry,
    Offer,
    Property,
    Standards,
    User,
} from "../../ui/v2/domain";
import { screen } from "../../ui/v2/screens/i18n";
import { accountTranslations } from "../../ui/v2/screens/platform/account/Account.i18n";
import { ClientDashboardScreen } from "../../ui/v2/screens/platform/client/dashboard/Index";
import { clientEnquiryDetailsTranslations as ClientEnquiryDetailsScreen } from "../../ui/v2/screens/platform/client/enquiry-details/EnquiryDetails.i18n";
import { OfferDetails } from "../../ui/v2/screens/platform/client/offer-details";
import { enquiryListTranslations } from "../../ui/v2/screens/platform/enquiry-list/EnquiryList.i18n";
import { profileTranslations } from "../../ui/v2/screens/platform/profile/Profile.i18n";
import { MenuBar } from "../../ui/v2/screens/platform/screen/menu-bar";
import { ValuerDashboardScreen } from "../../ui/v2/screens/platform/valuer/dashboard";
import { EnquiryDetailsScreen as ValuerEnquiryDetailsScreen } from "../../ui/v2/screens/platform/valuer/enquiry-details";
import { PasswordReset } from "../../ui/v2/screens/public/forgot-password/PasswordReset";
import { legal } from "../../ui/v2/screens/public/legal/i18n";
import { Login } from "../../ui/v2/screens/public/login";
import { Registration } from "../../ui/v2/screens/public/register";
import { SuccessScreen } from "../../ui/v2/screens/success";

const v2En = {
    common: common.en,
    domain: {
        case: Case.translations.en,
        client: User.translations.en,
        enquiry: Enquiry.translations.en,
        offer: Offer.translations.en,
        property: Property.translations.en,
        standards: Standards.translations.en,
        user: User.translations.en,
    },
    screens: {
        platform: {
            enquiryList: enquiryListTranslations.en,
            client: {
                offerDetails: OfferDetails.translations.en,
                enquiryDetails: ClientEnquiryDetailsScreen.en,
            },
            valuer: {
                valuerEnquiryDetails:
                    ValuerEnquiryDetailsScreen.translations.en,
            },
        },
    },
    forms: Forms.translations.en,
    languageToggle: LanguageToggle.translations.en,
    menubar: MenuBar.translations.en,
    awaitingAccessBanner: AwaitingAccess.translations.en,
    notifications: Notifications.translations.en,
    screen: screen.en,
    legal: legal.en,
    login: Login.translations.en,
    fileUpload: FileUpload.translations.en,
    passwordReset: PasswordReset.translations.en,
    registration: Registration.translations.en,
    successScreen: SuccessScreen.translations.en,
    clientDashboard: ClientDashboardScreen.translations.en,
    valuerDashboard: ValuerDashboardScreen.translations.en,
    breadcrumbs: breadcrumbTranslations.en,
    profile: profileTranslations.en,
    account: accountTranslations.en,
};

export default v2En;
