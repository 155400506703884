import { OfferType } from "../../../../../../../../types";
import { Enrichment } from "../../../enricher";
import { SHORTEST_TIMELINE } from "./enrichers";

export const shortestTimeline = <T extends OfferType>(
    offers: T[]
): Enrichment<T> => {
    const workingDaysFewToMany = [...offers].sort((o1, o2) => {
        const workingDay1 = o1.timeline;
        const workingDay2 = o2.timeline;

        if (workingDay1 < workingDay2) return -1;
        if (workingDay1 > workingDay2) return 1;
        return 0;
    });

    const highlightedData = workingDaysFewToMany
        .filter((o) => o.timeline === workingDaysFewToMany[0].timeline)
        .map((o) => ({
            data: o,
            highlight: {
                keyword: SHORTEST_TIMELINE,
            },
        }));

    return {
        fieldName: "timeline",
        highlightedData,
    };
};
