import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ModalIntention = "neutral" | "positive" | "negative";

const MODAL_ACTION_COLORS: Record<ModalIntention, string> = {
    neutral: "blue",
    positive: "green",
    negative: "red",
};

type ConfirmationModalPropsType = {
    isOpen: boolean;
    onConfirmation: Function;
    onClose: () => void;
    title: string;
    blurb: string;
    confirmationButtonLabel: string;
    confirmationButtonVariant: ModalIntention;
};

const ConfirmationModal = (props: ConfirmationModalPropsType) => {
    const {
        isOpen,
        onClose,
        onConfirmation,
        title,
        blurb,
        confirmationButtonLabel,
        confirmationButtonVariant,
    } = props;

    const t = useSmartTranslation();

    const confirmationButtonColor =
        MODAL_ACTION_COLORS[confirmationButtonVariant];

    const confirmationButton = (
        <Button
            onClick={() => onConfirmation()}
            ml={3}
            minW="112px"
            variant={"solid"}
            colorScheme={confirmationButtonColor}
        >
            {confirmationButtonLabel}
        </Button>
    );

    const cancelRef = useRef(null);

    const cancelButton = (
        <Button
            onClick={() => onClose()}
            ref={cancelRef}
            width="142px"
            variant={"default"}
        >
            {t("common.button.cancel")}
        </Button>
    );

    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay>
                <AlertDialogContent borderRadius={"1rem"}>
                    <AlertDialogHeader color={"blue.700"}>
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogCloseButton />

                    <AlertDialogBody>{blurb}</AlertDialogBody>

                    <AlertDialogFooter>
                        {cancelButton}
                        {confirmationButton}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default ConfirmationModal;
