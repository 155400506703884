import { CloseIcon } from "@chakra-ui/icons";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import PublicUserProfilePicture from "../../../../../../common/components/PublicUserProfilePicture";
import { OfferOnlyType, UserType } from "../../../../../../types";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";
import { NO_DATA } from "../../../../common/NO_DATA";
import { EnquiryMatchInfo } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";

type Column = DataTableColumnType<
    UserType & {
        enquiryMatchInfo?: EnquiryMatchInfo & {
            offers: OfferOnlyType[];
        };
    }
>;

export const valuerName: Column = {
    alignment: "left",
    title: (t) =>
        t(
            "screens.platform.valuer.valuerEnquiryDetails.companyOffers.headers.valuer"
        ),
    render: (user) => {
        const { firstName, lastName, orgPosition } = user;

        const name = `${firstName} ${lastName}`;

        const userRoleInCompany = orgPosition;

        if (!name) {
            return NO_DATA;
        }

        return (
            <HStack spacing="3">
                <PublicUserProfilePicture
                    size="2.5rem"
                    userId={user.id}
                    entityId={user.entityId}
                />

                <VStack
                    align="flex-start"
                    spacing="0"
                >
                    <Text
                        fontWeight="bold"
                        fontSize={"sm"}
                    >
                        {name}
                    </Text>
                    <Text fontSize={"xs"}>{userRoleInCompany}</Text>
                </VStack>
            </HStack>
        );
    },
};

export const officeLocation: Column = {
    alignment: "left",
    title: (t) =>
        t(
            "screens.platform.valuer.valuerEnquiryDetails.companyOffers.headers.officeLocation"
        ),
    render: (i) => {
        const { city } = i;

        if (city === "") {
            return NO_DATA;
        }

        return <Text fontSize={"sm"}>{city}</Text>;
    },
};

export const qualification: Column = {
    alignment: "center",
    title: (t) =>
        t(
            "screens.platform.valuer.valuerEnquiryDetails.companyOffers.headers.qualification"
        ),
    render: (i) => {
        const valuationStandards = i.enquiryMatchInfo?.valuationStandards;

        if (valuationStandards === undefined) {
            return NO_DATA;
        }

        const valuerStandardsDisplay =
            valuationStandards === true ? (
                <FiCheck
                    color="green"
                    size="24px"
                />
            ) : (
                <CloseIcon
                    boxSize="10px"
                    color="gray.400"
                />
            );

        return <Text fontSize={"sm"}>{valuerStandardsDisplay}</Text>;
    },
};

export const inRange: Column = {
    alignment: "center",
    title: (t) =>
        t(
            "screens.platform.valuer.valuerEnquiryDetails.companyOffers.headers.inRange"
        ),
    render: (i) => {
        const valuerMaxOfficeDistance =
            i.enquiryMatchInfo?.valuerMaxOfficeDistance;

        if (valuerMaxOfficeDistance === undefined) {
            return NO_DATA;
        }

        const valuerMaxDistanceDisplay =
            valuerMaxOfficeDistance === true ? (
                <FiCheck
                    color="green"
                    size="24px"
                />
            ) : (
                <CloseIcon
                    boxSize="10px"
                    color="gray.400"
                />
            );

        return <Text fontSize={"sm"}>{valuerMaxDistanceDisplay}</Text>;
    },
};

export const offersPending: Column = {
    alignment: "center",
    title: (t) =>
        t(
            "screens.platform.valuer.valuerEnquiryDetails.companyOffers.headers.offersPending"
        ),
    render: (i) => {
        const pendingOffers = i.enquiryMatchInfo?.offers?.filter(
            (o) => o.status === "PENDING"
        ).length;

        if (pendingOffers === undefined) {
            return NO_DATA;
        }

        return <Text fontSize={"sm"}>{pendingOffers}</Text>;
    },
};

export const companyValuerColumns = [
    valuerName,
    officeLocation,
    qualification,
    inRange,
    offersPending,
];
