import { Box, Icon, ResponsiveValue, Tooltip } from "@chakra-ui/react";
import { Icons } from "../../icons/Icons";

type PropTypes = {
    tooltip?: string;
    size?: ResponsiveValue<number | string>;
    right?: string;
};

const PanelRelationshipMarker = (props: PropTypes) => {
    const { tooltip, size = "1.2rem", right = "0" } = props;

    const iconBox = (
        <Box
            position={"absolute"}
            top={"0"}
            right={right}
            zIndex={1}
        >
            <Icon
                as={Icons.BookmarkCheck}
                boxSize={size}
                verticalAlign={"top"}
            />
        </Box>
    );

    return tooltip ? (
        <Tooltip
            hasArrow={true}
            placement={"top"}
            label={tooltip}
        >
            {iconBox}
        </Tooltip>
    ) : (
        iconBox
    );
};

export default PanelRelationshipMarker;
