import { CheckIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { OfferType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useAcceptOffer from "./useAcceptOffer";

type AcceptOfferProps = {
    offer: OfferType;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const AcceptOffer = (props: AcceptOfferProps) => {
    const { offer, onSuccess, onError } = props;

    const t = useSmartTranslation();

    const { acceptOffer, acceptationModal } = useAcceptOffer({
        onSuccess,
        onError,
        offer,
    });

    return (
        <Button
            leftIcon={<CheckIcon />}
            width={"100%"}
            variant={"solid"}
            colorScheme={"green"}
            onClick={acceptOffer}
        >
            {t("domain.offer.ACTIVITIES.accept.NAME")}
            {acceptationModal}
        </Button>
    );
};

export default AcceptOffer;
