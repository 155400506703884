import BookmarkCheckIcon from "./BookmarkCheckIcon";
import CertificateIcon from "./CertificateIcon";
import { EmailIcon } from "./EmailIcon";
import { FaxIcon } from "./FaxIcon";
import { MobileIcon } from "./MobileIcon";
import PersonGroupAddIcon from "./PersonGroupAddIcon";
import WvwLogoIcon from "./WvwLogoIcon";

export const Icons = {
    Mobile: MobileIcon,
    Email: EmailIcon,
    Fax: FaxIcon,
    BookmarkCheck: BookmarkCheckIcon,
    Certificate: CertificateIcon,
    WvwLogo: WvwLogoIcon,
    PersonGroupAdd: PersonGroupAddIcon,
};
