import { StandardPropertyCondition } from "../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyConditions";

type TranslationNSObject = {
    [key in StandardPropertyCondition]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const propertyConditionTranslations: Translations = {
    de: {
        DATED: "Älter/Nicht renoviert",
        NEW: "Neu",
        RECENTLY_REFURBISHED: "Kürzlich renoviert",
        UNSPECIFIED: "Keine Angabe",
    },
    en: {
        DATED: "Dated",
        NEW: "New",
        RECENTLY_REFURBISHED: "Recently refurbished",
        UNSPECIFIED: "Unspecified (N/A)",
    },
};
