import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { EnquiryType, OfferWithEnquiry } from "../../../../../../types";
import { usePermissions } from "../../../../app/usePermissions";
import DataTable from "../../../../common/components/data-table/DataTable";
import {
    enrichedOfferColumns,
    EnrichedOfferWithEnquiry,
} from "../../../../common/features/enrichment/domain/offer/display/EnrichedOfferColumns";
import { earliestAvailability } from "../../../../common/features/enrichment/domain/offer/enrichers/Availability";
import { bestSecurity } from "../../../../common/features/enrichment/domain/offer/enrichers/BestSecurity";
import { earliestDelivery } from "../../../../common/features/enrichment/domain/offer/enrichers/Delivery";
import { lowestPrice } from "../../../../common/features/enrichment/domain/offer/enrichers/Price";
import { shortestTimeline } from "../../../../common/features/enrichment/domain/offer/enrichers/ShortestTimeline";
import { enrichData } from "../../../../common/features/enrichment/enricher";

type OfferTableProps = {
    enquiry: EnquiryType;
    offers: OfferWithEnquiry[];
    checkedOffers: OfferWithEnquiry[];
    toggleOfferChecked: (offer: OfferWithEnquiry, checked: boolean) => void;
    footerContent?: ReactNode | ReactNode[];
};

const EnrichedOfferTable = (props: OfferTableProps) => {
    const {
        enquiry,
        offers,
        checkedOffers,
        toggleOfferChecked,
        footerContent,
    } = props;

    const navigate = useNavigate();

    const canI = usePermissions();
    const canEdit = canI(enquiry, "ENQUIRY", "EDIT");

    const enrichedOffers = enrichData(offers, [
        lowestPrice,
        bestSecurity,
        earliestAvailability,
        earliestDelivery,
        shortestTimeline,
    ]);

    const columns = [
        enrichedOfferColumns.valuerProfile,
        enrichedOfferColumns.commment,
        enrichedOfferColumns.price,
        enrichedOfferColumns.deliveryAndTimeline,
        enrichedOfferColumns.piCoverValue,
        enrichedOfferColumns.piCoverPercentage,
        enrichedOfferColumns.specialPi,
        enrichedOfferColumns.view,
        enrichedOfferColumns.deleteOffer,
    ];

    let toggleEnrichedOfferChecked:
        | ((offer: EnrichedOfferWithEnquiry, check: boolean) => void)
        | undefined;

    if (canEdit) {
        toggleEnrichedOfferChecked = (
            offer: EnrichedOfferWithEnquiry,
            check: boolean
        ) => toggleOfferChecked(offer.data, check);
    }

    const checkedEnrichedOffers = enrichedOffers.filter(({ data }) =>
        checkedOffers.find(o => o.id === data.id) !== undefined
    );

    return (
        <>
            <DataTable
                fullSectionWidth
                data={enrichedOffers}
                checkedRows={checkedEnrichedOffers}
                isChecked={(i) => checkedEnrichedOffers.find(o => o.data.id === i.data.id) !== undefined}
                onToggleRowChecked={toggleEnrichedOfferChecked}
                columns={columns}
                footerContent={footerContent}
                stickyColumns={1}
                onRowClick={(offer) => {
                    navigate(`/dashboard/enquiry/${offer.data.enquiryId}/offer/${offer.data.id}`);
                }}
            />
        </>
    );
};

export { EnrichedOfferTable };
