import { Flex } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/react";
import React from "react";
import { File } from "../../../common/components/display";
import { DocumentItemType } from "../../../common/components/display/File";

type AvailableAttachmentProps = {
    label: string;
    url?: string;
    size: number;
    menu?: React.ReactNode;
    onToggle: (checked: boolean) => void;
    checked: boolean;
};

export const AvailableAttachment = (props: AvailableAttachmentProps) => {
    const { label, url, size, menu, onToggle: handleToggle, checked } = props;

    const onToggle = () => handleToggle(!checked);

    const attachment: DocumentItemType = {
        name: label,
        url,
        size,
    };

    return (
        <Flex
            justifyContent={"space-between"}
            padding=".5rem 1rem"
            gap={2}
            bg={checked ? "blue.50" : "gray.50"}
        >
            <Checkbox
                onChange={onToggle}
                isChecked={checked}
                width={"100%"}
            >
                <File
                    documentItem={attachment}
                    showTypeIcon={false}
                />
            </Checkbox>
            {menu}
        </Flex>
    );
};
