import { useNavigate } from "react-router-dom";
import { useRole, useSmartTranslation, useToast } from "../../../common/hooks";
import useEnquiryColumns from "../../../common/hooks/useEnquiryColumns";
import {
    useEnquiryCancel,
    useEnquiryGetAll,
    useEnquiryGetEnquiriesWithAssignments,
    useEnquiryReactivate,
    useEnquirySetDormant,
    useOfferGetAllMyOffers,
} from "../../../utils/api/hooks";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import DataTable, {
    DataTableColumnType,
} from "../../../common/components/datatable/DataTable";
import { EnquiryWithAssignedValuersType } from "../../../types";
import { List, ListIcon, ListItem, Text, VStack } from "@chakra-ui/react";
import { TickCircle } from "iconsax-react";
import { ReactNode } from "react";

const EnquiryTable = () => {
    const navigate = useNavigate();

    const t = useSmartTranslation("common");

    const { createErrorToast } = useToast();

    const { userIsValuer, userIsCompanyAdmin, roleLoading } = useRole();

    const {
        data: enquiryList = [],
        isLoading: enquiryLoading,
        refresh,
    } = useEnquiryGetAll();

    const { data: adminEnquiryList = [], isLoading: adminEnquiryLoading } =
        useEnquiryGetEnquiriesWithAssignments();

    const { update: reactivate } = useEnquiryReactivate({
        onSuccess: (data) => {
            navigate(
                `/create-client-enquiry/enquiry-valuation-info/${data.data.id}`
            );
        },
    });

    const { data: offerList = [], isLoading: offerLoading } =
        useOfferGetAllMyOffers();

    const { update: setDormant } = useEnquirySetDormant({
        onSuccess: () => {
            refresh();
        },
        onError: (error) => {
            createErrorToast(error.message);
        },
    });

    const { cancelEnquiry, modal: cancelEnquiryModal } = useEnquiryCancel({
        onSuccess: () => refresh(),
        onError: (error) => {
            createErrorToast(error.message);
        },
    });

    const {
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    } = useEnquiryColumns(offerList, { cancelEnquiry, reactivate, setDormant });

    const columnAssigedValuers: DataTableColumnType<EnquiryWithAssignedValuersType> =
        {
            title: t("assignedValuers"),
            maxWidth: "13rem",
            render: (i) => <Text>{i.assignedValuers.length}</Text>,
            sorter: (a, b) =>
                a.assignedValuers.length - b.assignedValuers.length,
        };

    const renderExpandedContent = (enquiry: EnquiryWithAssignedValuersType) => {
        return (
            <VStack
                w="100%"
                align="left"
                bg="white"
                borderRadius="10"
                paddingTop="1rem"
                paddingBottom="1.5rem"
                paddingInline="2.5rem"
                spacing="2"
            >
                <Text fontWeight="bold">
                    {t("assignedValuers", { ns: "common" })}
                </Text>

                {enquiry.assignedValuers.map((valuer) => {
                    return (
                        <List>
                            <ListItem>
                                <ListIcon
                                    as={TickCircle}
                                    variant="Bold"
                                    color="wvwGreen"
                                />

                                {`${valuer.firstName} ${valuer.lastName}`}
                            </ListItem>
                        </List>
                    );
                })}
            </VStack>
        );
    };

    if (roleLoading || enquiryLoading || offerLoading || adminEnquiryLoading)
        return <LoadingSpinner />;

    const valuerColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    const clientColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    const valuerAdminColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnAssigedValuers,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    let columns = [];
    let tableData = enquiryList;
    let expandedContent:
        | undefined
        | ((i: EnquiryWithAssignedValuersType) => ReactNode) = undefined;

    if (userIsValuer) {
        columns = valuerColumns;
        if (userIsCompanyAdmin) {
            columns = valuerAdminColumns;
            tableData = adminEnquiryList;
            expandedContent = renderExpandedContent;
        }
    } else {
        columns = clientColumns;
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={[]}
                noDataText={t("noEnquiries", { ns: "enquiries" })}
                expandedContent={expandedContent}
            />

            {cancelEnquiryModal}
        </>
    );
};

export default EnquiryTable;
