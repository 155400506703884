import { StandardMinimumPartners } from "../../../../../common/vars/valuationsAndMembershipTypes/standardMinimumPartners";

type TranslationNSObject = {
    [key in StandardMinimumPartners]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const minPartnersTranslations: Translations = {
    de: {
        "0": "Keine Angabe",
        "1": "Einzelgutachter",
        "2": "Zwei Gutachter",
        "3": "Drei Gutachter",
        "4": "Vier oder mehr Gutachter",
    },
    en: {
        "0": "No Requirement",
        "1": "Single Valuer",
        "2": "Two Valuers",
        "3": "Three Valuers",
        "4": "Four or More Valuers",
    },
};
