import { EnquiryType, OfferOnlyType } from "../../../../../../types";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";
import { enquiryColumns } from "../../../../domain/enquiry/columns/EnquiryColumns";
import { enquiryWithOfferColumns } from "../../../../domain/enquiry/columns/EnquiryWithOfferColumns";

// types
type EnquiryTableDataType = EnquiryType & {
    offers: OfferOnlyType[];
};

type createEnquiriesTableDataProps = {
    enquiries: EnquiryType[];
    offers: OfferOnlyType[];
};

export const createEnquiriesTableData = (
    props: createEnquiriesTableDataProps
) => {
    const { enquiries, offers } = props;
    
    return enquiries.map((enquiry) => ({
        ...enquiry,
        offers: offers.filter(
            ({ enquiryId, status }) =>
                enquiryId === enquiry.id && status === "PENDING"
        ),
    }));
};

// columns
export const columns: DataTableColumnType<EnquiryTableDataType>[] = [
    enquiryColumns.enquiryIdWithProperties,
    enquiryWithOfferColumns.activeOffers,
    enquiryWithOfferColumns.bestDelivery,
    enquiryWithOfferColumns.bestOffer,
    enquiryColumns.viewEnquiry,
];
