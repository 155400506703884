import { Flex, Heading, Spacer, VStack } from "@chakra-ui/react";

type PropTypes = {
    title: string;
    subtitle?: React.ReactNode;
    rightSide?: React.ReactNode;
};

const Title = (props: PropTypes) => {
    const { title, subtitle, rightSide } = props;

    return (
        <Flex
            bg="white"
            w="100%"
            align="center"
            padding="1em 1.5em"
            borderRadius="8"
        >
            <VStack
                align="start"
                spacing={1}
            >
                <Heading
                    color="blue.700"
                    fontSize={"2xl"}
                >
                    {title}
                </Heading>

                {subtitle}
            </VStack>

            <Spacer />
            {rightSide}
        </Flex>
    );
};

export default Title;
