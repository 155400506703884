import { Button, ButtonProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { EnquiryType } from "../../../../types";
import { useAuthedUser } from "../../app/useMeV2";
import { usePermissions } from "../../app/usePermissions";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type EditEnquiryProps = ButtonProps & {
    enquiry: EnquiryType;
};

const EditEnquiry = (props: EditEnquiryProps) => {
    const {
        enquiry: { id: enquiryId, status, dormant },
        enquiry,
        ...cssProps
    } = props;

    const t = useSmartTranslation();

    const { id } = useAuthedUser();

    const navigate = useNavigate();

    const canI = usePermissions();

    const permissionToEdit = canI(enquiry, "ENQUIRY", "EDIT");
    const editable = status === "DRAFT" && !dormant;

    const label = t("domain.enquiry.ACTIVITIES.edit.NAME");

    return (
        <Button
            {...cssProps}
            isDisabled={!(editable && permissionToEdit)}
            onClick={(ev) => {
                ev.stopPropagation();
                navigate(
                    `/create-client-enquiry/enquiry-valuation-info/${enquiryId}`
                );
            }}
        >
            {label}
        </Button>
    );
};

export { EditEnquiry };
