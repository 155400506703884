import { Button, Heading } from "@chakra-ui/react";
import delimitNumber from "../../../../../../../common/functions/delimitNumber";
import {
    EnquiryWithFromUserAndFromEntityType,
    OfferOnlyType,
} from "../../../../../../../types";
import { useAuthedUser } from "../../../../../app/useMeV2";
import { DataTableColumnType } from "../../../../../common/components/data-table/DataTable";
import { Enquiry, User } from "../../../../../domain";
import {
    clientPictureAndName,
    enquiryColumns,
} from "../../../../../domain/enquiry/columns/EnquiryColumns";
import { enquiryWithOfferColumns } from "../../../../../domain/enquiry/columns/EnquiryWithOfferColumns";

type TableDataType = EnquiryWithFromUserAndFromEntityType & {
    offers: OfferOnlyType[];
};

// create table data
type RecentMatchesProps = {
    enquiries: EnquiryWithFromUserAndFromEntityType[];
    offers: OfferOnlyType[];
    user: User;
};

/*
"show only enquiries we can submit an offer on"
ie.
- enquiry is pending
- the user hasn't submitted an offer on the enquiry
*/
export const createRecentMatchesData = (props: RecentMatchesProps) => {
    const { enquiries, offers, user } = props;

    const recentMatchData = enquiries
        .filter((enquiry) => {
            if (enquiry.status !== "PENDING") return false;

            if (
                offers.find(
                    (offer) =>
                        offer.enquiryId === enquiry.id &&
                        offer.fromValuerId === user.id
                )
            )
                return false;

            return true;
        })
        .map((enquiry): TableDataType => {
            const enquiryOffers = offers.filter(
                (offer) => offer.enquiryId === enquiry.id
            );

            return {
                ...enquiry,
                offers: enquiryOffers,
            };
        });

    return recentMatchData;
};

type CurrentOfferEnquiryProps = {
    enquiries: EnquiryWithFromUserAndFromEntityType[];
    offers: OfferOnlyType[];
    user: User;
};

export const createCurrentOfferEnquiryData = (
    props: CurrentOfferEnquiryProps
) => {
    const { enquiries, offers, user } = props;

    const currentOfferEnquiryData = enquiries
        .filter((enquiry) => {
            if (enquiry.status !== "PENDING") return false;

            return offers.find(
                (offer) =>
                    offer.enquiryId === enquiry.id &&
                    offer.fromValuerId === user.id
            );
        })
        .map((enquiry): TableDataType => {
            const enquiryOffers = offers.filter(
                (offer) => offer.enquiryId === enquiry.id
            );

            // enquiry has fromUser and fromEntity, but the fromUser does not have the fromEntity
            return {
                ...enquiry,
                fromUser: {
                    ...enquiry.fromUser,
                    entity: enquiry.fromEntity,
                },
                offers: enquiryOffers,
            };
        });

    return currentOfferEnquiryData;
};

export const myOffer: DataTableColumnType<TableDataType> = {
    alignment: "left",
    title: (t) => t(`domain.enquiry.columns.myOffer`),
    render: (i, t) => {
        const user = useAuthedUser();

        const myOffer = i.offers.find(
            (offer) => offer.fromValuerId === user.id
        );

        const myOfferPrice = `€${delimitNumber(myOffer?.price)}`;

        if (!myOffer) {
            return <Button>{t("domain.offer.submit")}</Button>;
        } else {
            return (
                <Heading
                    size="xs"
                    color="green.500"
                >
                    {myOfferPrice}
                </Heading>
            );
        }
    },
};

// columns
export const columns: DataTableColumnType<TableDataType>[] = [
    enquiryColumns.enquiryIdWithProperties,
    enquiryColumns.estPortfolioValueEnquiry,
    enquiryColumns.valuationScopeAndStandard,
    enquiryColumns.expectedDeliveryDate,
    enquiryColumns.piCoverRequirements,
    enquiryWithOfferColumns.offers,
    enquiryColumns.viewEnquiry,
];

export const enquiryWithMatchingOfferColumns: DataTableColumnType<TableDataType>[] =
    [
        enquiryColumns.enquiryIdWithProperties,
        enquiryColumns.createdAt,
        clientPictureAndName,
        enquiryColumns.valuationScopeAndStandard,
        enquiryColumns.expectedDeliveryDate,
        enquiryColumns.estPortfolioValueEnquiry,
        Enquiry.TableColumns.EnquiryWithOffer.valuerOfferStatus,
        enquiryColumns.viewEnquiry,
    ];
