import { Flex, Text } from "@chakra-ui/react";

type InformationRowItemProps = {
    label: string;
    content: React.ReactNode | React.ReactNode[];
};

const InformationRowItem = (props: InformationRowItemProps) => {
    const { label, content } = props;

    return (
        <Flex>
            <Text
                maxW={"16rem"}
                w={"100%"}
                fontSize={"sm"}
                color={"gray.500"}
            >
                {label}
            </Text>

            {content}
        </Flex>
    );
};

export default InformationRowItem;
