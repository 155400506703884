export type StandardValuerMaxOperatingDistance =
    (typeof standardValuerMaxOperatingDistance)[number]["value"];

const standardValuerMaxOperatingDistance = [
    { label: "standardValuerMaxOperatingDistance.to5", value: 5 },
    { label: "standardValuerMaxOperatingDistance.to10", value: 10 },
    { label: "standardValuerMaxOperatingDistance.to20", value: 20 },
    { label: "standardValuerMaxOperatingDistance.to50", value: 50 },
    { label: "standardValuerMaxOperatingDistance.to100", value: 100 },
    { label: "standardValuerMaxOperatingDistance.to150", value: 150 },
    { label: "standardValuerMaxOperatingDistance.to200", value: 200 },
    { label: "standardValuerMaxOperatingDistance.to250", value: 250 },
    { label: "standardValuerMaxOperatingDistance.to300", value: 300 },
] as const;

export default standardValuerMaxOperatingDistance;
