import { HStack, Text } from "@chakra-ui/layout";
import { ResponsiveValue } from "@chakra-ui/react";
import { User } from ".";
import { EnquiryType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { ClientProfilePicture } from "./ClientProfilePicture";
import { useExposeClientData } from "./useExposeClientData";

type ProfilePictureWithNameProps = {
    user: User;
    enquiry: EnquiryType | undefined;
    nameSize?: string;
    profileLogoSize?: ResponsiveValue<number | string>;
    companyLogoSize?: ResponsiveValue<number | string>;
};

export const ProfilePictureWithName = (props: ProfilePictureWithNameProps) => {
    const {
        user: { firstName, lastName },
        user,
        enquiry,
        nameSize = "sm",
        profileLogoSize,
        companyLogoSize,
    } = props;

    const t = useSmartTranslation();

    const { exposeClientData: showClient } = useExposeClientData({
        client: user,
        enquiry,
    });

    const name = showClient
        ? `${firstName} ${lastName}`
        : t("domain.client.obscuredClient");

    return (
        <HStack spacing="3">
            <ClientProfilePicture
                client={user}
                enquiry={enquiry}
                profileLogoSize={profileLogoSize}
                companyLogoSize={companyLogoSize}
            />
            <Text fontSize={nameSize}>{name}</Text>
        </HStack>
    );
};
