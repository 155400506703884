import { OfferType } from "../../../../../../../../types";
import { Enrichment } from "../../../enricher";
import { EARLIEST_AVAILABILITY } from "./enrichers";

export const earliestAvailability = <T extends OfferType>(
    offers: T[]
): Enrichment<T> => {
    const availabilitiesSortedSoonToLate = [...offers].sort((o1, o2) => {
        const availabilityDate1 = new Date(o1.availability).valueOf();
        const availabilityDate2 = new Date(o2.availability).valueOf();

        if (availabilityDate1 < availabilityDate2) return -1;
        if (availabilityDate1 > availabilityDate2) return 1;
        return 0;
    });

    const highlightedData = availabilitiesSortedSoonToLate
        .filter(
            (o) =>
                o.availability ===
                availabilitiesSortedSoonToLate[0].availability
        )
        .map((o) => ({
            data: o,
            highlight: {
                keyword: EARLIEST_AVAILABILITY,
            },
        }));

    return {
        fieldName: "availability",
        highlightedData,
    };
};
