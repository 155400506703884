import {
    DrawerProps as BaseDrawerProps,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react";

type DrawerProps = Partial<BaseDrawerProps> &
    (
        | {
              title?: React.ReactNode;
              header?: undefined;
          }
        | {
              title?: undefined;
              header?: React.ReactNode;
          }
    ) & {
        content: React.ReactNode;
        footer?: React.ReactNode;
    };

const useDrawer = (props: DrawerProps) => {
    const {
        title,
        header: customHeader,
        content,
        footer,
        size,
        placement = "right",
    } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const header = customHeader ? (
        customHeader
    ) : (
        <DrawerHeader
            fontSize={"xl"}
            color={"blue.700"}
        >
            {title}
        </DrawerHeader>
    );

    const drawer = (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement={placement}
            size={size}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                {header}

                <DrawerBody>{content}</DrawerBody>

                {footer && <DrawerFooter>{footer}</DrawerFooter>}
            </DrawerContent>
        </Drawer>
    );

    return {
        drawer,
        isDrawerOpen: isOpen,
        openDrawer: onOpen,
        closeDrawer: onClose,
    };
};

export default useDrawer;
