import { Outlet, Route, Routes } from "react-router-dom";
import ClientEnquiryBox from "../../../../common/components/display/enquiry-form/display/ClientEnquiryBox";
import Admin from "../../../../views/admin/Admin";
import AdminCaseReport from "../../../../views/admin/case-report/AdminCaseReport";
import AdminCaseReports from "../../../../views/admin/case-reports/AdminCaseReports";
import AdminCases from "../../../../views/admin/cases/AdminCases";
import AdminDocuments from "../../../../views/admin/documents/AdminDocuments";
import AdminEnquiries from "../../../../views/admin/enquiries/AdminEnquiries";
import AdminEnquiry from "../../../../views/admin/enquiry/AdminEnquiry";
import AdminEntities from "../../../../views/admin/entities/AdminEntities";
import AdminInspections from "../../../../views/admin/inspections/AdminInspections";
import AdminOffers from "../../../../views/admin/offers/AdminOffers";
import AdminUploadedContract from "../../../../views/admin/uploaded-contract/AdminUploadedContract";
import AdminUploadedContracts from "../../../../views/admin/uploaded-contracts/AdminUploadedContracts";
import AdminUsers from "../../../../views/admin/users/AdminUsers";
import AdminUser from "../../../../views/admin/users/user/AdminUser";
import EnquiryInvoiceInfo from "../../../../views/create-client-enquiry/enquiry-contact-info/EnquiryContactInfo";
import EnquiryOverviewInfo from "../../../../views/create-client-enquiry/enquiry-overview-info/EnquiryOverviewInfo";
import EnquiryPropertyInfo from "../../../../views/create-client-enquiry/enquiry-property-info/EnquiryPropertyInfo";
import EnquiryValuationInfo from "../../../../views/create-client-enquiry/enquiry-valuation-info/EnquiryValuationInfo";
import EnquiryValuerInfo from "../../../../views/create-client-enquiry/enquiry-valuer-info/EnquiryValuerInfo";
import Account from "../../../../views/dashboard/account/Account";
import CompanyCustomize from "../../../../views/dashboard/account/customize/CompanyCustomize";
import CompanyDocuments from "../../../../views/dashboard/account/documents/CompanyDocuments";
import EmailSharedDocs from "../../../../views/dashboard/account/documents/email/EmailSharedDocs";
import CompanyDetails from "../../../../views/dashboard/account/index/CompanyDetails";
import EnquiryEmailSlider from "../../../../views/dashboard/account/integration/enquiry-email-link/EnquiryEmailSlider";
import Integration from "../../../../views/dashboard/account/integration/Integration";
import CompanyTeam from "../../../../views/dashboard/account/team/CompanyTeam";
import ValuerPanel from "../../../../views/dashboard/account/valuer-panel/ValuerPanel";
import ProfileCompletionBilling from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionBilling";
import ProfileCompletionContactDetails from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionContactDetails";
import ProfileCompletionExtras from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionExtras";
import ProfileCompletionLanding from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionLanding";
import ProfileCompletionQualifications from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionQualifications";
import ProfileCompletionCompetencies from "../../../../views/dashboard/dashboard/profile-completion-wizard/ProfileCompletionCompetencies";
import ProfileCompletionWizard from "../../../../views/dashboard/dashboard/profile-completion-wizard/ProfileCompletionWizard";
import ProfileTemplates from "../../../../views/dashboard/profile/components/ProfileTemplates";
import { Case } from "../../domain";
import { User } from "../../domain/user";
import { ClientScreens } from "../../screens";
import Logout from "../../screens/platform/logout/Logout";
import { NotificationsScreen } from "../../screens/platform/notifications/NotificationsScreen";
import { PlatformScreens } from "../../screens/platform/PlatformScreens";
import { PlatformScreen } from "../../screens/platform/screen/PlatformScreen";
import { EnquiryDetailsScreen as ValuerEnquiryDetailsScreen } from "../../screens/platform/valuer/enquiry-details";
import { ValuerScreens } from "../../screens/platform/valuer/ValuerScreens";
import RegisterSuccess from "../../screens/public/register/RegisterSuccess";
import { LayoutV1Wrapper } from "../LayoutV1Wrapper";
import { ThemeV1Wrapper } from "../ThemeV1Wrapper";
import { useAuthedUser } from "../useMeV2";
import { global_routes } from "./global";

const { REACT_APP_FEATURE_ENABLE_DMS = "false" } = process.env;

// route fragments

const sysadmin = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="admin"
            element={<Admin />}
        >
            <Route
                index
                element={<AdminUsers />}
            />
            <Route
                path="cases"
                element={<AdminCases />}
            />
            <Route
                path="case-reports"
                element={<AdminCaseReports />}
            />
            <Route
                path="case-report/:reportId"
                element={<AdminCaseReport />}
            />
            <Route
                path="documents"
                element={<AdminDocuments />}
            />
            <Route
                path="enquiries"
                element={<AdminEnquiries />}
            />
            <Route
                path="enquiry/:enquiryId"
                element={<AdminEnquiry />}
            />
            <Route
                path="entities"
                element={<AdminEntities />}
            />
            <Route
                path="inspections"
                element={<AdminInspections />}
            />
            <Route
                path="offers"
                element={<AdminOffers />}
            />
            <Route
                path="uploaded-contract/:contractId"
                element={<AdminUploadedContract />}
            />
            <Route
                path="uploaded-contracts"
                element={<AdminUploadedContracts />}
            />
            <Route
                path="users"
                element={<AdminUsers />}
            />
            <Route
                path="users/:userId"
                element={<AdminUser />}
            />
        </Route>
    </Route>
);

const account = (
    <Route element={<LayoutV1Wrapper />}>
        <Route
            path="account"
            element={<PlatformScreens.Account.Screen />}
        >
            <Route
                index
                element={<PlatformScreens.Account.Details />}
            />

            <Route
                path="*"
                element={<PlatformScreens.Account.Details />}
            />
            <Route
                path="team"
                element={<CompanyTeam />}
            />
            <Route
                path="valuer-panel"
                element={<ValuerPanel />}
            />

            <Route
                path="documents"
                element={<CompanyDocuments />}
            >
                <Route
                    path="email"
                    element={<EmailSharedDocs />}
                />
            </Route>

            <Route
                path="customize"
                element={<CompanyCustomize />}
            />

            <Route
                path="integration"
                element={<Integration />}
            >
                <Route
                    path="enquiry-email-link/:isCompanyOrIndividual"
                    element={<EnquiryEmailSlider />}
                />
            </Route>
        </Route>
    </Route>
);

const notifications = (
    <Route
        path="notifications"
        element={<NotificationsScreen />}
    />
);

const profile = (
    <Route element={<LayoutV1Wrapper />}>
        <Route
            path="profile"
            element={<PlatformScreens.Profile.Screen />}
        >
            <Route
                index
                element={<PlatformScreens.Profile.Details />}
            />

            <Route
                path="*"
                element={<PlatformScreens.Profile.Details />}
            />

            {REACT_APP_FEATURE_ENABLE_DMS === "true" && (
                <Route
                    path="templates"
                    element={<ProfileTemplates />}
                />
            )}
        </Route>
    </Route>
);

const profileCompletion = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="complete-profile"
            element={<ProfileCompletionWizard />}
        >
            <Route
                index
                element={<ProfileCompletionLanding />}
            />

            <Route
                path="company"
                element={<ProfileCompletionBilling />}
            />

            <Route
                path="personal"
                element={<ProfileCompletionContactDetails />}
            />

            <Route
                path="qualifications"
                element={<ProfileCompletionQualifications />}
            />

            <Route
                path="valuationOffer"
                element={<ProfileCompletionCompetencies />}
            />

            <Route
                path="extras"
                element={<ProfileCompletionExtras />}
            />
        </Route>
    </Route>
);

const enquiryCreationRoutes = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="create-client-enquiry"
            element={<ClientEnquiryBox />}
        >
            <Route
                index
                element={<EnquiryValuationInfo />}
            />

            <Route
                path="enquiry-valuation-info/:enquiryId"
                element={<EnquiryValuationInfo />}
            />
            <Route
                path="enquiry-valuer-info/:enquiryId"
                element={<EnquiryValuerInfo />}
            />
            <Route
                path="enquiry-property-info/:enquiryId"
                element={<EnquiryPropertyInfo />}
            />
            <Route
                path="enquiry-invoice-info/:enquiryId"
                element={<EnquiryInvoiceInfo />}
            />
            <Route
                path="enquiry-overview-info/:enquiryId"
                element={<EnquiryOverviewInfo />}
            />
        </Route>

        <Route
            path="enquiry-success/:enquiryId"
            element={<PlatformScreens.EnquirySuccess />}
        />

        <Route
            path="enquiry-success/:enquiryId/:redirectUrl"
            element={<PlatformScreens.EnquirySuccess />}
        />
    </Route>
);

const common_routes = {
    dashboard: (
        <>
            {notifications}

            {profile}

            {account}
        </>
    ),
    root: (
        <>
            <Route
                path="/logout"
                element={<Logout />}
            />

            <Route element={<ThemeV1Wrapper />}>
                <Route
                    path="register-success"
                    element={<RegisterSuccess />}
                />
            </Route>

            <Route element={<ThemeV1Wrapper />}>
                <Route
                    path="login"
                    element={<div>user-login</div>}
                />
            </Route>

            {enquiryCreationRoutes}

            {profileCompletion}

            {global_routes}

            {sysadmin}
        </>
    ),
};

type RoutesProps = {
    user: User;
};

// actual, user-type-speficic routes

const ENQUIRY_ROUTE_BASE = "/dashboard/enquiry/";

const ClientRoutes = (props: RoutesProps) => {
    const { user } = props;

    return (
        <Routes>
            <Route element={<PlatformScreen />}>
                <Route
                    path="/"
                    element={<ClientScreens.Dashboard />}
                />
                <Route
                    path="dashboard"
                    element={<Outlet />}
                >
                    <Route
                        index
                        element={<ClientScreens.Dashboard />}
                    />
                    <Route
                        path="enquiries"
                        element={
                            <ClientScreens.EnquiryList prefilter={"ACTIVE"} />
                        }
                    />
                    <Route
                        path="enquiries/archived"
                        element={
                            <ClientScreens.EnquiryList prefilter={"ARCHIVED"} />
                        }
                    />
                    <Route
                        path="enquiries/draft"
                        element={
                            <ClientScreens.EnquiryList prefilter={"DRAFT"} />
                        }
                    />
                    <Route
                        path={`${ENQUIRY_ROUTE_BASE}:enquiryId`}
                        element={<ClientScreens.EnquiryDetails />}
                    />
                    <Route
                        path={`${ENQUIRY_ROUTE_BASE}:enquiryId/offer/:offerId`}
                        element={<ClientScreens.OfferDetails />}
                    />
                    <Route
                        path="/dashboard/case/:caseId"
                        element={
                            <Case.RedirectToEnquiry
                                createEnquiryRoute={(id) =>
                                    `${ENQUIRY_ROUTE_BASE}${id}`
                                }
                            />
                        }
                    />

                    {common_routes.dashboard}
                </Route>
            </Route>

            {common_routes.root}
        </Routes>
    );
};

const ValuerRoutes = (props: RoutesProps) => {
    const { user } = props;

    return (
        <Routes>
            <Route element={<PlatformScreen />}>
                <Route
                    path="/"
                    element={<ValuerScreens.Dashboard />}
                />
                <Route
                    path="dashboard"
                    element={<Outlet />}
                >
                    <Route
                        index
                        element={<ValuerScreens.Dashboard />}
                    />
                    <Route
                        path="enquiries"
                        element={
                            <ValuerScreens.EnquiryList prefilter={"ACTIVE"} />
                        }
                    />
                    <Route
                        path="enquiries/archived"
                        element={
                            <ValuerScreens.EnquiryList prefilter={"ARCHIVED"} />
                        }
                    />
                    <Route
                        path={`${ENQUIRY_ROUTE_BASE}:enquiryId`}
                        element={
                            <ValuerEnquiryDetailsScreen.EnquiryDetails
                                user={user}
                            />
                        }
                    />
                    <Route
                        path="/dashboard/case/:caseId"
                        element={
                            <Case.RedirectToEnquiry
                                createEnquiryRoute={(id) =>
                                    `${ENQUIRY_ROUTE_BASE}${id}`
                                }
                            />
                        }
                    />

                    {common_routes.dashboard}
                </Route>
            </Route>

            {common_routes.root}
        </Routes>
    );
};

export const RoutesUser = () => {
    const user = useAuthedUser();

    const isClient = user.entity.accountType === "CLIENT";

    return isClient ? (
        <ClientRoutes user={user} />
    ) : (
        <ValuerRoutes user={user} />
    );
};
